import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { CalendarOutlined, CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, ConfigProvider, DatePicker, message, Radio, Select, TimePicker } from 'antd';
import {
  CREATE_SEASON_TICKET,
  GET_ACTIVE_MATCHES,
  GET_SEASON_TICKET_INFO,
  GET_SEASON_TICKET_INFO_LIST,
  GET_SEASONS_INFO_LIST,
  GET_STADIUMS,
  GET_STAGES,
  GET_TEMPLATES,
  GET_TOURNAMENTS_INFO_LIST,
  RECALCULATE_SEASON_TICKET,
  UPDATE_SEASON_TICKET,
} from '../graphql';
import { withApollo } from 'react-apollo';
import { copyTextToClipboard } from 'utils/copyTextToClipboard';
import './index.scss';
import TextEditor from 'common/TextEditor/TextEditorComponent';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from 'utils/LocalizedString';
import LocalizedSelect from 'common/LocalizedSelect/LocalizedSelect';
import i18next from 'i18next';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';
import dayjs from 'dayjs';
import { joinUriPath } from 'utils/apiHelpers';

const SelectOption = Select.Option;

const SeasonTicketsComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    selectedSeasonTicket: {},
    newSeasonTicket: {},
  });

  const restoreState = () => {
    setState({
      selectedSeasonTicket: {},
    });
  };

  const getNewValue = (string) => {
    return pathOr(undefined, ['newSeasonTicket', string], stateProps);
  };

  const setUpdating = (bool) => {
    setState({
      ...stateProps,
      isUpdatingProcessing: bool,
    });
  };

  const recalculateSeasonTicketPlaces = async () => {
    try {
      const res = await props.client.query({
        query: RECALCULATE_SEASON_TICKET,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            seasonTicketDescriptorIds: [stateProps.selectedSeasonTicket.id],
          },
        },
      });
      return pathOr('', ['data', 'seasonTicket', 'recalculatePlaceStatus'], res);
    } catch (e) {
      console.error(e);
      message.error(t('modules.season_tickets_component.message_error.subscription_update_error'));
    }
  };

  const createDataObj = () => {
    const title = getNewValue('title');
    const season = getNewValue('season');
    const tournaments = getNewValue('tournaments');
    const stages = getNewValue('stages');
    const matches = getNewValue('matches');
    let gracePeriod = getNewValue('gracePeriod');
    if (!stateProps.selectedSeasonTicket.canProlong) {
      gracePeriod = null;
    }
    const statusRadioValue = getNewValue('statusRadioValue');
    let parentDescriptor = getNewValue('parentDescriptor');
    if (!stateProps.selectedSeasonTicket.canBeProlonged) {
      parentDescriptor = null;
    }
    const pricetemplate = getNewValue('priceTemplate');
    const venue = getNewValue('venue');
    const periodOfActingRadioValue = getNewValue('periodOfActingRadioValue');
    const description = getNewValue('description');

    const getParentIds = () => {
      if (parentDescriptor) {
        return [parentDescriptor.id]
      } else if (parentDescriptor === null) {
        return null
      } else {
        return undefined
      }
    }

    return {
      description: description,
      title: title || undefined,
      seasonIds: periodOfActingRadioValue === 2 ? [] : season ? [season.id] : undefined,
      tournamentIds: tournaments ? tournaments.map((item) => item.id) : undefined,
      stageIds: stages ? stages.map((item) => item.id) : undefined,
      matchIds: periodOfActingRadioValue === 1 ? [] : matches ? matches.map((item) => item.id) : undefined,
      gracePeriod,
      status:
        statusRadioValue === 3
          ? 'PROLONG_ONLY'
          : statusRadioValue === 2 || statusRadioValue === 4
          ? 'ACTIVE'
          : statusRadioValue !== 1 && !stateProps.isNewSeasonTicket
          ? undefined
          : 'NOT_ACTIVE',
      hidden: statusRadioValue === 4,
      parentIds: getParentIds(),
      priceTemplateId: pricetemplate ? pricetemplate.id : undefined,
      venueId: venue ? venue.id : undefined,
    };
  };

  const createSeasonTicketDescriptor = async () => {
    if (!getNewValue('title')) {
      message.error(t('modules.season_tickets_component.message_error.enter_subscription_name'));
      setUpdating(false);
    } else if (!getNewValue('priceTemplate')) {
      message.error(t('modules.season_tickets_component.message_error.choose_pricing_template'));
      setUpdating(false);
    } else if (!getNewValue('venue')) {
      message.error(t('modules.season_tickets_component.message_error.select_stadium'));
      setUpdating(false);
    } else if (!getNewValue('matches') && !getNewValue('season')) {
      message.error(t('modules.season_tickets_component.message_error.select_season_matches'));
      setUpdating(false);
    } else {
      try {
        await props.client.query({
          query: CREATE_SEASON_TICKET,
          fetchPolicy: 'no-cache',
          variables: {
            data: createDataObj(),
          },
        });
        message.success(t('modules.season_tickets_component.message_success.subscription_created'));
      } catch (e) {
        console.error(e);
        message.error(t('modules.season_tickets_component.message_error.subscription_creation_error'));
      } finally {
        restoreState();
      }
    }
  };

  const updateSeasonTicketDescriptor = async () => {
    try {
      await props.client.query({
        query: UPDATE_SEASON_TICKET,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedSeasonTicket.id,
          data: createDataObj(),
        },
      });
      message.success(t('modules.season_tickets_component.message_success.subscription_updated'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.season_tickets_component.message_error.subscription_update_error'));
    } finally {
      await recalculateSeasonTicketPlaces();
      restoreState();
    }
  };

  const getSeasonTicket = async (id) => {
    try {
      const seasonTicketRes = await props.client.query({
        query: GET_SEASON_TICKET_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          id,
        },
      });

      const selectedSeasonTicket = pathOr({}, ['data', 'seasonTicketDescriptor', 'getById'], seasonTicketRes);

      setState({
        ...stateProps,
        isNewSeasonTicket: false,
        newSeasonTicket: {},
        selectedSeasonTicket: {
          ...selectedSeasonTicket,
          statusRadioValue:
            selectedSeasonTicket.status === 'PROLONG_ONLY'
              ? 3
              : selectedSeasonTicket.status === 'ACTIVE'
              ? selectedSeasonTicket.hidden
                ? 4
                : 2
              : 1,
          matches: selectedSeasonTicket.matches || [],
          stages: selectedSeasonTicket.stages || [],
          season: selectedSeasonTicket.seasons
            ? {
                ...selectedSeasonTicket.seasons[0],
                title:
                  new Date(selectedSeasonTicket.seasons[0].startDate).getFullYear() +
                  '-' +
                  new Date(selectedSeasonTicket.seasons[0].endDate).getFullYear(),
              }
            : null,
          parentDescriptor: selectedSeasonTicket.parentDescriptors
            ? {
                ...selectedSeasonTicket.parentDescriptors[0],
              }
            : null,
          canProlong: Boolean(selectedSeasonTicket.gracePeriod),
          canBeProlonged: Boolean(selectedSeasonTicket.parentDescriptors),
          periodOfActingRadioValue:
            selectedSeasonTicket.seasons && selectedSeasonTicket.matches ? 3 : selectedSeasonTicket.seasons ? 1 : 2,
          tournamentsRadioValue: selectedSeasonTicket.tournaments ? 2 : 1,
          tournaments: selectedSeasonTicket.tournaments
            ? selectedSeasonTicket.tournaments.map((item) => ({
                ...item,
                stages: stateProps.tournamentsArr.find((tournament) => {
                  return tournament.id === item.id;
                }).stages,
                radioValue: selectedSeasonTicket.stages
                  ? selectedSeasonTicket.stages.find((stage) => {
                      return stage.tournament.id === item.id;
                    })
                    ? 2
                    : 1
                  : 1,
              }))
            : [],
        },
        isUpdated: true,
      });
    } catch (e) {
      message.error(t('modules.season_tickets_component.message_error.subscription_download_error'));
      console.error(e);
    }
  };

  const updateSeasonTicket = (key, value) => {
    setState({
      ...stateProps,
      selectedSeasonTicket: {
        ...stateProps.selectedSeasonTicket,
        [key]: value,
      },
      newSeasonTicket: {
        ...stateProps.newSeasonTicket,
        [key]: value,
      },
    });
  };

  const buildISOString = (dateValue, timeValue, prevDate) => {
    const date = new Date(dateValue ? dateValue : prevDate);
    const time = new Date(timeValue ? timeValue : prevDate);
    const hours = time.getHours();
    const minutes = time.getMinutes();

    date.setHours(hours);
    date.setMinutes(minutes);

    return date.toISOString();
  };

  useEffect(() => {
    const init = async () => {
      const seasonTicketRes = await props.client.query({
        query: GET_SEASON_TICKET_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const seasonTicketArr = pathOr([], ['data', 'seasonTicketDescriptor', 'getList', 'list'], seasonTicketRes);

      const seasonsRes = await props.client.query({
        query: GET_SEASONS_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const seasonsArr = pathOr([], ['data', 'season', 'getList', 'list'], seasonsRes);

      const matchesRes = await props.client.query({
        query: GET_ACTIVE_MATCHES,
        fetchPolicy: 'no-cache',
      });

      const matchesArr = pathOr([], ['data', 'matchLocalised', 'getList', 'list'], matchesRes).map((item) => {
        return {
          ...item,
          title: LocalizedString.fromObject(item.title),
          description: LocalizedString.fromObject(item.description),
        };
      });

      const tournamentsRes = await props.client.query({
        query: GET_TOURNAMENTS_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const tournamentsArr = pathOr([], ['data', 'tournamentLocalised', 'getList', 'list'], tournamentsRes);

      const stagesRes = await props.client.query({
        query: GET_STAGES,
        fetchPolicy: 'no-cache',
      });

      const stagesArr = pathOr([], ['data', 'stageLocalised', 'getList', 'list'], stagesRes);

      const templatesRes = await props.client.query({
        query: GET_TEMPLATES,
        fetchPolicy: 'no-cache',
      });

      const templatesArr = pathOr([], ['data', 'priceTemplate', 'getList', 'list'], templatesRes);

      const stadiumsRes = await props.client.query({
        query: GET_STADIUMS,
        fetchPolicy: 'no-cache',
      });

      const stadiumsArr = pathOr([], ['data', 'venueLocalised', 'getList', 'list'], stadiumsRes);

      setState({
        ...stateProps,
        isLoaded: true,
        seasonTicketArr,
        seasonsArr: seasonsArr.map((season) => ({
          ...season,
          title: new Date(season.startDate).getFullYear() + '-' + new Date(season.endDate).getFullYear(),
        })),
        matchesArr,
        tournamentsArr,
        stagesArr,
        templatesArr,
        stadiumsArr,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  const getDefaultValue = (state, option) => {
    if (state.selectedSeasonTicket.gracePeriod !== null) {
      if (option === 'start') {
        return dayjs(state.selectedSeasonTicket.gracePeriod.start)
      } else {
        return dayjs(state.selectedSeasonTicket.gracePeriod.end)
      }
    }
  }
  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <h1>{t('modules.season_tickets_component.h1')}</h1>

        <div className="events">
          {stateProps.isLoaded ? (
            <>
              <div className="events__left-container">
                {stateProps.isNewSeasonTicket && (
                  <div className="events__left-container__item-active">
                    <div className="events__left-container__item__info-container__top-section">
                      <div className="events__left-container__item__info-container__top-section__date">
                        {t('modules.season_tickets_component.div.new_subscription')}
                      </div>
                    </div>
                  </div>
                )}
                {stateProps.seasonTicketArr.map((seasonTicket) => {
                  return (
                    <div
                      className={
                        stateProps.selectedSeasonTicket.id === seasonTicket.id
                          ? 'events__left-container__item-active'
                          : 'events__left-container__item'
                      }
                      onClick={() => {
                        getSeasonTicket(seasonTicket.id);
                      }}
                    >
                      <div className="events__left-container__item__info-container__top-section">
                        <div className="events__left-container__item__info-container__top-section__date">
                          {seasonTicket.title}
                        </div>
                        {seasonTicket.status === 'ACTIVE' ||
                        seasonTicket.status === 'NOT_ACTIVE' ||
                        seasonTicket.status === 'PROLONG_ONLY' ||
                        seasonTicket.hidden ? (
                          <div
                            className="events__left-container__item__info-container__top-section__status"
                            style={{
                              color:
                                seasonTicket.status === 'PROLONG_ONLY'
                                  ? '#8E8E8E'
                                  : seasonTicket.hidden
                                  ? '#F8A608'
                                  : seasonTicket.status === 'ACTIVE'
                                  ? '#03B94E'
                                  : '#D21939',
                              borderColor:
                                seasonTicket.status === 'PROLONG_ONLY'
                                  ? '#8E8E8E'
                                  : seasonTicket.hidden
                                  ? '#F8A608'
                                  : seasonTicket.status === 'ACTIVE'
                                  ? '#03B94E'
                                  : '#D21939',
                            }}
                          >
                            {seasonTicket.status === 'PROLONG_ONLY'
                              ? t('modules.season_tickets_component.div.for_renewal')
                              : seasonTicket.hidden
                              ? t('modules.season_tickets_component.div.link')
                              : seasonTicket.status === 'ACTIVE'
                              ? t('modules.season_tickets_component.div.on_sale')
                              : t('modules.season_tickets_component.div.not_available')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* //////// */}

              {(!isEmpty(stateProps.selectedSeasonTicket) || stateProps.isNewSeasonTicket) && !stateProps.isUpdated && (
                <div className="events__right-container">
                  <div className="events__right-container__body">
                    <CloseOutlined
                      className="permissions__right-container__body__close-form-icon"
                      onClick={() => {
                        restoreState();
                      }}
                    />
                    <div className="permissions__right-container__body__switcher">
                      <div
                        className={
                          !stateProps.isDataVisible
                            ? 'permissions__right-container__body__switcher__item__active'
                            : 'permissions__right-container__body__switcher__item'
                        }
                        onClick={() => {
                          setState({
                            ...stateProps,
                            isDataVisible: false,
                          });
                        }}
                        style={{
                          borderRadius: '4px 0 0 4px',
                        }}
                      >
                        {t('modules.season_tickets_component.div.data')}
                      </div>
                      <div
                        className={
                          !stateProps.isDataVisible
                            ? 'permissions__right-container__body__switcher__item'
                            : 'permissions__right-container__body__switcher__item__active'
                        }
                        onClick={() => {
                          setState({
                            ...stateProps,
                            isDataVisible: true,
                          });
                        }}
                        style={{
                          borderRadius: '0 4px 4px 0',
                        }}
                      >
                        {t('modules.season_tickets_component.div.promo')}
                      </div>
                    </div>
                    {!stateProps.isDataVisible ? (
                      <>
                        <div className="events__right-container__body__title" style={{ marginTop: '0px' }}>
                          {t('modules.season_tickets_component.div.sale_status')}
                        </div>
                        <div className="season-ticket__status-container">
                          <Radio.Group
                            onChange={(ev) => {
                              updateSeasonTicket('statusRadioValue', ev.target.value);
                            }}
                            defaultValue={stateProps.selectedSeasonTicket.statusRadioValue || 1}
                            className="season-ticket__status-container__radio-container"
                          >
                            <Radio className="season-ticket__status-container__radio-container__text" value={1}>
                              {t('modules.season_tickets_component.radio.not_available')}
                            </Radio>
                            <Radio className="season-ticket__status-container__radio-container__text" value={2}>
                              {t('modules.season_tickets_component.radio.in_general_sale')}
                            </Radio>
                            <Radio className="season-ticket__status-container__radio-container__text" value={3}>
                              {t('modules.season_tickets_component.radio.only_extend_expiring')}
                            </Radio>
                            <Radio className="season-ticket__status-container__radio-container__text" value={4}>
                              {t('modules.season_tickets_component.radio.access_link_only')}
                            </Radio>
                          </Radio.Group>
                          <div
                            className="season-ticket__status-container__link-creator"
                            style={{
                              color:
                                stateProps.selectedSeasonTicket.statusRadioValue === 4 ||
                                !stateProps.selectedSeasonTicket.statusRadioValue
                                  ? '#03B94E'
                                  : '#C4C4C4',
                              cursor:
                                stateProps.selectedSeasonTicket.statusRadioValue === 4 ||
                                !stateProps.selectedSeasonTicket.statusRadioValue
                                  ? 'pointer'
                                  : 'default',
                            }}
                            onClick={() => {
                              if (stateProps.selectedSeasonTicket.statusRadioValue === 4) {
                                if (pathOr(null, ['selectedSeasonTicket', 'id'], stateProps)) {
                                  const url = joinUriPath(window.parametrize('REACT_APP_STORE_LINK'), 'season-tickets',
                                    stateProps.selectedSeasonTicket.id) + '?type=subscription';
                                  copyTextToClipboard(url);
                                  message.success(t('modules.season_tickets_component.message_success.link_copied'));
                                } else {
                                  message.error(
                                    t('modules.season_tickets_component.message_error.match_not_created_yet')
                                  );
                                }
                              }
                            }}
                          >
                            {t('modules.season_tickets_component.div.copy_link')}
                          </div>
                        </div>
                        <div className="events__right-container__body__title">
                          {t('modules.season_tickets_component.div.subscription_name')}
                        </div>
                        <input
                          className="events__right-container__body__input"
                          defaultValue={stateProps.selectedSeasonTicket.title}
                          placeholder={t('modules.season_tickets_component.input')}
                          onBlur={(ev) => {
                            updateSeasonTicket('title', ev.target.value);
                          }}
                        />
                        <div className="events__right-container__body__title">
                          {t('modules.season_tickets_component.div.price_template')}
                        </div>
                        <Select
                          placeholder={t('modules.season_tickets_component.select.choose_price_template')}
                          defaultValue={
                            stateProps.selectedSeasonTicket.priceTemplate
                              ? stateProps.selectedSeasonTicket.priceTemplate.id
                              : undefined
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            updateSeasonTicket(
                              'priceTemplate',
                              stateProps.templatesArr.find((item) => {
                                return item.id === ev;
                              })
                            );
                          }}
                        >
                          {stateProps.templatesArr.map((item) => {
                            return <SelectOption value={item.id}>{item.title.toUpperCase()}</SelectOption>;
                          })}
                        </Select>
                        <div className="events__right-container__body__title">
                          {t('modules.season_tickets_component.div.stadium')}
                        </div>
                        <Select
                          placeholder={t('modules.season_tickets_component.select.choose_stadium')}
                          defaultValue={
                            stateProps.selectedSeasonTicket.venue ? stateProps.selectedSeasonTicket.venue.id : undefined
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            updateSeasonTicket(
                              'venue',
                              stateProps.stadiumsArr.find((item) => {
                                return item.id === ev;
                              })
                            );
                          }}
                        >
                          {stateProps.stadiumsArr.map((item) => {
                            return (
                              <SelectOption value={item.id}>
                                {LocalizedString.fromObject(item.title).toString().toUpperCase()}
                              </SelectOption>
                            );
                          })}
                        </Select>
                        <div className="events__right-container__body__title">
                          {t('modules.season_tickets_component.div.renewal_settings')}
                        </div>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Checkbox
                            defaultChecked={stateProps.selectedSeasonTicket.canProlong}
                            className="events__right-container__body__checkbox"
                            onChange={(ev) => {
                              updateSeasonTicket('canProlong', ev.target.checked);
                            }}
                          >
                            {t('modules.season_tickets_component.checkbox.subscription_extended')}
                          </Checkbox>
                        </div>
                        {stateProps.selectedSeasonTicket.canProlong && (
                          <div className="events__right-container__body__range-container">
                            {t('modules.season_tickets_component.div.period')}:
                            <DatePicker
                              className="events__right-container__body__date-container__item"
                              defaultValue={getDefaultValue(stateProps, 'start')}
                              placeholder={t('modules.season_tickets_component.date_picker')}
                              format="DD.MM.YYYY"
                              suffixIcon={<CalendarOutlined />}
                              onChange={(ev) => {
                                updateSeasonTicket('gracePeriod', {
                                  start: buildISOString(
                                    ev,
                                    null,
                                    stateProps.selectedSeasonTicket.gracePeriod
                                      ? stateProps.selectedSeasonTicket.gracePeriod.start
                                      : new Date()
                                  ),
                                  end: stateProps.selectedSeasonTicket.gracePeriod
                                    ? stateProps.selectedSeasonTicket.gracePeriod.end
                                    : new Date(),
                                });
                              }}
                            />
                            <TimePicker
                              placeholder={t('modules.season_tickets_component.time_picker')}
                              defaultValue={getDefaultValue(stateProps, 'start')}
                              className="events__right-container__body__date-container__item"
                              format="HH:mm"
                              minuteStep={5}
                              onChange={(ev) => {
                                updateSeasonTicket('gracePeriod', {
                                  start: buildISOString(
                                    null,
                                    ev,
                                    stateProps.selectedSeasonTicket.gracePeriod
                                      ? stateProps.selectedSeasonTicket.gracePeriod.start
                                      : new Date()
                                  ),
                                  end: stateProps.selectedSeasonTicket.gracePeriod
                                    ? stateProps.selectedSeasonTicket.gracePeriod.end
                                    : new Date(),
                                });
                              }}
                            />
                            —
                            <DatePicker
                              className="events__right-container__body__date-container__item"
                              placeholder={t('modules.season_tickets_component.date_picker')}
                              defaultValue={getDefaultValue(stateProps)}
                              format="DD.MM.YYYY"
                              suffixIcon={<CalendarOutlined />}
                              onChange={(ev) => {
                                updateSeasonTicket('gracePeriod', {
                                  start: stateProps.selectedSeasonTicket.gracePeriod
                                    ? stateProps.selectedSeasonTicket.gracePeriod.start
                                    : new Date(),
                                  end: buildISOString(
                                    ev,
                                    null,
                                    stateProps.selectedSeasonTicket.gracePeriod
                                      ? stateProps.selectedSeasonTicket.gracePeriod.end
                                      : new Date()
                                  ),
                                });
                              }}
                            />
                            <TimePicker
                              defaultValue={getDefaultValue(stateProps)}
                              placeholder={t('modules.season_tickets_component.time_picker')}
                              className="events__right-container__body__date-container__item"
                              format="HH:mm"
                              minuteStep={5}
                              onChange={(ev) => {
                                updateSeasonTicket('gracePeriod', {
                                  start: stateProps.selectedSeasonTicket.gracePeriod
                                    ? stateProps.selectedSeasonTicket.gracePeriod.start
                                    : new Date(),
                                  end: buildISOString(
                                    null,
                                    ev,
                                    stateProps.selectedSeasonTicket.gracePeriod
                                      ? stateProps.selectedSeasonTicket.gracePeriod.end
                                      : new Date()
                                  ),
                                });
                              }}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Checkbox
                            defaultChecked={stateProps.selectedSeasonTicket.canBeProlonged}
                            className="events__right-container__body__checkbox"
                            onChange={(ev) => {
                              updateSeasonTicket('canBeProlonged', ev.target.checked);
                            }}
                          >
                            {t('modules.season_tickets_component.checkbox.subscription_extend_another')}
                          </Checkbox>
                        </div>
                        {stateProps.selectedSeasonTicket.canBeProlonged ? (
                          <div className="events__right-container__body__select-container">
                            <Select
                              placeholder={t('modules.season_tickets_component.select.choose_renewable_subscription')}
                              defaultValue={
                                stateProps.selectedSeasonTicket.parentDescriptor
                                  ? stateProps.selectedSeasonTicket.parentDescriptor.id
                                  : undefined
                              }
                              className="events__right-container__body__select"
                              onChange={(ev) => {
                                updateSeasonTicket(
                                  'parentDescriptor',
                                  stateProps.seasonTicketArr.find((item) => {
                                    return item.id === ev;
                                  })
                                );
                              }}
                            >
                              {stateProps.seasonTicketArr.map((item) => {
                                return <SelectOption value={item.id}>{item.title.toUpperCase()}</SelectOption>;
                              })}
                            </Select>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="events__right-container__body__title">
                          {t('modules.season_tickets_component.div.event_period')}
                        </div>
                        <Radio.Group
                          onChange={(ev) => {
                            updateSeasonTicket('periodOfActingRadioValue', ev.target.value);
                          }}
                          defaultValue={stateProps.selectedSeasonTicket.periodOfActingRadioValue}
                          className="events__right-container__body__radio"
                          style={{
                            marginBottom: '0',
                          }}
                        >
                          <Radio
                            className="events__right-container__body__radio__text"
                            value={1}
                            onClick={() => {
                              updateSeasonTicket('matches', []);
                            }}
                          >
                            {t('modules.season_tickets_component.radio.season')}
                          </Radio>
                          <Radio
                            className="events__right-container__body__radio__text"
                            value={2}
                            onClick={() => {
                              updateSeasonTicket('season', {});
                            }}
                          >
                            {t('modules.season_tickets_component.radio.all')}
                          </Radio>
                          <Radio className="events__right-container__body__radio__text" value={3}>
                            {t('modules.season_tickets_component.radio.select_multiple')}
                          </Radio>
                        </Radio.Group>
                        {(stateProps.selectedSeasonTicket.periodOfActingRadioValue === 1 ||
                          stateProps.selectedSeasonTicket.periodOfActingRadioValue === 3) && (
                          <>
                            <div className="events__right-container__body__title">
                              {t('modules.season_tickets_component.div.incoming_season')}
                            </div>
                            <div className="events__right-container__body__select-container">
                              <Select
                                placeholder={t('modules.season_tickets_component.select.choose_season')}
                                defaultValue={
                                  stateProps.selectedSeasonTicket.season
                                    ? stateProps.selectedSeasonTicket.season.title
                                    : undefined
                                }
                                className="events__right-container__body__select"
                                onChange={(ev) => {
                                  updateSeasonTicket(
                                    'season',
                                    stateProps.seasonsArr.find((season) => {
                                      return season.id === ev;
                                    })
                                  );
                                }}
                              >
                                {/* !!!!! */}
                                {stateProps.seasonsArr.map((item) => {
                                  return <SelectOption value={item.id}>{item.title.toUpperCase()}</SelectOption>;
                                })}
                              </Select>
                              <div className="events__right-container__body__title">
                                {t('modules.season_tickets_component.div.incoming_tournaments')}
                              </div>
                              <Radio.Group
                                onChange={(ev) => {
                                  updateSeasonTicket('tournamentsRadioValue', ev.target.value);
                                  setState({
                                    ...stateProps,
                                    selectedSeasonTicket: {
                                      ...stateProps.selectedSeasonTicket,
                                      tournamentsRadioValue: ev.target.value,
                                      tournaments: [],
                                    },
                                    newSeasonTicket: {
                                      ...stateProps.newSeasonTicket,
                                      tournamentsRadioValue: ev.target.value,
                                      tournaments: [],
                                    },
                                  });
                                }}
                                defaultValue={
                                  stateProps.selectedSeasonTicket.tournamentsRadioValue ||
                                  (stateProps.isNewSeasonTicket && 1)
                                }
                                className="events__right-container__body__radio"
                                style={{
                                  marginBottom: '12px',
                                  width: '100%',
                                }}
                              >
                                <Radio className="events__right-container__body__radio__text" value={1}>
                                  {t('modules.season_tickets_component.radio.all_stages')}
                                </Radio>
                                <Radio className="events__right-container__body__radio__text" value={2}>
                                  {t('modules.season_tickets_component.radio.add_multiple_steps')}
                                </Radio>
                              </Radio.Group>
                              {stateProps.selectedSeasonTicket.tournamentsRadioValue === 2 && (
                                <>
                                  <Select
                                    value={t('modules.season_tickets_component.select.choose_tournament')}
                                    className="events__right-container__body__select"
                                    onChange={(ev) => {
                                      updateSeasonTicket('tournaments', [
                                        ...stateProps.selectedSeasonTicket.tournaments.filter((item) => {
                                          return item.id !== ev;
                                        }),
                                        stateProps.tournamentsArr.find((item) => {
                                          return item.id === ev;
                                        }),
                                      ]);
                                    }}
                                  >
                                    {stateProps.tournamentsArr.map((item) => {
                                      return (
                                        <SelectOption value={item.id}>
                                          {LocalizedString.fromObject(item.title)
                                            .toString(i18next.language)
                                            .toUpperCase()}
                                        </SelectOption>
                                      );
                                    })}
                                  </Select>
                                  {stateProps.selectedSeasonTicket.tournaments.map((tournament) => {
                                    return (
                                      <div className="events__right-container__body__tournament-container">
                                        <CloseOutlined
                                          className="events__right-container__body__tournament-container__close-icon"
                                          onClick={() => {
                                            updateSeasonTicket(
                                              'tournaments',
                                              stateProps.selectedSeasonTicket.tournaments.filter((item) => {
                                                return item.id !== tournament.id;
                                              })
                                            );
                                          }}
                                        />
                                        <div className="events__right-container__body__tournament-container__title">
                                          {LocalizedString.fromObject(tournament.title).toString(i18next.language)}
                                        </div>
                                        <Radio.Group
                                          onChange={(ev) => {
                                            const tournaments = stateProps.selectedSeasonTicket.tournaments.map(
                                              (item) => {
                                                if (item.id === tournament.id) {
                                                  return {
                                                    ...item,
                                                    radioValue: ev.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            const stages = stateProps.selectedSeasonTicket.stages.filter((stage) => {
                                              return tournament.id !== stage.tournament.id;
                                            });
                                            setState({
                                              ...stateProps,
                                              selectedSeasonTicket: {
                                                ...stateProps.selectedSeasonTicket,
                                                tournaments,
                                                stages:
                                                  ev.target.value === 1
                                                    ? stages
                                                    : stateProps.selectedSeasonTicket.stages,
                                              },
                                              newSeasonTicket: {
                                                ...stateProps.newSeasonTicket,
                                                tournaments,
                                                stages:
                                                  ev.target.value === 1
                                                    ? stages
                                                    : stateProps.selectedSeasonTicket.stages,
                                              },
                                            });
                                          }}
                                          defaultValue={tournament.radioValue || 1}
                                          className="events__right-container__body__radio"
                                          style={{
                                            marginBottom: '4px',
                                            width: '100%',
                                          }}
                                        >
                                          <Radio className="events__right-container__body__radio__text" value={1}>
                                            {t('modules.season_tickets_component.radio.all_stages')}
                                          </Radio>
                                          <Radio className="events__right-container__body__radio__text" value={2}>
                                            {t('modules.season_tickets_component.radio.add_multiple_steps')}
                                          </Radio>
                                        </Radio.Group>
                                        {tournament.radioValue === 2 && (
                                          <Select
                                            value={t('modules.season_tickets_component.select.add_stages')}
                                            className="events__right-container__body__select"
                                            onChange={(ev) => {
                                              updateSeasonTicket('stages', [
                                                ...stateProps.selectedSeasonTicket.stages.filter((item) => {
                                                  return item.id !== ev;
                                                }),
                                                {
                                                  ...stateProps.stagesArr.find((item) => {
                                                    return item.id === ev;
                                                  }),
                                                  tournament: {
                                                    id: tournament.id,
                                                  },
                                                },
                                              ]);
                                            }}
                                          >
                                            {tournament.stages.map((item) => {
                                              return (
                                                <SelectOption value={item.id}>
                                                  {LocalizedString.fromObject(pathOr('', ['title'], item))
                                                    .toString(i18next.language)
                                                    .toUpperCase()}
                                                </SelectOption>
                                              );
                                            })}
                                          </Select>
                                        )}
                                        {stateProps.selectedSeasonTicket.stages &&
                                          stateProps.selectedSeasonTicket.stages.map(
                                            (item) =>
                                              item.tournament.id === tournament.id && (
                                                <div className="events__right-container__body__stage-container">
                                                  <div className="events__right-container__body__stage-container__text">
                                                    {LocalizedString.fromObject(item.title)
                                                      .toString(i18next.language)
                                                      .toUpperCase()}
                                                  </div>
                                                  <CloseOutlined
                                                    className="events__right-container__body__stage-container__close-icon"
                                                    onClick={() => {
                                                      updateSeasonTicket(
                                                        'stages',
                                                        stateProps.selectedSeasonTicket.stages.filter((stage) => {
                                                          return item.id !== stage.id;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )
                                          )}
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {(stateProps.selectedSeasonTicket.periodOfActingRadioValue === 2 ||
                          stateProps.selectedSeasonTicket.periodOfActingRadioValue === 3) && (
                          <>
                            <div className="events__right-container__body__title">
                              {t('modules.season_tickets_component.div.incoming_matches')}
                            </div>
                            <LocalizedSelect
                              data={stateProps.matchesArr}
                              currentLng={i18next.language}
                              defaultValue={stateProps.matchesArr}
                              placeholder={t('modules.season_tickets_component.select.choose_match')}
                              className="events__right-container__body__select"
                              onChange={(ev) => {
                                updateSeasonTicket('matches', [
                                  ...stateProps.selectedSeasonTicket.matches.filter((item) => {
                                    return item.id !== ev;
                                  }),
                                  stateProps.matchesArr.find((item) => {
                                    return item.id === ev;
                                  }),
                                ]);
                              }}
                            />

                            {stateProps.selectedSeasonTicket.matches &&
                              stateProps.selectedSeasonTicket.matches.map((match) => {
                                return (
                                  <div
                                    className={'events__left-container__item'}
                                    style={{
                                      cursor: 'default',
                                    }}
                                  >
                                    <CloseOutlined
                                      className="events__left-container__item__close-icon"
                                      onClick={() => {
                                        updateSeasonTicket(
                                          'matches',
                                          stateProps.selectedSeasonTicket.matches.filter((item) => {
                                            return item.id !== match.id;
                                          })
                                        );
                                      }}
                                    />
                                    <div
                                      className="events__left-container__item__circle"
                                      style={{
                                        backgroundImage: `url(${match.team2.logo ? match.team2.logo.publicLink : ''})`,
                                      }}
                                    />
                                    <div className="events__left-container__item__info-container">
                                      <div className="events__left-container__item__info-container__top-section">
                                        <div className="events__left-container__item__info-container__top-section__date">
                                          {new Intl.DateTimeFormat(i18next.language).format(new Date(match.startTime)) +
                                            ', ' +
                                            new Intl.DateTimeFormat(i18next.language, {
                                              hour: '2-digit',
                                              minute: '2-digit',
                                            }).format(new Date(match.startTime))}
                                        </div>
                                      </div>
                                      <div className="events__left-container__item__info-container__bottom-section">
                                        {LocalizedString.fromObject(pathOr('', ['team1', 'title'], match)).toString(
                                          i18next.language
                                        ) +
                                          ' vs ' +
                                          LocalizedString.fromObject(pathOr('', ['team2', 'title'], match)).toString(
                                            i18next.language
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className="events__right-container__body__title" style={{ marginBottom: 0 }}>
                          {t('modules.season_tickets_component.h3')}
                        </h3>
                        <TextEditor
                          initialValue={stateProps.selectedSeasonTicket.description}
                          onChange={(value) => {
                            updateSeasonTicket('description', value);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="events__right-container__footer">
                    <Button
                      id="SeasonTicketsComponentSaveButton"
                      type="primary"
                      className="permissions__right-container__footer__button"
                      onClick={() => {
                        setUpdating(true);
                        if (stateProps.isNewSeasonTicket) {
                          createSeasonTicketDescriptor();
                        } else {
                          updateSeasonTicketDescriptor();
                        }
                      }}
                    >
                      {stateProps.isUpdatingProcessing ? (
                        <LoadingOutlined />
                      ) : stateProps.isNewSeasonTicket ? (
                        t('modules.season_tickets_component.button.save_subscription')
                      ) : (
                        t('modules.season_tickets_component.button.save_changes')
                      )}
                    </Button>
                  </div>
                </div>
              )}
              <Button
                id="SeasonTicketsComponentAddSubscriptionButton"
                className="sales__price-template__add-template"
                onClick={() => {
                  setState({
                    ...stateProps,
                    isNewSeasonTicket: true,
                    isUpdated: true,
                    selectedSeasonTicket: {
                      tournaments: [],
                      stages: [],
                    },
                  });
                }}
              >
                <PlusOutlined className="sales__price-template__add-template__icon" />
                <span className="sales__price-template__add-template__text">
                  {t('modules.season_tickets_component.span')}
                </span>
              </Button>
            </>
          ) : (
            <div className="with-preloader__map">
              <LoadingOutlined />
              <div className="with-preloader__info"></div>
            </div>
          )}
        </div>
      </ConfigProvider>
    </>
  );
};

export default withApollo(SeasonTicketsComponent);
