import React from 'react';
import {ACC_RequestType, Events, Match, Season, Tournament} from '../../../model/accreditationModel';
import {Col, Row} from 'antd';
import styles from './EventSelector.module.scss';
import {date} from '../../../../../../utils/helpers';
import {useTranslation} from 'react-i18next';

export default function EventSelector(props: { currentEvent: Events; requestType: ACC_RequestType }) {
  const {t} = useTranslation();
  switch (props.requestType) {
    case 'FOR_MATCH':
      return (
        <Row className={styles.item}>
          {props.currentEvent.matches.map((match: Match, i) => (
            <Col key={i} className={styles.name + ' ' + styles.block} xs={24} sm={12}>
              <span className={styles.title}>
                {match.team1.title} vs {match.team2.title}
              </span>
              <span className={styles.date}>
                {date(match.startTime)}
              </span>
            </Col>
          ))}
        </Row>
      );
    case 'FOR_SEASON':
      return (
        <Row className={styles.item}>
          {props.currentEvent.seasons.map((season: Season, i) => (
            <Col key={i} className={styles.name + ' ' + styles.block} xs={24} sm={12}>
              <span>
                {t('modules.event_selector')} {season.startDate.getFullYear()}/{season.endDate.getFullYear()}
              </span>
            </Col>
          ))}
        </Row>
      );

    case 'FOR_TOURNAMENT':
      return (
        <Row className={styles.item}>
          {props.currentEvent.tournaments.map((tournament: Tournament, i) => (
            <Col key={i} className={styles.name + ' ' + styles.block} xs={24} sm={12}>
              <span>{tournament.title}</span>
            </Col>
          ))}
        </Row>
      );
  }
  return <></>;
}
