import { t } from 'i18next';
import { withProps } from 'recompose';

export default withProps({
  listTitles: [
    {
      title: t('modules.with_props.bounds'),
      key: 'bounds',
    },
    {
      title: t('modules.with_props.comments'),
      key: 'commentary',
    },
  ],
});
