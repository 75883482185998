import React, { useState } from 'react';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import './index.scss';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Modal
        visible={props.visible}
        centered
        closable={props.closable || false}
        onCancel={(ev) => {
          props.onClose();
        }}
        width={props.width || 415}
        footer={null}
      >
        <>
          <div className="confirm-modal__text-container">
            <CloseCircleOutlined className="confirm-modal__text-container__icon" />
            <div className="confirm-modal__text-container__text">
              {`${t('modules.confirmation_modal.are_you_sure')} ${props.infoText}?`}
            </div>
          </div>
          <div className="confirm-modal__button-container">
            <Button
              id="ConfirmationModalCancelButton"
              className="confirm-modal__button-container__decline-button"
              onClick={(ev) => {
                props.onClose();
              }}
            >
              {t('modules.confirmation_modal.cancel')}
            </Button>
            <Button
              id="ConfirmationModalPropsTextButton"
              className="confirm-modal__button-container__accept-button"
              onClick={(ev) => {
                setLoading(true);
                props.onConfirm();
              }}
            >
              {loading ? <LoadingOutlined style={{ color: '#000' }} /> : props.buttonText}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
