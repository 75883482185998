import React, { useEffect, useState } from 'react';
import { Table, Popover } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ApolloClient from 'apollo-client';

import './LoyaltyTable.scss';
import Loader from 'common/Loader/Loader';

import { LoyaltyBonusTransaction, LoyaltyBonusTransactionList, LoyaltyRules } from '../../../../model/usersModel';
import { GET_LOYALTY_BONUS_TRANSACTION } from '../../../../model/usersQueries';
import { deserializeLoyaltyBonusTransaction } from '../../../../model/usersDeserializers';

import { Pagination } from 'utils/commonTypes';
import { deserialize, fetchData, formatFraction, formatSign } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';

const LoyaltyTable = (props: { userId: string; apolloClient: ApolloClient<any>; loyaltyRules?: LoyaltyRules }) => {
  const { t } = useTranslation();
  const { Column } = Table;

  const [loading, setLoading] = useState<boolean>(false);
  const [loyaltyBonusTransaction, setLoyaltyBonusTransaction] = useState<LoyaltyBonusTransaction>();

  const defaultPagination = { limit: 10, page: 1 };
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  function getLoyaltyBonusTransaction(userId: string, pagination: Pagination) {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_LOYALTY_BONUS_TRANSACTION,
        variables: { userIds: [userId], pagination: { limit: pagination.limit, page: pagination.page - 1 } },
        fetchPolicy: 'no-cache',
      }),
      setLoyaltyBonusTransaction,
      (res) =>
        deserialize(res, (o) =>
          o.required('data.loyalty.transactionQuery.getList').asObject((o) => deserializeLoyaltyBonusTransaction(o))
        )
    );
  }

  useEffect(() => {
    setPagination(defaultPagination);
  }, [props.userId]);

  useEffect(() => {
    getLoyaltyBonusTransaction(props.userId, pagination);
  }, [pagination]);

  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page : 1,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    setPagination(newPagination);
  }

  // type FieldGetter<T, R> = (item: T) => R
  //
  // function groupBy<T, R>(list: T[], fieldGetter: FieldGetter<T, R>): Map<R,T[]> {
  //   return list.reduce((previous, current) => {
  //     const key = fieldGetter(current)
  //     if(previous.has(key)){
  //       const existed = previous.get(key)!!
  //       previous.set(key, existed?.concat([current]))
  //     } else {
  //       previous.set(key, [current])
  //     }
  //     return previous
  //   }, new Map<R,T[]>())
  // }
  //
  // const uniqueObjs = groupBy(props.loyaltyBonusTransaction, (value) => value.createdAt.toLocaleString('en-En', {month: 'long', year: 'numeric'}));
  //
  // console.log('uniqueObjs', uniqueObjs);
  // console.log('uniqueObjs.entries()', uniqueObjs.entries());

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          dataSource={loyaltyBonusTransaction?.list}
          rowKey="id"
          pagination={{
            total: loyaltyBonusTransaction?.total,
            onChange: onPageChange,
            current: pagination.page,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          className="components-table-demo-nested"
          style={{ width: '100%', marginTop: '-16px' }}
        >
          {/*<Column*/}
          {/*  title=''*/}
          {/*  dataIndex='visibleId'*/}
          {/*  align='left'*/}
          {/*  render={(text, row, index) => {*/}
          {/*    return {*/}
          {/*      children: <span>{ text }</span>,*/}
          {/*      props: {*/}
          {/*        colSpan: 4,*/}
          {/*      },*/}
          {/*    };*/}
          {/*  }}*/}
          {/*/>*/}
          <Column
            title={`${t('modules.loyalty_table.accrual')} ${props.loyaltyRules?.currencyNameMod50.toLowerCase()}`}
            key="transactionValue"
            width="20%"
            align="left"
            render={(dataSource: LoyaltyBonusTransactionList) => {
              const classForSpan = dataSource.isCanceled
                ? 'loyalty-table__transaction-value loyalty-table__transaction-value_canceled'
                : 'loyalty-table__transaction-value';

              const contentForPopover = (
                <div>
                  <p>{t('modules.loyalty_table.text.0')}</p>
                  <p>{t('modules.loyalty_table.text.1')}</p>
                </div>
              );
              const popoverBlock = dataSource.isCanceled ? (
                <Popover content={contentForPopover}>
                  <ExclamationCircleFilled style={{ color: '#EB5757', marginLeft: '6px' }} />
                </Popover>
              ) : null;

              return (
                <span className={classForSpan}>
                  {formatSign(formatFraction(dataSource.transactionValue))}
                  {popoverBlock}
                </span>
              );
            }}
          />
          <Column
            title={t('modules.loyalty_table.date')}
            key="createdAt"
            width="20%"
            align="left"
            render={(dataSource: LoyaltyBonusTransactionList) => (
              <span className="loyalty-table__date">
                {dataSource.createdAt.toLocaleString('ru-Ru', { dateStyle: 'short', timeStyle: 'short' })}
              </span>
            )}
          />
          <Column
            title={t('modules.loyalty_table.comment')}
            key="reason"
            width="60%"
            align="left"
            render={(dataSource: LoyaltyBonusTransactionList) => {
              let reasonComment = null;

              if (dataSource.reason) {
                try {
                  let comment = JSON.parse(dataSource.reason);
                  reasonComment = comment.message;
                } catch (e) {
                  console.log(e);
                  reasonComment = dataSource.reason;
                }
              }

              return <span className="loyalty-table__comment">{reasonComment}</span>;
            }}
          />
        </Table>
      )}
    </>
  );
};

export default LoyaltyTable;
