import {
  SegmentData,
  UsersTotal,
  PersonContacts,
  PersonData,
  SegmentType,
  SegmentsPersonDescriptor,
} from './segmentsModel';
import { ObjectDeserializator } from 'utils/apiHelpers';
import { enumFromString } from 'utils/typeHelpers';
import { LocalizedString } from 'utils/LocalizedString';

export const usersDeserializer = (o: ObjectDeserializator): UsersTotal => {
  return {
    total: o.required('total').asNumber,
  };
};

export const segmentsListDeserializer = (o: ObjectDeserializator): SegmentData => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    comment: o.optional('comment')?.asString,
    userIds: o.required('userIds').asArray((u) => u),
    users: o.required('users.total').asNumber,
    segmentType: o.required('segmentType').as((o) => enumFromString(SegmentType, o)),
    sql: o.optional('sql')?.asString,
    createdAt: o.required('createdAt').asDate,
    updatedAt: o.required('updatedAt').asDate,
    deletedAt: o.optional('deletedAt')?.asDate,
  };
};

export const segmentsPersonContactsDeserializer = (o: ObjectDeserializator): PersonContacts => {
  return {
    value: o.required('value').asString,
  };
};

export const segmentsPersonDataDeserializer = (o: ObjectDeserializator): PersonData => {
  const contacts = o.required('contacts').asArrayOfObjects((c) => ({
    value: c.required('value').asString,
    type: c.required('type').asString,
  }));

  return {
    name: o.required('name').as((o) => LocalizedString.fromObject(o)),
    surname: o.required('surname').as((o) => LocalizedString.fromObject(o)),
    patronymic: o.optional('patronymic')?.as((o) => LocalizedString.fromObject(o)),
    phone: contacts.find((contact) => contact.type === 'PHONE')?.value,
    email: contacts.find((contact) => contact.type === 'EMAIL')?.value,
  };
};

export const userDataDeserializer = (o: ObjectDeserializator): SegmentsPersonDescriptor => {
  return {
    id: o.required('id').asString,
    person: o.required('person').asObject((o) => segmentsPersonDataDeserializer(o)),
  };
};
