import { Discount } from '../model/discountsModel';
import { formatFraction } from 'utils/apiHelpers';
import { NBS } from '../../../../../constants';
import { t } from 'i18next';

export default function formatDiscount(discount: Discount) {
  switch (discount.type) {
    case 'ABSOLUTE':
      return `−${formatFraction(discount.value)}${NBS}${t('currency')}`;
    case 'PERCENT':
      return `−${formatFraction(discount.value)}${NBS}%`;
    case 'FIXED_PRICE':
      return `${t('modules.format_discount')}${NBS}${formatFraction(discount.value)}${NBS}${t('currency')}`;
  }
}
