import React from 'react';
import { Modal, Button, Form, Space, Select, DatePicker, TimePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withApollo } from 'react-apollo';
import './ModalSegmentsSales.scss';

import {
  TransformedConfiguration,
  SegmentsConfiguration,
  FormSubmitResult,
  SegmentsTitles,
  FormValue,
} from '../../../model/eventsModel';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import i18next from 'i18next';

const ModalSegmentsSales = (props: {
  allUsersSegmentId: string;
  segmentSalesSubmitHandler: (value: SegmentsConfiguration[]) => void;
  isModalSegmentsSalesVisible: boolean;
  setIsModalSegmentsSalesVisible: (value: boolean) => void;
  transformedConfiguration: TransformedConfiguration[];
  segmentsTitles: SegmentsTitles[];
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const FORMAT_TIME = 'HH:mm';
  // The function converts the data from the array to default values in the form
  // If the data does not come from the server, then the array is filled with empty values to display in the form inputs (MatchesComponent.tsx, 40)
  // In such an array, the key is a unique id, in the data from the server, the key is the date and time
  // The function checks the source of the data in the array and displays either existing data or empty inputs
  const initialValueFromConfiguration = (configuration: TransformedConfiguration[]) => {
    const isItemFromServer = (conf: TransformedConfiguration) => !conf.key.startsWith('id');
    return configuration.map((item) => {
      const newItem = { date: undefined, time: undefined, segmentsId: undefined };

      if (isItemFromServer(item)) {
        let day, time;
        item.value.forEach((i) => {
          day = new Intl.DateTimeFormat(i18next.language).format(i.releaseDatetime);
          time = new Intl.DateTimeFormat(i18next.language).format(i.releaseDatetime);
        });
        Object.assign(newItem, {
          date: dayjs(day),
          time: dayjs(time, FORMAT_TIME),
          segmentsId: item.value.map((item: SegmentsConfiguration) => {
            if (item.segmentId === undefined || item.segmentId === null) {
              return props.allUsersSegmentId;
            } else if (item.segment?.deletedAt) {
              // TODO: remove this segment from the array: here, line 72, SegmentsConfigurationList.tsx, 46
              return `${item.segment.title} (${t('modules.modal_segments_sales.segments_id')})`;
            } else {
              return `${item.segmentId}`;
            }
          }),
        });
      }
      return newItem;
    });
  };

  // TODO: the time is displayed in the user's time zone
  return (
    <Modal
      visible={props.isModalSegmentsSalesVisible}
      onCancel={() => props.setIsModalSegmentsSalesVisible(false)}
      title={<h3 className="modal-segments__title">{t('modules.modal_segments_sales.modal')}</h3>}
      footer={false}
      width={914}
      zIndex={1000}
    >
      <Form
        name="dynamic_form_nest_item"
        onFinish={(formValue: FormSubmitResult) => {
          const transformedFormValue: SegmentsConfiguration[] = Object.values(formValue)
            .flat()
            .flatMap((item: FormValue) => {
              // TODO: check for the presence of a deleted segment and remove it from the array: here, line 45, SegmentsConfigurationList.tsx, 47
              return item.segmentsId.map((segmentId: string) => {
                const date = item.date.toDate();
                date.setHours(item.time.toDate().getHours(), item.time.toDate().getMinutes());

                return {
                  segmentId: segmentId === props.allUsersSegmentId ? null : segmentId,
                  releaseDatetime: date,
                  segment:
                    segmentId === props.allUsersSegmentId
                      ? undefined
                      : {
                          id: segmentId,
                          title:
                            props.segmentsTitles.find((item) => item.id === segmentId) &&
                            props.segmentsTitles.find((item) => item.id === segmentId)?.title,
                        },
                };
              });
            });
          props.segmentSalesSubmitHandler(transformedFormValue);
        }}
        autoComplete="off"
      >
        <Form.List name="stage" initialValue={initialValueFromConfiguration(props.transformedConfiguration)}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} className="modal-segments__stage-item" direction="horizontal">
                    {t('modules.modal_segments_sales.space.open_from')}
                    <Form.Item
                      {...restField}
                      name={[name, 'date']}
                      fieldKey={[Number(`${fieldKey && fieldKey}`), 'date']}
                      rules={[{ required: true, message: t('modules.modal_segments_sales.form_item.select_date')! }]}
                    >
                      <DatePicker
                        name="date"
                        id="date"
                        className="modal-segments__date"
                        size="large"
                        placeholder={t('modules.modal_segments_sales.date_picker')!}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'time']}
                      fieldKey={[Number(`${fieldKey && fieldKey}`), 'time']}
                      rules={[{ required: true, message: t('modules.modal_segments_sales.form_item.select_time')! }]}
                    >
                      <TimePicker
                        name="time"
                        id="time"
                        className="modal-segments__date"
                        size="large"
                        format={FORMAT_TIME}
                        placeholder={t('modules.modal_segments_sales.time_picker')!}
                      />
                    </Form.Item>
                    {t('modules.modal_segments_sales.space.for')}
                    <Form.Item
                      {...restField}
                      name={[name, 'segmentsId']}
                      fieldKey={[Number(`${fieldKey && fieldKey}`), 'segmentsId']}
                      rules={[{ required: true, message: t('modules.modal_segments_sales.form_item.select_segment')! }]}
                    >
                      <Select
                        id="segmentsId"
                        mode="multiple"
                        allowClear
                        className="modal-segments__select"
                        size="large"
                        placeholder={t('modules.modal_segments_sales.select')}
                      >
                        {props.segmentsTitles &&
                          props.segmentsTitles.map((item: SegmentsTitles) => (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {fields.length === 1 ? null : (
                      <PlusOutlined className="modal-segments__delete-icon" onClick={() => remove(name)} />
                    )}
                  </Space>
                ))}

                <Form.Item>
                  <p className="modal-segments__add-stage" onClick={() => add()}>
                    {t('modules.modal_segments_sales.p')}
                  </p>
                </Form.Item>
              </>
            );
          }}
        </Form.List>

        <Form.Item className="modal-segments__button-block">
          <Button type="primary" htmlType="submit" id="ModalSegmentsSalesSaveButton" className="modal-segments__button">
            {t('modules.modal_segments_sales.button')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withApollo(ModalSegmentsSales);
