import { connect } from 'react-redux';
import pathOr from 'ramda/es/pathOr';

import { setCodesToReducer, openCodesModal } from '../../actions';

const mapStateToProps = (state: any) => {
  return {
    codesData: pathOr(
      {},
      ['reducer', 'promocodes', 'promoRootReducer', 'codesData'],
      state
    ),
    codesModalIsOpened: pathOr(
      false,
      ['reducer', 'promocodes', 'promoRootReducer', 'codesModalIsOpened'],
      state
    ),
    defaultPromo: pathOr(
      false,
      ['reducer', 'promocodes', 'promoReducer', 'defaultPromo'],
      state
    )
  };
};

const mapDispatchToProps = { setCodesToReducer, openCodesModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
