import { withHandlers } from 'recompose';
import moment from 'moment';
import propOr from 'ramda/es/propOr';
import { transliterate } from '../../../../../../../utils/helpers';

export default withHandlers({
  getUser: props => values => {
    const name = {
      ru: values.name,
      en: transliterate(values.name)
    };
    const surname = {
      ru: values.surname,
      en: transliterate(values.surname)
    };
    const patronymic = {
      ru: values.patronymic,
      en: transliterate(values.patronymic)
    };
    const birthday = moment(values.birthday).format('YYYY-MM-DD');
    const contacts = [
      {
        type: 'EMAIL',
        value: propOr('', 'email', values)
      },
      {
        type: 'PHONE',
        value: propOr('', 'phone', values)
      }
    ];
    return {
      person: {
        name,
        surname,
        patronymic,
        birthday,
        contacts
      }
    };
  }
});
