export class Action {
  title: string;
  icon?: string;
  name: string;
  order: number;
  enabled: boolean;
  visible: boolean;
  subitems?: Action[] | undefined;
  permission?: string;
  localizationKey?: string;

  constructor(obj: Action) {
    this.title = obj.title;
    this.icon = obj.icon;
    this.name = obj.name;
    this.order = obj.order;
    this.enabled = obj.enabled;
    this.visible = obj.visible;
    this.subitems = obj.subitems || [];
    this.permission = obj.permission;
    this.localizationKey = obj.localizationKey;
  }
}

export class LinkAction extends Action {
  link: string;

  constructor(obj: LinkAction) {
    super(obj);
    this.link = obj.link;
  }
}

export class HandlerAction extends Action {
  callback: (action: HandlerAction) => void;

  constructor(obj: HandlerAction) {
    super(obj);
    this.callback = obj.callback;
  }
}

export class RouteAction extends Action {
  route: string;

  constructor(obj: RouteAction) {
    super(obj);
    this.route = obj.route;
  }
}
