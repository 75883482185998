import Loyalty from './Loyalty/Loyalty';

import { IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { withProps } from 'recompose';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { t } from 'i18next';

export class ModuleLoyalty implements Module {
  private app: IApp;
  private component: any;
  private routeContainer: IRouteContainer;
  private menuContainer: ISidebar;
  private reducers: Array<{ name: string; reducer: any }>;

  constructor(app: IApp, routeContainer: IRouteContainer, menuContainer: ISidebar, authClient: AuthClient) {
    this.component = {
      Loyalty: withProps({
        authClient: authClient
      })(Loyalty)
    };
    this.reducers = [];

    this.app = app;
    this.menuContainer = menuContainer;
    this.routeContainer = routeContainer;

    this.reducers.map((reducer: any) => this.app.addReducer(reducer));

    this.routeContainer.addRoute({
      path: '/admin/loyalty',
      component: this.component.Loyalty
    });

    const loyaltyLink = new RouteAction({
      title: t('modules.loyalty_index'),
      icon: 'star',
      name: 'loyalty',
      route: 'admin/loyalty',
      order: 22, // number random
      enabled: true,
      visible: true,
      permission: 'ACCESS_LOYALTY',
      localizationKey: 'loyalty'
    });

    this.menuContainer.addLink(loyaltyLink);
  }

  start() {
  }
}
