/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr, uniq } from 'ramda';
import { CalendarOutlined, CloseCircleOutlined, CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, ConfigProvider, DatePicker, message, Modal, Radio, Select, TimePicker, Typography } from 'antd';
import {
  ADD_MATCH,
  DELETE_MATCH,
  GET_MATCH_TEMPLATE,
  GET_MATCHES,
  GET_STADIUMS,
  GET_STAGES,
  GET_TEAMS,
  GET_TEMPLATES,
  GET_TOURNAMENTS,
  RECALCULATE_SEASON_TICKETS,
  UPDATE_MATCH,
  UPDATE_PRICE_TEMPLATE,
} from '../graphql';
import { withApollo } from 'react-apollo';
import { v4 as uuid4 } from 'uuid';

import './index.scss';
import ModalSegmentsSales from './components/ModalSegmentsSales/ModalSegmentsSales';
import SegmentsConfigurationList from './components/SegmentsConfigurationList/SegmentsConfigurationList';

import { deserialize, fetchData, useDidMount, joinUriPath } from 'utils/apiHelpers';
import { GET_ALL_SEGMENTS_LIST, GET_SEGMENTS_CONFIGURATION } from '../model/eventsQueries';
import { deserializeSegmentsConfiguration, deserializeSegmentsTitles } from '../model/eventsDeserializers';
import { SegmentsConfiguration, SegmentsTitles, TransformedConfiguration } from '../model/eventsModel';
import { groupBy, mapToArray } from 'utils/typeHelpers';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from 'utils/LocalizedString';
import i18next from 'i18next';
import LocalizedSelect from 'common/LocalizedSelect/LocalizedSelect';
import LocalizedTextEditor from 'common/LocalizedTextEditor/LocalizedTextEditor';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';
import { getSupportedLngs } from 'i18n';
import dayjs from 'dayjs';

const SelectOption = Select.Option;
const supportedLangs = getSupportedLngs();

const MatchesComponent = (props) => {
  const { t } = useTranslation();
  const CURRENT_DATE = new Date();
  const ALL_USERS_SEGMENT_ID = 'allUsers';
  const ALL_USERS_SEGMENT_TITLE = t('modules.matches_component.all_users_segment_title');
  const emptyConfiguration: TransformedConfiguration[] = [
    {
      key: `id-${uuid4()}`,
      value: [
        {
          segmentId: undefined,
          releaseDatetime: CURRENT_DATE,
          segment: undefined,
        },
      ],
    },
  ];
  const allUsersSegment: SegmentsTitles = {
    id: ALL_USERS_SEGMENT_ID,
    title: ALL_USERS_SEGMENT_TITLE,
  };

  const [loading, setLoading] = useState(false);
  const [isModalSegmentsSalesVisible, setIsModalSegmentsSalesVisible] = useState(false);
  const [segmentsConfiguration, setSegmentsConfiguration] = useState<SegmentsConfiguration[]>([]);
  const [transformedConfiguration, setTransformedConfiguration] = useState<TransformedConfiguration[]>(
    emptyConfiguration
  );
  const [segmentsTitles, setSegmentsTitles] = useState<SegmentsTitles[]>([allUsersSegment]);

  const [stateProps, setState] = useState({
    isModalSegmentsSalesVisible: false,
    isDataVisible: true,
    selectedMatch: {},
    newData: {},
  });

  const updateMatches = function (key, value) {
    setState({
      ...stateProps,
      selectedMatch: {
        ...stateProps.selectedMatch,
        [key]: value,
      },
      newData: {
        ...stateProps.newData,
        [key]: value,
      },
    });
  };

  const setMatch = async (match) => {
    try {
      const templateResOnline = await props.client.query({
        query: GET_MATCH_TEMPLATE,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            eventId: match.id,
            PTETags: 'ONLINE',
          },
        },
      });
      const matchTemplateOnline = pathOr([], ['data', 'priceTemplate', 'getList', 'list'], templateResOnline)[0];

      const templateResOffline = await props.client.query({
        query: GET_MATCH_TEMPLATE,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            eventId: match.id,
            PTETags: 'OFFLINE',
          },
        },
      });
      const matchTemplateOffline = pathOr([], ['data', 'priceTemplate', 'getList', 'list'], templateResOffline)[0];

      const multSelect = Boolean(
        matchTemplateOnline && matchTemplateOffline && matchTemplateOnline.id !== matchTemplateOffline.id
      );

      setState({
        ...stateProps,
        selectedMatch: match,
        isUpdated: true,
        matchTemplateOnline: matchTemplateOnline,
        matchTemplateOffline: matchTemplateOffline,
        singleSelect: !multSelect,
        isNewMatch: false,
        isDataVisible: true,
      });
    } catch (e) {
      console.error(e);
      message.error(t('modules.matches_component.message_error.match_loading_error'));
    }
  };

  const handlePriceTemplate = ({ target: { value } }): RadioChangeEvent => {
    switch (value) {
      case true:
        stateProps.newData.onlineTemplate && delete stateProps.newData.onlineTemplate;
        stateProps.newData.offlineTemplate && delete stateProps.newData.offlineTemplate;
        break;
      case false:
        stateProps.newData.singleTemplate && delete stateProps.newData.singleTemplate;
        break;
    }

    setState({
      ...stateProps,
      singleSelect: value,
    });
  };

  const restoreState = () => {
    setState({
      isDataVisible: true,
      selectedMatch: {},
      newData: {},
      isLoaded: false,
    });
  };

  const deleteMatch = async () => {
    try {
      await props.client.query({
        query: DELETE_MATCH,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedMatch.id,
        },
      });
      message.success(t('modules.matches_component.message_success.match_removed'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.matches_component.message_error.cannot_delete_selected_match'));
    } finally {
      restoreState();
    }
  };

  const updateTemplate = async (id) => {
    try {
      const newData = stateProps.newData;
      if (stateProps.singleSelect && newData.singleTemplate) {
        await props.client.query({
          query: UPDATE_PRICE_TEMPLATE,
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              templateId: newData.singleTemplate,
              eventId: stateProps.selectedMatch.id ? stateProps.selectedMatch.id : id,
              tag: 'ONLINE',
            },
          },
        });
        await props.client.query({
          query: UPDATE_PRICE_TEMPLATE,
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              templateId: newData.singleTemplate,
              eventId: stateProps.selectedMatch.id ? stateProps.selectedMatch.id : id,
              tag: 'OFFLINE',
            },
          },
        });
      } else {
        if (newData.onlineTemplate) {
          await props.client.query({
            query: UPDATE_PRICE_TEMPLATE,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                templateId: newData.onlineTemplate,
                eventId: stateProps.selectedMatch.id ? stateProps.selectedMatch.id : id,
                tag: 'ONLINE',
              },
            },
          });
        }
        if (newData.offlineTemplate) {
          await props.client.query({
            query: UPDATE_PRICE_TEMPLATE,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                templateId: newData.offlineTemplate,
                eventId: stateProps.selectedMatch.id ? stateProps.selectedMatch.id : id,
                tag: 'OFFLINE',
              },
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const recalculateSeasonTicketPlaces = async () => {
    try {
      const res = await props.client.query({
        query: RECALCULATE_SEASON_TICKETS,
        fetchPolicy: 'no-cache',
      });
      return pathOr('', ['data', 'seasonTicket', 'recalculatePlaceStatus'], res);
    } catch (e) {
      console.error(e);
      message.error(t('modules.matches_component.message_error.subscription_update_error'));
    }
  };

  const updateMatch = async () => {
    try {
      const newData = stateProps.newData;
      let date = undefined;

      if (Boolean(newData.date || newData.time)) {
        date = new Date(newData.date ? newData.date : stateProps.selectedMatch.startTime);
        const time = new Date(newData.time ? newData.time : stateProps.selectedMatch.startTime);

        date.setHours(time.getHours());

        date.setMinutes(time.getMinutes());

        date.setSeconds(0);

        date = date.toISOString();
      }
      await props.client.query({
        query: UPDATE_MATCH,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedMatch.id,
          data: {
            description: newData.description ? (newData.description as LocalizedString).toObject() : undefined,
            startTime: date,
            venueId: newData.stadium ? newData.stadium : undefined,
            team1Id: newData.team1 ? newData.team1 : undefined,
            team2Id: newData.team2 ? newData.team2 : undefined,
            stageId: newData.stage ? newData.stage : undefined,
            segmentsConfiguration: segmentsConfiguration
              ? segmentsConfiguration.map((segment: SegmentsConfiguration) => {
                  return {
                    segmentId: segment.segmentId,
                    releaseDatetime: segment.releaseDatetime,
                  };
                })
              : undefined,
            status: newData.statusRadioValue
              ? newData.statusRadioValue === 2 || newData.statusRadioValue === 3 || newData.statusRadioValue === 4
                ? 'ACTIVE'
                : 'CREATED'
              : undefined,
            hidden: newData.statusRadioValue ? newData.statusRadioValue === 3 : undefined,
            visibilityPlaces: newData.visibilityPlaces ? newData.visibilityPlaces : undefined,
          },
        },
      });
      //WIP

      await updateTemplate();
      await recalculateSeasonTicketPlaces();

      message.success(t('modules.matches_component.message_success.match_updated'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.matches_component.message_error.match_update_error'));
    } finally {
      restoreState();
    }
  };

  const addMatch = async () => {
    const newData = stateProps.newData;
    if (
      newData.team1 &&
      newData.team2 &&
      newData.stage &&
      newData.stadium &&
      newData.date &&
      newData.time &&
      (stateProps.singleSelect ? newData.singleTemplate : newData.onlineTemplate && newData.offlineTemplate) &&
      newData.visibilityPlaces
    ) {
      try {
        const date = new Date(newData.date);
        const time = new Date(newData.time);

        date.setHours(time.getHours());

        date.setMinutes(time.getMinutes());

        date.setSeconds(0);

        const matchRes = await props.client.query({
          query: ADD_MATCH,
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              startTime: date.toISOString(),
              venueId: newData.stadium,
              status: newData.statusRadioValue === 1 ? 'CREATED' : 'ACTIVE',
              hidden: !newData.statusRadioValue !== 3,
              team1Id: newData.team1,
              team2Id: newData.team2,
              stageId: newData.stage,
              visibilityPlaces: newData.visibilityPlaces,
              segmentsConfiguration: segmentsConfiguration.map((segment: SegmentsConfiguration) => {
                return {
                  segmentId: segment.segmentId,
                  releaseDatetime: segment.releaseDatetime,
                };
              }),
            },
          },
        });

        const matchId = pathOr('', ['data', 'matchLocalised', 'add', 'id'], matchRes);

        await updateTemplate(matchId);
        await recalculateSeasonTicketPlaces();

        message.success(t('modules.matches_component.message_success.match_created'));

        restoreState();
      } catch (e) {
        console.error(e);
        message.error(t('modules.matches_component.message_error.match_creation_error'));
        setState({
          ...stateProps,
          isUpdatingProcessing: false,
        });
      }
    } else {
      message.error(t('modules.matches_component.message_error.fill_all_fields'));
      setState({
        ...stateProps,
        isUpdatingProcessing: false,
      });
    }
  };

  // Release for sale by segments

  function getSegmentsConfiguration(id: string) {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_SEGMENTS_CONFIGURATION,
        fetchPolicy: 'no-cache',
        variables: { id },
      }),
      setSegmentsConfiguration,
      (res) =>
        deserialize(res, (o) =>
          o
            .required('data.match.getById.segmentsConfiguration')
            .asArrayOfObjects((o) => deserializeSegmentsConfiguration(o))
        )
    );
  }

  async function getSegmentsTitles() {
    if (loading) return;
    setLoading(true);
    try {
      const res = await props.client.query({
        query: GET_ALL_SEGMENTS_LIST,
        fetchPolicy: 'no-cache',
      });
      setSegmentsTitles([
        ...segmentsTitles,
        ...deserialize(res, (o) =>
          o.required('data.segments.getList.list').asArrayOfObjects((o) => deserializeSegmentsTitles(o))
        ),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const setEmptyConfiguration = () => {
    setTransformedConfiguration(emptyConfiguration);
  };

  const transformSegmentsConfiguration = () => {
    if (segmentsConfiguration) {
      if (segmentsConfiguration.length !== 0) {
        setTransformedConfiguration(
          mapToArray(
            groupBy(segmentsConfiguration, (value: SegmentsConfiguration) => {
              return value.releaseDatetime.toLocaleString({ dateStyle: 'short', timeStyle: 'short' });
            })
          )
        );
      } else {
        setEmptyConfiguration();
      }
    }
  };

  useEffect(() => {
    if (stateProps.selectedMatch.id) {
      getSegmentsConfiguration(stateProps.selectedMatch.id);
    }
  }, [stateProps.selectedMatch.id]);

  useEffect(() => {
    transformSegmentsConfiguration();
    if (segmentsConfiguration && segmentsConfiguration.length !== 0 && stateProps.selectedMatch.status === 'ACTIVE') {
      setState({
        ...stateProps,
        newData: {
          ...stateProps.newData,
          statusRadioValue: 4,
        },
        selectedMatch: {
          ...stateProps.selectedMatch,
          statusRadioValue: 4,
        },
      });
    }
  }, [segmentsConfiguration]);

  useDidMount(() => {
    getSegmentsTitles();
  });

  const segmentSalesSubmitHandler = (formValue: SegmentsConfiguration[]) => {
    setSegmentsConfiguration(formValue);
    transformSegmentsConfiguration();
    setIsModalSegmentsSalesVisible(false);
  };

  useEffect(() => {
    const init = async () => {
      const matchRes = await props.client.query({
        query: GET_MATCHES,
        fetchPolicy: 'no-cache',
      });
      const list = pathOr([], ['data', 'matchLocalised', 'getList', ['list']], matchRes);
      const filteredList = list.map((match) => {
        const radioValue = () => {
          if (match.status === 'ACTIVE') {
            if (segmentsConfiguration && segmentsConfiguration.length !== 0) {
              return 4;
            }
            if (match.hidden) {
              return 3;
            }
            return 2;
          }
          return 1;
        };

        return {
          id: match.id,
          team1: {
            title: LocalizedString.fromObject(pathOr('', ['team1', 'title'], match)),
            id: pathOr('', ['team1', 'id'], match),
          },
          team2: {
            title: LocalizedString.fromObject(pathOr('', ['team2', 'title'], match)),
            id: pathOr('', ['team2', 'id'], match),
            logo: pathOr('', ['team2', 'logo'], match),
          },
          cover: pathOr('', ['cover', 'publicLink'], match),
          startTime: match.startTime,
          month: new Intl.DateTimeFormat(i18next.language, { month: 'long' }).format(new Date(match.startTime)),
          year: new Date(match.startTime).getFullYear(),
          status: match.status,
          hidden: match.hidden,
          visibilityPlaces: match.visibilityPlaces,
          tournament: pathOr('', ['stage', 'tournament', 'id'], match),
          stage: pathOr('', ['stage', 'id'], match),
          stadium: pathOr('', ['venue', 'id'], match),
          statusRadioValue: radioValue(),
          description: LocalizedString.fromObject(pathOr('', ['description'], match)),
        };
      });
      const uniqYears = uniq(
        filteredList.map((item) => {
          return item.year;
        })
      );

      const localizeTitles = (arr) => {
        return arr.map((item) => {
          item.title = LocalizedString.fromObject(item.title);
          return { ...item };
        });
      };

      const tournamentRes = await props.client.query({
        query: GET_TOURNAMENTS,
        fetchPolicy: 'no-cache',
      });
      const tournamentList = pathOr([], ['data', 'tournamentLocalised', 'getList', 'list'], tournamentRes);
      const tournamentsLocalizedList = localizeTitles(tournamentList);

      const stagesRes = await props.client.query({
        query: GET_STAGES,
        fetchPolicy: 'no-cache',
      });
      const stagesList = pathOr([], ['data', 'stageLocalised', 'getList', 'list'], stagesRes);
      const stagesLocalizedList = localizeTitles(stagesList);

      const teamsRes = await props.client.query({
        query: GET_TEAMS,
        fetchPolicy: 'no-cache',
      });
      const teamsList = pathOr([], ['data', 'teamLocalised', 'getList', 'list'], teamsRes);
      let teamsLocalizedList = localizeTitles(teamsList);

      const stadiumsRes = await props.client.query({
        query: GET_STADIUMS,
        fetchPolicy: 'no-cache',
      });
      const stadiumsList = pathOr([], ['data', 'venueLocalised', 'getList', 'list'], stadiumsRes);
      const stadiumsLocalizedList = localizeTitles(stadiumsList);

      const templatesRes = await props.client.query({
        query: GET_TEMPLATES,
        fetchPolicy: 'no-cache',
      });

      const templateList = pathOr([], ['data', 'priceTemplate', 'getList', 'list'], templatesRes);

      setState({
        ...stateProps,
        isLoaded: true,
        filteredList,
        uniqYears,
        tournamentsLocalizedList,
        stagesLocalizedList,
        teamsLocalizedList,
        stadiumsLocalizedList,
        templateList,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    if (stateProps.isTournamentUpdated) {
      setState({
        ...stateProps,
        isTournamentUpdated: false,
      });
    }
  }, [stateProps.isLoaded, stateProps.isUpdated, stateProps.isTournamentUpdated]);

  const filteredStages = () => {
    const currentTournament = stateProps.isNewMatch
      ? stateProps.newData.tournament
      : stateProps.selectedMatch.tournament;

    return stateProps.stagesLocalizedList.filter((item) => item.tournament.id === currentTournament);
  };

  function getMatchLink() {
    if (pathOr(null, ['selectedMatch', 'id'], stateProps)) {
      const link = joinUriPath(window.parametrize('REACT_APP_STORE_LINK'), 'tickets', stateProps.selectedMatch.id) 
        + '?type=match';
      return link
    }
    return null;
  }

  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <h1>{t('modules.matches_component.h1')}</h1>

        <div className="events">
          {stateProps.isLoaded ? (
            <>
              <div className="events__left-container">
                {stateProps.isNewMatch && (
                  <div className="events__left-container__item-active-new">
                    {t('modules.matches_component.div.new_match')}
                  </div>
                )}
                {stateProps.uniqYears.map((year) => {
                  const matches = stateProps.filteredList.filter((match) => {
                    return match.year === year;
                  });
                  const uniqMonths = uniq(
                    matches.map((item) => {
                      return item.month;
                    })
                  );
                  return uniqMonths.map((month) => {
                    return (
                      <div key={uuid4()}>
                        <div className="events__left-container__title">
                          {month.charAt(0).toUpperCase() + month.slice(1)}
                        </div>
                        {matches.map(
                          (match) =>
                            match.month === month && (
                              <div
                                key={uuid4()}
                                className={
                                  stateProps.selectedMatch.id === match.id
                                    ? 'events__left-container__item-active events__left-container__item'
                                    : 'events__left-container__item'
                                }
                                onClick={() => {
                                  setMatch(match);
                                }}
                              >
                                <div
                                  className="events__left-container__item__circle"
                                  style={{
                                    backgroundImage: `url(${match.team2.logo ? match.team2.logo.publicLink : ''})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                  }}
                                />
                                <div className="events__left-container__item__info-container">
                                  <div className="events__left-container__item__info-container__top-section">
                                    <div className="events__left-container__item__info-container__top-section__date">
                                      {new Intl.DateTimeFormat(i18next.language).format(new Date(match.startTime))}
                                    </div>
                                    {match.status === 'ACTIVE' || match.status === 'CREATED' || match.hidden ? (
                                      <div
                                        className="events__left-container__item__info-container__top-section__status"
                                        style={{
                                          color:
                                            match.status !== 'ACTIVE'
                                              ? '#D21939'
                                              : match.hidden
                                              ? '#F8A608'
                                              : '#03B94E',
                                          borderColor:
                                            match.status !== 'ACTIVE'
                                              ? '#D21939'
                                              : match.hidden
                                              ? '#F8A608'
                                              : '#03B94E',
                                        }}
                                      >
                                        {match.status !== 'ACTIVE'
                                          ? t('modules.matches_component.div.not_available')
                                          : match.hidden
                                          ? t('modules.matches_component.div.link')
                                          : t('modules.matches_component.div.on_sale')}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className="events__left-container__item__info-container__bottom-section">
                                    {pathOr('', ['team1', 'title'], match).toString(i18next.language) +
                                      ' vs ' +
                                      pathOr('', ['team2', 'title'], match).toString(i18next.language)}
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    );
                  });
                })}
              </div>

              {/* //////// */}

              {(!isEmpty(stateProps.selectedMatch) || stateProps.isNewMatch) && !stateProps.isUpdated && (
                <div className="events__right-container">
                  <div className="events__right-container__body">
                    <CloseOutlined
                      className="permissions__right-container__body__close-form-icon"
                      onClick={() => {
                        setState({
                          filteredList: stateProps.filteredList,
                          isDataVisible: stateProps.isDataVisible,
                          isLoaded: stateProps.isLoaded,
                          isModalSegmentsSalesVisible: stateProps.isModalSegmentsSalesVisible,
                          newData: {},
                          selectedMatch: {},
                          stadiumsLocalizedList: stateProps.stadiumsLocalizedList,
                          stagesLocalizedList: stateProps.stagesLocalizedList,
                          teamsLocalizedList: stateProps.teamsLocalizedList,
                          templateList: stateProps.templateList,
                          tournamentsLocalizedList: stateProps.tournamentsLocalizedList,
                          uniqYears: stateProps.uniqYears,
                        });
                      }}
                    />
                    <div className="permissions__right-container__body__switcher">
                      <div
                        className={
                          stateProps.isDataVisible
                            ? 'permissions__right-container__body__switcher__item__active'
                            : 'permissions__right-container__body__switcher__item'
                        }
                        onClick={() => {
                          setState({
                            ...stateProps,
                            isDataVisible: true,
                          });
                        }}
                        style={{
                          borderRadius: '4px 0 0 4px',
                        }}
                      >
                        {t('modules.matches_component.div.match_data')}
                      </div>
                      <div
                        className={
                          stateProps.isDataVisible
                            ? 'permissions__right-container__body__switcher__item'
                            : 'permissions__right-container__body__switcher__item__active'
                        }
                        onClick={() => {
                          setState({
                            ...stateProps,
                            isDataVisible: false,
                          });
                        }}
                        style={{
                          borderRadius: '0 4px 4px 0',
                        }}
                      >
                        {t('modules.matches_component.div.promo')}
                      </div>
                    </div>
                    {stateProps.isDataVisible ? (
                      <>
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.status')}
                        </div>
                        <div className="matches__status-container">
                          <Radio.Group
                            onChange={(ev) => {
                              if (ev.target.value !== 4) {
                                setEmptyConfiguration();
                                setSegmentsConfiguration([]);
                              }
                              setState({
                                ...stateProps,
                                newData: {
                                  ...stateProps.newData,
                                  statusRadioValue: ev.target.value,
                                },
                                selectedMatch: {
                                  ...stateProps.selectedMatch,
                                  statusRadioValue: ev.target.value,
                                },
                              });
                            }}
                            value={
                              stateProps.selectedMatch?.statusRadioValue
                                ? stateProps.selectedMatch.statusRadioValue || 3
                                : stateProps.newData.statusRadioValue || 3
                            }
                            className="matches__status-container__radio-container"
                          >
                            <Radio className="matches__status-container__radio-container__text" value={1}>
                              {t('modules.matches_component.radio.not_available')}
                            </Radio>
                            <Radio className="matches__status-container__radio-container__text" value={2}>
                              {t('modules.matches_component.radio.run_everyone')}
                            </Radio>
                            <Radio className="matches__status-container__radio-container__text" value={3}>
                              {t('modules.matches_component.tooltip.access_link')}
                              <Typography.Text copyable={{ text: getMatchLink() }} />
                            </Radio>
                            <Radio className="matches__status-container__radio-container__text" value={4}>
                              {t('modules.matches_component.radio.phased_launch_segment')}
                              <Button
                                id="MatchesComponentTuneButton"
                                type="primary"
                                size="small"
                                className="matches__status-container__primary-button"
                                style={{ marginLeft: '10px' }}
                                ghost
                                onClick={() => setIsModalSegmentsSalesVisible(true)}
                                disabled={
                                  stateProps.newData.statusRadioValue !== 4 ||
                                  stateProps.selectedMatch.statusRadioValue !== 4
                                }
                              >
                                {t('modules.matches_component.button.tune')}
                              </Button>
                            </Radio>
                          </Radio.Group>

                          <ModalSegmentsSales
                            allUsersSegmentId={ALL_USERS_SEGMENT_ID}
                            segmentSalesSubmitHandler={segmentSalesSubmitHandler}
                            isModalSegmentsSalesVisible={isModalSegmentsSalesVisible}
                            setIsModalSegmentsSalesVisible={setIsModalSegmentsSalesVisible}
                            transformedConfiguration={transformedConfiguration}
                            segmentsTitles={segmentsTitles}
                          />
                        </div>

                        {stateProps.newData.statusRadioValue === 4 && segmentsConfiguration?.length !== 0 && (
                          <SegmentsConfigurationList
                            allUsersSegmentTitle={ALL_USERS_SEGMENT_TITLE}
                            transformedConfiguration={transformedConfiguration}
                          />
                        )}

                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.point_sale')}
                        </div>
                        <div className="matches__status-container">
                          <Checkbox.Group
                            className="matches__status-container__checkbox-container"
                            onChange={(ev) => {
                              setState({
                                ...stateProps,
                                newData: {
                                  ...stateProps.newData,
                                  visibilityPlaces: ev,
                                },
                                selectedMatch: {
                                  ...stateProps.selectedMatch,
                                  visibilityPlaces: ev,
                                },
                              });
                            }}
                            defaultValue={
                              stateProps.selectedMatch.visibilityPlaces
                                ? stateProps.selectedMatch.visibilityPlaces
                                : stateProps.newData.visibilityPlaces
                            }
                            options={[
                              { label: t('modules.matches_component.checkbox_group.web_store'), value: 'WEB_SHOP' },
                              { label: t('modules.matches_component.checkbox_group.mobile_app'), value: 'MOBILE_SHOP' },
                              { label: t('modules.matches_component.checkbox_group.cash_register'), value: 'CASHIER' },
                              { label: t('modules.matches_component.checkbox_group.widget'), value: 'WEB_WIDGET' },
                            ]}
                          />
                        </div>
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.date_of')}
                        </div>
                        <div className="events__right-container__body__date-container">
                          <DatePicker
                            className="events__right-container__body__date-container__item"
                            defaultValue={
                              stateProps.selectedMatch.startTime ? dayjs(stateProps.selectedMatch.startTime) : null
                            }
                            format="DD.MM.YYYY"
                            suffixIcon={<CalendarOutlined />}
                            onChange={(ev: dayjs.Dayjs | null) => {
                              setState({
                                ...stateProps,
                                newData: {
                                  ...stateProps.newData,
                                  date: ev,
                                },
                              });
                            }}
                          />
                          <TimePicker
                            defaultValue={
                              stateProps.selectedMatch.startTime ? dayjs(stateProps.selectedMatch.startTime) : null
                            }
                            className="events__right-container__body__date-container__item"
                            format="HH:mm"
                            minuteStep={5}
                            onChange={(ev) => {
                              setState({
                                ...stateProps,
                                newData: {
                                  ...stateProps.newData,
                                  time: ev,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.tournament')}
                        </div>
                        <LocalizedSelect
                          data={stateProps.tournamentsLocalizedList}
                          currentLng={i18next.language}
                          placeholder={t('modules.matches_component.select.choose_tournament')}
                          defaultValue={
                            stateProps.selectedMatch.tournament
                              ? stateProps.selectedMatch.tournament
                              : stateProps.newData.tournament
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            setState({
                              ...stateProps,
                              newData: {
                                ...stateProps.newData,
                                tournament: ev,
                                stage: undefined,
                              },
                              selectedMatch: {
                                ...stateProps.selectedMatch,
                                tournament: ev,
                                stage: undefined,
                              },
                              isTournamentUpdated: true,
                            });
                          }}
                        />
                        {(stateProps.selectedMatch.tournament || stateProps.newData.tournament) &&
                          !stateProps.isTournamentUpdated && (
                            <>
                              <div className="events__right-container__body__title">
                                {t('modules.matches_component.div.stage')}
                              </div>
                              <LocalizedSelect
                                data={filteredStages()}
                                currentLng={i18next.language}
                                placeholder={t('modules.matches_component.select.choose_stage')}
                                defaultValue={
                                  stateProps.selectedMatch.stage
                                    ? stateProps.selectedMatch.stage
                                    : stateProps.newData.stage
                                }
                                className="events__right-container__body__select"
                                onChange={(ev) => {
                                  setState({
                                    ...stateProps,
                                    newData: {
                                      ...stateProps.newData,
                                      stage: ev,
                                    },
                                  });
                                }}
                              />
                            </>
                          )}
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.home_team')}
                        </div>
                        <LocalizedSelect
                          data={stateProps.teamsLocalizedList}
                          currentLng={i18next.language}
                          placeholder={t('modules.matches_component.select.choose_command')}
                          defaultValue={
                            stateProps.selectedMatch.team1
                              ? stateProps.selectedMatch.team1.id
                              : stateProps.newData.team1
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            setState({
                              ...stateProps,
                              newData: {
                                ...stateProps.newData,
                                team1: ev,
                              },
                            });
                          }}
                        />
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.guest_team')}
                        </div>
                        <LocalizedSelect
                          data={stateProps.teamsLocalizedList}
                          currentLng={i18next.language}
                          placeholder={t('modules.matches_component.select.choose_command')}
                          defaultValue={
                            stateProps.selectedMatch.team2
                              ? stateProps.selectedMatch.team2.id
                              : stateProps.newData.team2
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            setState({
                              ...stateProps,
                              newData: {
                                ...stateProps.newData,
                                team2: ev,
                              },
                            });
                          }}
                        />
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.stadium')}
                        </div>
                        <LocalizedSelect
                          data={stateProps.stadiumsLocalizedList}
                          currentLng={i18next.language}
                          placeholder={t('modules.matches_component.select.choose_stadium')}
                          defaultValue={
                            stateProps.selectedMatch.stadium
                              ? stateProps.selectedMatch.stadium
                              : stateProps.newData.stadium
                          }
                          className="events__right-container__body__select"
                          onChange={(ev) => {
                            setState({
                              ...stateProps,
                              newData: {
                                ...stateProps.newData,
                                stadium: ev,
                              },
                            });
                          }}
                        />
                        <div className="events__right-container__body__title">
                          {t('modules.matches_component.div.price_template')}
                        </div>
                        <Radio.Group
                          onChange={handlePriceTemplate}
                          defaultValue={stateProps.singleSelect}
                          className="events__right-container__body__radio"
                        >
                          <Radio className="events__right-container__body__radio__text" value={true}>
                            {t('modules.matches_component.radio.single_price')}
                          </Radio>
                          <Radio className="events__right-container__body__radio__text" value={false}>
                            {t('modules.matches_component.radio.different_price')}
                          </Radio>
                        </Radio.Group>
                        {stateProps.singleSelect ? (
                          <Select
                            placeholder={t('modules.matches_component.select.choose_price_template')}
                            className="events__right-container__body__select"
                            defaultValue={
                              stateProps.matchTemplateOnline
                                ? stateProps.matchTemplateOnline.id
                                : stateProps.newData.singleTemplate
                            }
                            onChange={(ev) => {
                              setState({
                                ...stateProps,
                                newData: {
                                  ...stateProps.newData,
                                  singleTemplate: ev,
                                },
                              });
                            }}
                          >
                            {stateProps.templateList.map((item) => {
                              return (
                                <SelectOption key={uuid4()} value={item.id}>
                                  {item.title.toUpperCase()}
                                </SelectOption>
                              );
                            })}
                          </Select>
                        ) : (
                          <>
                            <div className="events__right-container__body__subTitle">
                              {t('modules.matches_component.div.price_template_online_sales')}
                            </div>
                            <Select
                              placeholder={t('modules.matches_component.select.choose_price_template')}
                              className="events__right-container__body__select"
                              defaultValue={
                                stateProps.matchTemplateOnline
                                  ? stateProps.matchTemplateOnline.id
                                  : stateProps.newData.onlineTemplate
                              }
                              onChange={(ev) => {
                                setState({
                                  ...stateProps,
                                  newData: {
                                    ...stateProps.newData,
                                    onlineTemplate: ev,
                                  },
                                });
                              }}
                            >
                              {stateProps.templateList.map((item) => {
                                return (
                                  <SelectOption key={uuid4()} value={item.id}>
                                    {item.title.toUpperCase()}
                                  </SelectOption>
                                );
                              })}
                            </Select>
                            <div className="events__right-container__body__subTitle-last">
                              {t('modules.matches_component.div.price_template_offline_sales')}
                            </div>
                            <Select
                              placeholder={t('modules.matches_component.select.choose_price_template')}
                              className="events__right-container__body__select"
                              defaultValue={
                                stateProps.matchTemplateOffline
                                  ? stateProps.matchTemplateOffline.id
                                  : stateProps.newData.offlineTemplate
                              }
                              onChange={(ev) => {
                                setState({
                                  ...stateProps,
                                  newData: {
                                    ...stateProps.newData,
                                    offlineTemplate: ev,
                                  },
                                });
                              }}
                            >
                              {stateProps.templateList.map((item) => {
                                return (
                                  <SelectOption key={uuid4()} value={item.id}>
                                    {item.title.toUpperCase()}
                                  </SelectOption>
                                );
                              })}
                            </Select>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <LocalizedTextEditor
                          value={stateProps.selectedMatch.description}
                          onChange={(value) => updateMatches('description', value)}
                          labelCustomization={(lng) => (
                            <h3 className="events__right-container__body__title" style={{ marginBottom: 10 }}>
                              {`${t('modules.matches_component.h3')}${supportedLangs.length > 1 ? ` [${lng}]` : ''}`}
                            </h3>
                          )}
                        />
                      </>
                    )}
                  </div>
                  <div className="events__right-container__footer">
                    <Button
                      id="MatchesComponentSaveButton"
                      type="primary"
                      className="permissions__right-container__footer__button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isUpdatingProcessing: true,
                        });
                        if (stateProps.isNewMatch) {
                          addMatch();
                        } else {
                          updateMatch();
                        }
                        // else {
                        //   if (
                        //     stateProps.newTitle &&
                        //     !isEmpty(stateProps.newUserList)
                        //   ) {
                        //     setState({
                        //       ...stateProps,
                        //       isUpdatingProcessing: true
                        //     });
                        //     insertRole();
                        //   } else if (!stateProps.newTitle) {
                        //     message.error(t('modules.matches_component.message_error.enter_role_name'));
                        //   } else if (isEmpty(stateProps.newUserList)) {
                        //     message.error(t('modules.matches_component.message_error.assign_new_users'));
                        //   }
                        // }
                      }}
                    >
                      {stateProps.isUpdatingProcessing ? (
                        <LoadingOutlined />
                      ) : stateProps.isNewMatch ? (
                        t('modules.matches_component.button.save_match')
                      ) : (
                        t('modules.matches_component.button.save_changes')
                      )}
                    </Button>
                    {/* {!stateProps.isNewMatch && (
                    <Button
                      id="MatchesComponentDeleteMatchButton"
                      type="primary"
                      className="events__right-container__footer__delete"
                      onClick={ev => {
                        setState({
                          ...stateProps,
                          isDeleteModalVisible: true
                        });
                      }}
                    >
                      {t('modules.matches_component.button.delete_match')}
                    </Button>
                  )} */}
                  </div>
                </div>
              )}
              <Button
                id="MatchesComponentAddMatchButton"
                className="sales__price-template__add-template"
                onClick={() => {
                  setSegmentsConfiguration([]);
                  setState({
                    ...stateProps,
                    newData: {},
                    isNewMatch: true,
                    isUpdated: true,
                    isDataVisible: true,
                    selectedMatch: {},
                    singleSelect: true,
                    singleTemplate: null,
                    onlineTemplate: null,
                    offlineTemplate: null,
                    matchTemplateOnline: null,
                    matchTemplateOffline: null,
                  });
                }}
              >
                <PlusOutlined className="sales__price-template__add-template__icon" />
                <span className="sales__price-template__add-template__text">{t('modules.matches_component.span')}</span>
              </Button>
              <Modal
                visible={stateProps.isDeleteModalVisible}
                centered
                closable={false}
                onCancel={() => {
                  setState({
                    ...stateProps,
                    isDeleteModalVisible: false,
                  });
                }}
                width={415}
                footer={null}
              >
                <>
                  <div className="sales__tickets__list-table__accept-modal__text-container">
                    <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
                    <div className="sales__tickets__list-table__accept-modal__text">
                      {t('modules.matches_component.div.you_want_delete_match')}
                    </div>
                  </div>
                  <div className="sales__tickets__list-table__accept-modal__button-container">
                    <Button
                      id="MatchesComponentCancelButton"
                      className="sales__tickets__list-table__accept-modal__decline-button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDeleteModalVisible: false,
                        });
                      }}
                    >
                      {t('modules.matches_component.button.cancel')}
                    </Button>
                    <Button
                      id="MatchesComponentDeleteButton"
                      className="sales__tickets__list-table__accept-modal__accept-button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDeleteProcessing: true,
                        });
                        deleteMatch();
                      }}
                    >
                      {stateProps.isDeleteProcessing ? (
                        <LoadingOutlined />
                      ) : (
                        t('modules.matches_component.button.delete')
                      )}
                    </Button>
                  </div>
                </>
              </Modal>
            </>
          ) : (
            <div className="with-preloader__map">
              <LoadingOutlined />
              <div className="with-preloader__info"></div>
            </div>
          )}
        </div>
      </ConfigProvider>
    </>
  );
};

export default withApollo(MatchesComponent);
