import { pathOr } from 'ramda';
import { message } from 'antd';
import { GET_FILE_DESCRIPTOR } from '../query';
import { ACQUIRE_FILE, RELEASE_FILE } from '../mutation';
import { ApolloClient } from 'apollo-client';

export const getFileDescriptor = async (client: ApolloClient<any>, fileId: string, errorMessage?: string) => {
  try {
    const fileRes = await client.query({
      query: GET_FILE_DESCRIPTOR,
      fetchPolicy: 'no-cache',
      variables: {
        id: fileId
      }
    });
    const publicUrl: string = pathOr('', ['data', 'file', 'getFileDescriptor', 'publicLink'], fileRes);
    const useCount = pathOr<number | undefined>(undefined, ['data', 'file', 'getFileDescriptor', 'useCount'], fileRes);
    return {
      publicUrl,
      useCount
    };
  } catch (e) {
    console.error(e);
    errorMessage && message.error(errorMessage);
  }
};

/** TODO: Manual file acquiring and releasing can be removed when it is implemented on the backend */
export const releaseFile = async (client: ApolloClient<any>, fileId: string, errorMessage?: string) => {
  const fileData = await getFileDescriptor(client, fileId);
  if (fileData) {
    if (fileData?.useCount && (fileData.useCount > 0)) {
      // ... if the file was not used - do acquiring (+1)
      try {
        await client.query({
          query: RELEASE_FILE,
          fetchPolicy: 'no-cache',
          variables: {
            id: fileId
          }
        });
      } catch (e) {
        console.error(e);
        errorMessage && message.error(errorMessage);
      }
    }
  }
};

export const acquireFile = async (client: ApolloClient<any>, fileId: string, errorMessage?: string) => {
  // Check the number of uses of the file...
  const fileData = await getFileDescriptor(client, fileId);
  if (fileData?.useCount === 0) {
    // ... if the file was not used - do acquiring (+1)
    try {
      await client.query({
        query: ACQUIRE_FILE,
        fetchPolicy: 'no-cache',
        variables: {
          id: fileId
        }
      });
    } catch (e) {
      console.error(e);
      errorMessage && message.error(errorMessage);
    }
  }
};
