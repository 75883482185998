import { message } from 'antd';
import { t } from 'i18next';
import { pathOr } from 'ramda';

import { mutations } from '../../graphql';
import { IRemoveCode } from '../interfaces/IMutationEvents';

message.config({
  maxCount: 1,
});

export const removeCode = (props: IRemoveCode): void => {
  const loadMessage = message.loading(t('modules.remove_code.message_loading.remove'), 0);
  const { defaultPromoId, value } = props;
  const { client, setCodesToReducer } = props;
  let codes = pathOr([], ['codes'], props);

  client
    .mutate({
      mutation: mutations.REMOVE_CODE,
      variables: {
        data: {
          descriptorId: defaultPromoId,
          values: [value],
        },
      },
    })
    .then(() => {
      message.success(t('modules.remove_code.message_success.removed_successfully'));
      codes = codes.filter((item: { value: string }) => item.value !== value);
      const codesData = {
        codes,
      };
      setCodesToReducer(codesData);
    })
    .catch(() => {
      message.error(t('modules.remove_code.message_error.failed_remove'));
    })
    .finally(() => loadMessage());
};

export const generateNewCodes = async (props: any) => {
  const loadMessage = message.loading(t('modules.remove_code.message_loading.generation'), 0);
  const { prefix, quantity, defaultPromoId } = props;
  const { client } = props;

  if (prefix.trim().length === 0) return message.error(t('modules.remove_code.message_error.enter_name_promocode'));

  await client
    .mutate({
      mutation: mutations.GENERATE_NEW_CODES,
      variables: {
        data: {
          prefix,
          quantity: Number.isNaN(quantity) ? 1 : quantity,
          descriptorId: defaultPromoId,
        },
      },
    })
    .then((res: any) => {
      const newList = pathOr([], ['data', 'promocodes', 'generatePromocodes', 'codes', 'list'], res);
      props.setDataCode(newList);
      message.success(t('modules.remove_code.message_success.successfully_generated'));
    })
    .catch(() => {
      message.error(t('modules.remove_code.message_error.failed_generate'));
    })
    .finally(() => loadMessage());
};
