import React from 'react';
import { ACC_RequestType, Event, Events, Match, Season, Tournament } from '../../../../../model/accreditationModel';
import { Icon } from '@ant-design/compatible';
import { Button, Col, Dropdown, Menu, Radio, Row } from 'antd';
import { requestTypeToSelectorString, requestTypeToString } from '../../../../../../../../utils/helpers';
import EventSelector from '../../../EventSelector/EventSelector';
import styles from './AccreditationTypeSelector.module.scss';
import { withTranslation } from 'react-i18next';

function AccreditationTypeSelector(props: {
  requestTypes?: ACC_RequestType[];
  accreditationType: ACC_RequestType;
  setAccreditationType: any;
  events: Events;
  currentEvent: Events;
  setCurrentEvent: (currentEvent: Events) => void;
  t?: any;
}) {
  const getEvents = (type: ACC_RequestType) => {
    switch (type) {
      case ACC_RequestType.FOR_MATCH:
        return props.events.matches;
      case ACC_RequestType.FOR_SEASON:
        return props.events.seasons;
      case ACC_RequestType.FOR_TOURNAMENT:
        return props.events.tournaments;
    }
  };
  return (
    <>
      <Row className={styles.item}>
        <Col className={styles.name} span={5}>
          {props.t('modules.accreditation_type_selector.type')}
        </Col>
        <Col span={19}>
          <Radio.Group value={props.accreditationType} onChange={(e) => props.setAccreditationType(e.target.value)}>
            {props.requestTypes &&
              props.requestTypes.map((item: string, index: number) => (
                <Radio key={index} className={styles.radio} value={item}>
                  {requestTypeToString(item)}
                </Radio>
              ))}
          </Radio.Group>
        </Col>
      </Row>
      <ChangeEventDropdown
        events={getEvents(props.accreditationType)}
        setCurrentEvent={props.setCurrentEvent}
        accreditationType={props.accreditationType}
        t={props.t}
      />
      <EventSelector currentEvent={props.currentEvent} requestType={props.accreditationType} />
    </>
  );
}

function ChangeEventDropdown(props: {
  events: Array<Event>;
  accreditationType: ACC_RequestType;
  setCurrentEvent: (currentEvent: Events) => void;
  t: any;
}) {
  const dropdownTitle = `${props.t('modules.accreditation_type_selector.dropdown_title')} ${requestTypeToSelectorString(
    props.accreditationType
  )}`;

  function handleChange(e: any) {
    const id = e.key;
    props.setCurrentEvent({
      matches: [],
      tournaments: [],
      seasons: [],
      [eventTypeToKey(props.accreditationType)]: [...props.events.filter((event) => event.id === id)],
    });
  }

  function eventTypeToKey(eventType: string) {
    let key: string = '';
    switch (eventType) {
      case 'FOR_MATCH':
        key = 'matches';
        break;
      case 'FOR_TOURNAMENT':
        key = 'tournaments';
        break;
      case 'FOR_SEASON':
        key = 'seasons';
        break;
      default:
        break;
    }
    return key;
  }

  const menu = (
    <Menu onClick={handleChange}>
      {props.events.map((event) => (
        <Menu.Item key={event.id} className={styles.menuItem}>
          {renderOption(event)}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button id="ChangeEventDropdownButton" className={styles.btnSelect} type="ghost" size="large">
        {dropdownTitle}
        <Icon type="down" />
      </Button>
    </Dropdown>
  );
}

const MatchOption = (props: { match: Match | undefined }) => (
  <>
    {props.match && (
      <div className={'styles.match'}>
        <div>
          <div>{`${props.match.team1.title} vs ${props.match.team2.title},`}</div>
          {props.match.startTime && (
            <div>
              {props.match.startTime.toLocaleDateString('en', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          )}
        </div>
        {/* <img src={props.match.team2.logo?.publicLink} alt={props.match.team2.title} /> */}
      </div>
    )}
  </>
);

const SeasonOption = (props: { season: Season | undefined; t?: any }) => (
  <>
    {props.season && (
      <div className={styles.season}>
        {`
        ${props.t('modules.accreditation_type_selector.season_option')}
        ${props.season.startDate.getFullYear()}/${props.season.endDate.getFullYear()}
        `}
      </div>
    )}
  </>
);

const TournamentOption = (props: { tournament: Tournament | undefined }) => (
  <>
    {props.tournament && (
      <div className={'styles.tournament'}>
        {/* {props.tournament.logo && <img src={props.tournament.logo.publicLink} alt={props.tournament.title} />} */}
        {props.tournament.title}
      </div>
    )}
  </>
);

const renderOption = (event: Event) => {
  if ('team1' in event) return <MatchOption match={event as Match} />;
  if ('startDate' in event) return <SeasonOption season={event as Season} />;
  return <TournamentOption tournament={event as Tournament} />;
};

export default withTranslation()(AccreditationTypeSelector);
