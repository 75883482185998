import priceTemplate from './Tickets/priceTemplate';
import categoryPrice from './CategoryPrice';
import stadiumComponent from './Stadium';
import permissionsComponent from './Permissions';
import MatchesComponent from './Events/Matches/MatchesComponent';
import TeamsComponent from './Events/Teams/TeamsComponent';
import SeasonsComponent from './Events/Seasons/SeasonsComponent';
import TournamentsComponent from './Events/Tournaments/TournamentsComponent';
import SeasonTicketsComponent from './Events/SeasonTickets/SeasonTicketsComponent';
import './index.scss';
import { t } from 'i18next';

import { Action, IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { withProps } from 'recompose';
import cpReduser from './CategoryPrice/reducer';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import reducer from './Tickets/reducer';

export class ModuleTickets implements Module {
  private app: IApp;
  private component: any; //TODO
  private routeContainer: IRouteContainer;
  private menuContainer: ISidebar;
  private reducer: any;

  constructor(app: IApp, routeContainer: IRouteContainer, menuContainer: ISidebar, authClient: AuthClient) {
    this.component = {
      priceTemplate: withProps({
        authClient: authClient
      })(priceTemplate),
      categoryPrice,
      stadiumComponent,
      permissionsComponent,
      MatchesComponent,
      TeamsComponent: withProps({
        authClient: authClient
      })(TeamsComponent),
      SeasonsComponent,
      TournamentsComponent: withProps({
        authClient: authClient
      })(TournamentsComponent),
      SeasonTicketsComponent
    };

    this.app = app;
    this.menuContainer = menuContainer;
    this.routeContainer = routeContainer;

    this.routeContainer.addRoute({
      path: '/admin/sales',
      component: this.component.priceTemplate
    });
    this.routeContainer.addRoute({
      path: '/admin/sales/price-templates',
      component: this.component.priceTemplate
    });
    this.routeContainer.addRoute({
      path: '/admin/events/matches',
      component: this.component.MatchesComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/events/teams',
      component: this.component.TeamsComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/events/seasons',
      component: this.component.SeasonsComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/events/tournaments',
      component: this.component.TournamentsComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/events/seasonTickets',
      component: this.component.SeasonTicketsComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/stadium/history',
      component: this.component.stadiumComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/stadium/markup',
      component: this.component.stadiumComponent
    });
    this.routeContainer.addRoute({
      path: '/admin/category-price',
      component: this.component.categoryPrice
    });
    this.routeContainer.addRoute({
      path: '/admin/CDP/permissions',
      component: this.component.permissionsComponent
    });
    this.reducer = {
      name: 'map',
      reducer
    };
    this.app.addReducer(this.reducer);

    const permissionsLink = new RouteAction({
      title: t('modules.tickets_index.roles'),
      name: 'permissions',
      order: 12,
      enabled: true,
      visible: true,
      icon: 'user',
      route: 'admin/CDP/permissions',
      permission: 'ACCESS_ROLES',
      localizationKey: 'permissions'
    });

    this.menuContainer.addLink(permissionsLink);
    const pricesTemplateEventLink = new RouteAction({
      title: t('modules.tickets_index.tickets'),
      name: 'ticektsList',
      order: 4.1,
      enabled: true,
      visible: true,
      route: 'admin/sales',
      localizationKey: 'tickets_list'
    });
    const pricesSalesEventLink = new RouteAction({
      title: t('modules.tickets_index.prices'),
      name: 'templates',
      order: 4.2,
      enabled: true,
      visible: true,
      route: 'admin/sales/price-templates',
      localizationKey: 'prices'
    });
    const pricesLink = new Action({
      title: t('modules.tickets_index.sales'),
      icon: 'shopping-cart',
      name: 'tickets',
      order: 4,
      enabled: true,
      visible: true,
      subitems: [pricesTemplateEventLink, pricesSalesEventLink],
      permission: 'ACCESS_SALES',
      localizationKey: 'sales'
    });
    const eventMatchesLink = new RouteAction({
      title: t('modules.tickets_index.matches'),
      name: 'matches',
      order: 6.1,
      enabled: true,
      visible: true,
      route: 'admin/events/matches',
      localizationKey: 'matches'
    });
    const eventTournamentsLink = new RouteAction({
      title: t('modules.tickets_index.tournaments'),
      name: 'tournaments',
      order: 6.2,
      enabled: true,
      visible: true,
      route: 'admin/events/tournaments',
      localizationKey: 'tournaments'
    });
    const eventTeamsLink = new RouteAction({
      title: t('modules.tickets_index.teams'),
      name: 'matches',
      order: 6.3,
      enabled: true,
      visible: true,
      route: 'admin/events/teams',
      localizationKey: 'teams'
    });
    const eventSeasonsLink = new RouteAction({
      title: t('modules.tickets_index.seasons'),
      name: 'seasons',
      order: 6.4,
      enabled: true,
      visible: true,
      route: 'admin/events/seasons',
      localizationKey: 'seasons'
    });
    const eventSeasonTicketsLink = new RouteAction({
      title: t('modules.tickets_index.subscriptions'),
      name: 'seasonTickets',
      order: 6.5,
      enabled: true,
      visible: true,
      route: 'admin/events/seasonTickets',
      localizationKey: 'season_tickets'
    });
    const eventLink = new Action({
      title: t('modules.tickets_index.events'),
      icon: 'calendar',
      name: 'events',
      order: 6,
      enabled: true,
      visible: true,
      subitems: [eventMatchesLink, eventTeamsLink, eventSeasonsLink, eventTournamentsLink, eventSeasonTicketsLink],
      permission: 'ACCESS_EVENTS',
      localizationKey: 'events'
    });

    // const a1 = new Action({
    //   title: t('modules.tickets_index.tickets'),
    //   icon: 'line-chart',
    //   name: 'tickets',
    //   order: 4.2,
    //   enabled: true,
    //   visible: true,
    //   subitems: [priceTemplateEventLink, pricesTemplateLink]
    // });

    this.menuContainer.addLink(eventLink);
    this.menuContainer.addLink(pricesLink);

    const placesHistory = new RouteAction({
      title: t('modules.tickets_index.history'),
      name: 'placesHistory',
      order: 5.1,
      enabled: true,
      visible: true,
      route: 'admin/stadium/history',
      localizationKey: 'places_history'
    });
    const stadiumMarkup = new RouteAction({
      title: t('modules.tickets_index.marking'),
      name: 'markup',
      order: 5.2,
      enabled: true,
      visible: true,
      route: 'admin/stadium/markup',
      localizationKey: 'stadium_markup'
    });

    const stadium = new Action({
      title: t('modules.tickets_index.stadium'),
      icon: 'flag',
      name: 'tickets',
      order: 5,
      enabled: true,
      visible: true,
      subitems: [placesHistory, stadiumMarkup],
      permission: 'ACCESS_STADIUM',
      localizationKey: 'stadium'
    });

    this.menuContainer.addLink(stadium);

    this.app.addReducer({name: 'categoryPrice', reducer: cpReduser});
  }

  start() {
  }
}
