import ApolloClient from 'apollo-client';
import { InsertUser, Role, FilteredRole } from './usersModel';
import { GET_ROLES_LIST } from './usersQueries';
import { INSERT_USER } from './usersMutations';
import { deserializeRoles } from './usersDeserializers';
import { deserialize, ObjectDeserializator } from 'utils/apiHelpers';


export interface UsersRepository {
  insertUser(data: InsertUser): Promise<void>,
  getRolesList(): Promise<FilteredRole[]>
}


export class UsersRepositoryImpl implements UsersRepository {
  constructor(private client: ApolloClient<any>) {}

  async getRolesList() {
    const response = await this.client.query({ query: GET_ROLES_LIST });
    const deserializedResult: Role[] = deserialize(response, (o) =>
      o.required('data.roles.getList').asArrayOfObjects((o: ObjectDeserializator) => deserializeRoles(o)),
    );
    const result = deserializedResult.filter((item: Role) => !item.internal).map((item: Role) => {
      return {
        id: item.id,
        name: item.description?.split('&', 1).join('') || item.name,
      }
    });

    return result;
  }

  async insertUser(data: InsertUser) {
    await this.client.mutate({ mutation: INSERT_USER, variables: { user: data } });
  }
}
