import gql from 'graphql-tag';

export const ACCEPT_ACCREDITATION = gql`
  mutation acceptAccreditation($id: ID!) {
    accreditation {
      accreditation {
        acceptRequest(id: $id, notificationTypes: [BY_EMAIL, BY_SMS]) {
          id
        }
      }
    }
  }
`;

export const REJECT_ACCREDITATION = gql`
  mutation rejectAccreditation($id: ID!, $data: ACC_RejectionInput!) {
    accreditation {
      accreditation {
        rejectRequest(id: $id, data: $data, notificationTypes: [BY_EMAIL, BY_SMS]) {
          id
        }
      }
    }
  }
`;

export const USER_UPDATE = gql`
  mutation userUpdate($id: ID!, $user: UserUpdateInput!) {
    users {
      update(id: $id, user: $user) {
        id
        person {
          patronymic
          surname
          name
          photo {
            id
          }
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;

export const SERVICE_DATA_UPDATE = gql`
  mutation serviseDataUpdate($id: ID!, $data: ACC_AccreditationRequestUpdateInput!) {
    accreditation {
      accreditation {
        update(id: $id, data: $data) {
          id
        }
      }
    }
  }
`;

export const ACCREDITATION_UPDATE = gql`
  mutation serviseDataUpdate($id: ID!, $data: ACC_AccreditationRequestUpdateInput!) {
    accreditation {
      accreditation {
        update(id: $id, data: $data) {
          id
        }
      }
    }
  }
`;
