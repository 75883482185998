import React from 'react';

const Download = props => {
  return (
    <svg className={props.className} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.979 1.75171L11.979 18.8833" stroke="#C4C4C4" stroke-width="2"/>
        <path d="M19.1632 11.6991L11.979 18.8833L4.79478 11.6991" stroke="#C4C4C4" stroke-width="2"/>
        <path d="M2.53613 18.7517V22.7517H22.5361V18.7517" stroke="#C4C4C4" stroke-width="2"/>
    </svg>

  );
};

export default Download;
