import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import iconLoader from '../../img/iconLoader.svg';
import { t } from 'i18next';

const determineWordsEnd = (number: number) => {
  const wordsArray = [
    t('modules.format_use_coupons.words_array.once'),
    t('modules.format_use_coupons.words_array.times'),
  ];
  const string = number.toString();

  if (string.endsWith('12') || string.endsWith('13') || string.endsWith('14')) {
    return `${number} ${wordsArray[0]}`;
  } else if (string.endsWith('2') || string.endsWith('3') || string.endsWith('4')) {
    return `${number} ${wordsArray[1]}`;
  } else {
    return `${number} ${wordsArray[0]}`;
  }
};

const maxCounts = (number?: number) => {
  return number ? `${t('modules.format_use_coupons.max_counts')} ${number}` : '';
};

export default function formatUseCoupons(useCounter: number, maxUseCountsPerUser?: number) {
  switch (useCounter) {
    case 0:
      return (
        <>
          <img
            src={iconLoader}
            alt={t('modules.format_use_coupons.img')!}
            style={{ width: '14px', marginRight: '8px' }}
          />
          <span>{t('modules.format_use_coupons.span.not_applied_yet')}</span>
        </>
      );
    default:
      return (
        <>
          <CheckOutlined style={{ color: '#03B94E', marginRight: '8px' }} />
          <span>{`${t('modules.format_use_coupons.span.applied')} ${determineWordsEnd(useCounter)} ${maxCounts(
            maxUseCountsPerUser
          )}`}</span>
        </>
      );
  }
}
