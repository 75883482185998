import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdateInput!) {
    users {
      update(id: $id, user: $user) {
        id
      }
    }
  }
`;
