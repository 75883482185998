import gql from 'graphql-tag';

export const CREATE_COUPON = gql `
  mutation createCoupon($data: PromocodeDescriptorInput!) { 
    promocodes { 
      add(data: $data) {
        id
      } 
    }
  }
`;

export const ADD_USERS_TO_COUPON = gql `
  mutation addUsersToCoupon($descriptorId: ID!, $userIds: [ID!]!) { 
    promocodes { 
      addCouponToUsers(descriptorId: $descriptorId, userIds: $userIds) {
        value
      }
    }
  }
`;

export const UPDATE_COUPON = gql `
  mutation updateCoupon($id: ID!, $data: PromocodeDescriptorUpdateInput!) { 
    promocodes { 
      update(id: $id, data: $data) {
        id
      } 
    }
  }
`;
