import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import ModalCloseWindowWarning from './ModalCloseWindowWarning/ModalCloseWindowWarning';

interface PropsType {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  // stop reload page, if form was changed and wasn't save
  useEffect(() => {
    const fun = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (when) {
      window.addEventListener('beforeunload', fun);
    } else {
      window.removeEventListener('beforeunload', fun);
    }
    return () => window.removeEventListener('beforeunload', fun);
  }, [when]);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalCloseWindowWarning open={modalVisible} onCancel={closeModal} onConfirm={handleConfirmNavigationClick} />
    </>
  );
};
export default RouteLeavingGuard;
