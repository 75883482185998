import React from 'react';
import { Tag, Select } from 'antd';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

const PromoAddSelect = (props) => {
  const { t } = useTranslation();
  const tagList = pathOr([], ['tags'], props);
  const list = pathOr([], ['boundTypeList'], props);
  return (
    <div className="promo-modal__body">
      <div className="promo-modal__header">{props.title}</div>
      {props.seasonList && (
        <div className="promo-modal__season-select">
          <Select
            onChange={props.handleSelectSeason}
            style={{ width: '100%' }}
            placeholder={t('modules.promo_add_select')}
          >
            {props.seasonList.map((item) => (
              <Select.Option key={JSON.stringify({ id: item.id, title: item.title })}>{item.title}</Select.Option>
            ))}
          </Select>
        </div>
      )}
      <div className="promo-modal__select">
        <Select
          onChange={props.handleSelectBoundItems}
          style={{ width: '100%' }}
          mode="multiple"
          onInputKeyDown={props.handleChangePlace}
        >
          {list.map((item) => (
            <Select.Option
              key={JSON.stringify({ id: item.id, title: item.title, type: item.type ? item.type : undefined })}
            >
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="promo-modal__position-list">
        {tagList.map((item) => {
          return <Tag title={item.title} />;
        })}
      </div>
    </div>
  );
};

export default PromoAddSelect;
