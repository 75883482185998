import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import {
  ADD_STAGE_INFO,
  ADD_TOURNAMENT_INFO,
  GET_TOURNAMENTS_INFO_LIST,
  REMOVE_STAGE,
  REMOVE_TOURNAMENT,
  UPDATE_STAGE_INFO,
  UPDATE_TOURNAMENT_INFO,
} from '../graphql';
import { withApollo } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from 'utils/LocalizedString';
import { LocalizedTextInput } from 'common/LocalizedTextInput';
import styles from './TournamentsComponent.module.scss';
import i18next from 'i18next';
import { getSupportedLngs } from 'i18n';
import { acquireFile, getFileDescriptor, releaseFile } from '../graphql/utils/fileHandlers';
import { trimStringProperties } from 'utils/helpers';

const TournamentsComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    selectedTournament: {},
    newStages: [],
    updatedStages: [],
  });

  const supportedLangs = getSupportedLngs();

  const getTextLocalize = (obj, language) => {
    return LocalizedString.fromObject(obj).toString(language);
  };

  const isTitleStage = (stage) => {
    let isResult;
    if (stage.title) {
      isResult = supportedLangs.every((lng) => {
        if (stage.title.hasOwnProperty(lng)) {
          return getTextLocalize(stage.title, lng).trim().length > 0;
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
    return isResult;
  };

  const isTextTitlesStages = () => {
    let isResult;
    if (stateProps.newStages.length === 0 && stateProps.updatedStages.length === 0) {
      isResult = true;
      return isResult;
    } else if (stateProps.newStages.length > 0) {
      isResult = stateProps.newStages.every((stage) => {
        return isTitleStage(stage);
      });
    } else {
      isResult = stateProps.updatedStages.every((stage) => {
        return isTitleStage(stage);
      });
    }
    return isResult;
  };

  const addTournament = async () => {
    try {
      const tournamentRes = await props.client.query({
        query: ADD_TOURNAMENT_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            title: stateProps.newTitle,
            description: stateProps.newDescription,
            logoFileId: stateProps.logoFileId,
          },
        },
      });
      const id = pathOr('', ['data', 'tournamentLocalised', 'add', 'id'], tournamentRes);
      for (const stage of stateProps.newStages) {
        if (stage.title) {
          await props.client.query({
            query: ADD_STAGE_INFO,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                title: stage.title,
                tournamentId: id,
                order: 0,
              },
            },
          });
        }
      }
      if (stateProps.logoFileId) {
        await acquireFile(props.client, stateProps.logoFileId);
      }
      message.success(t('modules.tournaments_component.message_success.tournament_created'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tournaments_component.message_error.tournament_creation_error'));
    } finally {
      restoreState();
    }
  };

  const updateTournament = async () => {
    const data = {};
    if (stateProps.newTitle) {
      data.title = stateProps.newTitle;
    }
    if (stateProps.newDescription) {
      data.description = stateProps.newDescription;
    }
    data.logoFileId = stateProps.logoFileId;

    try {
      await props.client.query({
        query: UPDATE_TOURNAMENT_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedTournament.id,
          data: data,
        },
      });
      for (const stage of stateProps.updatedStages) {
        if (stage.title) {
          await props.client.query({
            query: UPDATE_STAGE_INFO,
            fetchPolicy: 'no-cache',
            variables: {
              id: stage.id,
              data: {
                title: stage.title,
                tournamentId: stateProps.selectedTournament.id,
                order: 0,
              },
            },
          });
        }
      }

      for (const stage of stateProps.newStages) {
        if (stage.title) {
          await props.client.query({
            query: ADD_STAGE_INFO,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                title: stage.title,
                tournamentId: stateProps.selectedTournament.id,
                order: 0,
              },
            },
          });
        }
      }
      if (stateProps.logoFileId && stateProps.selectedTournament.logo.id !== stateProps.logoFileId) {
        await releaseFile(props.client, stateProps.selectedTournament.logo.id);
        await acquireFile(props.client, stateProps.logoFileId);
      }
      message.success(t('modules.tournaments_component.message_success.tournament_updated'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tournaments_component.message_error.tournament_update_error'));
    } finally {
      restoreState();
    }
  };

  const removeTournament = async () => {
    try {
      await props.client.query({
        query: REMOVE_TOURNAMENT,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedTournament.id,
        },
      });
      for (const stage of stateProps.selectedTournament.stages) {
        await removeStage(stage.id);
      }
      await releaseFile(
        props.client,
        stateProps.selectedTournament.logo.id,
        t('modules.teams_component.message_error.logo_delete')
      );
      message.success(t('modules.tournaments_component.message_success.tournament_removed'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tournaments_component.message_error.tournament_deletion_error'));
    } finally {
      restoreState();
    }
  };

  const removeStage = async (stageId) => {
    try {
      await props.client.query({
        query: REMOVE_STAGE,
        fetchPolicy: 'no-cache',
        variables: {
          id: stageId,
        },
      });
      message.success(t('modules.tournaments_component.message_success.stage_deleted'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tournaments_component.message_error.stage_deletion_error'));
    } finally {
      restoreState();
    }
  };

  const handleRemove = async () => {
    setState({
      ...stateProps,
      isRemovingProcessing: true,
    });
    await removeTournament();
    setState({
      ...stateProps,
      isRemovingProcessing: false,
    });
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...stateProps,
        fileUploading: true,
      });
    }

    if (
      pathOr('', ['file', 'status'], info) === 'done' &&
      pathOr('', ['file', 'response', 'result'], info) === 'SUCCESS'
    ) {
      try {
        const fileId = pathOr([''], ['file', 'response', 'fileIds'], info)[0];
        const { publicUrl } = await getFileDescriptor(props.client, fileId);
        setState({
          ...stateProps,
          imageLink: publicUrl,
          fileUploading: false,
          logoFileId: fileId,
        });
      } catch (e) {
        console.error(e);
        message.error(t('modules.tournaments_component.message_error.file_upload_error'));
      }
    }
  };

  const restoreState = () => {
    setState({
      selectedTournament: {},
      newStages: [],
      updatedStages: [],
    });
  };

  const labelCustomization = (langCode) => {
    return `${langCode}:`;
  };

  useEffect(() => {
    const init = async () => {
      const tournamentRes = await props.client.query({
        query: GET_TOURNAMENTS_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const tournamentList = pathOr([], ['data', 'tournamentLocalised', 'getList', 'list'], tournamentRes);

      setState({
        ...stateProps,
        tournamentList,
        isLoaded: true,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  const localizedTextInputCommonProps = {
    labelPosition: 'left',
    columns: 1,
    direction: 'column',
    justify: 'start',
    placeholder: t('modules.tournaments_component.input'),
    wrapperClasses: styles.customWrapper,
    labelCustomization: labelCustomization,
    validation: {
      required: {
        value: true,
        message: t('common.validation.required'),
      },
    },
  };

  const isTextTitlesTournament = () => {
    let isResult;
    if (stateProps.newTitle !== null) {
      isResult = supportedLangs.every((lng) => {
        if (stateProps.newTitle.hasOwnProperty(lng)) {
          return getTextLocalize(stateProps.newTitle, lng).trim().length > 0;
        } else {
          return false;
        }
      });
    } else {
      isResult = supportedLangs.every(
        (lng) => getTextLocalize(stateProps.selectedTournament.title, lng).trim().length > 0
      );
    }
    return isResult;
  };

  return (
    <>
      <h1>{t('modules.tournaments_component.h1')}</h1>

      <div className="events">
        {stateProps.isLoaded ? (
          <>
            <div className="events__left-container">
              {stateProps.isNewTournament && (
                <div className="events__left-container__item-active">
                  <div className="events__left-container__item__text">
                    {t('modules.tournaments_component.div.new_tournament')}
                  </div>
                </div>
              )}
              {stateProps.tournamentList.map((tournament) => {
                return (
                  <div
                    key={'tournamentComponent' + tournament.id}
                    className={
                      stateProps.selectedTournament.id === tournament.id
                        ? 'events__left-container__item-active'
                        : 'events__left-container__item'
                    }
                    onClick={() => {
                      setState({
                        ...stateProps,
                        selectedTournament: tournament,
                        isUpdated: true,
                        isNewTournament: false,
                        newTitle: null,
                        newDescription: null,
                        fileId: '',
                        imageLink: '',
                        newStages: [],
                        updatedStages: [],
                      });
                    }}
                  >
                    <div
                      className="events__left-container__item__circle"
                      style={{
                        backgroundImage: tournament.logo ? 'url(' + tournament.logo.publicLink + ')' : '',
                      }}
                    />
                    <div className="events__left-container__item__text">
                      {LocalizedString.fromObject(tournament.title).toString(i18next.language)}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* //////// */}

            {(!isEmpty(stateProps.selectedTournament) || stateProps.isNewTournament) && !stateProps.isUpdated && (
              <div className="events__right-container">
                <div className="events__right-container__body">
                  <CloseOutlined
                    className="permissions__right-container__body__close-form-icon"
                    onClick={() => {
                      restoreState();
                    }}
                  />
                  <div className="events__right-container__body__title-first">
                    {t('modules.tournaments_component.div.logo')}
                  </div>
                  <div className="events__right-container__body__logo">
                    <Upload
                      action={window.parametrize('REACT_APP_FILE_STORAGE')}
                      headers={{
                        authorization: 'Bearer ' + props.authClient.credentials.idToken,
                      }}
                      name="tournament-logo"
                      accept=".jpg, .png"
                      showUploadList={false}
                      onChange={handleChange}
                    >
                      <Button
                        id="TournamentsComponentImgButton"
                        className="events__right-container__body__logo__upload"
                      >
                        {stateProps.fileUploading ? (
                          <LoadingOutlined />
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 20V0M0 10L20 10" stroke="white" strokeWidth="4" />
                          </svg>
                        )}
                      </Button>
                    </Upload>

                    {!isEmpty(stateProps.selectedTournament) && stateProps.selectedTournament.logo && (
                      <div
                        className="events__right-container__body__logo__img"
                        style={{
                          backgroundImage: 'url(' + stateProps.selectedTournament.logo.publicLink + ')',
                        }}
                      />
                    )}

                    {stateProps.imageLink && (
                      <div
                        className="events__right-container__body__logo__img"
                        style={{
                          backgroundImage: 'url(' + stateProps.imageLink + ')',
                        }}
                      />
                    )}
                  </div>
                  <div className="events__right-container__body__title">
                    {t('modules.tournaments_component.div.tournament_name')}
                  </div>
                  <LocalizedTextInput
                    inputName={'title'}
                    value={LocalizedString.fromObject(stateProps.selectedTournament.title)}
                    onChange={(value) => {
                      value &&
                        setState({
                          ...stateProps,
                          newTitle: trimStringProperties(value.toObject()),
                        });
                    }}
                    {...localizedTextInputCommonProps}
                  />
                  <div className="events__right-container__body__title">
                    {t('modules.tournaments_component.div.description')}
                  </div>
                  <LocalizedTextInput
                    inputName={'description'}
                    value={LocalizedString.fromObject(stateProps.selectedTournament.description)}
                    onChange={(value) => {
                      value &&
                        setState({
                          ...stateProps,
                          newDescription: trimStringProperties(value.toObject()),
                        });
                    }}
                    {...localizedTextInputCommonProps}
                    validation={{}}
                  />
                  <div className="events__right-container__body__title">
                    {t('modules.tournaments_component.div.stages')}
                  </div>
                  {!isEmpty(stateProps.selectedTournament) &&
                    stateProps.selectedTournament.stages.map((stage) => {
                      return (
                        <LocalizedTextInput
                          key={'tournamentComponent_stage_' + stage.id}
                          inputName={'stage'}
                          value={LocalizedString.fromObject(stage.title)}
                          onChange={(value) => {
                            value &&
                              setState({
                                ...stateProps,
                                updatedStages: [
                                  ...stateProps.updatedStages.filter((item) => {
                                    return item.id !== stage.id;
                                  }),
                                  {
                                    id: stage.id,
                                    title: trimStringProperties(value.toObject()),
                                  },
                                ],
                              });
                          }}
                          removeHandler={async () => await removeStage(stage.id)}
                          {...localizedTextInputCommonProps}
                        />
                      );
                    })}
                  {stateProps.newStages.map((stage, index) => {
                    return (
                      <LocalizedTextInput
                        key={'tournamentComponent_newStage_' + (stage.id || index)}
                        inputName={'stage'}
                        value={LocalizedString.fromObject(stage.title)}
                        wrapperClasses={styles.customWrapper}
                        onChange={(value) => {
                          const oldArray = stateProps.newStages;
                          value &&
                            setState({
                              ...stateProps,
                              newStages: [
                                ...oldArray.slice(0, oldArray.indexOf(stage)),
                                {
                                  title: trimStringProperties(value.toObject()),
                                },
                                ...oldArray.slice(oldArray.indexOf(stage) + 1),
                              ],
                            });
                        }}
                        {...localizedTextInputCommonProps}
                      />
                    );
                  })}
                  <div
                    className="events__right-container__body__add-new"
                    onClick={() => {
                      setState({
                        ...stateProps,
                        newStages: [...stateProps.newStages, {}],
                      });
                    }}
                  >
                    {t('modules.tournaments_component.div.add_stage')}
                  </div>
                </div>

                <div className="events__right-container__footer">
                  <Button
                    id="TournamentsComponentSaveButton"
                    type="primary"
                    className="permissions__right-container__footer__button"
                    onClick={() => {
                      setState({
                        ...stateProps,
                        isUpdatingProcessing: true,
                      });
                      if (stateProps.isNewTournament) {
                        if (stateProps.newTitle && isTextTitlesTournament() && isTextTitlesStages()) {
                          addTournament();
                        } else {
                          setState({
                            ...stateProps,
                            isUpdatingProcessing: false,
                          });
                          message.error(t('modules.tournaments_component.message_error.enter_new_tournament'));
                        }
                      } else {
                        if (isTextTitlesTournament() && isTextTitlesStages()) {
                          updateTournament();
                        } else {
                          setState({
                            ...stateProps,
                            isUpdatingProcessing: false,
                          });
                          message.error(t('modules.tournaments_component.message_error.tournament_update_error'));
                        }
                      }
                    }}
                  >
                    {stateProps.isUpdatingProcessing ? (
                      <LoadingOutlined />
                    ) : stateProps.isNewTournament ? (
                      t('modules.tournaments_component.button.save_tournament')
                    ) : (
                      t('modules.tournaments_component.button.save_changes')
                    )}
                  </Button>
                  {stateProps.isNewTournament === false ? (
                    <Button
                      danger
                      disabled
                      hidden
                      id="TournamentsComponentRemoveButton"
                      type="primary"
                      className="permissions__right-container__footer__delete"
                      onClick={() => handleRemove()}
                    >
                      {stateProps.isRemovingProcessing ? (
                        <LoadingOutlined />
                      ) : (
                        t('modules.tournaments_component.button.delete_tournament')
                      )}
                    </Button>
                  ) : null}
                </div>
              </div>
            )}
            <Button
              id="TournamentsComponentAddTournamentButton"
              className="sales__price-template__add-template"
              onClick={() => {
                setState({
                  ...stateProps,
                  isNewTournament: true,
                  isUpdated: true,
                  selectedTournament: {},
                  newTitle: '',
                  newLocation: '',
                  fileId: '',
                  imageLink: '',
                });
              }}
            >
              <PlusOutlined className="sales__price-template__add-template__icon" />
              <span className="sales__price-template__add-template__text">
                {t('modules.tournaments_component.span')}
              </span>
            </Button>
          </>
        ) : (
          <div className="with-preloader__map">
            <LoadingOutlined />
            <div className="with-preloader__info"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default withApollo(TournamentsComponent);
