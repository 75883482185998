import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { Modal, DatePicker, Button, message } from 'antd';
import { SEND_CONFIRMATION_SMS, CHECK_CONFIRMATIONS_SMS, REGISTER_USER, UPDATE_PROFILE } from '../../graphql';
import InputMask from 'react-input-mask';
import { transliterate } from '../../../../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const NewUserModal = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({});

  const sendConfirmation = async () => {
    try {
      if (stateProps.phone.length === 11) {
        const res = await props.client.query({
          query: SEND_CONFIRMATION_SMS,
          fetchPolicy: 'no-cache',
          variables: {
            phoneNumber: stateProps.phone,
          },
        });

        setState({
          ...stateProps,
          operationId: pathOr('', ['data', 'contacts', 'checkPhoneWithConfirmationCode', 'operationId'], res),
        });
        message.success(t('modules.new_user_modal.message_success.sms_sent'));
      } else {
        message.error(t('modules.new_user_modal.message_error.number_err'));
      }
    } catch (e) {
      console.error(e);
      message.error(t('modules.new_user_modal.message_error.sms_err'));
    }
  };

  const inTwoDigits = (number) => {
    if (number < 10) {
      return '0' + number;
    } else {
      return number;
    }
  };

  const registerUser = async () => {
    try {
      const res = await props.client.query({
        query: REGISTER_USER,
        fetchPolicy: 'no-cache',
        variables: {
          phone: stateProps.phone,
        },
      });
      const id = pathOr('', ['data', 'registration', 'simpleUserRegistration', 'id'], res);
      await props.client.query({
        query: UPDATE_PROFILE,
        fetchPolicy: 'no-cache',
        variables: {
          id,
          user: {
            person: {
              name: stateProps.name
                ? {
                    ru: stateProps.name,
                    en: transliterate(stateProps.name),
                  }
                : undefined,
              surname: stateProps.surname
                ? {
                    ru: stateProps.surname,
                    en: transliterate(stateProps.surname),
                  }
                : undefined,
              patronymic: stateProps.patronymic
                ? {
                    ru: stateProps.patronymic,
                    en: transliterate(stateProps.patronymic),
                  }
                : undefined,
              birthday: stateProps.birthday
                ? new Date(stateProps.birthday).getFullYear() +
                  '-' +
                  inTwoDigits(new Date(stateProps.birthday).getMonth() + 1) +
                  '-' +
                  inTwoDigits(new Date(stateProps.birthday).getDate())
                : undefined,
              contacts: stateProps.email
                ? [
                    {
                      type: 'EMAIL',
                      value: stateProps.email,
                    },
                    {
                      type: 'PHONE',
                      value: stateProps.phone,
                    },
                  ]
                : [
                    {
                      type: 'PHONE',
                      value: stateProps.phone,
                    },
                  ],
            },
          },
        },
      });
      props.setUser({
        login: stateProps.phone,
        userId: id,
        email: stateProps.email,
        userName: stateProps.surname + ' ' + stateProps.name + ' ' + (stateProps.patronymic || ''),
      });
    } catch (e) {
      console.error(e);
      message.error(t('modules.new_user_modal.message_error.registration_err'));
    }
  };

  const initRegisterUser = async () => {
    if (stateProps.phone.length === 11) {
      if (stateProps.operationId) {
        const res = await props.client.query({
          query: CHECK_CONFIRMATIONS_SMS,
          fetchPolicy: 'no-cache',
          variables: {
            operationId: stateProps.operationId,
            code: stateProps.password,
          },
        });
        const isConfirmed = pathOr('', ['data', 'contacts', 'confirmWithCode'], res);
        if (isConfirmed) {
          message.success(t('modules.new_user_modal.message_success.phone_confirmed'));
          registerUser();
        } else {
          message.error(t('modules.new_user_modal.message_error.code_err'));
        }
      } else {
        registerUser();
      }
    }
  };

  return (
    <Modal
      visible={props.isNewUserModalVisible}
      centered
      onCancel={() => {
        props.onCancel();
      }}
      className="sales__tickets__new-user-modal"
      width={825}
      title={t('modules.new_user_modal.modal')}
      footer={
        <>
          <Button
            id="NewUserModalRegisterButton"
            type="primary"
            style={{
              height: '40px',
            }}
            onClick={() => {
              if (stateProps.phone.length === 11) {
                initRegisterUser();
              }
            }}
          >
            {t('modules.new_user_modal.button.register')}
          </Button>
        </>
      }
    >
      <>
        <div className="sales__tickets__new-user-modal__subtitle">{t('modules.new_user_modal.div.data')}</div>
        <div className="sales__tickets__new-user-modal__inner-container">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.surname')}
          </div>
          <input
            className="sales__tickets__new-user-modal__inner-container__input"
            onChange={(ev) => {
              setState({
                ...stateProps,
                surname: ev.target.value,
              });
            }}
          />
        </div>
        <div className="sales__tickets__new-user-modal__inner-container">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.name')}
          </div>
          <input
            className="sales__tickets__new-user-modal__inner-container__input"
            onChange={(ev) => {
              setState({
                ...stateProps,
                name: ev.target.value,
              });
            }}
          />
        </div>
        <div className="sales__tickets__new-user-modal__inner-container">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.patronymic')}
          </div>
          <input
            className="sales__tickets__new-user-modal__inner-container__input"
            onChange={(ev) => {
              setState({
                ...stateProps,
                patronymic: ev.target.value,
              });
            }}
          />
        </div>
        <div className="sales__tickets__new-user-modal__inner-container">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.dob')}
          </div>
          <DatePicker
            placeholder="DD.MM.YYYY"
            format="DD.MM.YYYY"
            className="sales__tickets__new-user-modal__inner-container__date-picker"
            onChange={(ev) => {
              setState({
                ...stateProps,
                birthday: ev,
              });
            }}
          />
          <p className="sales__tickets__new-user-modal__inner-container__text">{t('modules.new_user_modal.p.birthday_points')} </p>
        </div>
        <div className="sales__tickets__new-user-modal__subtitle">{t('modules.new_user_modal.div.contacts')}</div>
        <div className="sales__tickets__new-user-modal__inner-container-mg">
          <div className="sales__tickets__new-user-modal__inner-container__title">E-mail</div>
          <input
            className="sales__tickets__new-user-modal__inner-container__input"
            onChange={(ev) => {
              setState({
                ...stateProps,
                email: ev.target.value,
              });
            }}
          />
          <p className="sales__tickets__new-user-modal__inner-container__text">{t('modules.new_user_modal.p.tickets_to_email')} </p>
        </div>
        <div className="sales__tickets__new-user-modal__inner-container-mg">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.phone')}
          </div>
          <InputMask
            type="tel"
            size="large"
            name="phoneNumber"
            placeholder="+7"
            mask="+7(999)999-99-99"
            maskChar="_"
            className="sales__tickets__new-user-modal__inner-container__input-phone"
            onChange={(ev) => {
              setState({
                ...stateProps,
                phone: ev.target.value.replace(/\+7/, '7').replace(/[()_-]/g, ''),
              });
            }}
          />
          <p className="sales__tickets__new-user-modal__inner-container__text">{t('modules.new_user_modal.p.pass_to_mobile')} </p>
          <Button
            id="NewUserModalConfirmNumberButton"
            className="sales__tickets__new-user-modal__inner-container__button"
            onClick={() => {
              if (stateProps.phone) {
                sendConfirmation();
              } else {
                message.warning(t('modules.new_user_modal.message_warning'));
              }
            }}
          >
            {t('modules.new_user_modal.button.confirm_number')}
          </Button>
        </div>
        <div className="sales__tickets__new-user-modal__inner-container-mg">
          <div className="sales__tickets__new-user-modal__inner-container__title">
            {t('modules.new_user_modal.div.sms_code')}
          </div>
          <input
            className="sales__tickets__new-user-modal__inner-container__sms-input"
            onChange={(ev) => {
              setState({
                ...stateProps,
                password: ev.target.value,
              });
            }}
          />
        </div>
      </>
    </Modal>
  );
};

export default NewUserModal;
