import React from 'react';
import { Menu, Input } from 'antd';
import './SegmentsMenu.scss';
import iconStaticSegment from '../../images/icon-static-segment-white.svg';
import iconDynamicSegment from '../../images/icon-dynamic-segment-white.svg';
import { useTranslation } from 'react-i18next';

function SegmentsMenu(props: {
  segmentsList: object;
  handleSelectSegment: (id: string) => void;
  isCreatingSegment: boolean;
}) {
  const { t } = useTranslation();
  // const { Search } = Input;

  const menuElement = Object.entries(props.segmentsList).map(([id, value]) => (
    <Menu.Item
      key={id}
      className={
        props.isCreatingSegment
          ? 'segments__menu-item segments__menu-item_background-transparent'
          : 'segments__menu-item'
      }
      onClick={() => {
        props.handleSelectSegment(value.id);
      }}
    >
      <div className="segments__menu-item-header-block">
        <p className="segments__menu-item-title">{value.title}</p>
        <div
          className="segments__menu-item-count-block"
          title={
            value.segmentType === 'STATIC'
              ? t('modules.segments_menu.static_segment')!
              : t('modules.segments_menu.dynamic_segment')!
          }
        >
          {value.segmentType === 'STATIC' ? (
            <img
              src={iconStaticSegment}
              className="segments__menu-item-count-icon segments__menu-item-count-icon_static"
              alt={t('modules.segments_menu.static_segment')!}
            />
          ) : (
            <img
              src={iconDynamicSegment}
              className="segments__menu-item-count-icon segments__menu-item-count-icon_dynamic"
              alt={t('modules.segments_menu.dynamic_segment')!}
            />
          )}
          <p className="segments__menu-item-count">{value.users}</p>
        </div>
      </div>
      {value.comment === undefined || value.comment.length === 0 ? null : (
        <p className="segments__menu-item-description" title={value.comment}>
          {value.comment}
        </p>
      )}
    </Menu.Item>
  ));

  return (
    <Menu className="segments__menu">
      {/* todo: do a search on the list of segments*/}
      {/*<div className='segments__menu-search-block'>*/}
      {/*  <Search*/}
      {/*    placeholder={t('modules.segments_menu.segment_name')}*/}
      {/*    size='large'*/}
      {/*    className='segments__menu-search'*/}
      {/*    onSearch={ value => console.log(value) }*/}
      {/*    onChange={ value => console.log(value) }*/}
      {/*  />*/}
      {/*</div>*/}

      {props.isCreatingSegment ? (
        <Menu.Item
          className="segments__menu-item segments__menu-item_new"
          title={t('modules.segments_menu.new_segment')}
        >
          <p className="segments__menu-item-title">{t('modules.segments_menu.new_segment')}</p>
        </Menu.Item>
      ) : null}

      {menuElement}
    </Menu>
  );
}

export default SegmentsMenu;
