import {
  ActiveStatusType,
  FilePublicLinkType,
  LoyaltyDataType,
  LegalityDocumentationType,
  LoyaltyCurrencyNameType
} from './loyaltyModel';
import { ObjectDeserializator } from '../../../../../utils/apiHelpers';

export const activeStatusDeserializer = (o: ObjectDeserializator): ActiveStatusType => {
  return {
    isActive: o.required('isActive').asBool
  };
};

export const filePublicLinkDeserializer = (o: ObjectDeserializator): FilePublicLinkType => {
  return {
    publicLink: o.required('publicLink').asString
  };
};

export const legalityDocumentationDeserializer = (o: ObjectDeserializator): LegalityDocumentationType => {
  return {
    id: o.required('id').asString,
    publicLink: o.required('publicLink').asString,
    fileName: o.optional('fileName')?.asString,
  }
}

export const loyaltyCurrencyNameDeserializer = (o: ObjectDeserializator): LoyaltyCurrencyNameType => {
  return {
    mod1: o.required('mod1').asString,
    plural: o.required('plural').asString,
    mod24: o.required('mod24').asString,
    mod50: o.required('mod50').asString
  }
}

export const loyaltyDataDeserializer = (o: ObjectDeserializator): LoyaltyDataType => {
  return {
    bonusActivePeriodDays: o.optional('bonusActivePeriodDays')?.asNumber || null,
    bonusPriceCoverPercentage: o.required('bonusPriceCoverPercentage').asNumber,
    accrualPercentage: o.required('accrualPercentage').asNumber,
    maxExpenditurePerTransactionPercentage: o.optional('bonusActivePeriodDays')?.asNumber,
    applicableWithDiscount: o.required('applicableWithDiscount').asBool,
    legalityDocumentation: o.optional('legalityDocumentation')?.asObject((o) => legalityDocumentationDeserializer(o)),
    legalityDocumentationLink: o.optional('legalityDocumentationLink')?.asString,
    loyaltyCurrencyName: o.required('legalityDocumentation').asObject((o) => loyaltyCurrencyNameDeserializer(o)),
    updatedAt: o.required('updatedAt').asDate,
    isActive: o.required('isActive').asBool,
  };
};
