import { COMMON_REGEXP } from 'constants.js';
import { t } from 'i18next';

export type Price = {
  id: string,
  value: string,
  color: string,
  [key: string]: any
}
export const isValidPrice = (price: string) => {
  const re = new RegExp(COMMON_REGEXP.PRICE);
  const val = parseFloat(price);
  return re.test(price) && !isNaN(val) && val >= 0;
};

export const formatPrice = (price: string) => {
  const priceVal = price.trim();
  return priceVal.replace(/\D/g, '');
};

export const isValidPrices = (prices: Price[]) => prices.every(price => isValidPrice(price.value));

export const pricesSort = (a: Price, b: Price) => Number(a.value) - Number(b.value);

export const updatePriceArray = (prevArray: Price[], newValue: string, defaultObj: Price) => {
  return prevArray
    .filter((item) => {
      return defaultObj.id !== item.id;
    })
    .concat({
      ...defaultObj,
      value: newValue
    });
};

const defaultPriceErrorText = t('modules.price_edit_menu.message_error.invalid_price');

export const generatePriceTemplateErrors = (prices: Price[], errorText: string = defaultPriceErrorText) => {
  let invalidItemsKeys: string[] = [];
  prices.forEach((price) => {
    if (!isValidPrice(price.value)) {
      invalidItemsKeys.push(price.id);
    }
  });
  const errors = new Map();
  invalidItemsKeys.forEach(k => {
    errors.set(k, errorText);
  });
  return errors;
};
