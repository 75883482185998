import DiscountsPage from './Discounts/DiscountsPage';
import PersonalCoupon from './Discounts/components/PersonalCoupon/PersonalCoupon';
import { IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { t } from 'i18next';

export class ModuleDiscounts implements Module {
  private component: any;

  constructor(private app: IApp, private routeContainer: IRouteContainer, private menuContainer: ISidebar) {
    this.component = {
      DiscountsPage,
      PersonalCoupon
    };

    this.routeContainer.addRoute({
      path: '/admin/discounts',
      component: this.component.DiscountsPage
    });

    this.routeContainer.addRoute({
      path: '/admin/discounts/coupon/:id',
      component: this.component.PersonalCoupon
    });

    const discountsLink = new RouteAction({
      title: t('modules.discounts_index'),
      icon: 'percentage',
      name: 'discounts',
      route: 'admin/discounts',
      order: 11,
      enabled: true,
      visible: true,
      permission: 'ACCESS_COUPONS',
      localizationKey: 'discounts'
    });

    this.menuContainer.addLink(discountsLink);
  }

  start() {
  }
}
