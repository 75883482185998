import React from "react";

function Coin(props) {
  return (
    <svg className={ props.styleClasses } viewBox="0 0 100 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M87.8595 18.327C88.3078 20.5851 85.2328 24.0121 78.9069 27.6151C72.1293 31.3077 62.1007 35.1764 50.8103 37.4179C39.5198 39.6594 28.774 39.9151 21.0998 39.0916C13.8773 38.1784 9.27475 36.2756 8.82645 34.0175C8.28849 31.3078 11.8151 27.7912 18.1411 24.1882C24.9186 20.4955 35.0369 17.0785 46.3273 14.837C57.6177 12.5955 68.2739 11.8882 75.9481 12.7117C83.1707 13.6249 87.3216 15.6173 87.8595 18.327ZM80.6934 41.3429L79.1692 33.6655C80.0724 33.4861 80.886 32.8552 81.7893 32.6759C85.1332 30.6037 87.5739 28.7109 89.4734 26.4562L90.4597 31.424C90.9976 34.1337 87.9226 37.5606 81.507 40.712L80.6934 41.3429ZM74.0188 36.0962L75.543 43.7737C72.1094 45.3942 68.5861 46.5631 64.6112 47.8217L63.087 40.1442C67.0619 38.8856 70.5852 37.7167 74.0188 36.0962ZM57.3056 41.7614L58.8298 49.4389C57.0234 49.7975 55.2169 50.1562 53.4104 50.5148C51.6039 50.8734 49.3459 51.3217 47.5394 51.6804L46.0152 44.0029C48.2733 43.5546 50.0797 43.1959 51.8862 42.8373C53.6927 42.4787 55.4991 42.12 57.3056 41.7614ZM40.5061 44.6272L42.0303 52.3047C37.8761 52.66 33.7219 53.0153 30.3812 52.7397L28.857 45.0622C32.6493 45.2482 36.3519 44.9825 40.5061 44.6272ZM22.717 44.8729L24.2412 52.5504L23.6999 52.1885C16.567 51.7269 11.9645 49.8241 11.4266 47.1144L10.4403 42.1466C13.0571 43.5048 16.4873 44.232 20.3693 44.8696C20.8209 44.7799 21.8138 45.0522 22.717 44.8729ZM3.40703 35.0934L6.00716 48.1903C7.08308 53.6097 13.3991 57.05 22.9694 57.9665C31.1849 59.152 42.3823 58.8066 54.576 56.3858C66.318 54.0547 77.2498 50.0067 84.3894 45.7728C92.8838 41.2699 97.4066 35.6778 96.3307 30.2584L93.7306 17.1615C92.6546 11.7421 86.3386 8.3018 76.7683 7.38529C68.5529 6.1998 56.9038 6.63482 45.1617 8.96598C32.9681 11.3868 22.4879 15.3451 15.3483 19.579C6.85394 24.0819 2.33112 29.674 3.40703 35.0934Z" fill="#EBEDEE"/>
    </svg>
  );
}

export default Coin;