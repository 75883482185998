import gql from 'graphql-tag';

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    users {
      delete(id: $id)
    }
  }
`;

export const INSERT_USER = gql `
  mutation insertUser($user: UserInput!) {
    users {
      insert(user: $user) {
        id
      }
    }
  }
`
