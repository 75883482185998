import { connect } from 'react-redux';
import {
  setIsAddVisible,
  setDefaultPromo,
  setEditingMode
} from '../../../../PromoAdd/actions';
import { setPromoList } from '../../../../actions';
import {
  openActivationsCodesModal,
  setCodesToReducer
} from '../../../../PromoModals/actions';
import pathOr from 'ramda/es/pathOr';

const mapStateToProps = state => ({
  promoList: pathOr(
    [],
    ['reducer', 'promocodes', 'promoRootReducer', 'promoList'],
    state
  )
});

const mapDispatchToProps = {
  setIsAddVisible,
  setDefaultPromo,
  setPromoList,
  setEditingMode,
  openActivationsCodesModal,
  setCodesToReducer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
