import gql from 'graphql-tag';


export const GET_MATCH_ITEM_INFO = gql`
  query(
  $filter: TicketFilter
){
  ticket{
    getList(
      filter: $filter
    ){
      list{
        id
        visibleId
        createdTime
        order{
          visibleId
          price
          priceWithDiscount
          additionalData{
            commentary
          }
        }
        orderItem {
          priceWithDiscount
        }
        user{
          login
          person{
            name
            surname
            patronymic
          }
        }
      }
    }
  } 
}
`

export const GET_SEASON_TICKET_INFO = gql`
query(
  $filter: SeasonTicketFilter
  ){
    seasonTicket{
      getList(
      filter: $filter
    ){
      list{
        id
        order{
          visibleId
          price
          priceWithDiscount
        }
        orderItem {
          priceWithDiscount
        }
        user{
          login
          person{
            name
            surname
            patronymic
          }
        }
      }
    }
    }
}
`