import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { pathOr } from 'ramda';

import { ICodesModalFooter } from '../../interfaces/IPromoCodesModal';
import { generateNewCodes } from '../../events/mutationEvents';
import { useTranslation } from 'react-i18next';

const CodesModalFooter = (props: ICodesModalFooter) => {
  const { client, setDataCode } = props;
  const defaultPromoId = pathOr('', ['defaultPromo', 'id'], props);
  const { t } = useTranslation();
  const [prefix, setPrefix] = useState('');
  const [quantity, setQuantity] = useState(1);

  return (
    <div className="df jcfe">
      <Input
        placeholder={t('modules.codes_modal_footer.input_placeholder_title') ?? ''}
        className="promocodes-modal__prefix-input"
        onChange={(e: any): void => setPrefix(e.target.value)}
      />
      <Input
        placeholder={t('modules.codes_modal_footer.input_placeholder_count')!}
        className="promocodes-modal__add-codes-input"
        onChange={(e: any): void => setQuantity(parseInt(e.target.value, 10))}
      />
      <Button
        id="CodesModalFooterGenerateButton"
        className="promocodes-modal__generate-btn"
        type="default"
        onClick={() =>
          generateNewCodes({
            client,
            defaultPromoId,
            prefix,
            quantity,
            setDataCode,
          })
        }
      >
        {t('modules.codes_modal_footer.button')}
      </Button>
    </div>
  );
};

export default CodesModalFooter;
