import React, { useState, useEffect } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { LeftOutlined, LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Radio, Switch, message } from 'antd';
import { APPLY_PROMOCODE, CANCEL_PROMOCODE, GET_ORDER_ITEMS } from '../../graphql';
import { useTranslation } from 'react-i18next';

const ActionsSideMenu = (props) => {
  const { t } = useTranslation();
  const sampleTicket = props.selectedRows[0];
  const [stateProps, setState] = useState({
    isNewUserModalVisible: false,
    isDataVisible: true,
    paymentMethod: 'CLOUDPAYMENTS',
    ticketsArr: [],
    isPaymentProcessing: false,
    sendToPhone: false,
    sendToEmail: true,
  });
  const wordend = (number, word, end1, end2, end3) => {
    if (Number(String(number).length) > 2) number = Number(String(number).substr(-2));
    if (number === 11 || number === 12 || number === 13 || number === 14) {
      return word + end3;
    } else {
      switch (Number(String(number).substr(-1))) {
        case 1:
          return word + end1;
        case 2:
        case 3:
        case 4:
          return word + end2;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 0:
          return word + end3;
        default:
          return word;
      }
    }
  };
  const initPayment = async () => {
    const script = document.createElement('script');
    const credentials = props.authClient.credentials;
    const token = credentials ? credentials.idToken : '';

    script.src = window.parametrize('REACT_APP_PAYMENT_LIB_LINK');
    script.async = true;

    document.body.appendChild(script);
    script.onload = function () {
      // eslint-disable-next-line no-unused-vars
      /* global PaymentsClient, a */
      /* eslint no-undef: "error" */
      var payments = new PaymentsClient(null, token, window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'), '#000', false);
      payments.pay(
        sampleTicket.id,
        stateProps.paymentMethod,
        () => {
          //in case of success
          message.success(t('modules.actions_side_menu.message_success'));
          setState({
            ...stateProps,
            isPaymentProcessing: false,
          });
          if (stateProps.sendToPhone) {
            props.sendOrderSMS(sampleTicket.login, sampleTicket.id);
          }
          if (stateProps.sendToEmail) {
            props.sendOrderEmail(sampleTicket.email, sampleTicket.id);
          }
          props.setLoading();
        },
        (data) => {
          //in case of error
          console.error(data);
          setState({
            ...stateProps,
            isPaymentProcessing: false,
          });
          message.error(t('modules.actions_side_menu.message_error.payment_error'));
        }
      );
    };
  };
  const applyPromocode = async () => {
    try {
      const res = await props.client.query({
        query: APPLY_PROMOCODE,
        variables: {
          orderId: sampleTicket.id,
          promocode: stateProps.promocode,
        },
        fetchPolicy: 'no-cache',
      });
      const priceWithDiscount = pathOr('', ['data', 'order', 'applyPromocode', 'priceWithDiscount'], res);

      setState({
        ...stateProps,
        promocodeApplied: true,
        priceWithDiscount: Math.round(parseInt(priceWithDiscount)),
        isPromocodeLoading: false,
      });
    } catch (e) {
      message.error(t('modules.actions_side_menu.message_error.promocode_application_error'));
      setState({
        ...stateProps,
        isPromocodeLoading: false,
      });
      console.error(e);
    }
  };
  const cancelPromocode = async () => {
    try {
      await props.client.query({
        query: CANCEL_PROMOCODE,
        variables: {
          orderId: sampleTicket.id,
        },
        fetchPolicy: 'no-cache',
      });
      setState({
        ...stateProps,
        promocodeApplied: false,
        priceWithDiscount: '',
        isPromocodeLoading: false,
      });
    } catch (e) {
      message.error(t('modules.actions_side_menu.message_error.promocode_cancellation_error'));
      console.error(e);
    }
  };
  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_ORDER_ITEMS,
        variables: {
          orderId: sampleTicket.id,
        },
      });
      const items = pathOr([], ['data', 'order', 'getById', 'items'], res);

      const ticketsArr = items.map((item) => {
        return {
          sector: pathOr('', ['item', 'place', 'row', 'sector', 'title'], item),
          row: pathOr('', ['item', 'place', 'row', 'number'], item),
          place: pathOr('', ['item', 'place', 'number'], item),
          price: Math.round(parseInt(pathOr('', ['price'], item))),
        };
      });

      setState({
        ...stateProps,
        ticketsArr,
      });
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="sales__tickets__side-menu__container">
        <div className="sales__tickets__side-menu__header">
          {/* <div
              onClick={ev => {
                  setState({
                  ...stateProps,
                  isNewUserModalVisible: true
                  })
              }}
              >
                  n
              </div> */}
          <LeftOutlined
            onClick={() => {
              props.onClose();
            }}
            className="sales__tickets__side-menu__header__icon"
          />
          <div className="sales__tickets__side-menu__header__action">{t('modules.actions_side_menu.div.buy')}</div>
        </div>
        <div className="sales__tickets__side-menu__body">
          <div className="sales__tickets__side-menu__switcher">
            <div
              className={
                stateProps.isDataVisible
                  ? 'sales__tickets__side-menu__switcher__item__active'
                  : 'sales__tickets__side-menu__switcher__item'
              }
              onClick={() => {
                setState({
                  ...stateProps,
                  isDataVisible: true,
                });
              }}
            >
              {t('modules.actions_side_menu.div.data')}
            </div>
            <div
              className={
                stateProps.isDataVisible
                  ? 'sales__tickets__side-menu__switcher__item'
                  : 'sales__tickets__side-menu__switcher__item__active'
              }
              onClick={() => {
                if (!isEmpty(stateProps.ticketsArr)) {
                  setState({
                    ...stateProps,
                    isDataVisible: false,
                  });
                }
              }}
            >
              {t('modules.actions_side_menu.div.tickets')}
            </div>
          </div>
          {stateProps.isDataVisible ? (
            <>
              <div className="sales__tickets__side-menu__name">{sampleTicket.userName}</div>
              <div className="sales__tickets__side-menu__item">
                <PhoneOutlined className="sales__tickets__side-menu__contacts-icon" />
                <div className="sales__tickets__side-menu__contacts-item">{sampleTicket.login}</div>
              </div>
              <div className="sales__tickets__side-menu__item">
                <MailOutlined className="sales__tickets__side-menu__contacts-icon" />
                <div className="sales__tickets__side-menu__contacts-item">{sampleTicket.email}</div>
              </div>
              <div className="sales__tickets__side-menu__subtitle">{t('modules.actions_side_menu.div.payment_method')}</div>
              <Radio.Group
                className="sales__tickets__side-menu__radio-group"
                value={stateProps.paymentMethod}
                onChange={(ev) => {
                  setState({
                    ...stateProps,
                    paymentMethod: ev.target.value,
                  });
                }}
              >
                <Radio value={'BANK_TRANSFER'}>{t('modules.actions_side_menu.radio.bank_transfer')}</Radio>
                <Radio value={'CLOUDPAYMENTS'}>{t('modules.actions_side_menu.radio.card')}</Radio>
                <Radio value={'OTHER'}>{t('modules.actions_side_menu.radio.other')}</Radio>
              </Radio.Group>
              <div className="sales__tickets__side-menu__subtitle">{t('modules.actions_side_menu.div.where_send_tickets')}</div>
              <div className="sales__tickets__side-menu__switch">
                <div
                  className={
                    stateProps.sendToPhone
                      ? 'sales__tickets__side-menu__switch__text'
                      : 'sales__tickets__side-menu__switch__text sales__tickets__side-menu__disabled'
                  }
                >
                  {t('modules.actions_side_menu.div.via_sms')} {sampleTicket.login}
                </div>
                <Switch
                  size="small"
                  className="sales__tickets__side-menu__switch__item"
                  checked={stateProps.sendToPhone}
                  onChange={(ev) => {
                    setState({
                      ...stateProps,
                      sendToPhone: ev,
                    });
                  }}
                />
              </div>
              {sampleTicket.email ? (
                <div className="sales__tickets__side-menu__switch">
                  <div
                    className={
                      stateProps.sendToEmail
                        ? 'sales__tickets__side-menu__switch__text'
                        : 'sales__tickets__side-menu__switch__text sales__tickets__side-menu__disabled'
                    }
                  >
                    {t('modules.actions_side_menu.div.via_email')} {sampleTicket.email}
                  </div>
                  <Switch
                    checked={stateProps.sendToEmail}
                    size="small"
                    className="sales__tickets__side-menu__switch__item"
                    defaultChecked={stateProps.sendToEmail}
                    onChange={(ev) => {
                      setState({
                        ...stateProps,
                        sendToEmail: ev,
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="sales__tickets__side-menu__switch__blank"></div>
              )}
              <div className="sales__tickets__side-menu__subtitle">{t('modules.actions_side_menu.div.promocode')}</div>
              <div className="sales__tickets__side-menu__promo">
                <input
                  className="sales__tickets__side-menu__promo-input"
                  placeholder={
                    stateProps.promocodeApplied ? stateProps.promocode : t('modules.actions_side_menu.input')
                  }
                  onChange={(ev) => {
                    setState({
                      ...stateProps,
                      promocode: ev.target.value,
                    });
                  }}
                />
                <Button
                  id="ActionsSideMenuPromocodeAppliedButton"
                  className="sales__tickets__side-menu__promo-accept"
                  onClick={() => {
                    if (stateProps.promocodeApplied) {
                      setState({
                        ...stateProps,
                        isPromocodeLoading: true,
                      });
                      cancelPromocode();
                    } else {
                      if (!isEmpty(stateProps.promocode)) {
                        setState({
                          ...stateProps,
                          isPromocodeLoading: true,
                        });
                        applyPromocode();
                      } else {
                        message.error(t('modules.actions_side_menu.message_error.enter_promocode'));
                      }
                    }
                  }}
                >
                  {stateProps.isPromocodeLoading ? (
                    <LoadingOutlined />
                  ) : stateProps.promocodeApplied ? (
                    t('modules.actions_side_menu.button.cancel')
                  ) : (
                    t('modules.actions_side_menu.button.apply')
                  )}
                </Button>
              </div>
              <div className="sales__tickets__side-menu__footer">
                <div className="sales__tickets__side-menu__ticket-count">
                  {stateProps.ticketsArr.length}{' '}
                  {wordend(
                    stateProps.ticketsArr.length,
                    t('modules.actions_side_menu.wordend.word'),
                    '',
                    `${t('modules.actions_side_menu.wordend.singular')} `,
                    t('modules.actions_side_menu.wordend.plural')
                  )}{' '}
                </div>
                <div className="sales__tickets__side-menu__price">
                  <span
                    className={
                      stateProps.promocodeApplied
                        ? 'sales__tickets__side-menu__price__promo'
                        : 'sales__tickets__side-menu__price'
                    }
                  >
                    {stateProps.ticketsArr.map((item) => item.price).reduce((a, b) => a + b, 0)}
                  </span>{' '}
                  <span className="sales__tickets__side-menu__price__new">{stateProps.priceWithDiscount}</span>{' '}
                  {t('currency')}
                </div>
              </div>
              <Button
                id="ActionsSideMenuBuyButton"
                className="sales__tickets__side-menu__accept-button"
                onClick={async () => {
                  setState({
                    ...stateProps,
                    isPaymentProcessing: true,
                  });
                  await initPayment();
                }}
              >
                {stateProps.isPaymentProcessing ? <LoadingOutlined /> : t('modules.actions_side_menu.button.buy')}
              </Button>
            </>
          ) : (
            ''
          )}
        </div>
        {!stateProps.isDataVisible && (
          <div className="sales__tickets__side-menu__ticket__container">
            {stateProps.ticketsArr.map((item) => {
              return (
                <div className="sales__tickets__side-menu__ticket">
                  <div className="sales__tickets__side-menu__ticket__item">
                    <div className="sales__tickets__side-menu__ticket__item-title">
                      {t('modules.actions_side_menu.div.sector')}
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item-text">{item.sector}</div>
                  </div>
                  <div className="sales__tickets__side-menu__ticket__item">
                    <div className="sales__tickets__side-menu__ticket__item-title">
                      {t('modules.actions_side_menu.div.row')}
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item-text">{item.row}</div>
                  </div>
                  <div className="sales__tickets__side-menu__ticket__item">
                    <div className="sales__tickets__side-menu__ticket__item-title">
                      {t('modules.actions_side_menu.div.seat')}
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item-text">{item.place}</div>
                  </div>
                  <div className="sales__tickets__side-menu__ticket__item">
                    <div className="sales__tickets__side-menu__ticket__item-title">
                      {t('modules.actions_side_menu.div.price')}
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item-text">
                      {item.price + ` ${t('currency')}`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* <NewUserModal
      isNewUserModalVisible={stateProps.isNewUserModalVisible}
      onCancel={ev => {
        onModalCancel();
      }}
    /> */}
    </>
  );
};

export default ActionsSideMenu;
