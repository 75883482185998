import gql from 'graphql-tag';

export const DELETE_MATCH = gql`
  mutation($id: ID!) {
    matchLocalised {
      delete(id: $id)
    }
  }
`;

export const UPDATE_MATCH = gql`
  mutation($id: ID!, $data: MatchUpdateInputLocalised!) {
    matchLocalised {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const UPDATE_PRICE_TEMPLATE = gql`
  mutation($data: PriceTemplateEventInput!) {
    priceTemplateEvent {
      add(data: $data) {
        tag
      }
    }
  }
`;
export const ADD_MATCH = gql`
  mutation($data: MatchInputLocalised!) {
    matchLocalised {
      add(data: $data) {
        id
      }
    }
  }
`;
export const UPDATE_SEASON = gql`
  mutation($data: SeasonUpdateInput!, $id: ID!) {
    season {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_SEASON = gql`
  mutation($data: SeasonInput!) {
    season {
      add(data: $data) {
        id
      }
    }
  }
`;
export const ACQUIRE_FILE = gql`
  mutation acquireFile($id: String!) {
    file {
      acquireFile(fileId: $id)
    }
  }
`;

export const RELEASE_FILE = gql`
  mutation releaseFile($id: String!) {
    file {
      releaseFile(fileId: $id)
    }
  }
`;

export const RECALCULATE_SEASON_TICKETS = gql`
  mutation recalculatePlaceStatus {
    seasonTicket {
      recalculatePlaceStatus
    }
  }
`;

export const RECALCULATE_SEASON_TICKET = gql`
  mutation recalculatePlaceStatus($filter: SeasonTicketFilter) {
    seasonTicket {
      recalculatePlaceStatus(filter: $filter)
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation($id: ID!, $data: TeamUpdateInputLocalised!) {
    teamLocalised {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_TEAM = gql`
  mutation($data: TeamInputLocalised!) {
    teamLocalised {
      add(data: $data) {
        id
      }
    }
  }
`;

export const REMOVE_TEAM = gql`
  mutation($id: ID!) {
    teamLocalised {
      delete(id: $id)
    }
  }
`;

export const UPDATE_STAGE_INFO = gql`
  mutation($id: ID!, $data: StageUpdateInputLocalised!) {
    stageLocalised {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_STAGE_INFO = gql`
  mutation($data: StageInputLocalised!) {
    stageLocalised {
      add(data: $data) {
        id
      }
    }
  }
`;

export const REMOVE_STAGE = gql`
  mutation($id: ID!) {
    stageLocalised {
      delete(id: $id)
    }
  }
`;

export const UPDATE_TOURNAMENT_INFO = gql`
  mutation($id: ID!, $data: TournamentUpdateInputLocalised!) {
    tournamentLocalised {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_TOURNAMENT_INFO = gql`
  mutation($data: TournamentInputLocalised!) {
    tournamentLocalised {
      add(data: $data) {
        id
      }
    }
  }
`;

export const REMOVE_TOURNAMENT = gql`
  mutation($id: ID!) {
    tournamentLocalised {
      delete(id: $id)
    }
  }
`;

export const UPDATE_SEASON_TICKET = gql`
  mutation($id: ID!, $data: SeasonTicketDescriptorUpdate!) {
    seasonTicketDescriptor {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const CREATE_SEASON_TICKET = gql`
  mutation($data: SeasonTicketDescriptorInput!) {
    seasonTicketDescriptor {
      create(data: $data) {
        id
      }
    }
  }
`;
