import { Tooltip } from 'antd';
import React from 'react';
import { Price } from '../../utils/priceTemplateHelpers';

interface PriceCategoryItemProps {
  price: Price,
  onBlur: (e: React.FocusEvent<HTMLInputElement>, item: Price) => void,
  onChange: (e: React.ChangeEvent<HTMLInputElement>, item: Price) => void,
  value?: string,
  hint?: string,
}

const PriceCategoryItem = ({
  price,
  onBlur,
  onChange,
  value,
  hint
}: PriceCategoryItemProps) => {
  const priceItem = () => (
    <div className="sales__price-template__side-menu__body__item" key={`priceTemplateItem-${price.title}`}>
      <div className="sales__price-template__side-menu__body__item__text">{price.title}</div>
      <input
        className="sales__price-template__side-menu__body__item__input"
        value={value ?? price.value}
        placeholder={price.value}
        onBlur={(ev) => onBlur(ev, price)}
        onChange={(ev) => onChange(ev, price)}
        style={{
          borderColor: price.color
        }}
      />
    </div>
  );

  return (
    <Tooltip
      title={hint}
      color={'red'}
      open={!!hint}
      placement={'left'}
      key={`priceTemplateItemTooltip-${price.title}`}
    >
      {priceItem()}
    </Tooltip>
  );
};

export default PriceCategoryItem;
