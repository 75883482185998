import { pathOr } from 'ramda';
import { queries } from '../../graphql';
import { IGetCodes } from '../interfaces/IQueryEvents';

export const getCodes = (props: IGetCodes): void => {
  const { selectedPromoId } = props;
  const { client, setCodesToReducer } = props;

  client
    .query({
      query: queries.GET_CODES,
      variables: {
        id: selectedPromoId
      }
    })
    .then((item: any) => {
      const data: object = {
        codes: pathOr(
          [],
          ['data', 'promocodes', 'getById', 'codes', 'list'],
          item
        ),
        maxUseCounts: pathOr(
          [],
          ['data', 'promocodes', 'getById', 'maxUseCounts'],
          item
        )
      };
      setCodesToReducer(data);
    });
};
