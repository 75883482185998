import { withApollo } from 'react-apollo';
import { compose } from 'recompose';

import PromoCodesActivationModal from './components/promoCodesActivationModal';
import connect from './containers/connect';

export default compose(
  withApollo,
  connect
)(PromoCodesActivationModal);
