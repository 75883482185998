import React, { useState } from 'react';
import styles from './DiscountsFormsItems.module.scss';

import couponExampleRuble from '../../../img/coupon-example-1.png';
import couponExamplePercent from '../../../img/coupon-example-2.png';
import promocodeExample from '../../../img/promocode-example-1.png';
import { PromocodeType, DiscountType, PeriodType } from '../../model/discountsModel';

import classNames from 'classnames';
import { Form, Input, Radio, Popover, Select, DatePicker } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function DiscountsFormsItems() {
  const { t } = useTranslation();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.ABSOLUTE);
  const [datePeriodType, setDatePeriodType] = useState<PeriodType>(PeriodType.PERIOD);

  const inlineItemsClass = classNames(styles.item, styles.inlineInputItem);
  const promptUnderInput = classNames(styles.prompt, styles.promptUnderInput);
  const promptUnderTitle = classNames(styles.prompt, styles.promptUnderTitle);

  // Hints in the section "Type of discount"
  const PersonalCouponPopover = (
    <div className={styles.popover}>
      <p className={styles.popoverText}>{t('modules.discounts_forms_items.p.text_1')}</p>
      <div className={styles.popoverImgBlock}>
        <img
          src={couponExampleRuble}
          className={styles.popoverImg}
          alt={t('modules.discounts_forms_items.img.coupon_example')!}
        />
        <img
          src={couponExamplePercent}
          className={styles.popoverImg}
          alt={t('modules.discounts_forms_items.img.coupon_example')!}
        />
      </div>
    </div>
  );

  const PromocodePopover = (
    <div className={styles.popover}>
      <p className={styles.popoverText}>{t('modules.discounts_forms_items.p.text_2')}</p>
      <div className={styles.popoverImgBlock}>
        <img
          src={promocodeExample}
          className={styles.popoverImg}
          alt={t('modules.discounts_forms_items.img.promo_code_example')!}
        />
      </div>
    </div>
  );

  // Date inputs in the "Validity period" section
  const Period = (
    <Form.Item
      name="period"
      className={inlineItemsClass}
      rules={[{ required: true, message: t('modules.discounts_forms_items.form_item.validity_period')! }]}
    >
      <RangePicker size="large" />
    </Form.Item>
  );

  const PeriodStart = (
    <Form.Item
      name="periodStart"
      className={inlineItemsClass}
      rules={[{ required: true, message: t('modules.discounts_forms_items.form_item.validity_period')! }]}
    >
      <DatePicker size="large" />
    </Form.Item>
  );

  const PeriodEnd = (
    <Form.Item
      name="periodEnd"
      className={inlineItemsClass}
      rules={[{ required: true, message: t('modules.discounts_forms_items.form_item.validity_period')! }]}
    >
      <DatePicker size="large" />
    </Form.Item>
  );

  const DATE_INPUT_MAP: any = {
    PERIOD: Period,
    PERIOD_START: PeriodStart,
    PERIOD_END: PeriodEnd,
  };

  return (
    <div className={styles.modalItemsBlock}>
      <h3 className={styles.title}>{t('modules.discounts_forms_items.h3.type_discount')}</h3>
      <Form.Item name="promocodeType" className={styles.item}>
        <Radio.Group id="promocodeType" className={styles.radioGroup}>
          <Radio value={PromocodeType.PERSONAL_COUPON}>{t('modules.discounts_forms_items.radio.coupon')}</Radio>
          <Popover content={PersonalCouponPopover}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>

          <Radio value={PromocodeType.PROMOCODE} disabled>
            {t('modules.discounts_forms_items.radio.promo_code')}
          </Radio>
          <Popover content={PromocodePopover}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>
        </Radio.Group>
      </Form.Item>

      <h3 className={styles.title}>{t('modules.discounts_forms_items.h3.name')}</h3>
      <Form.Item
        name="title"
        className={styles.item}
        rules={[{ required: true, message: t('modules.discounts_forms_items.form_item.title')! }]}
      >
        <Input id="title" size="large" className={styles.input} />
      </Form.Item>
      <p className={promptUnderInput}>{t('modules.discounts_forms_items.p.text_3')}</p>

      <h3 className={styles.title}>{t('modules.discounts_forms_items.h3.discount')}</h3>
      <Form.Item
        name="discountValue"
        className={inlineItemsClass}
        rules={[{ required: true, message: t('modules.discounts_forms_items.form_item.discount')! }]}
      >
        <Input
          type="number"
          id="discountValue"
          className={styles.discountInput}
          size="large"
          max={discountType === 'PERCENT' ? 100 : undefined}
          min={0} // 0 or 1?
        />
      </Form.Item>
      <Form.Item name="discountType" className={inlineItemsClass}>
        <Select
          id="discountType"
          className={styles.discountSelect}
          size="large"
          onChange={(value: DiscountType) => setDiscountType(value)}
        >
          <Option value={DiscountType.ABSOLUTE}>{t('currency')}</Option>
          <Option value={DiscountType.PERCENT}>%</Option>
          <Option value={DiscountType.FIXED_PRICE}>Fix</Option>
        </Select>
      </Form.Item>

      <h3 className={styles.title}>{t('modules.discounts_forms_items.h3.activations')}</h3>
      <p className={promptUnderTitle}>{t('modules.discounts_forms_items.p.text_4')}</p>
      <Form.Item name="maxUseCountsPerUser" className={styles.item}>
        <Input id="maxUseCountsPerUser" type="number" min={0} size="large" className={styles.numberInput} />
      </Form.Item>

      <h3 className={styles.title}>{t('modules.discounts_forms_items.h3.validity_period')}</h3>
      <Form.Item
        name="datePeriodType"
        className={inlineItemsClass}
        getValueProps={(value: PeriodType) => {
          setDatePeriodType(value);
          return { value };
        }}
      >
        <Select
          id="datePeriodType"
          className={styles.periodSelect}
          size="large"
          onChange={(value: PeriodType) => setDatePeriodType(value)}
        >
          <Option value={PeriodType.PERIOD} className={styles.periodOption}>
            {t('modules.discounts_forms_items.option.period')}
          </Option>
          <Option value={PeriodType.PERIOD_START} className={styles.periodOption}>
            {t('modules.discounts_forms_items.option.from')}
          </Option>
          <Option value={PeriodType.PERIOD_END} className={styles.periodOption}>
            {t('modules.discounts_forms_items.option.to')}
          </Option>
        </Select>
      </Form.Item>
      {DATE_INPUT_MAP[datePeriodType]}
    </div>
  );
}

export default DiscountsFormsItems;
