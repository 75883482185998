import React, { useState } from 'react';
import { Select } from 'antd';
import { LocalizedSelectProps } from './LocalizedSelect.types';

const SelectOption = Select.Option;

function LocalizedSelect({
  data,
  currentLng,
  placeholder,
  defaultValue,
  onChange,
  className
}: LocalizedSelectProps) {
  const [value, setValue] = useState<string | undefined>(defaultValue);

  const handleSelectChange = (ev: string) => {
    setValue(ev);
    onChange(ev);
  }

  return (
    <Select
      placeholder={placeholder}
      value={value}
      className={className}
      onChange={(ev) => {
        handleSelectChange(ev)
      }}
    >
      {data.map(item => {
        return (
          <SelectOption key={item.id+"localized_select_option"} value={item.id}>
            {item.title.toString(currentLng).toUpperCase()}
          </SelectOption>
        );
      })}
    </Select>
  )
}

export default LocalizedSelect;
