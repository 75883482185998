import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import ModalDeleteWarning from '../ModalDeleteWarning/ModalDeleteWarning';
import { FormType } from '../../graphql/accessZoneModel';
import './AccessZoneForm.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

interface AccessZoneFormProps extends FormComponentProps {
  onSubmit: (e: React.MouseEvent) => void;
  data: { title: string; description: string; controlPresence: boolean };
  setIsChangedInput: (isChanged: boolean) => void;
  formType: FormType;
  handleDelete: () => void;
  changeCheck: React.Dispatch<React.SetStateAction<boolean>>;
  check: boolean;
}
type Ref = FormComponentProps;

const AccessZoneForm = forwardRef<Ref, AccessZoneFormProps>(
  (
    { form, onSubmit, data, setIsChangedInput, formType, handleDelete, changeCheck, check }: AccessZoneFormProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const { t } = useTranslation();

    const [isVisibleModalDelete, setIsVisibleModalDelete] = useState<boolean>(false);
    const errors = form.getFieldsError();
    const isTouched = form.isFieldsTouched(['title', 'description', 'controlPresence']);
    const [isChangedCheck, setIsChangedCheck] = useState(true);
    const disabledButton = Boolean(errors.title) || isChangedCheck;
    const [isCheckNewZone, setIsCheckNewZone] = useState(false);

    //Resetting values in form fields when switching between menu items and between forms
    useEffect(() => {
      formType === 'initial' && form.resetFields();
      setIsChangedInput(false);
    }, [formType]);

    useEffect(() => {
      isTouched && setIsChangedInput(isTouched);
    }, [isTouched]);

    const processingCheckbox = (e: CheckboxChangeEvent) => {
      if (data) {
        data.controlPresence = e.target.checked;
      }
      setIsCheckNewZone(!isCheckNewZone);
      changeCheck(e.target.checked);
      form.setFieldsValue({ controlPresence: e.target.checked });
      setIsChangedCheck(false);
    };

    return (
      <>
        <Form className="AccessZoneForm" autoComplete="off">
          <div className="AccessZoneForm__content">
            <div className="AccessZoneForm__inputs">
              <p className="AccessZoneForm__subtitle">{t('modules.access_zone_form.input.title')}</p>

              <Form.Item>
                {form.getFieldDecorator('title', {
                  initialValue: formType !== 'create zone' && data ? data.title : '',
                  rules: [{ required: true, message: t('modules.access_zone_form.form_item') }],
                })(
                  <Input
                    className="AccessZoneForm__input"
                    placeholder={t('modules.access_zone_form.placeholder.zone')!}
                    size="large"
                    onChange={() => setIsChangedCheck(false)}
                  />
                )}
              </Form.Item>

              <p className="AccessZoneForm__subtitle">{t('modules.access_zone_form.input.description')}</p>
              <Form.Item>
                {form.getFieldDecorator('description', {
                  initialValue: formType !== 'create zone' && data ? data.description : ''
                  // rules: [{ required: true, message: t('modules.access_zone_form.form_item') }],
                })(
                  <TextArea
                    className="AccessZoneForm__textarea"
                    placeholder={t('modules.access_zone_form.placeholder.entrance')!}
                    rows={3}
                    onChange={() => setIsChangedCheck(false)}
                  />
                )}
              </Form.Item>

              <Form.Item>
                <Checkbox
                  checked={data ? data.controlPresence : isCheckNewZone}
                  onChange={processingCheckbox}
                  className="AccessZoneForm__checkbox"
                >
                  {t('modules.access_zone_form.checkbox')}
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </Form>
        <footer
          className={
            formType !== 'create zone'
              ? 'AccessZoneForm__footer'
              : 'AccessZoneForm__footer AccessZoneForm__footer_create'
          }
        >
          <Button
            id='AccessZoneFormDeleteButton'
            type="primary"
            className={
              formType !== 'create zone'
                ? 'AccessZoneForm__button-delete'
                : 'AccessZoneForm__button-delete AccessZoneForm__button-delete_hidden'
            }
            onClick={() => setIsVisibleModalDelete(true)}
          >
            {t('modules.access_zone_form.button.delete')}
          </Button>
          <button id='AccessZoneFormSaveButton' className="AccessZoneForm__button" disabled={disabledButton} onClick={onSubmit}>
            {t('modules.access_zone_form.button.save')}
          </button>
        </footer>
        <ModalDeleteWarning
          isVisibleModalDelete={isVisibleModalDelete}
          setIsVisibleModalDelete={setIsVisibleModalDelete}
          handleDelete={handleDelete}
          title={t('modules.access_zone_form.modal_delete_warning')}
        />
      </>
    );
  }
);

export default Form.create<AccessZoneFormProps>()(AccessZoneForm);
