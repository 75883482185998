import React from 'react';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import './index.scss';

const SideMenuContainer = props => {
  return (
    <div className="side-menu-container">
      <div className="side-menu-container__header">
        <CloseOutlined
          onClick={ev => {
            props.onClose();
          }}
          className="side-menu-container__header__icon" />
        <div className="side-menu-container__header__text">
          {props.headerTitle}
        </div>
      </div>

      <div className="side-menu-container__main">
        {props.isLoaded ? (
          <>{props.children}</>
        ) : (
          <div className="side-menu-container__loader">
            <div className="with-preloader__map">
              <LoadingOutlined />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideMenuContainer;
