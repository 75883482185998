import types from '../types';

export const setIsAddVisible = bool => ({
  type: types.SET_IS_ADD_VISIBLE,
  bool
});

export const setDefaultPromo = obj => ({
  type: types.SET_DEFAULT_PROMO,
  obj
});

export const setEditingMode = bool => ({
  type: types.SET_EDITING_MODE,
  bool
})
