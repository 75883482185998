/** For __typenames from GraphQL. Do not forget to update when updating types on the backend **/
export enum RemoteTypeNames {
  TICKET = 'Ticket',
  SEASON_TICKET = 'SeasonTicket',
  SEASON_TICKET_DESCRIPTOR = 'SeasonTicketDescriptor',
  MATCH = 'MatchLocalised',
}

/** Checking __typenames from GraphQL */
export default function checkTypeName(obj: any, type: RemoteTypeNames | string): boolean {
  if (obj.hasOwnProperty('__typename')) {
    const objType = obj.__typename;
    return objType === type;
  } else if ((typeof obj === 'string') && Object.values(RemoteTypeNames).find(t => t === obj)) {
    return obj === type;
  } else {
    console.error('An object that cannot be checked: ', obj);
    throw new Error('The type name cannot be checked, the __typename property is missing in the object');
  }
}
