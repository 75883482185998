import React, {useEffect, useState} from 'react';
import {LocalizedString, localizedStringIsNotEmpty} from 'utils/LocalizedString';
import TextEditor from '../TextEditor/TextEditorComponent';

import {LangCode} from '../LocalizedTextInput/LocalizedTextInput.types';
import {LocalizedTextEditorProps} from './LocalizedTextEditor.types';
import {getSupportedLngs} from 'i18n';

const supportedLangs = getSupportedLngs();

function LocalizedTextEditor({
  localizationLangs = supportedLangs,
  value,
  onChange,
  wrapperClasses,
  labelCustomization,
  placeholder,
}: LocalizedTextEditorProps) {
  const initialValue = value ?? LocalizedString.fromObject({});
  const [localizedValue, setLocalizedValue] = useState<LocalizedString>(initialValue);

  useEffect(() => {
    if (onChange && localizedValue) {
      onChange(localizedStringIsNotEmpty(localizedValue) ? localizedValue : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizedValue]);
  const handleInputChange = (newValue: string, lang: LangCode) => {
    setLocalizedValue((currentState) => LocalizedString.fromObject({...currentState.toObject(), [lang]: newValue}));
  };

  const definePlaceholder = (placeholder: LocalizedString | string | undefined, lang: LangCode) => {
    if (placeholder) {
      return (typeof placeholder === 'string') ? placeholder : placeholder.toString(lang);
    } else {
      return '';
    }
  }

  const generateFields = () => {
    let inputs: React.ReactNode[] = [];
    localizationLangs.forEach(lng => {
      const label = labelCustomization ? labelCustomization(lng) : null;
      const ph = definePlaceholder(placeholder, lng);
      inputs.push(
        <React.Fragment key={`localized-text-editor-${lng}`}>
          {label}
          <TextEditor
            placeholder={ph}
            initialValue={localizedValue.hasLanguage(lng) ? localizedValue.toString(lng) : ''}
            onChange={value => handleInputChange(value, lng)}
          />
        </React.Fragment>
      )
    })
    return inputs;
  }

  return (
    <>
      {wrapperClasses
        ? <div className={wrapperClasses}>{generateFields()}</div>
        : <>{generateFields()}</>
      }
    </>
  )
}

export default LocalizedTextEditor;
