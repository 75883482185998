import { ObjectDeserializator } from 'utils/apiHelpers';
import { LocalizedString } from 'utils/LocalizedString';
import { enumFromString } from 'utils/typeHelpers';

import {
  ListOfUsers,
  User,
  UserInfo,
  UserOrdersList,
  UserOrders,
  OrderItem,
  OrderStatus,
  Product,
  OrderItemStatus,
  LoyaltyRules,
  AdditionalData,
  PaymentPlace,
  LoyaltyBonusTransactionList,
  LoyaltyBonusTransaction,
  EntrancePassList,
  EntrancePass,
  AccessObjectType,
  Entrance,
  ZonesIn,
  ZonesOut,
  Events,
  Role,
} from './usersModel';

// a list of users

export const deserializeUserList = (u: ObjectDeserializator): ListOfUsers => {
  const contacts = u.required('person.contacts').asArrayOfObjects((c) => ({
    type: c.required('type').asString,
    value: c.required('value').asString,
  }));

  return {
    userId: u.required('id').asString,
    login: u.required('login').asString,
    birthday: u.optional('person.birthday')?.asDate,
    name: u.optional('person.name')?.as((o) => LocalizedString.fromObject(o)),
    surname: u.optional('person.surname')?.as((o) => LocalizedString.fromObject(o)),
    patronymic: u.optional('person.patronymic')?.as((o) => LocalizedString.fromObject(o)),
    phone: contacts.find((contact) => contact.type === 'PHONE')?.value,
    email: contacts.find((contact) => contact.type === 'EMAIL')?.value,
    isConfirmed: u.optional('person.contacts.isConfirmed')?.asBool,
  };
};

export const deserializeUser = (u: ObjectDeserializator): User => {
  return {
    total: u.required('total').asNumber,
    list: u.required('list').asArrayOfObjects((o) => deserializeUserList(o)),
  };
};

// User data

export const deserializeUserInfo = (u: ObjectDeserializator): UserInfo => {
  const contacts = u.required('person.contacts').asArrayOfObjects((c) => ({
    type: c.required('type').asString,
    value: c.required('value').asString,
  }));

  return {
    userId: u.required('id').asString,
    login: u.required('login').asString,
    name: u.required('person.name').as((o) => LocalizedString.fromObject(o)),
    surname: u.required('person.surname').as((o) => LocalizedString.fromObject(o)),
    patronymic: u.optional('person.patronymic')?.as((o) => LocalizedString.fromObject(o)),
    birthday: u.optional('person.birthday')?.asDate,
    phone: contacts.find((contact) => contact.type === 'PHONE')?.value,
    email: contacts.find((contact) => contact.type === 'EMAIL')?.value,
  };
};

// History of orders

export const deserializeOrderItem = (i: ObjectDeserializator): OrderItem => {
  return {
    id: i.required('id').asString,
    title: i.required('title').asString,
    type: enumFromString(Product, i.required('type').asString),
    status: enumFromString(OrderItemStatus, i.required('status').asString),
    price: i.required('price').asString,
    priceWithDiscount: i.required('priceWithDiscount').asString,
    refundTime: i.optional('refundTime')?.asDate,
  };
};

export const deserializeAdditionalData = (d: ObjectDeserializator): AdditionalData => {
  return {
    loyaltyAmount: d.optional('loyaltyAmount')?.asString,
    loyaltyAccrued: d.optional('loyaltyAccrued')?.asString,
    amount: d.optional('amount')?.asObject((o) => ({
      cash: o.required('cash').asString,
      electronic: o.required('electronic').asString,
    })),
    payment: d.optional('payment')?.asObject((o) => ({
      paymentPlace: enumFromString(PaymentPlace, o.required('paymentPlace').asString),
    })),
  };
};

export const deserializeUserOrdersList = (o: ObjectDeserializator): UserOrdersList => {
  return {
    id: o.required('id').asString,
    status: enumFromString(OrderStatus, o.required('status').asString),
    createdTime: o.required('createdTime').asDate,
    purchaseTime: o.optional('purchaseTime')?.asDate,
    price: o.required('price').asString,
    priceWithDiscount: o.required('priceWithDiscount').asString,
    visibleId: o.optional('visibleId')?.asString,
    appliedPromocode: o.optional('appliedPromocode')?.asString,
    additionalData: o.optional('additionalData')?.asObject((d) => deserializeAdditionalData(d)),
    items: o.required('items').asArrayOfObjects((i) => deserializeOrderItem(i)),
  };
};

export const deserializeUserOrders = (o: ObjectDeserializator): UserOrders => {
  return {
    total: o.required('total').asNumber,
    list: o.required('list').asArrayOfObjects((o) => deserializeUserOrdersList(o)),
  };
};

// Loyalty program

export const deserializeLoyaltyRules = (o: ObjectDeserializator): LoyaltyRules => {
  return {
    isActive: o.required('isActive').asBool,
    currencyNameMod50: o.required('loyaltyCurrencyName.mod50').asString,
  };
};

export const deserializeLoyaltyBonusTransactionList = (o: ObjectDeserializator): LoyaltyBonusTransactionList => {
  return {
    id: o.required('id').asString,
    userId: o.required('userId').asString,
    transactionValue: o.required('transactionValue').asString,
    isCanceled: o.required('isCanceled').asBool,
    createdAt: o.required('createdAt').asDate,
    expiresAt: o.optional('expiresAt')?.asDate,
    reason: o.optional('transactionBounds.reason')?.asString,
  };
};

export const deserializeLoyaltyBonusTransaction = (o: ObjectDeserializator): LoyaltyBonusTransaction => {
  return {
    total: o.required('total').asNumber,
    list: o.required('list').asArrayOfObjects((o) => deserializeLoyaltyBonusTransactionList(o)),
  };
};

// Pass data

export const deserializeZonesIn = (z: ObjectDeserializator): ZonesIn => {
  return {
    title: z.required('title').asString,
  };
};

export const deserializeZonesOut = (z: ObjectDeserializator): ZonesOut => {
  return {
    title: z.required('title').asString,
  };
};

export const deserializeEntrance = (e: ObjectDeserializator): Entrance => {
  return {
    title: e.required('title').asString,
    zonesIn: e.required('zonesIn').asArrayOfObjects((z) => deserializeZonesIn(z)),
    zonesOut: e.required('zonesOut').asArrayOfObjects((z) => deserializeZonesOut(z)),
  };
};

export const deserializeEvents = (e: ObjectDeserializator): Events => {
  return {
    title: e.required('title').asString,
  };
};

export const deserializeEntrancePassList = (o: ObjectDeserializator): EntrancePassList => {
  return {
    entrance: o.required('entrance').asObject((e) => deserializeEntrance(e)),
    direction: o.optional('direction')?.asString,
    dateTime: o.required('dateTime').asDate,
    accessObjectId: o.required('accessObject.id').asString,
    accessObjectTitle: o.required('accessObject.title').asString,
    accessObjectType: enumFromString(AccessObjectType, o.required('accessObject.type').asString),
    events: o.required('events').asArrayOfObjects((e) => deserializeEvents(e)),
    isOffline: o.required('isOffline').asBool,
  };
};

export const deserializeEntrancePass = (o: ObjectDeserializator): EntrancePass => {
  return {
    total: o.required('total').asNumber,
    list: o.required('list').asArrayOfObjects((o) => deserializeEntrancePassList(o)),
  };
};

// Role Data

export const deserializeRoles = (o: ObjectDeserializator): Role => {
  return {
    id: o.required('id').asString,
    name: o.required('name').asString,
    description: o.optional('description')?.asString,
    internal: o.required('internal').asBool,
  };
};
