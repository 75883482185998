import React from 'react';
import PromoHeader from './PromoHeader';
import PromoBody from './PromoBody';
import PromoCodesModal from '../../PromoModals/PromoCodesModal';
import PromoCodesActivationModal from '../../PromoModals/PromoCodesActivationsModal';
import { ConfigProvider } from 'antd';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';

const PromoList = (props) => {
  return (
    <ConfigProvider locale={determiningCurrentLocale()}>
      <div className="promocode">
        <PromoCodesActivationModal />
        <PromoCodesModal />
        <PromoHeader {...props} />
        <PromoBody />
      </div>
    </ConfigProvider>
  );
};

export default PromoList;
