import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CouponsTable.module.scss';
import discountBoundMaker from '../../utils/discountBoundMaker';
import formatDiscount from '../../utils/formatDiscount';
import formatPeriod from '../../utils/formatPeriod';
import formatWhomAvailable from '../../utils/formatWhomAvailable';

import { Table } from 'antd';
import { Pagination } from 'utils/commonTypes';
import { NBS } from 'constants.js';
import { CouponsList, Coupon } from '../../model/discountsModel';
import { useTranslation } from 'react-i18next';

function CouponsTable(props: {
  couponsList?: CouponsList;
  pagination: Pagination;
  onPageChange: (value?: number) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { Column } = Table;

  return (
    <Table
      dataSource={props.couponsList?.list}
      rowKey="id"
      pagination={{
        total: props.couponsList?.total,
        onChange: props.onPageChange,
        current: props.pagination.page + 1,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      rowClassName={styles.row}
      onRow={(couponsData: Coupon) => {
        return {
          onClick: () => {
            history.push(`/admin/discounts/coupon/${couponsData.id}`);
          },
        };
      }}
    >
      <Column title={t('modules.coupons_table.column.name')} dataIndex="title" align="left" />
      <Column
        title={t('modules.coupons_table.column.discount')}
        key="discount.value"
        align="left"
        render={(couponsData: Coupon) => {
          return formatDiscount(couponsData.discount);
        }}
      />
      <Column
        title={t('modules.coupons_table.column.condition')}
        key="priceBound.start"
        align="left"
        render={(couponData: Coupon) => {
          return discountBoundMaker(couponData);
        }}
      />
      <Column
        title={t('modules.coupons_table.column.validity')}
        key="couponsData.period.start"
        align="left"
        render={(couponsData: Coupon) => {
          return formatPeriod(couponsData.period);
        }}
      />
      <Column
        title={t('modules.coupons_table.column.whom_is_available')}
        key="couponsData.codes.total"
        align="left"
        render={(couponsData: Coupon) => {
          return formatWhomAvailable(couponsData.codes);
        }}
      />
      <Column
        title={`${t('modules.coupons_table.column.activations_on')}${NBS}${t('modules.coupons_table.column.account')}`}
        dataIndex="maxUseCountsPerUser"
        align="left"
      />
    </Table>
  );
}

export default CouponsTable;
