import i18next from 'i18next';
import { getSupportedLngs } from '../i18n';

type KeyValueObject = Record<string, string>;

const fallbackLangCodes = i18next.options.fallbackLng as string[];
const fallbackLangCode = fallbackLangCodes[0];

/**
 * String with multiple localizations
 */
export class LocalizedString {
  private constructor(private readonly data: KeyValueObject) {
  }

  /**
   * Create from object {string: string}.
   * @param data Key - language, value - string value for the specified language.
   */
  static fromObject(data: any): LocalizedString {
    const d: KeyValueObject = {};

    if (typeof data === 'string') {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
        data = parsedData;
        this.getStringByKey(data, d);
      } catch (error) {
        d[fallbackLangCode] = data;
      }
    } else {
      this.getStringByKey(data, d);
    }
    return new LocalizedString(d);
  }

  static fromString(s: string, langCode?: string): LocalizedString {
    return new LocalizedString({
      [langCode || fallbackLangCode]: s
    });
  }

  toObject(): KeyValueObject {
    return this.data;
  }

  hasLanguage(langCode: string): boolean {
    return this.data.hasOwnProperty(langCode);
  }

  private static getStringByKey(data: any, d: KeyValueObject) {
    if (data && typeof data === 'object' && Object.keys(data).length) {
      for (const key in data) {
        const value = data[key];
        if (typeof value === 'string') {
          d[key] = value;
        } else if (value === null) {
          d[key] = '';
        } else {
          throw new Error('<eecdb6c6> Data is not a string');
        }
      }
    } else {
      d[fallbackLangCode] = '';
    }
  }

  toString(langCode?: string): string {
    let result;
    if (langCode) {
      result = this.data[langCode];
      if (result || result === '') {
        return result;
      }
    }
    result = this.data[fallbackLangCode];
    if (result || result === '') {
      return result;
    }
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        result = this.data[key];
        return result || '';
      }
    }
    throw new Error(`<dce73154> Cannot get localized string for lang code: ${langCode}`);
  }
}

export function localizedStringIsNotEmpty(localizedString: LocalizedString) {
  const supportedLangs = getSupportedLngs();
  let isNotEmpty = false;
  supportedLangs.forEach((lng) => {
    if (localizedString.toString(lng) !== '') {
      isNotEmpty = true;
    }
  });
  return isNotEmpty;
}

export function localizedStringIsNotFull(localizedString: LocalizedString) {
  const supportedLangs = getSupportedLngs();
  let isNotFull = false;
  supportedLangs.forEach((lng) => {
    if (!localizedString.hasLanguage(lng) || localizedString.toString(lng) === '') {
      isNotFull = true;
    }
  });
  return isNotFull;
}

export function localizedStringsIsEqual(localizedString1: LocalizedString | undefined, localizedString2: LocalizedString | undefined) {
  let isEqual = false;
  if (localizedString1 && localizedString2) {
    const ls1Data = localizedString1.toObject();
    const ls1Keys = Object.keys(ls1Data);
    const ls2Data = localizedString2.toObject();
    const ls2Keys = Object.keys(ls2Data);
    if (ls1Keys.length === ls2Keys.length) {
      const isKeysEqual = ls1Keys.every((key) => ls2Keys.includes(key));
      return isKeysEqual ? ls1Keys.every((key) => ls1Data[key] === ls2Data[key]) : isEqual;
    }
    return isEqual;
  } else {
    return localizedString1 === localizedString2;
  }
}
