import { Contact, PeriodInput } from 'utils/commonTypes';
import { LocalizedString } from 'utils/LocalizedString';

export type RemoteFile = {
  id: string;
  publicLink: string;
  fileName?: string;
};

export type RemotePerson = {
  name: LocalizedString;
  patronymic?: LocalizedString;
  surname: LocalizedString;
  photo?: RemoteFile;
  contacts: Contact[];
};

export type RemoteUser = {
  id: string;
  login: string;
  visibleId: string;
  person: RemotePerson;
};

export type User = {
  user?: RemoteUser;
  createdAt: Date;
  updatedAt: Date;
};

export enum AccreditationRequestStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export enum ACC_Field_Type {
  TEXT = 'TEXT',
  LOCALIZED_TEXT = 'LOCALIZED_TEXT',
  NUMERIC = 'NUMERIC',
  DATE_TIME = 'DATE_TIME',
  FILE = 'FILE',
  IMAGE_FILE = 'IMAGE_FILE',
  PHONE = 'PHONE',
  BOOLEAN = 'BOOLEAN',
}

export enum ACC_RequestType {
  FOR_MATCH = 'FOR_MATCH',
  FOR_SEASON = 'FOR_SEASON',
  FOR_TOURNAMENT = 'FOR_TOURNAMENT',
}

export type ACC_AccessCategoryField = {
  id: string;
  title: LocalizedString;
  description?: LocalizedString;
  hint: LocalizedString;
  required: boolean;
  type: ACC_Field_Type;
  appliedCategoryId?: string;
  createdAt?: Date;
};

export type ACC_AccessCategory = {
  id: string;
  title: LocalizedString;
  fields?: ACC_AccessCategoryField[];
  // hidden: boolean;
  // createdAt: Date;
  // updatedAt: Date;
};

export type AccreditationFilterInput = {
  ids?: string[];
  visibleIds?: string[];
  quickSearch?: string;
  userIds?: string[];
  accessCategoryIds?: string[];
  tournamentIds?: string[];
  requestStatuses?: AccreditationRequestStatus[];
  updatedAt?: PeriodInput;
  createdAt?: PeriodInput;
};

export type Accreditation = {
  id: string;
  visibleId: string;
  accreditedUser: User;
  requestStatus: AccreditationRequestStatus;
  accessCategory: ACC_AccessCategory;
  createdAt: Date;
  requestType: ACC_RequestType;
  seasons: Season[];
  tournaments: Tournament[];
  matches: Match[];
};

export type AccreditationDetails = {};

export type AccreditationRejectionInput = {
  rejectionReasonId: string;
  notes?: string;
};

export type AccreditationRejectionReason = {
  id: string;
  title: LocalizedString;
  description: LocalizedString;
};

export type Tournament = {
  id: string;
  title: string;
};

export type Season = {
  id: string;
  startDate: Date;
  endDate: Date;
};

export type Team = {
  id: string;
  title: string;
  logo?: RemoteFile;
  location?: string;
};

export type Match = {
  id: string;
  title: string;
  description?: string;
  startTime?: Date;
  endTime?: Date;
  team1: Team;
  team2: Team;
  season?: Season;
  cover?: RemoteFile;
};

export type AdditionalFields = {
  value?: any;
  field: ACC_AccessCategoryField;
};

export type AccreditationDescriptor = {
  id: string;
  requestTypes: ACC_RequestType[];
  availableMatches: Match[];
  availableTournaments: Tournament[];
  availableSeasons: Season[];
  availableAccessCategories: ACC_AccessCategory[];
};

export type Event = Match | Tournament | Season;

export type Events = {
  matches: Match[];
  tournaments: Tournament[];
  seasons: Season[];
};

export type ACC_AdditionalFieldInput = {
  id: string;
  value?: string;
};

export type ACC_AccreditationRequestUpdateInput = {
  matchIds?: string[];
  tournamentIds?: string[];
  seasonIds?: string[];
  requestType: ACC_RequestType;
  accessCategoryId?: string;
  additionalFields?: ACC_AdditionalFieldInput[];
  stageIds: [];
  name?: Object;
  surname?: Object;
  patronymic?: Object;
  email?: string;
  photoFileId?: string;
  // requestStatus: AccreditationRequestStatus;
  // photoFileId?: string;
  // operationId: string;
};
