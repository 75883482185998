import gql from 'graphql-tag';

export const GET_PROMOCODES = gql`
  query (
    $filter: PromocodesDesciptorFilter
  ) {
    promocodes {
      getList(
        paging: {limit: 10000},
        filter: $filter
      ) {
        list {
          id
          title
          maxUseCounts
          maxUseCountsPerUser
          period {
            start
            end
          }
          status
          isActive
          commentary
          discount {
            type
            value
          }
          adminUseOnly
          useOn
          sectorBound {
            sectorIds
          }
          rowBound {
            rowIds
          }
          placeBound {
            placeIds
          }
          tournamentBound {
            tournamentIds
          }
          stageBound {
            stageIds
          }
          seasonBound {
            seasonIds
          }
          priceBound {
            priceRange {
              start
              end
            }
          }
          orderItemTypeBound {
            types
          }
          userBound {
            userIds
          }
          orderItemBound {
            items {
              type
              descriptorIds
            }
          }
          itemsNumberRange {
            start
            end
          }
          codes {
            list {
              value
              useCounter
            }
          }
        }
      }
    }
  }
`;

export const GET_SECTOR_BOUNDS = gql`
  query getSectorById($id: ID!) {
    sector {
      getById(id: $id) {
        id
        title
      }
    }
  }
`;
export const GET_ROW_BOUNDS = gql`
  query getRowById($id: ID!) {
    row {
      getById(id: $id) {
        id
        number
        sector {
          id
          title
        }
      }
    }
  }
`;
export const GET_PLACE_BOUNDS = gql`
  query getPlaceById($id: ID!) {
    place {
      getById(id: $id) {
        id
        number
        row {
          id
          number
          sector {
            id
            title
          }
        }
      }
    }
  }
`;
export const GET_TOURNAMENT_BOUNDS = gql`
  query getTournamentById($id: ID!) {
    tournament {
      getById(id: $id) {
        id
        title
      }
    }
  }
`;
export const GET_STAGE_BOUNDS = gql`
  query getStageById($id: ID!) {
    stage {
      getById(id: $id) {
        id
        title
      }
    }
  }
`;
export const GET_SEASON_BOUNDS = gql`
  query getSeasonById($id: ID!) {
    season {
      getById(id: $id) {
        id
        startDate
        endDate
      }
    }
  }
`;
export const GET_USER_BOUNDS = gql`
  query getUserById($id: ID!) {
    users {
      getUserById(id: $id) {
        id
        person {
          name
          surname
        }
      }
    }
  }
`;

export const GET_MATCHES = gql`
  query($id: ID!) {
    match {
      getById(id: $id) {
        title
      }
    }
  }
`;

export const GET_SEASON_TICKET_DESCRIPTORS = gql`
  query($id: ID!) {
    seasonTicketDescriptor {
      getById(id: $id) {
        title
      }
    }
  }
`;