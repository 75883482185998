import React from 'react';
import styles from './AccreditationTournamentsFilter.module.scss';
import { Select } from 'antd';
const { Option } = Select;

export default function AccreditationTournamentsFilter(props: {
  hadnleSelectTournament: (id: string) => void;
  list: any[];
  defaultValue: any;
}) {
  function handleChange(value: string) {
    props.hadnleSelectTournament(value);
  }
  return (
    <div className={styles.container}>
      <Select defaultValue={props.defaultValue || 'ALL'} className={styles.select} onChange={handleChange} size="large">
        {props.list.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.title}
          </Option>
        ))}
      </Select>
    </div>
  );
}
