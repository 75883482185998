import dayjs from 'dayjs';
import { NamedEntity } from 'utils/commonTypes';
import { LocalizedString } from 'utils/LocalizedString';

export enum PromocodeType {
  PROMOCODE = 'PROMOCODE',
  PERSONAL_COUPON = 'PERSONAL_COUPON',
}

export type Period = {
  start?: Date;
  end?: Date;
};

export enum Status {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export enum DiscountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
  FIXED_PRICE = 'FIXED_PRICE',
}
export type Discount = {
  type: DiscountType;
  value: string;
};
export type PromocodeDescriptor = {
  maxUseCountsPerUser?: number;
};
export type PersonData = {
  name: LocalizedString;
  surname: LocalizedString;
  patronymic?: LocalizedString;
  phone?: string;
  email?: string;
};
export type UserData = {
  id: string;
  login: string;
  person: PersonData;
};
export type CouponCode = {
  value: string;
  useCounter: number;
  descriptor: PromocodeDescriptor;
  couponUser?: UserData;
};
export type CodesPaginatedResult = {
  total: number;
  list: CouponCode[];
};

export type Season = {
  id: string;
  startDate: Date;
  endDate: Date;
};
export type Tournaments = {
  title: string;
};
export type TournamentBound = {
  season: Season;
  tournaments: Tournaments[];
};

export type Stages = {
  title: string;
};
export type StageBound = {
  season: Season;
  stages: Stages[];
};

export type SectorBound = {
  sectorIds: string[];
};

export type PriceBound = {
  start?: string;
  end?: string;
};

export enum ItemType {
  TICKET = 'TICKET',
  SEASON_TICKET = 'SEASON_TICKET',
  PROLONG_SEASON_TICKET = 'PROLONG_SEASON_TICKET',
  OUTFIT = 'OUTFIT',
}
export type OrderItemTypeBound = {
  types: ItemType[];
};

export type OrderItemConnection = {
  type: ItemType;
  descriptorIds: string[];
  items: NamedEntity[];
};

export type Coupon = {
  id: string;
  title: string;
  type: PromocodeType;
  period: Period;
  maxUseCountsPerUser?: number;
  tournamentBound?: TournamentBound;
  stageBound?: StageBound;
  seasonBound?: Season[];
  sectorBound?: SectorBound;
  priceBound?: PriceBound;
  orderItemTypeBound?: OrderItemTypeBound;
  orderItemBound?: OrderItemConnection[];
  discount: Discount;
  codes: CodesPaginatedResult;
};

export type CouponsList = {
  total: number;
  list: Coupon[];
};

export enum PeriodType {
  PERIOD = 'PERIOD',
  PERIOD_START = 'PERIOD_START',
  PERIOD_END = 'PERIOD_END',
}

export enum WayToAddUsersType {
  SEARCH_USER = 'SEARCH_USER',
  PHONES_LIST = 'PHONES_LIST',
}

export enum PromocodeUseOn {
  ORDER = 'ORDER',
  ITEM = 'ITEM',
}

export type NumbersRangeInput = {
  start: number;
  end: number;
};

export type SectorBoundInput = {
  sectorIds: string[];
};

export type RowBoundInput = {
  rowIds: string[];
};

export type PlaceBoundInput = {
  placeIds: string[];
};

export type TournamentBoundInput = {
  seasonId: string;
  tournamentIds: string[];
};

export type StageBoundInput = {
  seasonId: string;
  stageIds: string[];
};

export type SeasonBoundInput = {
  seasonIds: string[];
};

export type PriceRangeInput = {
  start?: number;
  end?: number;
};
export type PriceBoundInput = {
  priceRange: PriceRangeInput;
};

export type OrderItemTypeBoundInput = {
  types: ItemType;
};

export type OrderItemConnectionInput = {
  type: ItemType;
  descriptorIds: string[];
};
export type OrderItemsBoundInput = {
  items: OrderItemConnectionInput;
};

export type UserBoundInput = {
  userIds: string[];
};

export type UserDataForInitial = {
  userId?: string;
  login?: string;
  name?: LocalizedString;
  surname?: LocalizedString;
  patronymic?: LocalizedString;
  phone?: string;
  email?: string;
};

export type InitialFormValues = {
  promocodeType: PromocodeType;
  title: string;
  discountValue: string;
  discountType: DiscountType;
  maxUseCountsPerUser?: number;
  datePeriodType: PeriodType;
  period?: dayjs.Dayjs[];
  periodStart?: dayjs.Dayjs;
  periodEnd?: dayjs.Dayjs;
};

export type AddUsersInitialFormValues = {
  addUsers: UserDataForInitial[];
  addUsersRadios: WayToAddUsersType;
  couponsNumber?: number;
  phonesList?: string;
};

export type PhonesListInput = {
  phonesList: string;
};

export type PeriodStrings = {
  start?: string;
  end?: string;
};

export type FormSubmitValues = {
  title: string;
  type: PromocodeType;
  maxUseCounts?: number;
  maxUseCountsPerUser?: number;
  period: PeriodStrings;
  status: Status;
  commentary?: string;
  discount: Discount;
  adminUseOnly: boolean;
  useOn: PromocodeUseOn;
  itemsNumberRange?: NumbersRangeInput;
  sectorBound?: SectorBoundInput;
  rowBound?: RowBoundInput;
  placeBound?: PlaceBoundInput;
  tournamentBound?: TournamentBoundInput;
  stageBound?: StageBoundInput;
  seasonBound?: SeasonBoundInput;
  priceBound?: PriceBoundInput;
  orderItemTypeBound?: OrderItemTypeBoundInput;
  orderItemBound?: OrderItemsBoundInput;
  userBound?: UserBoundInput;
  groupIds: string[];
};

export type FormUpdateSubmitValues = {
  title: string;
  maxUseCounts?: number;
  maxUseCountsPerUser?: number;
  period: PeriodStrings;
  status: Status;
  commentary?: string;
  discount: Discount;
  adminUseOnly: boolean;
  useOn: PromocodeUseOn;
  itemsNumberRange?: NumbersRangeInput;
  sectorBound?: SectorBoundInput;
  rowBound?: RowBoundInput;
  placeBound?: PlaceBoundInput;
  tournamentBound?: TournamentBoundInput;
  stageBound?: StageBoundInput;
  seasonBound?: SeasonBoundInput;
  priceBound?: PriceBoundInput;
  orderItemTypeBound?: OrderItemTypeBoundInput;
  orderItemBound?: OrderItemsBoundInput;
  userBound?: UserBoundInput;
  groupIds: string[];
};

export type UserListPromocodeDescriptor = {
  maxUseCountsPerUser?: number;
};
export type UsersListPromocode = {
  value: string;
  useCounter: number;
  descriptor: UserListPromocodeDescriptor;
  couponUserId?: any;
  couponUser?: UserData;
};
export type CouponsUsersList = {
  key: string;
  value: UsersListPromocode[];
};
