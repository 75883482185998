import React, { useEffect } from 'react';
import * as L from 'leaflet';
import { pathOr } from 'ramda';
//DONT DELETE
import SelectArea from 'leaflet-area-select';
//DONT DELETE

export const zoomValues = [
  {
    coef: 3,
    value: 3,
    restand: false
  },
  {
    coef: 4,
    value: 4,
    restand: false
  },
  {
    coef: 8,
    value: 5,
    restand: false
  },
  {
    coef: 17,
    value: 6,
    restand: false
  },
  {
    coef: 30,
    value: 7,
    restand: true
  },
  {
    coef: 55,
    value: 8,
    restand: true
  },
]

const MapComponent = props => {
  const placeRadius = props.placeRadius ? props.placeRadius : 80 / 164;
  const weight = props.weight ? props.weight : 0.2;
  const initialZoom = props.initialZoom ? props.initialZoom : 3;
  const minZoom = props.minZoom ? props.minZoom : 3;
  const maxZoom = props.maxZoom ? props.maxZoom : 8;
  const eventHandler = props.eventHandler ? props.eventHandler : () => {};
  const remoteHandler = props.remoteHandler ? props.remoteHandler : () => {};
  const canvasTolerance = props.canvasTolerance ? props.canvasTolerance : 5;
  const clickableFromZoom = props.clickableFromZoom ? props.clickableFromZoom : 5;
  const defaultPlacesColor = props.defaultPlacesColor;

  useEffect(() => {
    let placeCount = 0;
    let zoomKf = 1;

    const map = L.map(props.containerId, {
      keyboard: false,
      crs: L.CRS.Simple,
      renderer: L.canvas({ tolerance: canvasTolerance }),
      minZoom: minZoom,
      maxZoom: maxZoom,
      zoomControl: false,
      selectArea: true, // will enable it by default,
      preferCanvas: true,
      boxZoom: false,
      mapMode: 'SELECT',
    });
    map.selectArea.setShiftKey(true)
    //rendering plazes
    const placeNumbers = [];

    const places = props.placesList.map(item => {
      const lat = pathOr(0, ['coordinates', 'y'], item);
      const lng = pathOr(0, ['coordinates', 'x'], item);
      const latLng = L.latLng([lat, lng]);

      const place = L.circleMarker(latLng, {
        radius: 1,
        weight: 0,
        id: pathOr('', ['id'], item),
        number: pathOr('', ['number'], item),
        fillColor: defaultPlacesColor,
        originalColor: defaultPlacesColor,
        fillOpacity: 1,
        interactive: true,
        placeObj: item,
      }).addTo(map);

      place.on('click', async function() {
        const zoomLevel = map._zoom;
        if (zoomLevel >= clickableFromZoom) {
          if (!place.options.isSelected && placeCount < props.maxSelection) {
            ++placeCount;
            place.setStyle({
              color: place.options.originalColor,
              fillColor: '#ffffff',
              weight: weight * zoomKf,
              isSelected: true,
            });
            eventHandler({
              type: 'placeSelect',
              data: place,
            });
          } else if (place.options.isSelected) {
            --placeCount;
            place.setStyle({
              fillColor: place.options.originalColor,
              weight: 0,
              isSelected: false,
            });
            eventHandler({
              type: 'placeRemove',
              data: {
                id: place.options.id,
              },
            });
          }
        }
      });
      return place;
    });
    map.on('areaselected', e => {
      const newPlaces = places.map(item => {
        if (e.bounds.contains(item.getLatLng())) {
          item.setStyle({
            color: item.options.originalColor,
            fillColor: '#ffffff',
            weight: weight * zoomKf,
            isSelected: true,
          });
          return item;
        } else {
          item.setStyle({
            fillColor: item.options.originalColor,
            weight: 0,
            isSelected: false,
          });
        }
      });
      props.setSelectedPlaces(newPlaces.filter(item => Boolean(item)));
    });
    eventHandler({
      type: 'mapInit',
      data: {
        map,
        places,
      },
    });
    ///
    //add overlay
    const bounds = [[0, 0], [props.overlaySize.height, props.overlaySize.width]];
    L.imageOverlay(props.overlayUrl, bounds).addTo(map);
    map.setMaxBounds(bounds);
    //add zoom
    L.control
      .zoom({
        position: 'topleft',
      })
      .addTo(map);
    //setting map drag
    map.dragging.enable();
    //set viev
    map.setView(new L.LatLng(props.overlaySize.height / 2, props.overlaySize.width / 2), initialZoom);
    //
    const restandNumbers = () => {
      dropNumbers();
      const zoomClass = map.getZoom() === 8 ? 'zoom-1' : 'zoom-2';
      places.forEach(item => {
        if (map.getBounds().contains(item.getLatLng())) {
          const divIcon = L.divIcon({
            html: `<div class="${zoomClass}">${pathOr('0', ['options', 'number'], item)}</div>`,
          });
          placeNumbers.push(
            L.marker(item.getLatLng(), {
              icon: divIcon,
            }).addTo(map)
          );
        }
      });
    };
    const dropNumbers = () => {
      placeNumbers.forEach(item => item.remove());
      placeNumbers.length = 0;
    };
    map.on('moveend', () => {
      if (map.getZoom() > 6) {
        restandNumbers();
      }
      eventHandler({
        type: 'moveEnd',
        data: {
          zoom: map.getZoom(),
          position: map.getCenter(),
        },
      });
    });
    map.on('zoom', e => {
      const handler = (k, item) => {
        item.setRadius(placeRadius * k);
        if (item.options.isSelected) {
          item.setStyle({
            ...item.options,
            weight: weight * k,
          });
        }
      };
      eventHandler({
        type: 'moveEnd',
        data: {
          zoom: map.getZoom(),
          position: map.getCenter(),
        },
      });
      const zoom = e.target._zoom;
      const defaultZoomConfig = {coef: 1, restand: false};
      const zoomConfig = zoomValues.find(item => item.value === zoom) || defaultZoomConfig;
      places.map(item => {
        zoomKf = zoomConfig.coef;
        handler(zoomKf, item);
        return true;
      });
      zoomConfig.restand ? restandNumbers() : dropNumbers();
      return true;
    });
    remoteHandler(map, places);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="stadium__markup__map-container">
      <div id={props.containerId}></div>
    </div>
  );
};

export default MapComponent;
