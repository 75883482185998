import React from 'react';
import './ModalDeleteWarning.scss';
import { Icon } from '@ant-design/compatible';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

function ModalDeleteWarning(props: {
  isVisibleModalDelete: boolean;
  setIsVisibleModalDelete: any;
  handleDelete: any;
  title: string;
}) {
  const { t } = useTranslation();
  const handleOk = (e: any) => {
    props.handleDelete();
    props.setIsVisibleModalDelete(false);
  };

  const handleCancel = (e: any) => {
    props.setIsVisibleModalDelete(false);
  };

  return (
    <div className="ModalDeleteWarning">
      <Modal
        title={false}
        closable={false}
        visible={props.isVisibleModalDelete}
        bodyStyle={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
        footer={null}
        width={415}
      >
        <div className="ModalDeleteWarning__content">
          <h3 className="ModalDeleteWarning__title">
            <Icon type="close-circle" className="ModalDeleteWarning__icon" /> {props.title}
          </h3>
        </div>
        <div>
          <Button
            id="ModalDeleteWarningDeleteButton"
            key="close"
            className="ModalDeleteWarning__button-delete"
            onClick={(e) => {
              handleOk(e);
            }}
          >
            {t('modules.modal_delete_warning.button.delete')}
          </Button>

          <Button id="ModalDeleteWarningCancelButton" key="cancel" className="ModalDeleteWarning__button-cancel" type="primary" onClick={handleCancel}>
            {t('modules.modal_delete_warning.button.cancel')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ModalDeleteWarning;
