import gql from 'graphql-tag';

export const GET_MAP = gql`
  query {
    place {
      getList(paging: { limit: 100000 }) {
        list {
          id
          number
          row {
            id
            number
            sector {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_ROWS = gql`
  query {
    place {
      getList(paging: { limit: 100000 }) {
        list {
          id
          number
          row {
            id
            number
            sector {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_SECTORS = gql`
  query {
    place {
      getList(paging: { limit: 100000 }) {
        list {
          id
          number
          row {
            id
            number
            sector {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_TOURNAMENTS = gql`
  query {
    tournament {
      getList(filter: { pagination: { limit: 100000 } }) {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_STAGE = gql`
  query {
    stage {
      getList(filter: { pagination: { limit: 100000 } }) {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_SEASON = gql`
  query {
    season {
      getList(filter: { pagination: { limit: 100000 } }) {
        list {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query {
    users {
      getListAdvanced(paging: { limit: 10000 }) {
        list {
          id
          login
        }
      }
    }
  }
`;

export const GET_MATCHES = gql`
  query {
    match {
      getList(filter: { statuses: [ACTIVE, CREATED], hidden: null  }) {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_ACTIVE_SEASON_TICKET_DESCRIPTORS = gql`
  query {
    seasonTicketDescriptor {
      getList(filter: { statuses: [ACTIVE], hidden: null }) {
        list {
          id
          title
          status
        }
      }
    }
  }
`;

export const GET_PROLONG_SEASON_TICKET_DESCRIPTORS = gql`
  query {
    seasonTicketDescriptor {
      getList(filter: { statuses: [PROLONG_ONLY], hidden: null }) {
        list {
          id
          title
          status
        }
      }
    }
  }
`;
