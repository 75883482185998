import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {Input} from 'antd';

import { LocalizedString, localizedStringIsNotEmpty } from 'utils/LocalizedString';
import {LangCode, LocalizedTextInputProps} from './LocalizedTextInput.types';
import {getSupportedLngs} from 'i18n';
import styles from './LocalizedTextInput.module.scss';
import {capitalize} from 'lodash';
import useLocalizedTextValidation from '../hooks/useLocalizedTextValidation/useLocalizedTextValidation';
import { CloseCircleFilled } from '@ant-design/icons';

const supportedLangs = getSupportedLngs();

function LocalizedTextInput({
  inputName,
  localizationLangs = supportedLangs,
  labelTitle,
  onChange,
  onPaste,
  onError,
  value,
  validation,
  labelPosition = 'above',
  direction = 'row',
  justify = 'start',
  borderRadius = '4px',
  columns,
  wrapperClasses,
  placeholder,
  labelCustomization,
  hintCustomization,
  removeHandler,
}: LocalizedTextInputProps) {

  const initialValue = value ?? LocalizedString.fromObject({});
  const [input, setInput] = useState<LocalizedString>(initialValue);
  const {hint, restrictKeyInput, restrictPaste, checkRequired, handleErrors} = useLocalizedTextValidation({config: validation, onError});

  // Effects

  useEffect(() => {
    if (onChange && input) {
      onChange(localizedStringIsNotEmpty(input) ? input : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  // Dynamic css classes

  const wrapperClassNames = cn(
    styles.wrapper,
    styles[`direction${capitalize(direction)}`],
    {[styles[`justify-${justify}`]]: direction === 'row'},
    {[styles[`align-${justify}`]]: direction === 'column'},
    wrapperClasses
  );

  const labelClasses = cn(
    styles.label,
    styles[`label${capitalize(labelPosition)}`],
  );

  const columnWidth = columns ? Math.floor(100 / columns).toString() + '%' : '';

  // Handlers

  const handleInputChange = (inputValue: string, lang: LangCode) => {
    const currentInput = input?.toObject();
    const merged = {
      ...currentInput,
      [lang]: inputValue
    };
    setInput(LocalizedString.fromObject(merged));

    handleErrors();
  };

  // UI

  const generateFields = () => {
    const inputs: JSX.Element[] = [];
    localizationLangs.forEach((lng) => {

      const definePlaceholder = (placeholder: LocalizedString | string | undefined) => {
        if (placeholder) {
          return (typeof placeholder === 'string') ? placeholder : placeholder.toString(lng);
        } else {
          return '';
        }
      };

      const definedPlaceholder = definePlaceholder(placeholder);
      const definedLabel = labelCustomization
        ? labelCustomization(lng)
        : `${labelTitle || ''} [${lng}]${validation?.required ? '*' : ''}`;

      inputs.push(
        <label className={labelClasses} key={`${inputName}-${lng}-label`} style={{width: columnWidth}}>
          <h3 className={styles.itemTitle}>{definedLabel}</h3>
          <Input
            id={`${inputName}-${lng}`}
            name={inputName}
            size="large"
            className={styles.input}
            onChange={(e) => handleInputChange(e.target.value, lng)}
            onKeyPress={e => restrictKeyInput(e, lng)}
            onBlur={() => {
              checkRequired(input);
              handleErrors();
            }}
            onPaste={(e)=> {
              onPaste && onPaste(e);
              restrictPaste(e, lng);
              handleErrors();
            }}
            maxLength={validation?.length?.max}
            minLength={validation?.length?.min}
            required={validation?.required?.value}
            spellCheck={false}
            style={{borderRadius}}
            placeholder={definedPlaceholder}
            value={input.hasLanguage(lng) ? input.toString(lng) : ''}
          />
        </label>
      );
    });

    return inputs;
  };

  const inputFields = generateFields();
  const displayHints = () => {
    if (hint) {
      return hintCustomization ? hintCustomization(hint) : <span className={styles.hint}>{hint}</span>;
    } else {
      return null;
    }
  };

  return (
    <div className={wrapperClassNames}>
      {removeHandler
        ? <div className={styles.closeButton} onClick={removeHandler}><CloseCircleFilled /></div>
        : null}
      {inputFields}
      {displayHints()}
    </div>
  );
}

export default LocalizedTextInput;
