import React, { useState } from 'react';
import styles from './AddUsersItem.module.scss';

import SearchUser from 'common/SearchUsers/SearchUsers';
import { WayToAddUsersType } from '../../../model/discountsModel';
import { User } from 'common/users/types';

import { Form, Input, Radio, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function AddUsersItem(props: {
  wrongPhonesList: string[];
  setWrongPhonesList: (value: string[]) => void;
  onChange?: (value: User[]) => void;
  value?: User[];
}) {
  const { t } = useTranslation();
  const { Column } = Table;
  const { TextArea } = Input;

  const [wayToAddUsers, setWayToAddUsers] = useState<WayToAddUsersType>(WayToAddUsersType.SEARCH_USER);

  function deleteUser(userId: string) {
    if (props.value) {
      props.onChange && props.onChange([...props.value?.filter((u: User) => u.userId !== userId)]);
    }
  }

  function onUserSelect(newUser: User) {
    props.onChange && props.onChange([newUser, ...(props.value || [])]);
  }

  const InputPhonesList = (
    <Form.Item name="phonesList">
      <TextArea
        id="phonesList"
        placeholder={t('modules.add_users_item.textarea')!}
        className={styles.textArea}
        autoSize={true}
        autoComplete="off"
        spellCheck={false}
        rows={1}
      />
    </Form.Item>
  );

  const SearchUserItem = <SearchUser onSelect={onUserSelect} />;

  const ADD_USER_INPUT_MAP: any = {
    SEARCH_USER: SearchUserItem,
    PHONES_LIST: InputPhonesList,
  };

  return (
    <>
      <div className={styles.addUserInputWrapper}>{ADD_USER_INPUT_MAP[wayToAddUsers]}</div>
      <Form.Item name="addUsersRadios" className={styles.item}>
        <Radio.Group
          id="addUsersRadios"
          onChange={(e) => setWayToAddUsers(e.target.value)}
          className={styles.radioGroup}
        >
          <Radio id="addUsersBySearch" value={WayToAddUsersType.SEARCH_USER}>
            {t('modules.add_users_item.radio.find_via_search')}
          </Radio>
          <Radio id="addUsersByPhonesList" value={WayToAddUsersType.PHONES_LIST}>
            {t('modules.add_users_item.radio.insert_list_numbers')}
          </Radio>
        </Radio.Group>
      </Form.Item>

      {props.wrongPhonesList?.length !== 0 && (
        <div className={styles.wrongNumbersBlock}>
          <div className={styles.wrongNumbersBlockHeader}>
            <h4 className={styles.wrongNumbersBlockTitle}>
              {props.wrongPhonesList?.length === 1
                ? t('modules.add_users_item.h4.wrong_number')
                : t('modules.add_users_item.h4.invalid_numbers')}
            </h4>
            <PlusOutlined
              title={t('modules.add_users_item.plus_outlined.hide')!}
              id="closeWrongNumbersBlockButton"
              className={styles.wrongNumbersBlockCross}
              onClick={() => {
                props.setWrongPhonesList([]);
              }}
            />
          </div>
          <ul className={styles.wrongNumbersBlockList}>
            {props.wrongPhonesList.map((wrongNumber: string) => (
              <li key={wrongNumber} className={styles.wrongNumbersBlockItem}>
                {wrongNumber}
              </li>
            ))}
          </ul>
        </div>
      )}

      <Table
        dataSource={props.value}
        size="middle"
        rowKey={(record) => record.userId}
        className={styles.table}
        pagination={{
          total: props.value?.length,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
      >
        <Column
          title={t('modules.add_users_item.column.full_name')}
          key="userId"
          align="left"
          render={(usersArr) =>
            usersArr &&
            `${usersArr.surname.toString()} ${usersArr.name.toString()} ${usersArr.patronymic?.toString() || ''}`
          }
        />
        <Column
          title={t('modules.add_users_item.column.number')}
          key="phone"
          align="left"
          render={(userArr) => userArr.phone}
        />
        <Column title="E-mail" key="email" align="left" render={(userArr) => userArr.email} />
        <Column
          title=""
          render={(usersArr) => {
            return (
              <PlusOutlined
                title={t('modules.add_users_item.plus_outlined.delete')!}
                className={styles.crossIcon}
                onClick={() => {
                  deleteUser(usersArr.userId);
                }}
              />
            );
          }}
        />
      </Table>
    </>
  );
}

export default AddUsersItem;
