import './colors.css';
import 'antd/dist/reset.css';
import './index.scss';
import './figma/styles/colors.css';
import './figma/styles/text.css';

import { Application } from './modules/app/src';
import { ModuleAdmin } from './modules/admin-panel/src';
import { ModuleTickets } from './modules/tickets/src';
import { ModuleUsers } from './modules/users/src';
import { AuthModule } from './modules/auth/src';
import { ModulePromocodes } from './modules/promocode/src';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import CrossDomainCredentialStorage from '@sebbia/auth-client/lib/CrossDomainCredentialStorage';
import GraphQLClientImpl from '@sebbia/auth-client/lib/GraphQLClientImpl';
import { createTokenRefreshLink } from '@sebbia/auth-client/lib/CredentailsRefreshLink';
import * as Sentry from '@sentry/react';
import { ModuleAccreditation } from './modules/accreditation/src';
import { ModuleDiscounts } from './modules/discounts/src';
import { ModuleAccessControl } from './modules/accessControl/src/';
import { ModuleLoyalty } from './modules/loyalty/src';
import { Integrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import './i18n';
import { parametrizeEnv } from 'utils/helpers';
import { t } from 'i18next';

declare global {
  interface Window {
    parametrize: any;
    less: any;
  }
}

window.parametrize = parametrizeEnv;

Sentry.init({
  release: process.env['REACT_APP_VERSION_TAG'],
  dsn: window.parametrize('REACT_APP_SENTRY_DNS'),
  environment: window.parametrize('REACT_APP_SENTRY_ENV'),
  integrations: [
    new Integrations.BrowserTracing(),
    new SentryRRWeb({
      // ...options
      maskAllInputs: false
    })
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

document.title = t('react_app.title');
(document as any).querySelector('link[rel=\'shortcut icon\']').href = window.parametrize('REACT_APP_FAVICON');

const startApp = async () => {
  const graphQlClient = new GraphQLClientImpl(window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'), 20000);
  const storage = new CrossDomainCredentialStorage(
    window.parametrize('REACT_APP_CROSSDOMAIN_STORAGE'),
    'SportId',
    20000
  );

  function initGlobalStyles() {
    const colorOverridesStyle = document.createElement('style');
    colorOverridesStyle.innerHTML = `
    :root {
      --clubAccent-override: ${window.parametrize('REACT_APP_CLUB_ACCENT')};
      --clubBg1-override: ${window.parametrize('REACT_APP_CLUB_BG_1')};
      --clubBg2-override: ${window.parametrize('REACT_APP_CLUB_BG_2')};
      --clubBg3-override: ${window.parametrize('REACT_APP_CLUB_BG_3')};
      --clubNavigation1-override: ${window.parametrize('REACT_APP_CLUB_NAVIGATION_1')};
      --clubNavigation2-override: ${window.parametrize('REACT_APP_CLUB_NAVIGATION_2')};
      --clubPrimary-override: ${window.parametrize('REACT_APP_CLUB_PRIMARY')};
      --clubPrimaryLight-override: ${window.parametrize('REACT_APP_CLUB_PRIMARY_LIGHT')};
    }
  `;
    document.head.appendChild(colorOverridesStyle);
  }

  initGlobalStyles();
  const authClient = new AuthClient(graphQlClient, storage);
  await authClient.init();
  const refreshAuthLink = createTokenRefreshLink(authClient);
  const app = new Application();
  const moduleAdmin = new ModuleAdmin(app, authClient);
  const moduleTickets = new ModuleTickets(app, moduleAdmin, moduleAdmin, authClient);
  const modulePromocodes = new ModulePromocodes(app, moduleAdmin, moduleAdmin);
  const moduleUsers = new ModuleUsers(app, moduleAdmin, moduleAdmin, authClient);
  const moduleAuth = new AuthModule(authClient, app);
  // const moduleCustomization = new ModuleCustomization(app, moduleAdmin, moduleAdmin);
  const moduleAccreditation = new ModuleAccreditation(app, moduleAdmin, moduleAdmin);
  const moduleDiscounts = new ModuleDiscounts(app, moduleAdmin, moduleAdmin);
  const moduleAccessControl = new ModuleAccessControl(app, moduleAdmin, moduleAdmin);
  const moduleLoyalty = new ModuleLoyalty(app, moduleAdmin, moduleAdmin, authClient);
  app.start(authClient, refreshAuthLink);

  [
    moduleAdmin,
    moduleTickets,
    modulePromocodes,
    moduleUsers,
    moduleAuth,
    // moduleCustomization,
    moduleAccreditation,
    moduleDiscounts,
    moduleAccessControl,
    moduleLoyalty
  ].forEach((element) => {
    element.start();
  });
};
startApp();
