import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message } from 'antd';
import { withFormik } from 'formik';
import uniqBy from 'ramda/es/uniqBy';
import assoc from 'ramda/es/assoc';
import prop from 'ramda/es/prop';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const PromoAddRange = (props) => {
  const { t } = useTranslation();
  return (
    <div className="promo-modal__body">
      <div className="promo-modal__header">{props.title}</div>
      <div className="promo-modal__range">
        <Form onSubmit={props.handleSubmit}>
          <Form.Item label={t('modules.promo_add_modal.promo_add_range.from')}>
            <Input
              type="number"
              value={props.values.from}
              onChange={(e) => props.values.from.trim().length < 11 && props.setFieldValue('from', e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t('modules.promo_add_modal.promo_add_range.to')}>
            <Input
              type="number"
              value={props.values.to}
              onChange={(e) => props.values.from.trim().length < 11 && props.setFieldValue('to', e.target.value)}
            />
          </Form.Item>
          <Button id="PromoAddRangePriceSelectionButton" onClick={props.handleSubmit}>
            {t('modules.promo_add_modal.promo_add_range.select')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    from: '',
    to: '',
  }),
  handleSubmit: (formValues, { props }) => {
    const checkValueZero = Number(formValues.from.trim()) === 0 && Number(formValues.to.trim()) === 0;
    if (!checkValueZero) {
      const values = [{ title: formValues.from }, { title: formValues.to }];
      const updated = assoc('values', values, props.boundItem);
      const updatedBounds = uniqBy(prop('id'), [updated, ...props.values.bounds]);
      props.handleSelect('bounds', updatedBounds);
      props.onClose();
    } else {
      message.error(t('modules.message_error'));
    }
  },
})(PromoAddRange);
