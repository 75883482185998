import PromoBody from './components';
import withHandlers from './containers/withHandlers';
import withFormatHandlers from '../../../containers/withHandlers';
import lifecycle from './containers/lifecycle';
import connect from './containers/connect';

import { withApollo } from 'react-apollo';

import { compose } from 'ramda';

export default compose(connect, withApollo, withFormatHandlers, withHandlers, lifecycle)(PromoBody);
