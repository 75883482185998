import promoList from './PromoList';
import './index.scss';

import { IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import reducer from './reducer';
import { t } from 'i18next';

export class ModulePromocodes implements Module {
  private app: IApp;
  private component: any; //TODO
  private reducers: Array<{ name: string; reducer: any }>;
  private routeContainer: IRouteContainer;
  private menuContainer: ISidebar;

  constructor(app: IApp, routeContainer: IRouteContainer, menuContainer: ISidebar) {
    this.component = {
      promoList
    };
    this.reducers = [
      {
        name: 'promocodes',
        reducer
      }
    ];

    this.app = app;
    this.menuContainer = menuContainer;
    this.routeContainer = routeContainer;

    this.routeContainer.addRoute({
      path: '/admin/promo',
      component: this.component.promoList
    });

    this.reducers.map((reducer: any) => this.app.addReducer(reducer));

    const priceCategoryTemplateEventLink = new RouteAction({
      title: t('modules.promocode_index'),
      icon: 'qrcode',
      name: 'tickets',
      order: 7,
      enabled: true,
      visible: true,
      route: 'admin/promo',
      permission: 'ACCESS_PROMOCODE',
      localizationKey: 'promocodes'
    });

    this.menuContainer.addLink(priceCategoryTemplateEventLink);
  }

  start() {
  }
}
