import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { GET_PRICE_TEMPLATES } from '../../graphql';
import PriceEditMenu from './PriceEditMenu';
import PriceAddMenu from './PriceAddMenu';
import { useTranslation } from 'react-i18next';
import { pricesSort } from '../../utils/priceTemplateHelpers';

const PriceTemplatesComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    isLoaded: false,
    priceEditVisible: false,
    selectedPriceTemplate: {},
    isNewPriceTemplate: false,
  });

  const scrollTop = () => {
    window.document.getElementsByClassName('page-wrapper')[0].scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const setLoading = () => {
    setState({
      ...stateProps,
      isLoaded: false,
      priceEditVisible: false,
      isNewPriceTemplate: false,
    });
  };

  const onMenuClose = () => {
    setState({
      ...stateProps,
      priceEditVisible: false,
    });
  };
  const onAddMenuClose = () => {
    setState({
      ...stateProps,
      isNewPriceTemplate: false,
    });
  };

  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_PRICE_TEMPLATES,
        fetchPolicy: 'no-cache',
      });

      const list = pathOr([], ['data', 'priceTemplate', 'getList', 'list'], res);
      const filteredList = list.map((item) => {
        return {
          id: item.id,
          title: item.title,
          prices: item.prices.map((price) => {
            return {
              title: pathOr('', ['priceCategory', 'title'], price),
              color: pathOr('', ['priceCategory', 'color'], price),
              value: Math.round(parseInt(pathOr('', ['value'], price))),
              id: pathOr('', ['priceCategory', 'id'], price),
            };
          }),
        };
      });
      setState({
        ...stateProps,
        isLoaded: true,
        filteredList,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return (
    <>
      <h1>{t('modules.price_templates_component.h1')}</h1>
      <div className="route-content">
        {
          <>
            {!stateProps.isLoaded && (
              <div className="with-preloader__map">
                <LoadingOutlined />
              </div>
            )}
            {stateProps.isLoaded &&
              stateProps.filteredList.map((item) => (
                <>
                  <div className="sales__price-template" key={`priceTemplate-${item.title}`}>
                    <div className="sales__price-template__header">
                      <div className="sales__price-template__header__title">{item.title}</div>
                      <EditOutlined
                        className="sales__price-template__header__icon"
                        onClick={() => {
                          scrollTop();
                          setState({
                            ...stateProps,
                            priceEditVisible: true,
                            selectedPriceTemplate: item,
                          });
                        }}
                      />
                    </div>

                    <div className="sales__price-template__body">
                      {item.prices
                      .sort(pricesSort)
                      .map((price) => (
                        <div className="sales__price-template__body__item" key={`priceTemplateBody-${price.title}`}>
                          <div
                            className="sales__price-template__body__item__circle"
                            style={{
                              backgroundColor: price.color,
                            }}
                          ></div>
                          <div className="sales__price-template__body__item__title">{price.title}</div>
                          <div className="sales__price-template__body__item__price">
                            {price.value + ` ${t('currency')}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            {stateProps.priceEditVisible && (
              <PriceEditMenu
                setLoading={() => {
                  setLoading();
                }}
                onClose={() => {
                  onMenuClose();
                }}
                selectedPriceTemplate={stateProps.selectedPriceTemplate}
                client={props.client}
              />
            )}
            {stateProps.isNewPriceTemplate && (
              <PriceAddMenu
                setNewLoading={() => {
                  setLoading();
                }}
                onClose={() => {
                  onAddMenuClose();
                }}
                client={props.client}
                setLoading={() => {
                  setLoading();
                }}
              />
            )}
            <Button
              id="PriceTemplatesComponentAddTemplateButton"
              className="sales__price-template__add-template"
              onClick={() => {
                setState({
                  ...stateProps,
                  isNewPriceTemplate: true,
                });
              }}
            >
              <PlusOutlined className="sales__price-template__add-template__icon" />
              <span className="sales__price-template__add-template__text">
                {t('modules.price_templates_component.span')}
              </span>
            </Button>
          </>
        }
      </div>
    </>
  );
};

export default PriceTemplatesComponent;
