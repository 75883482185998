import React from 'react';
import { Empty, Button } from 'antd';
import { Icon } from '@ant-design/compatible';

import image from '../../img/empy-placholder-image.png';
import './EmptyPlaceholder.scss';

function EmptyPlaceholder(props: {
  handleClick?: () => void;
  title?: string;
  text: string | JSX.Element;
  buttonText?: string;
}) {
  return (
    <div className="EmptyPlaceholder">
      <Empty
        image={image}
        imageStyle={{
          height: 100,
          marginBottom: '24px',
        }}
        description={null}
      ></Empty>
      <h3 className="EmptyPlaceholder__title">{props.title}</h3>
      <p className="EmptyPlaceholder__text">{props.text}</p>
      {props.buttonText && (
        <Button
          id='EmptyPlaceholderButton'
          type="ghost"
          size="large"
          className="EmptyPlaceholder__addButton"
          onClick={() => props.handleClick && props.handleClick()}
        >
          <Icon type="plus" className="EmptyPlaceholder__addButton-icon" />
          <span className="EmptyPlaceholder__addButton-text">{props.buttonText}</span>
        </Button>
      )}
    </div>
  );
}

export default EmptyPlaceholder;
