import { connect } from 'react-redux';
import * as actions from '../actions';
import { setPromoList } from '../../actions';
import { openCodesModal, setCodesToReducer } from '../../PromoModals/actions';
import pathOr from 'ramda/es/pathOr';

const mapStateToProps = state => ({
  isAddOpen: pathOr(
    false,
    ['reducer', 'promocodes', 'promoReducer', 'isAddOpen'],
    state
  ),
  defaultPromo: pathOr(
    false,
    ['reducer', 'promocodes', 'promoReducer', 'defaultPromo'],
    state
  ),
  promoList: pathOr(
    [],
    ['reducer', 'promocodes', 'promoRootReducer', 'promoList'],
    state
  ),
  isEditingMode: pathOr(
    false,
    ['reducer', 'promocodes', 'promoReducer', 'isEditingMode'],
    state
  )
});

const mapDispatchToProps = {
  ...actions,
  setPromoList,
  openCodesModal,
  setCodesToReducer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
