import gql from 'graphql-tag';

export const GET_ACTIVE_STATUS = gql`
  query LTY_LoyaltyDescriptorQuery {
    loyalty {
      loyaltyDescriptorQuery {
        getDescriptor {
          isActive 
        }
      }
    }
  }
`;

export const GET_FILE_PUBLIC_LINK = gql`
  query getPublicLink($id: String!) {
    file {
      getFileDescriptor(id: $id) {
        publicLink
      }
    }
  }
`;

export const GET_LOYALTY_DATA = gql`
  query LTY_LoyaltyDescriptorQuery {
    loyalty {
      loyaltyDescriptorQuery {
        getDescriptor {
          bonusActivePeriodDays,
          bonusPriceCoverPercentage,
          accrualPercentage,
          maxExpenditurePerTransactionPercentage,
          applicableWithDiscount,
          legalityDocumentationLink,
          legalityDocumentation { 
            id 
            publicLink 
            fileName 
          },
          loyaltyCurrencyName{
            mod1 
            plural
            mod24 
            mod50
          },
          updatedAt,
          isActive 
        }
      }
    }
  }
`;
