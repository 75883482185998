import gql from 'graphql-tag';

export const SEARCH_USERS = gql`
  query searchUsers($quickSearch: String!) {
    users {
      getListAdvanced(filter: { quickSearch: $quickSearch }) {
        list {
          id
          login
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_PAGE = gql`
  query getUsersByPage($page: Int!, $searchString: String!) {
    users {
      getListAdvanced(
        filter: { quickSearch: $searchString }
        paging: { limit: 10, page: $page }
      ) {
        total
        list {
          id
          login
          person {
            birthday
            name
            patronymic
            surname
            contacts {
              type
              value
              isConfirmed
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: ID!) {
    users {
      getUserById(id: $userId) {
        id
        visibleId
        login
        person {
          name
          surname
          patronymic
          contacts {
            type
            value
          }
          birthday
        }
      }
    }
  }
`;

export const GET_USER_ORDERS = gql`
  query getUserOrders($userIds: [ID!], $pagination: Pagination) {
    order {
      getList(filter: { userIds: $userIds }, paging: $pagination) {
        total
        list {
          id
          status
          createdTime
          purchaseTime
          price
          priceWithDiscount
          visibleId
          appliedPromocode
          additionalData {
            loyaltyAmount
            loyaltyAccrued
            amount {
              cash
              electronic
            }
            payment {
              paymentPlace
            }
          }
          items {
            id
            title
            type
            status
            price
            priceWithDiscount
            refundTime
          }
        }
      }
    }
  }
`;


export const GET_USER_BONUS_COUNT = gql`
  query getUserBonusCount($userId: ID!) {
    loyalty {
      transactionQuery {
        getUserBonusCount(userId: $userId)
      }
    }
  }
`;

export const GET_LOYALTY_RULES = gql`
  query getLoyaltyRules {
    loyalty {
      loyaltyDescriptorQuery {
        getDescriptor {
          isActive
          loyaltyCurrencyName {
            mod50
          }
        }
      }
    }
  }
`;

export const GET_LOYALTY_BONUS_TRANSACTION = gql`
  query getLoyaltyBonusTransaction($userIds: [ID!], $pagination: Pagination) {
    loyalty {
      transactionQuery {
        getList(filter: { userIds: $userIds }, pagination: $pagination) {
          total
          list {
            id
            userId
            transactionValue
            isCanceled
            createdAt
            expiresAt
            transactionBounds {
              reason
            }
          }
        }
      }
    }
  }
`;

export const GET_ENTRANCE_PASS = gql `
  query getEntrancePass($userIds: [ID!], $pagination: Pagination) {
    acs {
      entrancePass {
        getList(filter: { ownerIds: $userIds }, paging: $pagination) {
        total
          list {
            entrance {
              title
              zonesIn {
                title
              }
              zonesOut {
                title
              }
            }
            direction
            dateTime
            accessObject {
              id
              title
              type
            }
            events {
              title
            }
            isOffline
          }
        }
      }
    }
  }
`;

export const GET_ROLES_LIST = gql `
  query getRoles {
    roles {
      getList {
        id
        name
        description
        internal
      }
    }
  }
`
