import gql from 'graphql-tag';
import { PROMOCODE_DESCRIPTOR_FRAGMENT } from './discountsFragments';


export const GET_COUPONS_LIST = gql `
  ${ PROMOCODE_DESCRIPTOR_FRAGMENT }
  query getCouponsList($paging: Pagination, $filter: PromocodesDesciptorFilter) {
    promocodes {
      getList(paging: $paging, filter: $filter) {
        total
        list {
          ...PromocodeDescriptorFragment
        }
      }
    }
  }
`;

export const GET_COUPONS_USERS = gql `
  query getCouponsUsers($id: ID!, $paging: Pagination) {
    promocodes {
      getById(id: $id) {
        codes(paging: $paging) {
          total
          page
          limit
          list {
            value
            useCounter
            descriptor {
              maxUseCountsPerUser
            }
            couponUserId
            couponUser {
              id
              login
              person {
                name
                surname
                patronymic
                contacts {
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_COUPON_BY_ID = gql `
  ${ PROMOCODE_DESCRIPTOR_FRAGMENT }
  query getCouponById($id: ID!) {
    promocodes {
      getById(id: $id) {
        ...PromocodeDescriptorFragment
      }
    }
  }
`;

export const GET_USER_BY_LOGIN = gql `
  query getUserByLogin($login: String!) {
    users {
      findByLogin(login: $login) {
        id
        login
        person {
          name
          surname
          patronymic
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;
