import React, { useState } from 'react';
import './SearchUsers.scss';

import { withApollo, WithApolloClient } from 'react-apollo';
import debounce from 'lodash.debounce';
import { AutoComplete, Input } from 'antd';
import { PhoneOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';

import { SEARCH_USERS } from 'common/users/queries';
import { User } from 'common/users/types';
import { deserializeUser } from 'common/users/deserializers';
import { deserialize, fetchData } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';

type SearchProps = {
  onSelect: (user: User) => void;
};

function SearchUsers(props: WithApolloClient<SearchProps>) {
  const { t } = useTranslation();
  const { Option } = AutoComplete;
  const [userList, setUserList] = useState<User[]>([]);

  const renderOption = (item: User) => {
    return (
      <Option key={item.userId} value={''} className="search-users__option">
        <div
          onClick={() => {
            props.onSelect(item);
          }}
        >
          <div className="search-users__option-name">{`${item.name} ${item.surname}`}</div>

          <div className="search-users__option-info">
            <div className="search-users__option-item">
              <PhoneOutlined className="search-users__option-item-icon" />
              <div className="search-users__option-item-text">{item.login}</div>
            </div>

            <div className="search-users__option-item">
              <MailOutlined className="search-users__option-item-icon" />
              <div className="search-users__option-item-text">{item.email}</div>
            </div>
          </div>
        </div>
      </Option>
    );
  };

  async function handleUserSearch(value: string) {
    fetchData(
      false, // TODO: loader ?
      (state) => {},
      props.client.query({
        query: SEARCH_USERS,
        variables: {
          quickSearch: value,
        },
      }),
      setUserList,
      (res) =>
        deserialize(res, (o) =>
          o.required('data.users.getListAdvanced.list').asArrayOfObjects((o) => deserializeUser(o))
        )
    );
  }

  return (
    <AutoComplete
      className="search-users__autocomplete"
      placeholder={t('common.search_users.autocomplete_placeholder')}
      dataSource={userList.map(renderOption)}
      defaultActiveFirstOption={false}
      onSearch={debounce(handleUserSearch, 500)}
      filterOption={() => {
        return true;
      }}
    >
      <Input
        id="search"
        autoComplete="off"
        spellCheck={false}
        size="large"
        suffix={<SearchOutlined className="certain-category-icon" />}
      />
    </AutoComplete>
  );
}

export default withApollo(SearchUsers);
