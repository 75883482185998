import React, { useEffect } from 'react';
import * as L from 'leaflet';
import { pathOr } from 'ramda';
//DONT DELETE
//DONT DELETE

const MapComponent = props => {
  const placeRadius = props.placeRadius ? props.placeRadius : 80 / 164;
  const weight = props.weight ? props.weight : 0.2;
  const initialZoom = props.initialZoom ? props.initialZoom : 3;
  const minZoom = props.minZoom ? props.minZoom : 3;
  const maxZoom = props.maxZoom ? props.maxZoom : 8;
  const eventHandler = props.eventHandler ? props.eventHandler : () => {
  };
  const remoteHandler = props.remoteHandler ? props.remoteHandler : () => {
  };
  const canvasTolerance = props.canvasTolerance ? props.canvasTolerance : 5;
  const clickableFromZoom = props.clickableFromZoom ? props.clickableFromZoom : 5;

  const getColor = (status, color) => {
    switch (status) {
      case 'ACTIVE':
        return color
      case 'RESERVED':
        return '#5C5C5C'
      case 'BLOCKED':
        return '#C4C4C4'
      case 'SOLD':
        return '#C4C4C4'
      default:
        break
    }
  }

  useEffect(() => {
    let zoomKf = 1;

    const map = L.map(props.containerId, {
      keyboard: false,
      crs: L.CRS.Simple,
      renderer: L.canvas({tolerance: canvasTolerance}),
      minZoom: minZoom,
      maxZoom: maxZoom,
      zoomControl: false,
      preferCanvas: true,
      selectArea: true, // will enable it by default,
      mapMode: 'SELECT',
    });

    map.selectArea.setShiftKey(true)

    // rendering places
    const placeNumbers = [];
    const places = props.placesList.map(item => {
      const lat = pathOr(0, ['place', 'coordinates', 'y'], item);
      const lng = pathOr(0, ['place', 'coordinates', 'x'], item);
      const latLng = L.latLng([lat, lng]);

      const status = pathOr('', ['status'], item);
      const color = pathOr('#d3d3d3', ['color'], item);
      const ticketId = pathOr('', ['ticketId'], item);

      const place = L.circleMarker(latLng, {
        radius: status === 'SOLD' ? placeRadius / 0.65 : placeRadius,
        weight: 0,
        id: pathOr('', ['place', 'id'], item),
        number: pathOr('', ['place', 'number'], item),
        row: pathOr('', ['place', 'row', 'number'], item),
        sector: pathOr('', ['place', 'row', 'sector', 'title'], item),
        title: pathOr(0, ['number'], item),
        price: pathOr('', ['price'], item),
        orderId: pathOr('', ['orderId'], item),
        orderItemId: pathOr('', ['orderItemId'], item),
        orderType: pathOr('', ['orderType'], item),
        userId: pathOr('', ['user', 'id'], item),
        fillColor: getColor(status, color),
        originalColor: getColor(status, color),
        status: status,
        ticketId,
        fillOpacity: 1,
        interactive: true,
        placeObj: item,
      }).addTo(map);


      place.on('click', async function () {
        const zoomLevel = map._zoom;
        if (zoomLevel >= clickableFromZoom) {
          if (!place.options.isSelected) {
            place.setStyle({
              color: place.options.originalColor,
              fillColor: '#ffffff',
              weight: weight * zoomKf,
              isSelected: true,
            });
            eventHandler({
              type: 'placeSelect',
              data: {
                id: place.options.id,
                number: place.options.number,
                row: place.options.row,
                sector: place.options.sector,
                price: place.options.price,
                place,
              },
            });
          } else if (place.options.isSelected) {
            place.setStyle({
              fillColor: place.options.originalColor,
              weight: 0,
              isSelected: false,
            });
            eventHandler({
              type: 'placeRemove',
              data: {
                id: place.options.id,
              },
            });
          }
        }
      });
      return place;
    });
    eventHandler({
      type: 'mapInit',
      data: {
        map,
        places,
      },
    });
    ///
    //add overlay
    const bounds = [[0, 0], [props.overlaySize.height, props.overlaySize.width]];
    L.imageOverlay(props.overlayUrl, bounds).addTo(map);
    map.setMaxBounds(bounds);
    //add zoom
    L.control
    .zoom({
      position: 'topright',
    })
    .addTo(map);
    //setting map drag
    map.dragging.enable();
    //set viev
    map.setView(new L.LatLng(props.overlaySize.height / 2, props.overlaySize.width / 2), initialZoom);
    //
    const restandNumbers = () => {
      dropNumbers();
      const zoomClass = map.getZoom() === 8 ? 'zoom-1' : 'zoom-2';
      places.forEach(item => {
        if (map.getBounds().contains(item.getLatLng())) {
          const divIcon = L.divIcon({
            html: `<div class="${zoomClass}">${pathOr('0', ['options', 'number'], item)}</div>`,
          });
          placeNumbers.push(
            L.marker(item.getLatLng(), {
              icon: divIcon,
            }).addTo(map)
          );
        }
      });
    };
    const dropNumbers = () => {
      placeNumbers.forEach(item => item.remove());
      placeNumbers.length = 0;
    };
    map.on('areaselected', e => {
      const selectedPlaces = places
      .filter(item => e.bounds.contains(item.getLatLng()))
      .map(item => {
          item.setStyle({
            color: item.options.originalColor,
            fillColor: '#ffffff',
            weight: weight * zoomKf,
            isSelected: true,
          })
          return {
            id: item.options.id,
            number: item.options.number,
            row: item.options.row,
            sector: item.options.sector,
            price: item.options.price,
            place: item,
          }
        }
      );
      eventHandler({
        type: 'placeArraySelect',
        data: selectedPlaces,
      });
    });
    map.on('moveend', () => {
      if (map.getZoom() > 6) {
        restandNumbers();
      }
      eventHandler({
        type: 'moveEnd',
        data: {
          zoom: map.getZoom(),
          position: map.getCenter(),
        },
      });
    });
    map.on('zoom', e => {
      const handler = (k, item) => {
        item.options.status === 'SOLD' ? item.setRadius(placeRadius * 0.65 * k) : item.setRadius(placeRadius * k);
        if (item.options.isSelected) {
          item.setStyle({
            ...item.options,
            weight: weight * k,
          });
        }
      };
      eventHandler({
        type: 'moveEnd',
        data: {
          zoom: map.getZoom(),
          position: map.getCenter(),
        },
      });
      const zoom = e.target._zoom;
      switch (zoom) {
        case 3:
          places.map(item => {
            zoomKf = 3;
            handler(zoomKf, item);
            return true;
          });
          dropNumbers();
          break;
        case 4:
          places.map(item => {
            zoomKf = 4;
            handler(zoomKf, item);
            return true;
          });
          dropNumbers();
          break;
        case 5:
          places.map(item => {
            zoomKf = 8;
            handler(zoomKf, item);
            return true;
          });
          dropNumbers();
          break;
        case 6:
          places.map(item => {
            zoomKf = 17;
            handler(zoomKf, item);
            return true;
          });
          dropNumbers();
          break;
        case 7:
          places.map(item => {
            zoomKf = 30;
            handler(zoomKf, item);
            return true;
          });
          restandNumbers();
          break;
        case 8:
          places.map(item => {
            zoomKf = 55;
            handler(zoomKf, item);
            return true;
          });
          restandNumbers();
          break;
        default:
          zoomKf = 1;
          places.map(item => handler(zoomKf, item));
          dropNumbers();
      }
      return true;
    });
    remoteHandler(map, places);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={props.containerId}></div>;
};

export default MapComponent;
