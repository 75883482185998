import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import prop from 'ramda/es/prop';
import { pathOr } from 'ramda';
import { compose, withHandlers } from 'recompose';
import { getCodes } from '../../../../PromoModals/PromoCodesActivationsModal/events/queryEvents';

import OptionMenu from '../components/OptionMenu';
import { t } from 'i18next';

export default compose(
  withHandlers({}),
  withHandlers({
    handleUpdatePromo: (props) => (id) => {
      const { setEditingMode, setIsAddVisible, setDefaultPromo } = props;
      setEditingMode(true);
      setIsAddVisible(true);
      const item = props.promoList.find((item) => item.id === id);
      setDefaultPromo(item);
    },
  }),
  withHandlers({
    getColumns: (props) => () => [
      {
        title: t('modules.with_handlers_promo_body.title.name'),
        dataIndex: 'title',
        key: 'title',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: <h3>{value.title}</h3>,
              props: {
                colSpan: 6,
              },
            };
          }
          return value;
        },
      },
      {
        title: t('modules.with_handlers_promo_body.title.code'),
        dataIndex: 'code',
        key: 'code',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: null,
              props: {
                colSpan: 0,
              },
            };
          }
          return <span className="promocode__value">{value}</span>;
        },
      },
      {
        title: t('modules.with_handlers_promo_body.title.discount'),
        dataIndex: 'sale',
        key: 'sale',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: null,
              props: {
                colSpan: 0,
              },
            };
          }
          return <span className="promocode__sale">{value}</span>;
        },
      },
      {
        title: t('modules.with_handlers_promo_body.title.period'),
        dataIndex: 'period',
        key: 'period',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: null,
              props: {
                colSpan: 0,
              },
            };
          }
          if (Array.isArray(value)) {
            return (
              <span className="promocode__date df fdc">
                <span>{value[0]}</span>
                <span>{value[1]}</span>
              </span>
            );
          }
          return value;
        },
      },
      {
        title: t('modules.with_handlers_promo_body.title.activations'),
        dataIndex: 'activations',
        key: 'activations',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: null,
              props: {
                colSpan: 0,
              },
            };
          }
          const moreThenOneCode = pathOr(false, ['initial', 'codes', 'list', 'length'], row) > 1;
          const activations = pathOr('', ['activations'], row);
          return moreThenOneCode ? (
            <Button
              id="MoreThenOneCodeInDetailButton"
              className="promocode__activations-info"
              onClick={() => {
                const { client, setCodesToReducer } = props;
                const selectedPromoId = row.id;
                props.openActivationsCodesModal(true);
                getCodes({ client, setCodesToReducer, selectedPromoId });
              }}
            >
              {t('modules.with_handlers_promo_body.button.detail')}
            </Button>
          ) : (
            <p>{activations}</p>
          );
        },
      },
      {
        title: t('modules.with_handlers_promo_body.title.group'),
        dataIndex: 'group',
        key: 'group',
        render: (value, row, index) => {
          if (prop('isGroupTitle', value)) {
            return {
              children: null,
              props: {
                colSpan: 0,
              },
            };
          }
          return value;
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (value, row, index) => {
          return (
            <Dropdown overlay={<OptionMenu id={row.id} {...props} />}>
              <Button id="DropdownActionsButton" size="small" type="default">
                {prop('isGroupTitle', value)
                  ? t('modules.with_handlers_promo_body.button.actions_group')
                  : t('modules.with_handlers_promo_body.button.actions')}{' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ],
  })
);
