import React, {useState} from 'react';
import {Accreditation, AdditionalFields, Events, RemoteFile} from '../../../model/accreditationModel';
import {Button, Col, Divider, Modal, Row} from 'antd';
import paperclip from '../../../img/paperclip.svg';
import styles from './Card.module.scss';
import AccreditationHeader from '../AccreditationHeader/AccreditationHeader';
import {LocalizedString} from 'utils/LocalizedString';
import {contactType, date, requestTypeToString, statusToString} from '../../../../../../utils/helpers';
import EventSelector from '../EventSelector/EventSelector';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

function Card(props: {
  accreditation: Accreditation | undefined;
  additionalFields: AdditionalFields[] | undefined;
  currentEvent: Events;
  isEdit: boolean;
  setIsEdit: any;
  setVisible: any;
  client: any;
  remoteFile?: RemoteFile;
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const name = props.accreditation?.accreditedUser.user?.person.name;
  const surname = props.accreditation?.accreditedUser.user?.person.surname;
  const patronymic = props.accreditation?.accreditedUser.user?.person.patronymic || '';
  const publicLink = props.accreditation?.accreditedUser.user?.person.photo?.publicLink;
  const accessCategory = props.accreditation?.accessCategory.title.toString();
  const requestType = props.accreditation?.requestType;
  const contacts = props.accreditation?.accreditedUser.user?.person.contacts;

  const additionalFields = (additionalFields: AdditionalFields | undefined, i: number) => {
    switch (additionalFields?.field.type) {
      case 'FILE':
        return (
          <Row className={styles.item} key={i}>
            <Col className={styles.name} span={5}>
              {additionalFields.field.title.toString()}
            </Col>
            <img className={styles.paperclip} src={paperclip} alt="" />
            <Col span={19}>
              {props.remoteFile?.publicLink ? (
                <a href={props.remoteFile.publicLink} target="_blank" rel="noopener noreferrer">
                  {props.remoteFile.fileName}
                </a>
              ) : (
                <p>{t('modules.card.not_found')}</p>
              )}
            </Col>
          </Row>
        );
      case 'TEXT':
        return <></>;
      case 'LOCALIZED_TEXT':
        const name: String = additionalFields.field.title.toString(i18next.language);
        const value = LocalizedString.fromObject(JSON.parse(additionalFields.value));
        return (
          <Row className={styles.item} key={i}>
            <Col className={styles.name} span={5}>
              {name}
            </Col>
            <Col span={9}>{value.toString()}</Col>
            <Col span={9}><span className={styles.value_en}>{value.toString('en')}</span></Col>
          </Row>
        );
    }
  };
  return (
    <>
      <AccreditationHeader
        isEdit={props.isEdit}
        setIsEdit={props.setIsEdit}
        setVisible={props.setVisible}
        title={t('modules.card.accreditation_header')}
      >
        <Button
          id="CardEditButton"
          className={styles.btnEdit}
          type={'ghost'}
          onClick={() => {
            props.setIsEdit(true);
          }}
        >
          {t('modules.card.button')}
        </Button>
      </AccreditationHeader>
      <div className={styles.card}>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.id')}
          </Col>
          <Col span={19}>{props.accreditation?.visibleId}</Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.date')}
          </Col>
          <Col span={19}>{date(props.accreditation?.createdAt)}</Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.status')}
          </Col>
          <Col span={19}>{statusToString(props.accreditation?.requestStatus)}</Col>
        </Row>
        <Divider orientation="left" orientationMargin={0}>
          <Row className={styles.item}>
            <Col className={styles.title} span={24}>
              {t('modules.card.field.personal')}
            </Col>
          </Row>
        </Divider>
        <Row className={styles.item + ' ' + styles.item_photoContainer}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.photo')}
          </Col>
          <Col>
            {' '}
            <img className={styles.photo} src={publicLink} alt="" onClick={() => setVisible(true)}/>
            <Modal
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              bodyStyle={{padding: 0}}
              closable={true}
              footer={null}
              centered={true}
            >
              <img className={styles.photo_modal} src={publicLink} alt=""/>
            </Modal>
          </Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.name')}
          </Col>
          <Col span={9}>{name?.toString()}</Col>
          <Col span={9}><span className={styles.value_en}>{name?.toString('en')}</span></Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.surname')}
          </Col>
          <Col span={9}>{surname?.toString()}</Col>
          <Col span={9}><span className={styles.value_en}>{surname?.toString('en')}</span></Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.patronymic')}
          </Col>
          <Col span={9}>{patronymic?.toString()}</Col>
          <Col span={9}><span className={styles.value_en}>{patronymic?.toString('en')}</span></Col>
        </Row>

        <Divider orientation="left" orientationMargin={0}>
          <Row className={styles.item}>
            <Col className={styles.title} span={24}>
              {t('modules.card.field.business')}
            </Col>
          </Row>
        </Divider>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.access')}
          </Col>
          <Col span={19}>{accessCategory}</Col>
        </Row>
        {props.additionalFields?.map((field, i) => additionalFields(field, i))}
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.accreditation')}
          </Col>
          <Col span={19}>{requestTypeToString(requestType)}</Col>
        </Row>
        {requestType && <EventSelector requestType={requestType} currentEvent={props.currentEvent}/>}
        <Divider orientation="left" orientationMargin={0}>
          <Row className={styles.item}>
            <Col className={styles.title} span={24}>
              {t('modules.card.field.contacts')}
            </Col>
          </Row>
        </Divider>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.phone')}
          </Col>
          <Col span={19}>{contactType(contacts, 'PHONE')}</Col>
        </Row>
        <Row className={styles.item}>
          <Col className={styles.name} span={5}>
            {t('modules.card.field.email')}
          </Col>
          <Col span={19}>{contactType(contacts, 'EMAIL')}</Col>
        </Row>
      </div>
    </>
  );
}

export default Card;
