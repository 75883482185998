import { t } from 'i18next';

export type Category = {
  id: string,
  placesIds: string[],
  title: string,
  color?: string,
}

const isEmptyTitle = (category: Category) => {
  return category.title.trim().length === 0
}

const isEmptyPlaces = (category: Category) => {
  return category.placesIds.length === 0
}

export const generateValidationErrors = (editedCategories: Category[], newCategories: Category[], newTemplateTitle: string | undefined, isAddNewTemplate: boolean) => {
  const errors: Record<string, string> = {};

  [...editedCategories, ...newCategories].forEach(category => {
    if (isEmptyTitle(category)) {
      errors[category.id] = t('modules.markup_side_menu.hints.enter_name');
    }
    if (isEmptyPlaces(category)) {
      if (!!errors[category.id]) {
        errors[category.id] = errors[category.id] + `, ${t('modules.markup_side_menu.hints.set_places')}`;
      } else {
        errors[category.id] = t('modules.markup_side_menu.hints.set_places');
      }
    }
  });

  const editingCondition = newTemplateTitle !== undefined && !newTemplateTitle?.trim().length;
  const newTemplateCondition = !newTemplateTitle || !newTemplateTitle?.trim().length;

  if (isAddNewTemplate ? newTemplateCondition : editingCondition) {
    errors['noTemplateTitle'] = t('modules.markup_side_menu.hints.enter_name');
  }

  return errors;
}
