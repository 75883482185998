import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Checkbox, DatePicker, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { reformattingDate } from 'utils/helpers';
import dayjs from 'dayjs';

const FilterModal = (props) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [stateProps, setState] = useState({
    priceDropdownVisible: false,
    dateDropdownVisible: false,
    priceDropdownMode: t('modules.filter_modal.use_state'),
    dateDropdownMode: t('modules.filter_modal.use_state'),
    priceFirstInputValue: '',
    priceSecondInputValue: '',
    dateValue: '',
    rangeValue: [],
    checkSold: false,
    checkReserved: false,
    checkCancelled: false,
    checkVisited: false,
    checkNotVisited: false,
  });

  const regexp = /^[0-9\b]+$/;

  return (
    <Modal
      open={props.isfilterModalVisible}
      centered
      title={t('modules.filter_modal.modal')}
      onCancel={() => {
        props.onCancel();
      }}
      width={915}
      footer={
        <Button
          id="FilterModalApplyButton"
          type="primary"
          className="sales__tickets__filter-modal__accept-button"
          onClick={() => {
            const priceFrom = (() => {
              switch (stateProps.priceDropdownMode) {
                case t('modules.filter_modal.case.equal'):
                  return stateProps.priceFirstInputValue;
                case t('modules.filter_modal.case.to'):
                  return null;
                case t('modules.filter_modal.case.from'):
                  return stateProps.priceFirstInputValue;
                case t('modules.filter_modal.case.range'):
                  return stateProps.priceFirstInputValue;
                default:
                  return '';
              }
            })();

            const priceTo = (() => {
              switch (stateProps.priceDropdownMode) {
                case t('modules.filter_modal.case.equal'):
                  return stateProps.priceFirstInputValue;
                case t('modules.filter_modal.case.to'):
                  return stateProps.priceFirstInputValue;
                case t('modules.filter_modal.case.from'):
                  return null;
                case t('modules.filter_modal.case.range'):
                  return stateProps.priceSecondInputValue;
                default:
                  return '';
              }
            })();

            const dateFrom = (() => {
              switch (stateProps.dateDropdownMode) {
                case t('modules.filter_modal.case.equal'):
                  return stateProps.dateValue;
                case t('modules.filter_modal.case.to'):
                  return null;
                case t('modules.filter_modal.case.from'):
                  return stateProps.dateValue;
                case t('modules.filter_modal.case.range'):
                  return stateProps.rangeValue[0];
                default:
                  return '';
              }
            })();
            const dateUntill = (() => {
              switch (stateProps.dateDropdownMode) {
                case t('modules.filter_modal.case.equal'):
                  return reformattingDate(new Date(stateProps.dateValue), true);
                case t('modules.filter_modal.case.to'):
                  return stateProps.dateValue;
                case t('modules.filter_modal.case.from'):
                  return null;
                case t('modules.filter_modal.case.range'):
                  return stateProps.rangeValue[1];
                default:
                  return '';
              }
            })();
            let filterStatus = [];

            if (stateProps.checkSold) {
              filterStatus = [...filterStatus, 'SOLD'];
            }
            if (stateProps.checkReserved) {
              filterStatus = [...filterStatus, 'RESERVED'];
            }
            if (stateProps.checkCancelled) {
              filterStatus = [...filterStatus, 'CANCELED'];
            }
            let passedToEvent;
            if (stateProps.checkVisited && stateProps.checkNotVisited) {
              passedToEvent = null;
            } else if (stateProps.checkVisited) {
              passedToEvent = true;
            } else if (stateProps.checkNotVisited) {
              passedToEvent = false;
            } else if (!stateProps.checkVisited && !stateProps.checkNotVisited) {
              passedToEvent = null;
            }
            props.onFilterModalApply({
              status: isEmpty(filterStatus) ? null : filterStatus,
              passedToEvent,
              period: {
                start: dateFrom ? dateFrom : null,
                end: dateUntill ? dateUntill : null,
              },
              priceRange: {
                start: priceFrom ? priceFrom + '.00' : null,
                end: priceTo ? priceTo + '.00' : null,
              },
            });
          }}
        >
          {t('modules.filter_modal.button')}
        </Button>
      }
      className="sales__tickets__filter-modal"
    >
      <div className="sales__tickets__filter-modal__container">
        <div className="sales__tickets__filter-modal__column">
          <div className="sales__tickets__filter-modal__column__title">{t('modules.filter_modal.div.status')}</div>
          <div className="sales__tickets__filter-modal__column__checkbox-item">
            <Checkbox
              checked={stateProps.checkSold}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  checkSold: ev.target.checked,
                });
              }}
            />{' '}
            <span
              onClick={() => {
                setState({
                  ...stateProps,
                  checkSold: !stateProps.checkSold,
                });
              }}
              className="sales__tickets__filter-modal__column__checkbox-text"
            >
              {t('modules.filter_modal.span.sold')}
            </span>
          </div>
          <div className="sales__tickets__filter-modal__column__checkbox-item">
            <Checkbox
              checked={stateProps.checkReserved}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  checkReserved: ev.target.checked,
                });
              }}
            />{' '}
            <span
              onClick={() => {
                setState({
                  ...stateProps,
                  checkReserved: !stateProps.checkReserved,
                });
              }}
              className="sales__tickets__filter-modal__column__checkbox-text"
            >
              {t('modules.filter_modal.span.booked')}
            </span>
          </div>
          <div className="sales__tickets__filter-modal__column__checkbox-item">
            <Checkbox
              checked={stateProps.checkCancelled}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  checkCancelled: ev.target.checked,
                });
              }}
            />{' '}
            <span
              onClick={() => {
                setState({
                  ...stateProps,
                  checkCancelled: !stateProps.checkCancelled,
                });
              }}
              className="sales__tickets__filter-modal__column__checkbox-text"
            >
              {t('modules.filter_modal.span.canceled')}
            </span>
          </div>
        </div>
        <div className="sales__tickets__filter-modal__column">
          <div className="sales__tickets__filter-modal__column__title">{t('modules.filter_modal.div.visiting')}</div>
          <div className="sales__tickets__filter-modal__column__checkbox-item">
            <Checkbox
              checked={stateProps.checkVisited}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  checkVisited: ev.target.checked,
                });
              }}
            />{' '}
            <span
              onClick={() => {
                setState({
                  ...stateProps,
                  checkVisited: !stateProps.checkVisited,
                });
              }}
              className="sales__tickets__filter-modal__column__checkbox-text"
            >
              {t('modules.filter_modal.span.user_visited')}
            </span>
          </div>
          <div className="sales__tickets__filter-modal__column__checkbox-item">
            <Checkbox
              checked={stateProps.checkNotVisited}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  checkNotVisited: ev.target.checked,
                });
              }}
            />{' '}
            <span
              onClick={() => {
                setState({
                  ...stateProps,
                  checkNotVisited: !stateProps.checkNotVisited,
                });
              }}
              className="sales__tickets__filter-modal__column__checkbox-text"
            >
              {t('modules.filter_modal.span.user_was_absent')}
            </span>
          </div>
        </div>
        <div className="sales__tickets__filter-modal__column">
          <div className="sales__tickets__filter-modal__column__title">{t('modules.filter_modal.div.price')}</div>
          <div className="sales__tickets__filter-modal__column__price-item">
            <Dropdown
              className="sales__tickets__filter-modal__dropdown"
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        priceDropdownMode: t('modules.filter_modal.menu_item.props.from'),
                        priceDropdownVisible: false,
                        priceFirstInputValue: '',
                        priceSecondInputValue: '',
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.from')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        priceDropdownMode: t('modules.filter_modal.menu_item.props.to'),
                        priceDropdownVisible: false,
                        priceFirstInputValue: '',
                        priceSecondInputValue: '',
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.to')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        priceDropdownMode: t('modules.filter_modal.menu_item.props.range'),
                        priceDropdownVisible: false,
                        priceFirstInputValue: '',
                        priceSecondInputValue: '',
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.range')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        priceDropdownMode: t('modules.filter_modal.menu_item.props.equal'),
                        priceDropdownVisible: false,
                        priceFirstInputValue: '',
                        priceSecondInputValue: '',
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.equal')}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              open={stateProps.priceDropdownVisible}
              onClick={() => {
                setState({
                  ...stateProps,
                  priceDropdownVisible: true,
                });
              }}
              onVisibleChange={(ev) => {
                setState({
                  ...stateProps,
                  priceDropdownVisible: ev,
                });
              }}
            >
              <div>
                <div className="sales__tickets__filter-modal__dropdown__item">{stateProps.priceDropdownMode}</div>
                <DownOutlined className="sales__tickets__filter-modal__dropdown__icon" />
              </div>
            </Dropdown>
            <input
              placeholder="0"
              className="sales__tickets__filter-modal__price__first-input"
              onChange={(ev) => {
                const value = ev.target.value;
                if (value === '' || regexp.test(value)) {
                  setState({
                    ...stateProps,
                    priceFirstInputValue: value,
                  });
                }
              }}
              value={stateProps.priceFirstInputValue}
            />
            {stateProps.priceDropdownMode === t('modules.filter_modal.price_dropdown_mode') ? (
              <>
                <div className="sales__tickets__filter-modal__price__divider">-</div>
                <input
                  placeholder="0"
                  className="sales__tickets__filter-modal__price__second-input"
                  value={stateProps.priceSecondInputValue}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    if (value === '' || regexp.test(value)) {
                      setState({
                        ...stateProps,
                        priceSecondInputValue: value,
                      });
                    }
                  }}
                />
              </>
            ) : (
              ''
            )}
            <div className="sales__tickets__filter-modal__price__currency">{t('currency')}</div>
          </div>
        </div>
        <div className="sales__tickets__filter-modal__column">
          <div className="sales__tickets__filter-modal__column__title">{t('modules.filter_modal.div.date')}</div>
          <div className="sales__tickets__filter-modal__column__date-item">
            <Dropdown
              className="sales__tickets__filter-modal__dropdown"
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        dateDropdownMode: t('modules.filter_modal.menu_item.props.from'),
                        dateDropdownVisible: false,
                        dateValue: '',
                        rangeValue: [],
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.from')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        dateDropdownMode: t('modules.filter_modal.menu_item.props.to'),
                        dateDropdownVisible: false,
                        dateValue: '',
                        rangeValue: [],
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.to')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        dateDropdownMode: t('modules.filter_modal.menu_item.props.range'),
                        dateDropdownVisible: false,
                        dateValue: '',
                        rangeValue: [],
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.range')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setState({
                        ...stateProps,
                        dateDropdownMode: t('modules.filter_modal.menu_item.props.equal'),
                        dateDropdownVisible: false,
                        dateValue: '',
                        rangeValue: [],
                      });
                    }}
                  >
                    {t('modules.filter_modal.menu_item.text.equal')}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              visible={stateProps.dateDropdownVisible}
              onClick={() => {
                setState({
                  ...stateProps,
                  dateDropdownVisible: true,
                });
              }}
              onVisibleChange={(ev) => {
                setState({
                  ...stateProps,
                  dateDropdownVisible: ev,
                });
              }}
            >
              <div>
                <div className="sales__tickets__filter-modal__dropdown__item">{stateProps.dateDropdownMode}</div>
                <DownOutlined className="sales__tickets__filter-modal__dropdown__icon" />
              </div>
            </Dropdown>
            <div>
              {stateProps.dateDropdownMode === t('modules.filter_modal.price_dropdown_mode') ? (
                <RangePicker
                  onChange={(ev) => {
                    if (ev[0] && ev[1]) {
                      const from = new Date(ev[0]);
                      const till = new Date(ev[1]);
                      setState({
                        ...stateProps,
                        rangeValue: [reformattingDate(from), reformattingDate(till, true)],
                      });
                    } else {
                      setState({
                        ...stateProps,
                        rangeValue: [],
                      });
                    }
                  }}
                  dropdownClassName="sales__tickets__dropdown_date_picker"
                  className="sales__tickets__filter-modal__date-picker"
                />
              ) : (
                <DatePicker
                  value={stateProps.dateValue.length === 0 ? null : dayjs(new Date(stateProps.dateValue))}
                  onChange={(ev) => {
                    const date = new Date(ev);
                    if (stateProps.dateDropdownMode === t('modules.filter_modal.case.to')) {
                      setState({
                        ...stateProps,
                        dateValue: reformattingDate(date, true),
                      });
                    } else {
                      setState({
                        ...stateProps,
                        dateValue: reformattingDate(date),
                      });
                    }
                  }}
                  dropdownClassName="sales__tickets__dropdown_date_picker"
                  className="sales__tickets__filter-modal__date-picker"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FilterModal;
