import { pathOr } from 'ramda';
import { LocalizedString } from 'utils/LocalizedString';
import i18next from 'i18next';

// Utils for preprocessing data from map places objects

export function parsePlacesToOrderData(places: any[]) {
  let orders: any[] = [];
  places.forEach(place => {
    const orderId = pathOr('', ['place', 'options', 'orderId'], place);
    const orderItemId = pathOr('', ['place', 'options', 'orderItemId'], place);
    const alreadyExistedOrderData = orders.find(order => order.id === orderId);
    const orderItems = alreadyExistedOrderData
      ? [...alreadyExistedOrderData.itemIds, orderItemId]
      : [orderItemId];
    const orderData = {
      id: orderId,
      itemIds: orderItems
    };
    orders = orders.filter(order => order.id !== orderId);
    orders.push(orderData);
  });
  return orders;
}

export function parseUser(person: Object, langCode: string = 'ru') {
  const contacts = pathOr([], ['person', 'contacts'], person);
  const email = contacts.find((contact) => {
    const contactType = pathOr(null, ['type'], contact);
    return contactType === 'EMAIL';
  });
  const phone = contacts.find((contact) => {
    const contactType = pathOr(null, ['type'], contact);
    return contactType === 'PHONE';
  });
  const name = LocalizedString.fromObject(pathOr('', ['person', 'name'], person)).toString(langCode);
  const surname = LocalizedString.fromObject(pathOr('', ['person', 'surname'], person)).toString(langCode);
  const patronymic = LocalizedString.fromObject(pathOr('', ['person', 'patronymic'], person)).toString(langCode);
  return {
    value: `${name} ${surname}`,
    text: `${name} ${surname}`,
    userId: pathOr('', ['id'], person),
    login: pathOr('', ['login'], person),
    email: pathOr('', ['value'], email),
    phone: pathOr('', ['value'], phone),
    userName: `${surname} ${name} ${patronymic}`,
    name,
    surname,
    patronymic
  };
}

export function getUserFromPlaceObj(place: Object) {
  return pathOr({}, ['place', 'options', 'placeObj', 'user'], place);
}

export async function handleRefund(places: any[], refundQuery: (id: string, idsArr: string[]) => Promise<void>) {
  const orders = parsePlacesToOrderData(places);
  const refundItems = async (arr: any[]) => {
    for (const order of orders) {
      await refundQuery(order.id, order.itemIds);
    }
  };
  await refundItems(orders);
}

export async function handleDeReserving(places: any[], deReservingQuery: (id: string, idsArr: string[]) => Promise<void>) {
  const orders = parsePlacesToOrderData(places);
  const deReserving = async (orders: { id: string, itemIds: string[] }[]) => {
    for (const order of orders) {
      await deReservingQuery(order.id, order.itemIds);
    }
  };

  await deReserving(orders);
}

export async function handlePrinting(places: any[], printQuery: (ticketIds: string | string[]) => Promise<void>) {
  const ticketIds = places.map((place) => pathOr('', ['place', 'options', 'ticketId'], place));
  await printQuery(ticketIds);
}

export async function handleTextSending(places: any[], sendingQuery: (orderId: string, contact: string) => Promise<void>, contactType: 'phone' | 'email') {
  const orders = parsePlacesToOrderData(places)
    .map(order => {
      const place = places.find(place => pathOr('', ['place', 'options', 'orderId'], place) === order.id);
      const user = getUserFromPlaceObj(place);
      const contact = parseUser(user, i18next.language)[contactType];
      return {
        id: order.id,
        contact
      };
    });

  const textSending = async (orders: { id: string, contact: string }[]) => {
    for (const order of orders) {
      await sendingQuery(order.id, order.contact);
    }
  };

  await textSending(orders);
}
