import React from 'react';
import styles from '../DiscountsFormModal/DiscountsFormModal.module.scss';
import { Button } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function DiscountsModalTitle(props: { isSubmitLoading: boolean; onModalClose: () => void; modalTitle: string }) {
  const { t } = useTranslation();
  return (
    <div className={styles.modalTitleBlock}>
      <PlusOutlined id="modalCloseButton" className={styles.modalCloseIcon} onClick={() => props.onModalClose()} />
      <h2 className={styles.modalTitle}>{props.modalTitle}</h2>
      <Button
        id="DiscountsModalTitleSaveButton"
        type="primary"
        size="large"
        className={styles.modalSaveButton}
        htmlType="submit"
        disabled={props.isSubmitLoading}
      >
        {props.isSubmitLoading ? <LoadingOutlined /> : t('modules.discounts_modal_title')}
      </Button>
    </div>
  );
}

export default DiscountsModalTitle;
