import React, {useEffect, useState} from 'react';
import styles from './ImageUpload.module.scss';
import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';
import avatar_placeholder from '../../../../../assets/avatar_placeholder.svg';
import {useTranslation} from 'react-i18next';

export default function ImageUpload(props: { onChange?: (fileId?: string) => void; value?: string }) {
  const [avatar, setAvatar] = useState(avatar_placeholder);
  const { t } = useTranslation();
  useEffect(() => {
    let linkServer = window.parametrize('REACT_APP_GRAPHQL_ENDPOINT');

    if (linkServer[linkServer.length - 1] === '/') {
      linkServer = linkServer.slice(0, -1);
    }

    setAvatar(props.value ? `${linkServer}/files/${props.value}` : avatar_placeholder);
  }, [props.value]);

  return (
    <div className={styles.upload}>
      <img src={avatar} alt={t('modules.image_upload.img')!}/>
      <div className={styles.upload__loader}>
        <SingleFileUpload
          title={t('modules.image_upload.single_file_upload')}
          onChange={props.onChange}
          initialValue={props.value}
          accept={['.jpg', '.png', '.jpeg']}
          maxFileSize={15}
          imageUpload
        />
        100KB - 15MB; <br/> 100x100 - 5000x5000.
      </div>
    </div>
  );
}
