import { withFormik } from 'formik';
import pathOr from 'ramda/es/pathOr';

export default withFormik({
  mapPropsToValues: (props) => {
    const initial = pathOr(false, ['defaultPromo', 'initial'], props);
    return props.formatPromoData(initial);
  },
  handleSubmit: (values, { props }) => {
    props.handleAddPromocode(values);
  },
  enableReinitialize: true,
});
