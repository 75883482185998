import {
  SET_CODES_TO_REDUCER,
  OPEN_CODES_MODAL,
  OPEN_ACTIVATIONS_CODES_MODAL
} from '../../types';

export const setCodesToReducer = (codesData: object) => ({
  type: SET_CODES_TO_REDUCER,
  codesData
});

export const openCodesModal = (isOpened: boolean) => ({
  type: OPEN_CODES_MODAL,
  isOpened
});

export const openActivationsCodesModal = (isOpened: boolean) => ({
  type: OPEN_ACTIVATIONS_CODES_MODAL,
  isOpened
});
