import React, { createRef, useState } from 'react';
import './AccessZones.scss';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import Loader from '../../../../../common/Loader/Loader';
import { GET_VENUES_LIST, GET_ZONES_LIST } from '../../graphql/query';
import { CREATE_ACCESS_ZONE, DELETE_ACCESS_ZONE, UPDATE_ACCESS_ZONE } from '../../graphql/mutation';
import { MESSAGE_TIME_DURATION } from '../../../../../constants.js';
import { deserialize, fetchData, sendData, useDidMount } from 'utils/apiHelpers';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Icon } from '@ant-design/compatible';
import { Button, message } from 'antd';
import ModalCloseWindowWarning from '../ModalCloseWindowWarning/ModalCloseWindowWarning';
import AccessMenu from '../AccessMenu/AccessMenu';
import AccessZoneForm from '../AccessZoneForm/AccessZoneForm';
import EmptyPlaceholder from '../../../../../common/EmptyPlaceholder/EmptyPlaceholder';
import { accessVenueDeserializer, accessZoneDeserializer } from '../../graphql/accessDeserializers';
import {
  CreateZoneData,
  FormType,
  IdAndTitleDescriptor,
  UpdateZoneData,
  ZonesDataDescriptor
} from '../../graphql/accessZoneModel';
import { useTranslation } from 'react-i18next';

// const { TabPane } = Tabs;

function AccessZones(props: { client: ApolloClient<any> }) {
  const { t } = useTranslation();
  const [data, setData] = useState<ZonesDataDescriptor>({});
  const [venues, setVenues] = useState<IdAndTitleDescriptor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>('empty');
  const [zoneId, setZoneId] = useState<string>('');
  const [modalCloseWindowWarningData, setModalCloseWindowWarningData] = useState<{ id: string; visible: boolean }>({
    id: '',
    visible: false,
  });
  const [isChangedInput, setIsChangedInput] = useState<boolean>(false);
  const formRef = createRef<FormComponentProps>();

  const [isCheckZone, setIsCheckZone] = useState(false);
  //Render data by zones on page load
  useDidMount(() => {
    refreshData();
    getVenuesList();
  });

  //Styling for the Stadium Tab
  // const tabBarStyle = {
  //   borderBottom: 'none',
  //   marginTop: '40px',
  //   marginBottom: 0,
  //   marginLeft: '24px',
  // };

  //When switching the submenu, we change the data on the right side
  function handleDataChange(id: string) {
    setZoneId(id);
    setFormType('update');
    setIsChangedInput(false);
  }

  //Open the add form
  const handleClick = () => {
    setFormType('create zone');
  };

  //Delete handler
  function handleDelete() {
    deleteZone(zoneId);
    setFormType('empty');
    setIsChangedInput(false);
  }

  //Submission handler
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    formRef.current!.form.validateFieldsAndScroll((err, values) => {
      switch (formType) {
        case 'update':
          updateZoneData({
            id: zoneId,
            data: {
              name: values.title,
              title: values.title,
              description: values.description || '',
              controlPresence: isCheckZone,
              venueId: venues[0].id,
            },
          });
          break;

        case 'create zone':
          createZone({
            name: values.title,
            title: values.title,
            description: values.description || '',
            controlPresence: isCheckZone,
            venueId: venues[0].id,
          });
          break;
      }
    });
  };

  //Getting data from the server
  function refreshData() {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ZONES_LIST,
        fetchPolicy: 'no-cache',
      }),

      setData,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.acs.accessZone.getList').asArrayOfObjects((o) => accessZoneDeserializer(o))
        ).reduce(
          (zones, zone) => ({
            ...zones,
            [zone.id]: { title: zone.title, description: zone.description, controlPresence: zone.controlPresence },
          }),
          {}
        )
    );
  }

  //Getting the venue list from the server
  function getVenuesList() {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_VENUES_LIST,
        fetchPolicy: 'no-cache',
      }),

      setVenues,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.venueLocalised.getList.list').asArrayOfObjects((o) => accessVenueDeserializer(o))
        )
    );
  }

  //Editing Zone Data
  async function updateZoneData(zoneData: UpdateZoneData) {
    await sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: UPDATE_ACCESS_ZONE,
        variables: { id: zoneData.id, data: zoneData.data },
        fetchPolicy: 'no-cache',
      }),
      () => {
        message.success(t('modules.access_zones.function_zone.message_success'), MESSAGE_TIME_DURATION);
        refreshData();
      },
      () => message.error(t('modules.access_zones.function_zone.message_error'))
    );

    // setIsVisibleModalSave(true);
  }

  //Deleting a Zone
  function deleteZone(id: string) {
    sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: DELETE_ACCESS_ZONE,
        variables: { id },
        fetchPolicy: 'no-cache',
      }),

      () => {
        refreshData();
      }
    );
  }

  //Adding a Zone
  async function createZone(data: CreateZoneData) {
    await sendData(
      loading,
      setLoading,

      props.client.mutate({
        mutation: CREATE_ACCESS_ZONE,
        variables: { data },
        fetchPolicy: 'no-cache',
      }),
      () => {
        message.success(t('modules.access_zones.function_zone.message_success'), MESSAGE_TIME_DURATION);
      },
      () => {
        console.log(t('modules.access_zones.function_zone.consolelog'));
      },
      () => {},
      (res) => {
        refreshData();
        setZoneId(deserialize(res, (d) => d.required('data.acs.accessZone.create.id').asString));
      }
    );
    await setIsChangedInput(false);
    setFormType('update');

    // setIsVisibleModalSave(true);
  }

  return (
    <section className="AccessZones">
      <header className="AccessZones__header">
        <h2 className="AccessZones__title">{t('modules.access_zones.h2')}</h2>
        <Button id="AccessZonesCreateZoneButton" type="ghost" size="large" className="AccessZones__addButton" onClick={() => setFormType('create zone')}>
          <Icon type="plus" className="AccessZones__addButton-icon" />
          <span className="AccessZones__addButton-text">{t('modules.access_zones.span')}</span>
        </Button>
      </header>

      <ModalCloseWindowWarning
        modalData={modalCloseWindowWarningData}
        setModalData={setModalCloseWindowWarningData}
        setFormType={setFormType}
        setId={setZoneId}
      />
      <div className="AccessZones__content">
        <div className="AccessZones__left-container">
          <AccessMenu
            data={data}
            handleDataChange={handleDataChange}
            id={zoneId}
            isChangedInput={isChangedInput}
            setModalCloseWindowWarningData={setModalCloseWindowWarningData}
            formType={formType}
          />
        </div>

        <div className="AccessZones__right-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {formType !== 'empty' ? (
                <div className="AccessZones__right-container-content">
                  <h3 className="AccessZones__right-container-title">
                    {formType !== 'create zone'
                      ? data[zoneId]
                        ? data[zoneId].title
                        : ''
                      : t('modules.access_zones.form_type')}
                  </h3>

                  <AccessZoneForm
                    data={data[zoneId]}
                    setIsChangedInput={setIsChangedInput}
                    formType={formType}
                    handleDelete={handleDelete}
                    onSubmit={handleSubmit}
                    wrappedComponentRef={formRef}
                    changeCheck={setIsCheckZone}
                    check={isCheckZone}
                  />
                </div>
              ) : (
                <EmptyPlaceholder
                  handleClick={handleClick}
                  title={t('modules.access_zones.empty_placeholder.acs')!}
                  text={t('modules.access_zones.empty_placeholder.manage_acs')!}
                  buttonText={t('modules.access_zones.empty_placeholder.add_zone')!}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default withApollo(AccessZones);
