import React from 'react';
import PromoAddSelect from '../PromoAddSelect';
import pathOr from 'ramda/es/pathOr';
import { Query } from 'react-apollo';
import {
  GET_MATCHES,
  GET_ACTIVE_SEASON_TICKET_DESCRIPTORS,
  GET_PROLONG_SEASON_TICKET_DESCRIPTORS,
} from '../../graphql';
import { useTranslation } from 'react-i18next';

const Type10PromoAddSelect = (props) => {
  const { t } = useTranslation();
  return (
    <Query query={GET_MATCHES}>
      {(match) => {
        const matchesList = props.getEventsList(pathOr([], ['data', 'match', 'getList', 'list'], match), 'TICKET');
        return (
          <Query query={GET_ACTIVE_SEASON_TICKET_DESCRIPTORS}>
            {(seasonTicket) => {
              const seasonTicketsList = props.getEventsList(
                pathOr([], ['data', 'seasonTicketDescriptor', 'getList', 'list'], seasonTicket),
                'SEASON_TICKET'
              );
              return (
                <Query query={GET_PROLONG_SEASON_TICKET_DESCRIPTORS}>
                  {(prolongSeasonTicket) => {
                    const prolongSeasonTicketsList = props.getEventsList(
                      pathOr([], ['data', 'seasonTicketDescriptor', 'getList', 'list'], prolongSeasonTicket),
                      'PROLONG_SEASON_TICKET'
                    );
                    const list = [...matchesList, ...seasonTicketsList, ...prolongSeasonTicketsList];
                    return (
                      <PromoAddSelect
                        title={t('modules.promo_add_modal.chunks.title')}
                        boundTypeList={list}
                        handleSelectBoundItems={props.handleSelectBoundItems}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default Type10PromoAddSelect;
