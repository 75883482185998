import React, { useState } from 'react';
import { GET_MATCH_ITEM_INFO, GET_SEASON_TICKET_INFO } from './graphql';
import { pathOr, contains } from 'ramda';
import styles from './index.module.scss';
import { useDidMount } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LocalizedString } from 'utils/LocalizedString';

const MapSideMenuItemComponent = (props) => {
  const { t } = useTranslation();
  const statusValue = props.item.place.options.status;
  const [itemInfo, setItemInfo] = useState({});
  const [isLoaded, setLoaded] = useState(false);

  const getStatus = (value) => {
    switch (value) {
      case 'SOLD':
        return t('modules.map_side_menu_item_component.get_status.sold');
      case 'ACTIVE':
        return t('modules.map_side_menu_item_component.get_status.active');
      case 'BLOCKED':
        return t('modules.map_side_menu_item_component.get_status.blocked');
      case 'RESERVED':
        return t('modules.map_side_menu_item_component.get_status.reserved');
      default:
        return '';
    }
  };

  const getDate = (dateValue) => {
    const date = new Date(dateValue);
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (
      (day < 10 ? '0' + day : day) +
      ' ' +
      new Intl.DateTimeFormat(i18next.language, { month: 'long' }).format(date) +
      ' ' +
      date.getFullYear() +
      ', ' +
      ' ' +
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes)
    );
  };

  const getSector = (sector) => {
    return sector.length > 3
      ? sector.split(' ')[0][0] + (contains(' ', sector) ? sector.split(' ')[1][0] : '')
      : sector;
  };

  const getUsername = (person, langCode = 'ru') => {
    const toLocale = (obj) => LocalizedString.fromObject(obj).toString(langCode);
    return `${toLocale(person.surname)} ${toLocale(person.name)}
    ${person.patronymic ? toLocale(person.patronymic) : ''}`;
  };

  const getPhoneNumber = (phoneValue) => {
    const resArr = phoneValue.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    return resArr[1] + ' ' + resArr[2] + ' ' + resArr[3] + ' ' + resArr[4] + ' ' + resArr[5];
  };

  useDidMount(() => {
    const init = async () => {
      try {
        const res = await props.client.query({
          query: props.isMatch ? GET_MATCH_ITEM_INFO : GET_SEASON_TICKET_INFO,
          variables: {
            filter: {
              [props.isMatch ? 'eventIds' : 'seasonTicketDescriptorIds']: [props.filterId],
              placeIds: props.item.id,
              [props.isMatch ? 'status' : 'statuses']: ['SOLD', 'RESERVED'],
            },
          },
          fetchPolicy: 'no-cache',
        });
        const itemInfoData = pathOr([], ['data', props.isMatch ? 'ticket' : 'seasonTicket', 'getList', 'list'], res)[0];

        setItemInfo(itemInfoData);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    };

    if (!isLoaded) {
      if (props.item.place.options.status === 'SOLD' || props.item.place.options.status === 'RESERVED') {
        init();
      } else {
        setLoaded(true);
      }
    }
    return () => {
      setLoaded(false);
    };
  });

  return (
    <>
      {isLoaded ? (
        <div className={styles.item}>
          <div className={styles.item__placeInfo}>
            <div className={styles.item__placeInfo__item}>
              <div className={styles.item__placeInfo__item__title}>
                {t('modules.map_side_menu_item_component.div.sector')}
              </div>
              <div className={styles.item__placeInfo__item__value}>{getSector(props.item.sector)}</div>
            </div>
            <div
              className={styles.item__placeInfo__item}
              style={{
                margin: '0 28px',
              }}
            >
              <div className={styles.item__placeInfo__item__title}>
                {t('modules.map_side_menu_item_component.div.row')}
              </div>
              <div className={styles.item__placeInfo__item__value}>{props.item.row}</div>
            </div>
            <div className={styles.item__placeInfo__item}>
              <div className={styles.item__placeInfo__item__title}>
                {t('modules.map_side_menu_item_component.div.seat')}
              </div>
              <div className={styles.item__placeInfo__item__value}>{props.item.number}</div>
            </div>
          </div>

          <div className={styles.item__infoItem}>
            <div className={styles.item__infoItem__title}>{t('modules.map_side_menu_item_component.div.status')}</div>
            <div className={styles.item__infoItem__value}>{getStatus(statusValue)}</div>
          </div>

          {(statusValue === 'SOLD' || statusValue === 'RESERVED') && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>{t('modules.map_side_menu_item_component.div.user')}</div>
              <div className={styles.item__infoItem__value}>{getUsername(itemInfo.user.person, i18next.language)}</div>
            </div>
          )}

          {(statusValue === 'SOLD' || statusValue === 'RESERVED') && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.telephone')}
              </div>
              <div className={styles.item__infoItem__value}>{getPhoneNumber(itemInfo.user.login)}</div>
            </div>
          )}

          {statusValue === 'SOLD' && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>{t('modules.map_side_menu_item_component.div.type')}</div>
              <div className={styles.item__infoItem__value}>
                {props.isMatch
                  ? t('modules.map_side_menu_item_component.div.ticket')
                  : t('modules.map_side_menu_item_component.div.season_ticket')}
              </div>
            </div>
          )}

          {statusValue === 'SOLD' && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.purchase_date')}
              </div>
              <div className={styles.item__infoItem__value}>{getDate(itemInfo.createdTime)}</div>
            </div>
          )}
          {statusValue === 'RESERVED' && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.booking_date')}
              </div>
              <div className={styles.item__infoItem__value}>{getDate(itemInfo.createdTime)}</div>
            </div>
          )}

          {/* {statusValue === 'BLOCKED' && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.blocking_date')}
              </div>
              <div className={styles.item__infoItem__value}>
                {getDate(props.item.placeStatuses.createdTime)}
              </div>
            </div>
          )} */}

          {(statusValue === 'SOLD' || statusValue === 'RESERVED') && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.order_number')}
              </div>
              <div className={styles.item__infoItem__value}>{itemInfo.order.visibleId}</div>
            </div>
          )}

          {statusValue !== 'BLOCKED' && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>{t('modules.map_side_menu_item_component.div.price')}</div>
              <div className={styles.item__infoItem__value}>{`${parseInt(props.item.price)} ${t('currency')}`}</div>
            </div>
          )}

          {(statusValue === 'SOLD' || statusValue === 'RESERVED') && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.with_discount')}
              </div>
              <div className={styles.item__infoItem__value}>
                {`${parseInt(itemInfo.orderItem.priceWithDiscount)} ${t('currency')}`}
              </div>
            </div>
          )}

          {(statusValue === 'SOLD' || statusValue === 'RESERVED') && itemInfo && (
            <div className={styles.item__infoItem}>
              <div className={styles.item__infoItem__title}>
                {t('modules.map_side_menu_item_component.div.comment')}
              </div>
              <div className={styles.item__infoItem__value}>
                {itemInfo.order.additionalData?.commentary || t('modules.map_side_menu_item_component.div.no_comment')}
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default MapSideMenuItemComponent;
