export const STYLE_BLOCK_COLOR_TITLE = {
    "width": `100%`,
    "height": `100%`
}

export const STYLE_MODAL_BUTTONS = {
    "height": `80px`, 
    "display": `flex`, 
    "justifyContent": `center`, 
    "alignItems": `center`,
    "marginTop": `20px`
}

export const STYLE_MODAL_CLOSE = {
    "lineHeight": `40px`,
    "cursor": `pointer`,
    "opacity": `.7`
}