import gql from 'graphql-tag';

export const GET_CODES: string = gql`
  query($id: ID!) {
    promocodes {
      getById(id: $id) {
        maxUseCounts
        codes(paging: { limit: 10000 }) {
          list {
            value
            useCounter
          }
        }
      }
    }
  }
`;
