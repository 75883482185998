import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, ConfigProvider } from 'antd';
import AddHeader from './AddHeader';
import AddBody from './AddBody';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';

const PromoAdd = (props) => {
  return (
    <ConfigProvider locale={determiningCurrentLocale()}>
      <Drawer width={1110} open={props.isAddOpen} closable={false} placement="right" onClose={props.onClose}>
        <Form onSubmit={props.handleSubmit}>
          <AddHeader {...props} />
          <AddBody {...props} />
        </Form>
      </Drawer>
    </ConfigProvider>
  );
};

export default PromoAdd;
