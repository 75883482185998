import React from 'react';
import { Mutation } from 'react-apollo';
import { Menu, message } from 'antd';
import { REMOVE_PROMO, GET_PROMOCODES } from '../graphql';
import pathOr from 'ramda/es/pathOr';
import assocPath from 'ramda/es/assocPath';
import { useTranslation } from 'react-i18next';

const OptionMenu = (props) => {
  const { t } = useTranslation();
  return (
    <Mutation mutation={REMOVE_PROMO}>
      {(removePromo) => (
        <Menu>
          <Menu.Item onClick={() => props.handleUpdatePromo(props.id)} key="1">
            {t('modules.option_menu.menu_item.edit')}
          </Menu.Item>
          <Menu.Item
            onClick={async () => {
              try {
                await removePromo({
                  variables: {
                    id: props.id,
                  },
                  update: (cache) => {
                    const promos = cache.readQuery({ query: GET_PROMOCODES });
                    const list = pathOr([], ['promocodes', 'getList', 'list'], promos);
                    const data = list.filter((item) => item.id !== props.id);
                    cache.writeQuery({
                      query: GET_PROMOCODES,
                      data: assocPath(['promocodes', 'getList', 'list'], data, promos),
                    });
                    props.formatData(data);
                  },
                });
                message.success(t('modules.option_menu.message_success'));
              } catch {
                message.error(t('modules.option_menu.message_error'));
              }
            }}
            key="2"
            style={{ background: 'tomato', color: 'white' }}
          >
            {t('modules.option_menu.menu_item.delete')}
          </Menu.Item>
        </Menu>
      )}
    </Mutation>
  );
};

export default OptionMenu;
