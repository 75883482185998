import moment from 'moment';

export type SegmentsTitles = {
  id: string;
  title: string;
};

export enum SegmentType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export type Segment = {
  id: string;
  title?: string;
  segmentType?: SegmentType;
  deletedAt?: Date;
};

export type SegmentsConfiguration = {
  releaseTime?: Date;
  segmentId?: string | null;
  releaseDatetime: Date;
  segment?: Segment;
};

export type SegmentsSalesConfiguration = {
  id: string;
  title: string;
  hidden: boolean;
  segmentsConfiguration: SegmentsConfiguration[];
};

export type TransformedConfiguration = {
  key: string;
  value: SegmentsConfiguration[];
};

export type FormValue = {
  segmentsId: string[];
  date: moment.Moment;
  time: moment.Moment;
};

export type FormSubmitResult = {
  [key: string]: FormValue;
};
