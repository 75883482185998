import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Table, Modal, Button, ConfigProvider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import SearchInput from './SearchInput.jsx';
import UserDataModal from './UserDataModal';
import UserUpdateForm from './UserUpdateForm';
import UserRegistration from './UserRegistration/UserRegistration';
import Loader from 'common/Loader/Loader';
import './UserList.scss';

import { fetchData, deserialize, useDidMount } from 'utils/apiHelpers';
import { deserializeUser } from '../model/usersDeserializers';
import { GET_USERS_BY_PAGE } from '../model/usersQueries';
import { UserInfo, ListOfUsers, User } from '../model/usersModel';
import { useTranslation } from 'react-i18next';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';
import i18next from 'i18next';

function UserList(props: any) {
  // TODO: make it a data repository

  const { t } = useTranslation();
  const { Column } = Table;
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User>();
  const [userId, setUserId] = useState<string>();
  const [isUserRegistrationModalOpened, setIsUserRegistrationModalOpened] = useState<boolean>(false);

  function getUsersByPage(currentSearchPage: number, searchString: string) {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_USERS_BY_PAGE,
        variables: {
          page: currentSearchPage,
          searchString: searchString,
        },
      }),
      setUsers,
      (res) => deserialize(res, (o) => o.required('data.users.getListAdvanced').asObject((o) => deserializeUser(o)))
    );
  }

  useDidMount(() => {
    getUsersByPage(props.currentSearchPage, props.searchString);
  });

  useEffect(() => {
    getUsersByPage(props.currentSearchPage, props.searchString);
  }, [props.currentSearchPage, props.searchString]);

  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <div className="user-list__header-container">
          <h1 className="user-list__header-title">{t('modules.user_list.list_users')}</h1>
          <Button
            id="UserListAddNewButton"
            type="ghost"
            className="user-list__header-button"
            size="large"
            onClick={() => setIsUserRegistrationModalOpened(true)}
          >
            <PlusOutlined className="discounts__header-button-icon" />
            {t('modules.user_list.add_new')}
          </Button>
        </div>

        <div className="route-content">
          <SearchInput
            handleSearchChange={props.handleSearchInputChange}
            inputStyle={{
              width: '480px',
              margin: '0 0 16px 0',
            }}
            iconStyle={{
              fontSize: '18px',
              color: 'rgba(0,0,0,.35)',
            }}
            inputPlaceholder={t('modules.user_list.quick_client_search')}
            inputSize={'large'}
            inputMaxLength={250}
            inputTimeout={300}
          />

          {loading ? (
            <Loader />
          ) : (
            <Table
              dataSource={users?.list}
              rowKey={(record) => record.userId}
              pagination={{
                onChange: props.handleSearchPageChange,
                total: users?.total,
                current: props.currentSearchPage + 1,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
              style={{ margin: '0 41px 0 0' }}
              rowClassName={() => 'user-list__table-row'}
              onRow={(dataSource: ListOfUsers) => {
                return {
                  onClick: (event) => {
                    setUserId(dataSource.userId);
                    props.handleOpenUserDataModal(true);
                  },
                };
              }}
            >
              <Column
                title={t('modules.user_list.full_name')}
                key="userId"
                align="left"
                render={(dataSource: UserInfo) => (
                  <div>
                    {dataSource &&
                      `${dataSource.surname.toString(i18next.language)} ${dataSource.name.toString(i18next.language)} ${
                        dataSource.patronymic?.toString(i18next.language) || ''
                      }`}
                  </div>
                )}
              />
              <Column title={t('modules.user_list.number_phone')} dataIndex="login" align="left" />
              <Column title="E-mail" dataIndex="email" align="left" />
              <Column
                title={t('modules.user_list.date_birth')}
                key="birthday"
                align="left"
                render={(dataSource) =>
                  dataSource.birthday && dataSource.birthday.toLocaleString('ru-Ru', { dateStyle: 'short' })
                }
              />
            </Table>
          )}
        </div>

        <Modal visible={props.isUserUpdate} onCancel={props.closeUserUpdate} footer={null}>
          <div className="user-update">
            <h2>{t('modules.user_list.editing_profile')}</h2>
            <UserUpdateForm user={props.user} onCancel={props.closeUserUpdate} />
          </div>
        </Modal>
        {isUserRegistrationModalOpened && (
          <UserRegistration
            isModalOpened={isUserRegistrationModalOpened}
            setIsModalOpened={setIsUserRegistrationModalOpened}
            client={props.client}
            getUsersByPage={getUsersByPage}
          />
        )}

        {userId && (
          <UserDataModal
            userId={userId}
            handleOpenUserDataModal={props.handleOpenUserDataModal}
            isUserDataModalOpened={props.isUserDataModalOpened}
            apolloClient={props.client}
          />
        )}
      </ConfigProvider>
    </>
  );
}

export default withApollo(UserList);
