import React from 'react';
import Discounts from './components/Discounts/Discounts';
import { DiscountsRepositoryImpl, DiscountsRepository } from './model/DiscountsRepository';

import ApolloClient from 'apollo-client';
import { withApollo } from 'react-apollo';

function DiscountsPage(props: { client: ApolloClient<any> }) {
  const discountsRepository: DiscountsRepository = new DiscountsRepositoryImpl(props.client);

  return <Discounts discountsRepository={discountsRepository} />;
}

export default withApollo(DiscountsPage);
