import gql from 'graphql-tag';

export const CHANGE_ACTIVE_STATUS = gql `
  mutation changeLoyaltyRules($data: LTY_LoyaltyDescriptorUpdateInput!) { 
    loyalty { 
      loyaltyDescriptorMutation { 
        update(data: $data) { 
         isActive 
       } 
     } 
   }
  }
`;

export const CHANGE_LOYALTY_RULES = gql `
  mutation changeLoyaltyRules($data: LTY_LoyaltyDescriptorUpdateInput!) {
    loyalty {
      loyaltyDescriptorMutation {
        update(data: $data) {
          bonusActivePeriodDays
          bonusPriceCoverPercentage
          accrualPercentage
          applicableWithDiscount
        }
      }
    }
  }
`;

export const CHANGE_LOYALTY_PROGRAM = gql `
  mutation changeLoyaltyRules($data: LTY_LoyaltyProgramUpdateInput!) {
    loyalty {
      loyaltyProgramMutation {
        update(data: $data) {
          legalityDocumentation {
            id
            publicLink
            fileName
          }
          legalityDocumentationLink
          loyaltyCurrencyName {
            mod1
            plural
            mod24
            mod50
          }
        }
      }
    }
  }
`;
