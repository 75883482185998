import AdminPanel from './IndexComponent';
import {
  ISidebar,
  IApp,
  Action,
  Module,
  ModuleRoute,
  IRouteContainer
} from '../../../common/src';
import { addMenuItem } from './actions';
import { withProps } from 'recompose';
import reducer from './reducer';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';

export class ModuleAdmin implements ISidebar, Module, IRouteContainer {
  private app: IApp;
  routes: ModuleRoute[] = [];
  private component: any;
  links: Action[];
  reducer: any;

  addLink(action: Action) {
    this.links.push(action);
  }

  addRoutes(routes: ModuleRoute[]) {
    routes.map(route => this.routes.push(route));
  }

  addRoute(route: ModuleRoute) {
    this.routes.push(route);
  }

  constructor(app: IApp, authClient: AuthClient) {
    this.component = withProps({ admin: this, authClient })(AdminPanel);
    this.app = app;
    this.links = [];
    this.reducer = {
      name: 'adminPanel',
      reducer
    };
    this.app.addReducer({ name: 'adminPanel', reducer });
    this.app.addRoute({ path: '/admin', component: this.component });
  }

  start() {
    const sorted = this.links.sort((a, b) => a.order - b.order);
    sorted.map(link => this.app.store.dispatch(addMenuItem(link)));
  }
}
