import React from 'react';
import { Table } from 'antd';
import './SegmentsConfigurationList.scss';

import { TransformedConfiguration, SegmentsConfiguration } from '../../../model/eventsModel';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const SegmentsConfigurationList = (props: {
  allUsersSegmentTitle: string;
  transformedConfiguration: TransformedConfiguration[];
}) => {
  const { t } = useTranslation();
  const { Column } = Table;
  return (
    <>
      {props.transformedConfiguration && (
        <div className="configuration-list__block">
          <Table
            dataSource={props.transformedConfiguration}
            size="small"
            pagination={false}
            className="configuration-list__table"
          >
            <Column
              width="121px"
              align="left"
              className="configuration-list__table-column"
              render={(dataSource) =>
                new Intl.DateTimeFormat(i18next.language).format(new Date(dataSource.value[0].releaseDatetime))
              }
            />
            <Column
              width="16px"
              align="left"
              className="configuration-list__table-column"
              render={(dataSource) =>
                new Intl.DateTimeFormat(i18next.language, { hour: 'numeric', minute: 'numeric' }).format(
                  dataSource.value[0].releaseDatetime
                )
              }
            />

            <Column
              align="left"
              className="configuration-list__table-column"
              render={(dataSource) => {
                return dataSource.value
                  .map((item: SegmentsConfiguration) => {
                    if (item.segment === undefined) {
                      return props.allUsersSegmentTitle;
                    } else if (item.segment.deletedAt) {
                      // TODO: remove this segment from the array: ModalSegmentsSales.tsx, 43 and 73
                      return `${item.segment.title} (${t('modules.segments_configuration_list')})`;
                    } else {
                      return item.segment.title;
                    }
                  })
                  .join(', ');
              }}
            />
          </Table>
        </div>
      )}
    </>
  );
};

export default SegmentsConfigurationList;
