import gql from 'graphql-tag';

export const UPDATE_ROLE = gql`
  mutation($id: ID!, $role: RoleUpdateInput!) {
    roles {
      update(id: $id, role: $role) {
        id
      }
    }
  }
`;
export const UPDATE_PERMISSIONS = gql`
  mutation($roleId: ID!, $permissions: [String!]!) {
    permissions {
      setRolePermissions(roleId: $roleId, permissions: $permissions)
    }
  }
`;
export const UPDATE_USERS = gql`
  mutation($id: ID!, $user: UserUpdateInput!) {
    users {
      update(id: $id, user: $user) {
        id
      }
    }
  }
`;
export const INSERT_ROLE = gql`
  mutation($role: RoleInput!) {
    roles {
      insert(role: $role) {
        id
      }
    }
  }
`;
export const DELETE_ROLE = gql`
  mutation($id: ID!) {
    roles {
      delete(id: $id)
    }
  }
`;
