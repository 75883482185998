import { compose, withHandlers } from 'recompose';
import pathOr from 'ramda/es/pathOr';
import { message } from 'antd';

import { getMoscowTime, indexOfToString } from '../../../../utils/helpers';
import { currency } from '../../../../constants';
import { promoBounds } from '../../../../constants';
import {
  GET_SECTOR_BOUNDS,
  GET_ROW_BOUNDS,
  GET_PLACE_BOUNDS,
  GET_TOURNAMENT_BOUNDS,
  GET_STAGE_BOUNDS,
  GET_SEASON_BOUNDS,
  GET_USER_BOUNDS,
  GET_MATCHES,
  GET_SEASON_TICKET_DESCRIPTORS,
} from '../PromoList/components/PromoBody/graphql';
import take from 'ramda/es/take';
import uniq from 'ramda/es/uniq';
import { withApollo } from 'react-apollo';
import { t } from 'i18next';

export default compose(
  withApollo,
  withHandlers({
    getPromoBounds: (props) => (item) => {
      const bounds = [];
      const title = t('modules.with_handlers.get_promo_bounds');
      let id = '';

      if (item.sectorBound) {
        Promise.all(
          item.sectorBound.sectorIds.map((id) =>
            props.client.query({
              query: GET_SECTOR_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              id = pathOr(false, ['data', 'sector', 'getById', 'id'], res);
              return pathOr(false, ['data', 'sector', 'getById', 'title'], res);
            });
            bounds.push({
              id,
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_sector_information')));
      }
      if (item.rowBound) {
        const title = t('modules.with_handlers.title.by_row');
        Promise.all(
          item.rowBound.rowIds.map((id) =>
            props.client.query({
              query: GET_ROW_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              const row = pathOr(false, ['data', 'row', 'getById', 'number'], res);
              const sector = pathOr(false, ['data', 'row', 'getById', 'sector', 'title'], res);
              return [sector, row].join('-');
            });
            bounds.push({
              id: 'row',
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_row_information')));
      }
      if (item.placeBound) {
        const title = t('modules.with_handlers.title.by_place');
        Promise.all(
          item.placeBound.placeIds.map((id) =>
            props.client.query({
              query: GET_PLACE_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const data = list.map((res) => {
              const place = pathOr(false, ['data', 'place', 'getById', 'number'], res);

              const row = pathOr(false, ['data', 'place', 'getById', 'row', 'number'], res);

              const sector = pathOr(false, ['data', 'place', 'getById', 'row', 'sector', 'title'], res);

              return [sector, row, place].join('-');
            });

            bounds.push({
              id: 'place',
              title,
              value: data,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_place_information')));
      }
      if (item.tournamentBound) {
        const title = t('modules.with_handlers.title.by_tournament');
        Promise.all(
          item.tournamentBound.tournamentIds.map((id) =>
            props.client.query({
              query: GET_TOURNAMENT_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              return pathOr(false, ['data', 'tournament', 'getById', 'title'], res);
            });
            bounds.push({
              id: 'tournament',
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_tournament_information')));
      }
      if (item.stageBound) {
        const title = t('modules.with_handlers.title.by_stage');
        Promise.all(
          item.stageBound.stageIds.map((id) =>
            props.client.query({
              query: GET_STAGE_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              return pathOr(false, ['data', 'stage', 'getById', 'title'], res);
            });

            bounds.push({
              id: 'stage',
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_stage_information')));
      }
      if (item.seasonBound) {
        const title = t('modules.with_handlers.title.by_season');
        Promise.all(
          item.seasonBound.seasonIds.map((id) =>
            props.client.query({
              query: GET_SEASON_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              const season = pathOr({}, ['data', 'season', 'getById'], res);
              const start = pathOr(false, ['startDate'], season);
              const end = pathOr(false, ['endDate'], season);
              const formattedStart = getMoscowTime(new Date(start), 'MM.yyyy');
              const formattedEnd = getMoscowTime(new Date(end), 'MM.yyyy');

              if (start && end) {
                return `${formattedStart} - ${formattedEnd}`;
              }
              if (start && !end) {
                return `> ${formattedStart}`;
              }
              if (!start && end) {
                return `< ${formattedEnd}`;
              }
              return '∞';
            });

            bounds.push({
              id: 'season',
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_season_information')));
      }
      if (item.priceBound) {
        const title = t('modules.with_handlers.title.by_price');
        const min = pathOr(false, ['priceBound', 'priceRange', 'start'], item);
        const max = pathOr(false, ['priceBound', 'priceRange', 'end'], item);

        const value = () => {
          if (min && max) {
            return [
              `${t('modules.with_handlers.value.from')} ${min}${currency} ${t(
                'modules.with_handlers.value.to'
              )} ${max}${currency}`,
            ];
          }
          if (min && !max) {
            return [`> ${min}${currency}`];
          }
          if (!min && max) {
            return [`< ${max}${currency}`];
          }
          return '∞';
        };

        bounds.push({
          id: 'priceBound',
          title,
          value: value(),
          type: indexOfToString(promoBounds, title),
        });
      }
      const isNull = item.itemsNumberRange?.start === null && item.itemsNumberRange?.end === null;
      if (item.itemsNumberRange && !isNull) {
        const title = t('modules.with_handlers.title.by_items_order');
        const start = pathOr('', ['itemsNumberRange', 'start'], item);
        const end = pathOr('', ['itemsNumberRange', 'end'], item);

        const value = () => {
          if (start && end) {
            return [`${t('modules.with_handlers.value.from')} ${start} ${t('modules.with_handlers.value.to')} ${end}`];
          }
          if (start && !end) {
            return [`> ${start}`];
          }
          if (!start && end) {
            return [`< ${end}`];
          }
          return '∞';
        };

        bounds.push({
          id: 'itemsNumberRange',
          title,
          value: value(),
          type: indexOfToString(promoBounds, title),
        });
      }
      if (item.orderItemTypeBound) {
        const title = t('modules.with_handlers.title.by_type');
        const typeTitle = (type) => {
          switch (type) {
            case 'TICKET':
              return t('modules.type_title.ticket');

            case 'SEASON_TICKET':
              return t('modules.type_title.subscription');

            case 'PROLONG_SEASON_TICKET':
              return t('modules.type_title.subscription_renewal');

            case 'OUTFIT':
              return t('modules.type_title.merch');

            default:
              return t('modules.type_title.unknown_type');
          }
        };
        const value = item.orderItemTypeBound.types.map(typeTitle);
        bounds.push({
          id: 'orderItemTypeBound',
          title,
          value,
          type: indexOfToString(promoBounds, title),
        });
      }
      if (item.userBound) {
        const title = t('modules.with_handlers.title.by_user');
        Promise.all(
          item.userBound.userIds.map((id) =>
            props.client.query({
              query: GET_USER_BOUNDS,
              variables: {
                id,
              },
            })
          )
        )
          .then((list) => {
            const value = list.map((res) => {
              const id = pathOr(t('modules.with_handlers.path_or'), ['data', 'users', 'getUserById', 'id'], res);
              const name = pathOr(
                `${t('modules.with_handlers.path_or')} (${take(5, id)}...)`,
                ['data', 'users', 'getUserById', 'person', 'name', 'ru'],
                res
              );
              const surname = pathOr('', ['data', 'users', 'getUserById', 'person', 'surname', 'ru'], res);

              return `${name} ${surname}`;
            });

            bounds.push({
              id: 'user',
              title,
              value,
              type: indexOfToString(promoBounds, title),
            });
          })
          .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_user_information')));
      }
      if (item.orderItemBound) {
        const title = t('modules.with_handlers.title.by_match');
        let isTicket;
        const items = pathOr([], ['orderItemBound', 'items'], item);
        let values = [];
        items.forEach((item) => {
          isTicket = item.type === 'TICKET';
          props.client
            .query({
              query: isTicket ? GET_MATCHES : GET_SEASON_TICKET_DESCRIPTORS,
              variables: {
                id: item.descriptorIds[0],
              },
            })
            .then((item) => {
              const title =
                pathOr(null, ['data', 'seasonTicketDescriptor', 'getById', 'title'], item) ||
                pathOr(null, ['data', 'match', 'getById', 'title'], item);
              values.push(title);
            })
            .catch(() => message.error(t('modules.with_handlers.message_error.failed_get_cart_information')));
        });
        bounds.push({
          id: 'orderItemBound',
          title,
          value: values,
          type: indexOfToString(promoBounds, title),
        });
      }

      return bounds;
    },
  }),
  withHandlers({
    formatData: (props) => (list, needHeader = true, resList) => {
      const curList = resList ? resList : list;
      const arr = curList.map((item) => {
        const saleValue = pathOr('null', ['discount', 'value'], item);
        const discountType = pathOr('', ['discount', 'type'], item);
        let saleSuffix;
        switch (discountType) {
          case 'PERCENT':
            saleSuffix = '%';
            break;
          case 'FIXED_PRICE':
            saleSuffix = t('currency');
            break;
          case 'ABSOLUTE':
            saleSuffix = 'f.';
            break;
          default:
            saleSuffix = '';
            break;
        }
        const saleString = `${saleValue} ${saleSuffix}`;

        const period = {
          start: pathOr(null, ['period', 'start'], item),
          end: pathOr(null, ['period', 'end'], item),
        };

        const isFinite = period.start && period.end;

        const periodValue = isFinite
          ? [getMoscowTime(period.start, 'dd.MM.yyyy'), getMoscowTime(period.end, 'dd.MM.yyyy')]
          : '∞';

        const codes = pathOr([], ['codes', 'list'], item);

        const count = codes.map((item) => item.useCounter).reduce((acc, curr) => acc + curr, 0);
        const limit = pathOr(false, ['maxUseCounts'], item);
        const bounds = props.getPromoBounds(item);
        const endOfCodeName = codes.length > 1 ? '...' : '';
        const activationsString = limit ? `${count} ${t('modules.with_handlers.activations_string')} ${limit}` : count;

        return {
          id: item.id,
          title: item.title,
          sale: saleString,
          period: periodValue,
          activations: activationsString,
          group: item.adminUseOnly ? t('modules.with_handlers.group.admin') : t('modules.with_handlers.group.all'),
          code: codes.length > 0 ? codes[0].value + endOfCodeName : t('modules.with_handlers.code'),
          bounds,
          commentary: item.commentary,
          initial: item,
        };
      });
      if (needHeader) {
        props.setPromoList(
          uniq([
            // {
            //   title: {
            //     isGroupTitle: true,
            //     title: 'Group: Test'
            //   },
            //   sale: {
            //     isGroupTitle: true
            //   },
            //   period: {
            //     isGroupTitle: true
            //   },
            //   activations: {
            //     isGroupTitle: true
            //   },
            //   group: {
            //     isGroupTitle: true
            //   },
            //   code: {
            //     isGroupTitle: true
            //   },
            //   action: {
            //     isGroupTitle: true
            //   }
            // },
            ...arr.reverse(),
          ])
        );
      }
      return arr.reverse();
    },
  })
);
