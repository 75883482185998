import React, { useState } from 'react';
import { Modal } from 'antd';
import { pathOr } from 'ramda';

import CodesModalBody from './chunks/body';
import CodesModalFooter from './chunks/footer';

import { IPromoCodesModalProps } from '../interfaces/IPromoCodesModal';
import { useTranslation } from 'react-i18next';

const PromoCodesModal = (props: IPromoCodesModalProps | any) => {
  const { codesData, defaultPromo } = props;
  const { client, openCodesModal, setCodesToReducer } = props;
  const codesModalIsOpened: boolean = pathOr(false, ['codesModalIsOpened'], props);
  const [dataCode, setDataCode] = useState(codesData.codes);
  const { t } = useTranslation();
  const footer = <CodesModalFooter setDataCode={setDataCode} defaultPromo={defaultPromo} client={client} />;

  return (
    <Modal
      visible={codesModalIsOpened}
      title={t('modules.promo_codes_modal')}
      footer={footer}
      onCancel={() => openCodesModal(false)}
      className="promocodes-modal"
    >
      <CodesModalBody
        codesData={codesData}
        setDataCode={setDataCode}
        dataCode={dataCode}
        client={client}
        defaultPromo={defaultPromo}
        setCodesToReducer={setCodesToReducer}
      />
    </Modal>
  );
};

export default PromoCodesModal;
