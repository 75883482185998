import React, { useState } from 'react';
import './ModalWarning.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

function ModalWarning(props: {
  title: string;
  onConfirmClick: () => void;
  buttonText: string;
  confirmationButtonText: string;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const modalTitle = (
    <div className="modal-warning__title-block">
      <CloseCircleOutlined className="modal-warning__close-circle" />
      <h3 className="modal-warning__title">{props.title}</h3>
    </div>
  );

  return (
    <>
      <Button
        id="ModalWarningButtonTextButton"
        key="openModalButton"
        size="large"
        className="modal-warning__deactivate-button"
        onClick={() => setIsOpen(true)}
      >
        {props.buttonText}
      </Button>
      <Modal visible={isOpen} title={modalTitle} footer={false} closable={false} width={415}>
        <div className="modal-warning__button-block">
          <Button
            id="ModalWarningConfirmButton"
            key="confirm"
            className="modal-warning__confirm-button"
            onClick={() => props.onConfirmClick()}
          >
            {props.confirmationButtonText}
          </Button>
          <Button
            id="ModalWarningCancelButton"
            key="cancel"
            className="modal-warning__cancel-button"
            type="primary"
            onClick={() => setIsOpen(false)}
          >
            {t('modules.modal_warning')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ModalWarning;
