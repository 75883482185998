import React from 'react';
import { pathOr } from 'ramda';
import { Icon } from '@ant-design/compatible';

import { removeCode } from '../../events/mutationEvents';
import { ICodesModalBody } from '../../interfaces/IPromoCodesModal';

const CodesModalBody = (props: ICodesModalBody) => {
  const { client, setCodesToReducer, codesData, dataCode, setDataCode } = props;
  const defaultPromoId: string = pathOr('', ['defaultPromo', 'id'], props);
  const codes: Array<object> = pathOr([], ['codesData', 'codes'], props);
  const currentData = dataCode === undefined ? codesData.codes : dataCode;
  return (
    <div className="promocodes-modal__body">
      {codesData.codes &&
        currentData.map((item: { value: string }, index: number) => {
          const isOddNumber: boolean = (index + 1) % 2 !== 0;
          const { value } = item;
          return (
            <div
              key={index}
              className={`df promocodes-modal__values-wrapper ${
                isOddNumber ? 'promocodes-modal__values-wrapper__odd-elem' : ''
              }`}
            >
              <span>{index + 1}</span>
              <div className="promocodes-modal__values">{value}</div>
              <button
                id="CodesModalBodyIconDeleteButton"
                onClick={() => {
                  removeCode({
                    client,
                    defaultPromoId,
                    value,
                    codes,
                    setCodesToReducer,
                  });
                  const newList = currentData.filter((item: { value: string }) => item.value != value);
                  setDataCode(newList);
                }}
                className="promocodes-modal__remove-btn"
              >
                <Icon type="delete" />
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default CodesModalBody;
