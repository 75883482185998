import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const SearchInput = ({
  handleSearchChange,
  inputStyle,
  iconStyle,
  inputPlaceholder,
  inputSize,
  inputMaxLength,
  inputTimeout
}) => {
  const [timerId, setTimerId] = useState(null);

  const delayedSearch = ev => {
    const inputValue = ev.target.value;
    window.clearTimeout(timerId);
    setTimerId(
      window.setTimeout(() => {
        handleSearchChange(inputValue);
      }, inputTimeout)
    );
  };
  return <>
    <Input
      placeholder={inputPlaceholder}
      style={inputStyle}
      size={inputSize}
      suffix={<SearchOutlined style={iconStyle} />}
      onChange={delayedSearch}
      maxLength={inputMaxLength}
    ></Input>
  </>;
};

export default SearchInput;
