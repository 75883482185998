import React from 'react';
import Coin from './svgComponents/Coin';
import '../../Loyalty.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

function PromoPage(props: { activeProgramState: () => void }) {
  const coinStyle: string = 'coin-image';
  const coinStyleModifierLarge: string = 'coin-image_large';
  const coinStyleModifierMedium: string = 'coin-image_medium';
  const coinStyleModifierSmall: string = 'coin-image_small';
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('modules.promo_page.h1')}</h1>
      <div className="route-content route-content_loyalty">
        <Coin styleClasses={coinStyle + ' ' + coinStyleModifierLarge} />
        <Coin styleClasses={coinStyle + ' ' + coinStyleModifierMedium} />
        <Coin styleClasses={coinStyle + ' ' + coinStyleModifierSmall} />

        <h2 className="route-content_loyalty-title">{t('modules.promo_page.h2')}</h2>
        <p className="route-content_loyalty-description">
          {t('modules.promo_page.p.text_1.0')}&nbsp;{t('modules.promo_page.p.text_1.1')}&nbsp;
          {t('modules.promo_page.p.text_1.2')}
        </p>

        <h3 className="route-content_loyalty-subtitle">{t('modules.promo_page.h3')}</h3>
        <ul className="route-content_loyalty-list">
          <li className="route-content_loyalty-list-item">{t('modules.promo_page.li.increase_purchases')}</li>
          <li className="route-content_loyalty-list-item">{t('modules.promo_page.li.return_fans')}</li>
          <li className="route-content_loyalty-list-item">
            {t('modules.promo_page.li.increase_attendance')}&nbsp;{t('modules.promo_page.li.top_matches')}
          </li>
        </ul>
        <Button
          id="PromoPageActivateButton"
          type="primary"
          className="route-content_loyalty-activate-button"
          onClick={() => {
            props.activeProgramState();
          }}
        >
          {t('modules.promo_page.button')}
        </Button>
        <p className="route-content_loyalty-activate-description">
          {t('modules.promo_page.p.text_2.0')}&nbsp;{t('modules.promo_page.p.text_2.1')}&nbsp;
          {t('modules.promo_page.p.text_2.2')}&nbsp;
          {t('modules.promo_page.p.text_2.3')}&nbsp;{t('modules.promo_page.p.text_2.4')}&nbsp;
          {t('modules.promo_page.p.text_2.5')}
        </p>
      </div>
    </>
  );
}

export default PromoPage;
