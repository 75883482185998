import {
  AccessCategoryDataDescriptor,
  AccessCategoryListDescriptor,
  AccessEntranceDescriptor,
  AccessZoneDescriptor,
  IdAndTitleDescriptor,
  KeyAndLabelDescriptor
} from './accessZoneModel';
import { ObjectDeserializator } from '../../../../utils/apiHelpers';
import { LocalizedString } from 'utils/LocalizedString';

export const accessZoneDeserializer = (a: ObjectDeserializator): AccessZoneDescriptor => {
  return {
    id: a.required('id').asString,
    title: a.required('title').asString,
    name: a.required('name').asString,
    description: a.optional('description')?.asString,
    venue: a.required('venue').asObject((a) => accessVenueDeserializer(a)),
    controlPresence: a.optional('controlPresence')?.asBool
  };
};

export const accessEntranceDeserializer = (a: ObjectDeserializator): AccessEntranceDescriptor => {
  return {
    id: a.required('id').asString,
    title: a.required('title').asString,
    name: a.required('name').asString,
    description: a.optional('description')?.asString,
    // deletedDate: a.optional('deletedDate')?.asString,
    venue: a.required('venue').asObject((a) => accessVenueDeserializer(a)),
    zonesIn: a.required('zonesIn').asArrayOfObjects((a) => accessEntranceZoneDeserializer(a)),
    zonesOut: a.required('zonesOut').asArrayOfObjects((a) => accessEntranceZoneDeserializer(a))
  };
};

export const accessCategoryListDeserializer = (a: ObjectDeserializator): AccessCategoryListDescriptor => {
  return {
    id: a.required('id').asString,
    title: a.required('title').as(o => LocalizedString.fromObject(o))
  };
};

export const accessCategoryDataDeserializer = (a: ObjectDeserializator): AccessCategoryDataDescriptor => {
  return {
    id: a.required('id').asString,
    title: a.required('title').asString,
    syncSourceId: a.required('syncSourceId').asString,
    zones: a.required('zones').asArrayOfObjects((z: any) => accessEntranceZoneDeserializer(z)),
    entrances: a.required('entrances').asArrayOfObjects((e: any) => accessEntranceZoneDeserializer(e))
  };
};

export const accessEntranceZoneDeserializer = (a: ObjectDeserializator): KeyAndLabelDescriptor => {
  return {
    key: a.required('id').asString,
    label: a.required('title').asString
  };
};
export const accessVenueDeserializer = (a: ObjectDeserializator): IdAndTitleDescriptor => {
  return {
    id: a.required('id').asString,
    title: a.required('title').as((a) => LocalizedString.fromObject(a))
  };
};
