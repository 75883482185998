import React, { useState, useEffect } from 'react';
import { isEmpty, pathOr } from 'ramda';

import {
  CloseCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { Modal, Checkbox, Input, Button, message } from 'antd';
import {
  GET_ROLES,
  GET_SERVICES,
  SEARCH_USERS,
  UPDATE_ROLE,
  UPDATE_PERMISSIONS,
  UPDATE_USERS,
  INSERT_ROLE,
  DELETE_ROLE,
  GET_USER_ROLES,
} from './graphql';
import { AutoComplete } from 'antd';
import debounce from 'lodash.debounce';
import { transliterate } from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LocalizedString } from 'utils/LocalizedString';

const { Option } = AutoComplete;

const PermissionsComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    selectedRole: {},
    isRoleVisible: true,
    isUpdated: false,
    userList: [],
    allGrouplessPermissions: [],
    newUserList: [],
  });

  const restoreState = () => {
    setState({
      selectedRole: {},
      isRoleVisible: true,
      isUpdated: false,
      userList: [],
      allGrouplessPermissions: [],
      newUserList: [],
    });
  };

  const deleteRole = async () => {
    try {
      await props.client.query({
        query: DELETE_ROLE,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedRole.id,
        },
      });
      message.success(t('modules.permissions_component.message_success.role_removed'));
    } catch (e) {
      message.error(t('modules.permissions_component.message_error.deletion_error'));
      console.error(e);
    } finally {
      restoreState();
    }
  };
  const updateRole = async () => {
    try {
      if (stateProps.isNewUser) {
        const deleteUsers = stateProps.selectedRole.users.filter(function (obj) {
          return stateProps.newUserList.indexOf(obj) === -1;
        });
        for (const newUsr of stateProps.newUserList) {
          const rolesRes = await props.client.query({
            query: GET_USER_ROLES,
            fetchPolicy: 'no-cache',
            variables: {
              id: newUsr.id,
            },
          });
          const rolesArr = pathOr([], ['data', 'users', 'getUserById', 'roles'], rolesRes);
          const rolesIds = rolesArr.map((roleItem) => {
            return roleItem.id;
          });
          await props.client.query({
            query: UPDATE_USERS,
            fetchPolicy: 'no-cache',
            variables: {
              id: newUsr.id,
              user: {
                roles: [...rolesIds, stateProps.selectedRole.id],
              },
            },
          });
        }
        for (const deleteUsr of deleteUsers) {
          const rolesRes = await props.client.query({
            query: GET_USER_ROLES,
            fetchPolicy: 'no-cache',
            variables: {
              id: deleteUsr.id,
            },
          });
          const rolesArr = pathOr([], ['data', 'users', 'getUserById', 'roles'], rolesRes);
          const rolesIds = rolesArr.map((roleItem) => {
            return roleItem.id;
          });
          await props.client.query({
            query: UPDATE_USERS,
            fetchPolicy: 'no-cache',
            variables: {
              id: deleteUsr.id,
              user: {
                roles: rolesIds.filter((role) => {
                  return role !== stateProps.selectedRole.id;
                }),
              },
            },
          });
        }
      }
      if (stateProps.newTitle || stateProps.newDescription) {
        const description = (stateProps.newTitle
          ? stateProps.newTitle
          : stateProps.selectedRole.description.split('&')[0]
          ? stateProps.selectedRole.description.split('&')[0]
          : ''
        ).concat(
          '&',
          stateProps.newDescription
            ? stateProps.newDescription
            : stateProps.selectedRole.description.split('&')[1]
            ? stateProps.selectedRole.description.split('&')[1]
            : ''
        );

        await props.client.query({
          query: UPDATE_ROLE,
          fetchPolicy: 'no-cache',
          variables: {
            id: stateProps.selectedRole.id,
            role: {
              description,
            },
          },
        });
      }
      if (stateProps.isNewPermissions) {
        await props.client.query({
          query: UPDATE_PERMISSIONS,
          fetchPolicy: 'no-cache',
          variables: {
            roleId: stateProps.selectedRole.id,
            permissions: stateProps.selectedRole.permissions,
          },
        });
      }

      message.success(t('modules.permissions_component.message_success.role_updated'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.permissions_component.message_error.update_error'));
    } finally {
      restoreState();
    }
  };
  const insertRole = async () => {
    try {
      const description = stateProps.newTitle.concat('&', stateProps.newDescription ? stateProps.newDescription : '');
      const roleRes = await props.client.query({
        query: INSERT_ROLE,
        fetchPolicy: 'no-cache',
        variables: {
          role: {
            name: transliterate(stateProps.newTitle.replace(/\s/g, '')),
            description,
          },
        },
      });
      const id = pathOr('', ['data', 'roles', 'insert', 'id'], roleRes);
      for (const newUsr of stateProps.newUserList) {
        const userRoleIds = newUsr.roles.map((item) => {
          return item.id;
        });
        await props.client.query({
          query: UPDATE_USERS,
          fetchPolicy: 'no-cache',
          variables: {
            id: newUsr.id,
            user: {
              roles: [...userRoleIds, id],
            },
          },
        });
      }
      if (stateProps.isNewPermissions) {
        await props.client.query({
          query: UPDATE_PERMISSIONS,
          fetchPolicy: 'no-cache',
          variables: {
            roleId: id,
            permissions: stateProps.selectedRole.permissions,
          },
        });
      }
      message.success(t('modules.permissions_component.message_success.role_created'));
    } catch (e) {
      message.error(t('modules.permissions_component.message_error.creation_error'));
      console.error(e);
    } finally {
      restoreState();
    }
  };
  const handleUserSearch = async (value) => {
    if (value) {
      const res = await props.client.query({
        query: SEARCH_USERS,
        variables: {
          quickSearch: value,
        },
        fetchPolicy: 'no-cache',
      });
      const list = pathOr([], ['data', 'users', 'getListAdvanced', 'list'], res);
      const mappedList = list.map((item) => {
        const contacts = pathOr([], ['person', 'contacts'], item);
        const email = contacts.find((item) => {
          return item.type === 'EMAIL';
        });
        return {
          value:
            LocalizedString.fromObject(pathOr('', ['person', 'surname'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'name'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'patronymic'], item)).toString(i18next.language),
          text:
            LocalizedString.fromObject(pathOr('', ['person', 'surname'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'name'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'patronymic'], item)).toString(i18next.language) +
            ' ' +
            pathOr('', ['login'], item) +
            ' ' +
            pathOr('', ['value'], email),
          userId: pathOr('', ['id'], item),
          name: pathOr('', ['person', 'name'], item),
          surname: pathOr('', ['person', 'surname'], item),
          patronymic: pathOr('', ['person', 'patronymic'], item),
          login: pathOr('', ['login'], item),
          email: pathOr('', ['value'], email),
          user: item,
          userName:
            LocalizedString.fromObject(pathOr('', ['person', 'surname'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'name'], item)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['person', 'patronymic'], item)).toString(i18next.language),
        };
      });
      setState({
        ...stateProps,
        userList: mappedList,
      });
    }
  };

  const renderOption = (item) => {
    return (
      <Option key={item.text} text={item.text} value={''} item={item}>
        <div
          className="permissions__right-container__body__autocomplete__option"
          onClick={() => {
            setState({
              ...stateProps,
              userList: [],
              isNewUser: true,
              newUserList: !isEmpty(stateProps.newUserList) ? [...stateProps.newUserList, item.user] : [item.user],
            });
          }}
        >
          <div className="permissions__right-container__body__autocomplete__option__name">{item.value}</div>
          <div className="permissions__right-container__body__autocomplete__option__info">
            <div className="permissions__right-container__body__autocomplete__option__item">
              <PhoneOutlined className="permissions__right-container__body__autocomplete__option__item__icon" />
              <div className="permissions__right-container__body__autocomplete__option__item__text">{item.login}</div>
            </div>
            <div className="permissions__right-container__body__autocomplete__option__item">
              <MailOutlined className="permissions__right-container__body__autocomplete__option__item__icon" />
              <div className="permissions__right-container__body__autocomplete__option__item__text">{item.email}</div>
            </div>
          </div>
        </div>
      </Option>
    );
  };

  useEffect(() => {
    const init = async () => {
      const roleRes = await props.client.query({
        query: GET_ROLES,
        fetchPolicy: 'no-cache',
      });

      const roleList = pathOr([], ['data', 'roles', 'getList'], roleRes);

      const filteredRoleList = roleList.filter((item) => {
        return item.description;
      });

      const servicesRes = await props.client.query({
        query: GET_SERVICES,
        fetchPolicy: 'no-cache',
      });

      const servicesList = pathOr([], ['data', 'services', 'getList'], servicesRes);

      let allPermissions = [];
      let allGroups = [];

      servicesList.forEach((item) => {
        item.permissions.forEach((prm) => {
          allPermissions.push(prm);
        });
        item.groups.forEach((group) => {
          allGroups.push(group);
        });
      });

      const allGrouplessPermissions = allPermissions.filter((prm) => {
        return !prm.groupName;
      });

      setState({
        ...stateProps,
        isLoaded: true,
        roleList: filteredRoleList,
        allPermissions,
        allGroups,
        allGrouplessPermissions,
        selectedRole: filteredRoleList[0] ? filteredRoleList[0] : {},
        isNewRole: false,
        newUserList: filteredRoleList[0] ? pathOr([], ['users'], filteredRoleList[0]) : [],
        newTitle: '',
        newDescription: '',
        userList: [],
        isNewUser: false,
        isNewPermissions: false,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  return (
    <>
      <h1>{t('modules.permissions_component.h1')}</h1>

      <div className="permissions">
        {stateProps.isLoaded ? (
          <>
            <div className="permissions__left-container">
              <div className="permissions__left-container__title">
                {t('modules.permissions_component.div.custom_roles')}
              </div>
              {stateProps.roleList.map((item) => {
                return (
                  <div
                    className={
                      stateProps.selectedRole.id === item.id
                        ? 'permissions__left-container__item-active'
                        : 'permissions__left-container__item'
                    }
                    onClick={() => {
                      setState({
                        ...stateProps,
                        selectedRole: item,
                        isNewRole: false,
                        newUserList: pathOr([], ['users'], item),
                        isUpdated: true,
                        newTitle: '',
                        newDescription: '',
                        userList: [],
                        isNewUser: false,
                        isNewPermissions: false,
                      });
                    }}
                  >
                    {item.description.split('&')[0]}
                  </div>
                );
              })}
              {stateProps.isNewRole && (
                <div className="permissions__left-container__item-active">
                  {t('modules.permissions_component.div.new_role')}
                </div>
              )}
            </div>
            {!isEmpty(stateProps.selectedRole) && !stateProps.isUpdated && (
              <div className="permissions__right-container">
                <div className="permissions__right-container__body">
                  <div className="permissions__right-container__body__switcher">
                    <div
                      className={
                        stateProps.isRoleVisible
                          ? 'permissions__right-container__body__switcher__item__active'
                          : 'permissions__right-container__body__switcher__item'
                      }
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isRoleVisible: true,
                        });
                      }}
                      style={{
                        borderRadius: '4px 0 0 4px',
                      }}
                    >
                      {t('modules.permissions_component.div.settings')}
                    </div>
                    <div
                      className={
                        stateProps.isRoleVisible
                          ? 'permissions__right-container__body__switcher__item'
                          : 'permissions__right-container__body__switcher__item__active'
                      }
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isRoleVisible: false,
                        });
                      }}
                      style={{
                        borderRadius: '0 4px 4px 0',
                      }}
                    >
                      {t('modules.permissions_component.div.users')}
                    </div>
                  </div>
                  {stateProps.isRoleVisible ? (
                    <>
                      <div className="permissions__right-container__body__title">
                        {t('modules.permissions_component.div.role_name')}
                      </div>
                      <input
                        className="permissions__right-container__body__input"
                        defaultValue={
                          stateProps.newTitle ? stateProps.newTitle : stateProps.selectedRole.description.split('&')[0]
                        }
                        placeholder={t('modules.permissions_component.input.enter_title')}
                        onBlur={(ev) => {
                          setState({
                            ...stateProps,
                            newTitle: ev.target.value,
                          });
                        }}
                      />
                      <div className="permissions__right-container__body__title">
                        {t('modules.permissions_component.div.role_description')}
                      </div>
                      <input
                        className="permissions__right-container__body__input"
                        defaultValue={
                          stateProps.newDescription
                            ? stateProps.newDescription
                            : stateProps.selectedRole.description.split('&')[1]
                        }
                        placeholder={t('modules.permissions_component.input.enter_description')}
                        onBlur={(ev) => {
                          setState({
                            ...stateProps,
                            newDescription: ev.target.value,
                          });
                        }}
                      />
                      {stateProps.allGroups.map((grp) => {
                        const title = pathOr(t('modules.permissions_component.path_or'), ['title'], grp);
                        return (
                          <div className="permissions__right-container__body__group">
                            <div className="permissions__right-container__body__group__title">
                              {LocalizedString.fromObject(title).toString(i18next.language)}
                            </div>
                            {stateProps.allPermissions.map(
                              (prm) =>
                                prm.groupName === grp.name && (
                                  <div className="permissions__right-container__body__group__checkbox-container">
                                    <Checkbox
                                      defaultChecked={stateProps.selectedRole.permissions.find((permission) => {
                                        return permission === prm.name;
                                      })}
                                      className="permissions__right-container__body__group__checkbox"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setState({
                                            ...stateProps,
                                            isNewPermissions: true,
                                            selectedRole: {
                                              ...stateProps.selectedRole,
                                              permissions: !isEmpty(stateProps.selectedRole.permissions)
                                                ? [...stateProps.selectedRole.permissions, prm.name]
                                                : [prm.name],
                                            },
                                          });
                                        } else {
                                          setState({
                                            ...stateProps,
                                            isNewPermissions: true,
                                            selectedRole: {
                                              ...stateProps.selectedRole,
                                              permissions: stateProps.selectedRole.permissions.filter((permission) => {
                                                return permission !== prm.name;
                                              }),
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      {prm.description
                                        ? LocalizedString.fromObject(prm.description).toString(i18next.language)
                                        : prm.name}
                                    </Checkbox>
                                  </div>
                                )
                            )}
                          </div>
                        );
                      })}
                      {!isEmpty(stateProps.allGrouplessPermissions) && (
                        <div className="permissions__right-container__body__group">
                          <div className="permissions__right-container__body__group__title">
                            {t('modules.permissions_component.div.other_roles')}
                          </div>
                          {stateProps.allGrouplessPermissions.map((prm) => {
                            return (
                              <div className="permissions__right-container__body__group__checkbox-container">
                                <Checkbox
                                  defaultChecked={stateProps.selectedRole.permissions.find((permission) => {
                                    return permission === prm.name;
                                  })}
                                  className="permissions__right-container__body__group__checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setState({
                                        ...stateProps,
                                        isNewPermissions: true,
                                        selectedRole: {
                                          ...stateProps.selectedRole,
                                          permissions: !isEmpty(stateProps.selectedRole.permissions)
                                            ? [...stateProps.selectedRole.permissions, prm.name]
                                            : [prm.name],
                                        },
                                      });
                                    } else {
                                      setState({
                                        ...stateProps,
                                        isNewPermissions: true,
                                        selectedRole: {
                                          ...stateProps.selectedRole,
                                          permissions: stateProps.selectedRole.permissions.filter((permission) => {
                                            return permission !== prm.name;
                                          }),
                                        },
                                      });
                                    }
                                  }}
                                >
                                  {prm.description.ru ? prm.description.ru : prm.name}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <AutoComplete
                        className="permissions__right-container__body__autocomplete"
                        placeholder={t('modules.permissions_component.autocomplete_placeholder')}
                        dataSource={[...stateProps.userList.map(renderOption)]}
                        optionLabelProp="value"
                        defaultActiveFirstOption={false}
                        onSearch={debounce(handleUserSearch, 500)}
                        filterOption={() => {
                          return true;
                        }}
                      >
                        <Input suffix={<SearchOutlined />} />
                      </AutoComplete>

                      {stateProps.newUserList.map((usr) => {
                        const userName =
                          LocalizedString.fromObject(pathOr('', ['person', 'surname'], usr)).toString(i18next.language) +
                          ' ' +
                          LocalizedString.fromObject(pathOr('', ['person', 'name'], usr)).toString(
                            i18next.language
                          ) +
                          ' ' +
                          LocalizedString.fromObject(pathOr('', ['person', 'patronymic'], usr)).toString(
                            i18next.language
                          );
                        return (
                          <div className="permissions__right-container__body__user">
                            <div className="permissions__right-container__body__user__text">
                              {userName.trim() ? userName : usr.login}
                            </div>
                            <CloseOutlined
                              onClick={() => {
                                setState({
                                  ...stateProps,
                                  isNewUser: true,
                                  newUserList: stateProps.newUserList.filter((item) => {
                                    return item.id !== usr.id;
                                  }),
                                });
                              }}
                              className="permissions__right-container__body__user__icon"
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="permissions__right-container__footer">
                  <Button
                    id="PermissionsComponentSaveButton"
                    type="primary"
                    className="permissions__right-container__footer__button"
                    onClick={() => {
                      if (!stateProps.isNewRole) {
                        if (isEmpty(stateProps.newUserList)) {
                          message.error(t('modules.permissions_component.message_error.assign_new_users'));
                        } else {
                          setState({
                            ...stateProps,
                            isUpdatingProcessing: true,
                          });
                          updateRole();
                        }
                      } else {
                        if (stateProps.newTitle && !isEmpty(stateProps.newUserList)) {
                          setState({
                            ...stateProps,
                            isUpdatingProcessing: true,
                          });
                          insertRole();
                        } else if (!stateProps.newTitle) {
                          message.error(t('modules.permissions_component.message_error.enter_role_name'));
                        } else if (isEmpty(stateProps.newUserList)) {
                          message.error(t('modules.permissions_component.message_error.assign_new_users'));
                        }
                      }
                    }}
                  >
                    {stateProps.isUpdatingProcessing ? (
                      <LoadingOutlined />
                    ) : stateProps.isNewRole ? (
                      t('modules.permissions_component.button.save_role')
                    ) : (
                      t('modules.permissions_component.button.save_changes')
                    )}
                  </Button>
                  {!stateProps.isNewRole && (
                    <Button
                      id="PermissionsComponentDeleteRoleButton"
                      type="primary"
                      className="permissions__right-container__footer__delete"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDeleteModalVisible: true,
                        });
                      }}
                    >
                      {t('modules.permissions_component.button.delete_role')}
                    </Button>
                  )}
                </div>
              </div>
            )}
            <Button
              id="PermissionsComponentAddRoleButton"
              className="sales__price-template__add-template"
              onClick={() => {
                setState({
                  ...stateProps,
                  isNewRole: true,
                  isUpdated: true,
                  selectedRole: { description: '', permissions: [] },
                  newUserList: [],
                });
              }}
            >
              <PlusOutlined className="sales__price-template__add-template__icon" />
              <span className="sales__price-template__add-template__text">
                {t('modules.permissions_component.span')}
              </span>
            </Button>
            <Modal
              visible={stateProps.isDeleteModalVisible}
              centered
              closable={false}
              onCancel={() => {
                setState({
                  ...stateProps,
                  isDeleteModalVisible: false,
                });
              }}
              width={415}
              footer={null}
            >
              <>
                <div className="sales__tickets__list-table__accept-modal__text-container">
                  <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
                  <div className="sales__tickets__list-table__accept-modal__text">
                    {t('modules.permissions_component.div.are_you_sure')}
                  </div>
                </div>
                <div className="sales__tickets__list-table__accept-modal__button-container">
                  <Button
                    id="PermissionsComponentCancelButton"
                    className="sales__tickets__list-table__accept-modal__decline-button"
                    onClick={() => {
                      setState({
                        ...stateProps,
                        isDeleteModalVisible: false,
                      });
                    }}
                  >
                    {t('modules.permissions_component.button.cancel')}
                  </Button>
                  <Button
                    id="PermissionsComponentDeleteButton"
                    className="sales__tickets__list-table__accept-modal__accept-button"
                    onClick={() => {
                      setState({
                        ...stateProps,
                        isDeleteProcessing: true,
                      });
                      deleteRole();
                    }}
                  >
                    {stateProps.isDeleteProcessing ? (
                      <LoadingOutlined />
                    ) : (
                      t('modules.permissions_component.button.delete')
                    )}
                  </Button>
                </div>
              </>
            </Modal>
          </>
        ) : (
          <div className="with-preloader__map">
            <LoadingOutlined />
            <div className="with-preloader__info"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PermissionsComponent;
