export type PaginatedResult<T> = {
  total: number;
  list: T[];
};

/**
 * Converts a string to an enum value and throws an exception if the value is not in the enum
 *
 * The reverse conversion of an enum to a string is done like this:
 * ```
 * MyEnum[MyEnum.VALUE] >> "VALUE"
 * ```
 *
 * @param type type enum
 * @param str value
 */
export function enumFromString<T>(type: T, str: string): T[keyof T] {
  const casted = str as keyof T;
  const result = type[casted];
  if (result === undefined) {
    throw new Error(`<a0bd549e> Unregistered enum value: ${str}`);
  }
  return result;
}

/**
 * Converting a numeric enum value to a string.
 *
 * You can also convert like this:
 * ```
 * MyEnum[MyEnum.VALUE] >> "VALUE"
 * ```
 *
 * @param enumObject - type enum
 * @param value - value enum
 */
export function enumToString(enumObject: { [s: number]: string }, value: number): string {
  return enumObject[value];
}

/**
 * Array conversion. Groups an array by a specific key
 *
 * [key: string]: Object
 *
 * @param list - array
 * @param fieldGetter - a function to get the grouping value by which the grouping occurs
 */
export type FieldGetter<T, R> = (item: T) => R;

export function groupBy<T, R>(list: T[], fieldGetter: FieldGetter<T, R>): Map<R, T[]> {
  return list.reduce((previous, current) => {
    const key = fieldGetter(current);
    if (previous.has(key)) {
      const existed = previous.get(key)!!;
      previous.set(key, existed?.concat([current]));
    } else {
      previous.set(key, [current]);
    }
    return previous;
  }, new Map<R, T[]>());
}

export function mapToArray(value: Map<string, any>) {
  return Array.from(value.entries()).map((array) => {
    return { key: array[0], value: array[1] };
  });
}
