import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { ADD_TEAM, GET_TEAMS_INFO_LIST, REMOVE_TEAM, UPDATE_TEAM } from '../graphql';
import { withApollo } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from 'utils/LocalizedString';
import i18next from 'i18next';
import { LocalizedTextInput } from 'common/LocalizedTextInput';
import { defaultRestrictionRules } from 'common/LocalizedTextInput/LocalizedTextInput.types';
import styles from './TeamsComponent.module.scss';
import { acquireFile, getFileDescriptor, releaseFile } from '../graphql/utils/fileHandlers';

const TeamsComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    selectedTeam: {},
    newData: {},
  });
  const [formHasErrors, setFormHasErrors] = useState(undefined);

  const restoreState = () => {
    setState({
      selectedTeam: {},
    });
  };

  // Only for single boolean state property switching
  const switchStatus = (prop, value) => {
    const currentValue = stateProps[prop];
    const isManual = value !== undefined;
    if (currentValue !== value || !isManual) {
      setState({
        ...stateProps,
        [prop]: isManual ? value : !currentValue,
      });
    }
  };

  const addTeam = async () => {
    try {
      await props.client.query({
        query: ADD_TEAM,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            title: stateProps.newTitle,
            location: stateProps.newLocation,
            logoFileId: stateProps.logoFileId,
          },
        },
      });
      if (stateProps.logoFileId) {
        await acquireFile(props.client, stateProps.logoFileId);
      }
      message.success(t('modules.teams_component.message_success.create'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.teams_component.message_error.create'));
    } finally {
      restoreState();
    }
  };

  const updateTeam = async () => {
    try {
      await props.client.query({
        query: UPDATE_TEAM,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedTeam.id,
          data: {
            title: stateProps.newTitle,
            location: stateProps.newLocation,
            logoFileId: stateProps.logoFileId,
          },
        },
      });
      if (stateProps.logoFileId && stateProps.selectedTeam.logo.id !== stateProps.logoFileId) {
        await releaseFile(props.client, stateProps.selectedTeam.logo.id);
        await acquireFile(props.client, stateProps.logoFileId);
      }
      message.success(t('modules.teams_component.message_success.update'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.teams_component.message_error.update'));
    } finally {
      restoreState();
    }
  };

  const removeTeam = async () => {
    try {
      await props.client.query({
        query: REMOVE_TEAM,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedTeam.id,
        },
      });

      if (stateProps.selectedTeam.logo !== null) {
        await releaseFile(
          props.client,
          stateProps.selectedTeam.logo.id,
          t('modules.teams_component.message_error.logo_delete')
        );
      }
      message.success(t('modules.teams_component.message_success.remove'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.teams_component.message_error.delete'));
    } finally {
      restoreState();
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      switchStatus('fileUploading', true);
    }

    if (
      pathOr('', ['file', 'status'], info) === 'done' &&
      pathOr('', ['file', 'response', 'result'], info) === 'SUCCESS'
    ) {
      try {
        const fileId = pathOr([''], ['file', 'response', 'fileIds'], info)[0];
        const { publicUrl } = await getFileDescriptor(props.client, fileId);
        setState({
          ...stateProps,
          imageLink: publicUrl,
          fileUploading: false,
          logoFileId: fileId,
        });
      } catch (e) {
        console.error(e);
        message.error(t('modules.teams_component.message_error.upload'));
      }
    }
  };

  const handleRemove = async () => {
    switchStatus('isRemovingProcessing', true);
    await removeTeam();
  };

  const newTeamProps = {
    isNewTeam: true,
    selectedTeam: {},
    newTitle: null,
    newLocation: null,
    fileId: null,
    imageLink: null,
  };

  useEffect(() => {
    const init = async () => {
      const teamsRes = await props.client.query({
        query: GET_TEAMS_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const teamsArr = pathOr([], ['data', 'teamLocalised', 'getList', 'list'], teamsRes);
      setState({
        ...stateProps,
        teamsArr,
        isLoaded: true,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      switchStatus('isUpdated', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  const inputRestrictions = {
    min: 2,
    max: 80,
  };

  return (
    <>
      <h1>{t('modules.teams_component.h1')}</h1>
      <div className="events">
        {stateProps.isLoaded ? (
          <>
            <div className="events__left-container">
              {stateProps.isNewTeam && (
                <div className="events__left-container__item-active">
                  <div className="events__left-container__item__text">{t('modules.teams_component.div.new_team')}</div>
                </div>
              )}
              {stateProps.teamsArr.map((team) => {
                const isTeamSelected = stateProps.selectedTeam && stateProps.selectedTeam.id === team.id;
                return (
                  <div
                    key={team.id}
                    className={isTeamSelected ? 'events__left-container__item-active' : 'events__left-container__item'}
                    onClick={() => {
                      setState({
                        ...stateProps,
                        selectedTeam: team,
                        isUpdated: true,
                        isNewTeam: false,
                        newTitle: null,
                        newLocation: null,
                        fileId: null,
                        imageLink: null,
                      });
                    }}
                  >
                    <div
                      className="events__left-container__item__circle"
                      style={{
                        backgroundImage: team.logo ? 'url(' + team.logo.publicLink + ')' : '',
                      }}
                    />
                    <div className="events__left-container__item__text">
                      {LocalizedString.fromObject(team.title).toString(i18next.language)}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* //////// */}

            {(!isEmpty(stateProps.selectedTeam) || stateProps.isNewTeam === true) && !stateProps.isUpdated && (
              <div className="events__right-container">
                <div className="events__right-container__body">
                  <CloseOutlined
                    className="permissions__right-container__body__close-form-icon"
                    onClick={() => {
                      restoreState();
                    }}
                  />
                  <div className="events__right-container__body__title-first">
                    {t('modules.teams_component.div.logo')}
                  </div>
                  <div className="events__right-container__body__logo">
                    <Upload
                      action={window.parametrize('REACT_APP_FILE_STORAGE')}
                      headers={{
                        authorization: 'Bearer ' + props.authClient.credentials.idToken,
                      }}
                      name="team-logo"
                      accept=".jpg, .png"
                      showUploadList={false}
                      onChange={handleChange}
                    >
                      <Button id="TeamsComponentImgButton" className="events__right-container__body__logo__upload">
                        {stateProps.fileUploading ? (
                          <LoadingOutlined />
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 20V0M0 10L20 10" stroke="white" strokeWidth="4" />
                          </svg>
                        )}
                      </Button>
                    </Upload>

                    {stateProps.selectedTeam && stateProps.selectedTeam.logo && (
                      <div
                        className="events__right-container__body__logo__img"
                        style={{
                          backgroundImage: 'url(' + stateProps.selectedTeam.logo.publicLink + ')',
                        }}
                      />
                    )}

                    {stateProps.imageLink && (
                      <div
                        className="events__right-container__body__logo__img"
                        style={{
                          backgroundImage: 'url(' + stateProps.imageLink + ')',
                        }}
                      />
                    )}
                  </div>
                  <div className="events__right-container__body__title">
                    {t('modules.teams_component.div.team_name')}
                  </div>
                  <LocalizedTextInput
                    inputName={'team'}
                    labelCustomization={(lng) => `${lng}: `}
                    placeholder={t('modules.teams_component.input.enter_title')}
                    value={LocalizedString.fromObject(stateProps.selectedTeam.title)}
                    onChange={(value) => {
                      value &&
                        setState({
                          ...stateProps,
                          newTitle: value.toObject(),
                        });
                    }}
                    onError={(hint) => {
                      setFormHasErrors(stateProps.newTitle ? !!hint : true);
                    }}
                    columns={1}
                    direction="column"
                    labelPosition="left"
                    justify="start"
                    validation={{
                      required: { value: true, message: t('common.validation.required') },
                      restrict: { value: true, rule: defaultRestrictionRules },
                      length: {
                        min: inputRestrictions.min,
                        max: inputRestrictions.max,
                        message: `
                            ${t('common.validation.length.from')}
                            ${inputRestrictions.min}
                            ${t('common.validation.length.to')}
                            ${inputRestrictions.max}
                            ${t('common.validation.length.symbols')}
                          `,
                      },
                    }}
                    wrapperClasses={styles.customWrapper}
                  />
                  <div className="events__right-container__body__title">
                    {t('modules.teams_component.div.team_from')}
                  </div>
                  <LocalizedTextInput
                    inputName={'location'}
                    labelCustomization={(lng) => `${lng}: `}
                    placeholder={t('modules.teams_component.input.country_city')}
                    value={LocalizedString.fromObject(stateProps.selectedTeam.location)}
                    onChange={(value) => {
                      value &&
                        setState({
                          ...stateProps,
                          newLocation: value.toObject(),
                        });
                    }}
                    columns={1}
                    direction="column"
                    labelPosition="left"
                    justify="start"
                    wrapperClasses={styles.customWrapper}
                  />
                </div>

                <div className="events__right-container__footer">
                  <Button
                    id="TeamsComponentSaveButton"
                    type="primary"
                    className="permissions__right-container__footer__button"
                    disabled={!!formHasErrors}
                    onClick={() => {
                      if (!stateProps.isNewRole) {
                        switchStatus('isUpdatingProcessing', true);
                        if (stateProps.isNewTeam) {
                          if (stateProps.newTitle) {
                            addTeam();
                          } else {
                            message.error(t('modules.teams_component.message_error.team_name'));
                            switchStatus('isUpdatingProcessing', false);
                          }
                        } else {
                          updateTeam();
                        }
                      }
                    }}
                  >
                    {stateProps.isUpdatingProcessing ? (
                      <LoadingOutlined />
                    ) : stateProps.isNewTeam ? (
                      t('modules.teams_component.button.new_team')
                    ) : (
                      t('modules.teams_component.button.team_update')
                    )}
                  </Button>
                  {stateProps.isNewTeam === false ? (
                    <Button
                      danger
                      id="TeamsComponentRemoveButton"
                      type="primary"
                      className="permissions__right-container__footer__delete"
                      onClick={() => handleRemove()}
                    >
                      {stateProps.isRemovingProcessing ? (
                        <LoadingOutlined />
                      ) : (
                        t('modules.teams_component.button.team_delete')
                      )}
                    </Button>
                  ) : null}
                </div>
              </div>
            )}
            <Button
              id="TeamsComponentAddTeamButton"
              className="sales__price-template__add-template"
              onClick={() => setState({ ...stateProps, isUpdated: true, ...newTeamProps })}
            >
              <PlusOutlined className="sales__price-template__add-template__icon" />
              <span className="sales__price-template__add-template__text">{t('modules.teams_component.span')}</span>
            </Button>
          </>
        ) : (
          <div className="with-preloader__map">
            <LoadingOutlined />
            <div className="with-preloader__info"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default withApollo(TeamsComponent);
