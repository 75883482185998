import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Input, DatePicker, Select, Button } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import assocPath from 'ramda/es/assocPath';
import { getCodes } from '../../PromoModals/PromoCodesModal/events/queryEvents';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

const PromoAddBasic = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, isEditingMode } = props;
  const { client, defaultPromoId, setCodesToReducer } = props;

  const getCurrentValuePeriod = () => {
    if (values.period === null) {
      return;
    } else if (dayjs(values.period[0]).format('YYYY') === '1970') {
      return [null, null];
    } else {
      return values.period;
    }
  };
  return (
    <div className="promo-add__basic">
      <Card title={t('modules.promo_add_basic.card')}>
        {/* <Row>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.code_group')}>
              <Select
                defaultValue="0"
                value={values.groupType}
                onChange={e => setFieldValue('groupType', e)}
              >
                <Option key="0">{t('modules.promo_add_basic.option.new_group')}</Option>
                <Option key="1">{t('modules.promo_add_basic.option.first_group')}</Option>
                <Option key="2">{t('modules.promo_add_basic.option.second_group')}</Option>
              </Select>
            </Form.Item>
          </Col>
          {values.groupType === '0' && (
            <Col span={8}>
              <Form.Item>
                <Input placeholder={t('modules.promo_add_basic.input.group_name')} />
              </Form.Item>
            </Col>
          )}
        </Row> */}
        <Row>
          {!isEditingMode && (
            <Col span={8}>
              <Form.Item label={t('modules.promo_add_basic.form_item.code_type')}>
                <Select
                  disabled={isEditingMode}
                  defaultValue="0"
                  value={values.promoType}
                  onChange={(e) => setFieldValue('promoType', e)}
                >
                  <Option key="0">{t('modules.promo_add_basic.option.with_prefix')}</Option>
                  <Option key="1">{t('modules.promo_add_basic.option.no_prefix')}</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {values.promoType === '0' && (
            <>
              <Col span={8}>
                <Form.Item label={t('modules.promo_add_basic.form_item.prefix')}>
                  <Input
                    disabled={isEditingMode}
                    placeholder="PROMO"
                    value={values.promoPrefixValue}
                    onChange={(e) => setFieldValue('promoPrefixValue', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t('modules.promo_add_basic.form_item.code_set')}>
                  {!isEditingMode && (
                    <Input
                      disabled={isEditingMode}
                      className="promo-add__promo-count"
                      value={values.promoCount}
                      onChange={(e) => setFieldValue('promoCount', e.target.value)}
                    />
                  )}
                  {isEditingMode && (
                    <Button
                      id="PromoAddBasicEditButton"
                      type="default"
                      onClick={() => {
                        getCodes({ client, setCodesToReducer, defaultPromoId });
                        props.openCodesModal(true);
                      }}
                      className="promo-add__edit-codes-btn"
                    >
                      {t('modules.promo_add_basic.button')}
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </>
          )}
          {values.promoType === '1' && (
            <>
              <Col span={8}>
                <Form.Item label={t('modules.promo_add_basic.form_item.promocode')}>
                  <Input
                    placeholder={t('modules.promo_add_basic.input.promocode')}
                    value={values.promoValue}
                    onChange={(e) => setFieldValue('promoValue', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.amount_discount')}>
              <Row style={{ padding: 0 }}>
                <Col span={16} style={{ paddingRight: 10 }}>
                  <Input value={values.saleValue} onChange={(e) => setFieldValue('saleValue', e.target.value)} />
                </Col>
                <Col span={8}>
                  <Select
                    defaultValue="PERCENT"
                    value={values.saleType}
                    onChange={(e) => {
                      setFieldValue('saleType', e);
                      setFieldValue('useOn', 'ITEM');
                    }}
                  >
                    <Option key="PERCENT">%</Option>
                    <Option key="ABSOLUTE">{t('currency')}</Option>
                    <Option key="FIXED_PRICE">Fix</Option>
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.operations_code')}>
              <Input
                className="promo-add__activation-count"
                value={values.saleCount}
                onChange={(e) => setFieldValue('saleCount', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('modules.promo_add_basic.form_item.operations_account')}>
              <Input
                className="promo-add__activation-count"
                value={values.maxUseCountsPerUser}
                onChange={(e) => setFieldValue('maxUseCountsPerUser', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.code_validity_period')}>
              <Select defaultValue="0" value={values.periodType} onChange={(e) => setFieldValue('periodType', e)}>
                <Option key="0">{t('modules.promo_add_basic.option.limited')}</Option>
                <Option key="1">{t('modules.promo_add_basic.option.unlimited')}</Option>
              </Select>
            </Form.Item>
          </Col>
          {values.periodType === '0' && (
            <Col span={8}>
              <Form.Item label={t('modules.promo_add_basic.form_item.period')}>
                <RangePicker
                  value={getCurrentValuePeriod()}
                  format={'DD.MM.YYYY'}
                  onChange={(e) => setFieldValue('period', e)}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.user_group')}>
              <Select defaultValue="0" value={values.userType} onChange={(e) => setFieldValue('userType', e)}>
                <Option key="0">{t('modules.promo_add_basic.option.all_users')}</Option>
                <Option key="1">{t('modules.promo_add_basic.option.admin')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('modules.promo_add_basic.form_item.use_on')}>
              <Select defaultValue="ITEM" disabled value={values.useOn}>
                <Option key="ITEM">{t('modules.promo_add_basic.option.order_element')}</Option>
                <Option key="ORDER">{t('modules.promo_add_basic.option.order_total')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PromoAddBasic;
