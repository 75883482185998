import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Input } from 'antd';

const InputComponent = ({
  handleSearchChange,
  inputClassName,
  iconStyle,
  inputPlaceholder,
  inputSize,
  inputMaxLength,
  inputTimeout,
  allowClear,
  inputContainerStyle,
  prefixIconType,
  suffixIconType,
  inputDefaultValue,
  iconOnClick,
  suffixText
}) => {
  const [timerId, setTimerId] = useState(null);

  const delayedSearch = ev => {
    const inputValue = ev.target.value;
    window.clearTimeout(timerId);
    setTimerId(
      window.setTimeout(() => {
        handleSearchChange(inputValue);
        // setDataSourceState(props)
      }, inputTimeout)
    );
  };
  const suffix = suffixText ? (
    suffixText
  ) : (
    <LegacyIcon type={suffixIconType} style={iconStyle} onClick={iconOnClick} />
  );
  return <>
    <div style={inputContainerStyle}>
      <Input
        placeholder={inputPlaceholder}
        className={inputClassName}
        size={inputSize}
        defaultValue={inputDefaultValue}
        prefix={<LegacyIcon type={prefixIconType} style={iconStyle} />}
        suffix={suffix}
        onChange={delayedSearch}
        maxLength={inputMaxLength}
        allowClear={allowClear}
      ></Input>
    </div>
  </>;
};

export default InputComponent;
