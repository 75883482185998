import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { enhancer } from './store/configureStore';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IRouteContainer, IApp, ModuleRoute, IReducerContainer } from '../../common/src';
import { ApolloLink } from 'apollo-link';

import Axios from 'axios';

import { parametrize } from '../../common/src/common/Parametrize';

import { getErrorMessage } from './dictionary';

import { pathOr, isEmpty } from 'ramda';
import { ConfigProvider, notification } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import contains from 'ramda/es/contains';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { t } from 'i18next';
import { getSupportedLngs } from 'i18n';

export class Application implements IApp, IRouteContainer {
  routes: ModuleRoute[];
  reducers: IReducerContainer[];
  store: any;
  graphQLErrors: any[];
  networkErrors: any[];

  constructor() {
    this.routes = [];
    this.reducers = [];
    this.graphQLErrors = [];
    this.networkErrors = [];
  }

  addRoute(route: ModuleRoute) {
    this.routes.push(route);
  }

  addReducer(reducer: any) {
    this.reducers.push(reducer);
  }

  configureStore(initialState: any) {
    const obj: any = {};
    this.reducers.forEach((item: any) => (obj[item.name] = item.reducer));
    const reducer = combineReducers(obj);
    return createStore(combineReducers({ reducer }), initialState, enhancer);
  }

  setGraphQLErrors = (arr: any) => {
    this.graphQLErrors = arr;
  };
  setNetworkErrors = (arr: any) => {
    this.networkErrors = arr;
  };

  start(authClient: AuthClient, refreshAuthLink?: any) {
    this.store = this.configureStore({});

    const currentLngs = getSupportedLngs();
    const token = authClient.credentials ? authClient.credentials.idToken : '';

    const httpLink = {
      uri: parametrize('REACT_APP_GRAPHQL_ENDPOINT'),
    };

    const errorHandlerLink = onError((props) => {
      if (pathOr(false, ['graphQLErrors', 'length'], props)) {
        this.setGraphQLErrors(props.graphQLErrors);
        pathOr([], ['graphQLErrors'], props).forEach((error: any) => {
          const message = getErrorMessage(error);
          if (contains('e8d21919', error.message)) {
            authClient.logout();
            window.location.reload();
          }
          if (!message.includes('uid=9bd114c9')) {
            notification.open({
              message: t('modules.app_index'),
              description: message,
            });
          }
        });
      }
      if (!isEmpty(props.networkError)) {
        const message = pathOr(false, ['networkError', 'message'], props);
        if (message) {
          notification.open({
            message: 'NetworkError',
            description: getErrorMessage(props.networkError),
          });
        }
      }
    });
    const middlewareLink = new ApolloLink((operation, forward: any) => {
      operation.setContext({
        headers: {
          authorization: authClient.credentials ? 'Bearer ' + authClient.credentials.idToken : '',
          'Accept-Language': currentLngs,
        },
      });
      return forward(operation);
    });

    const link = new HttpLink(httpLink);

    const client = new ApolloClient({
      link: ApolloLink.from([errorHandlerLink, refreshAuthLink, middlewareLink, link]),
      cache: new InMemoryCache(),
    });

    //TODO Remove Axios from wherever it is

    Axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };

    const AppComponent = () => (
      <Provider store={this.store}>
        <ApolloProvider client={client}>
          <ConfigProvider locale={ruRu}>
            <Router>
              <Switch>
                {this.routes.map((route: any, i: number) => {
                  return <Route key={i} path={route.path} component={route.component} />;
                })}
              </Switch>
            </Router>
          </ConfigProvider>
        </ApolloProvider>
      </Provider>
    );

    ReactDOM.render(<AppComponent />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
}
