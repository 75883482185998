import gql from 'graphql-tag';

export const GET_STADIUMS = gql`
  query getStadiums {
    venueLocalised {
      getList {
        list {
          id
          title
          size {
            height
            width
          }
          map {
            publicLink
          }
          places(paging: { limit: 900000 }) {
            list {
              id
              number
              coordinates {
                x
                y
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_STADIUMS_EXTRA_INFO = gql`
  query {
    venueLocalised {
      getList {
        list {
          id
          title
          sectors {
            list {
              id
              title
              places(paging: { limit: 9000 }) {
                list {
                  id
                }
              }
            }
          }
          size {
            height
            width
          }
          map {
            publicLink
          }
          places(paging: { limit: 900000 }) {
            list {
              id
              number
              coordinates {
                x
                y
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PRICE_CATEGORIES = gql`
  query {
    priceCategoryTemplate {
      getList(paging: { limit: 9000 }) {
        list {
          title
          id
        }
      }
    }
  }
`;
export const GET_PRICE_CATEGORY_INFO = gql`
  query($id: ID!) {
    priceCategoryTemplate {
      getById(id: $id) {
        priceCategories {
          id
          title
          color
          places {
            id
          }
        }
      }
    }
  }
`;
export const GET_PLACE_BY_ID = gql`
  query($id: ID!) {
    place {
      getById(id: $id) {
        row {
          number
          sector {
            title
          }
        }
      }
    }
  }
`;
export const GET_TICKET_INFO = gql`
  query($id: ID!) {
    ticket {
      getById(id: $id) {
        visibleId
        order {
          visibleId
        }
        event {
          title
          startTime
        }
      }
    }
  }
`;
export const GET_SEASON_TICKET_INFO = gql`
  query($id: ID!) {
    seasonTicket {
      getById(id: $id) {
        visibleId
        order {
          visibleId
        }
        descriptor {
          title
        }
      }
    }
  }
`;
export const GET_PLACE_BLOCK_INFO = gql`
  query($filter: PlaceStatusFilter!) {
    placeStatus {
      getStatusList(filter: $filter) {
        placeId
        statusList {
          placeId
          blockerId
          venueId
          blockerItemId
          period {
            start
            end
          }
          blockerType
          status
          comment
          gracePeriod {
            start
            end
          }
          createdTime
        }
      }
    }
  }
`;
export const GET_USER_ID = gql`
  query {
    users {
      getProfile {
        id
      }
    }
  }
`;
