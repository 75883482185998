import React, { useState, useEffect } from 'react';
import { pathOr, T } from 'ramda';

import { CloseCircleOutlined, CloseOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Modal, Calendar, Button, message, Select, Row, Col } from 'antd';
import {
  GET_PLACE_BY_ID,
  GET_PLACE_BLOCK_INFO,
  CANCEL_PLACE_BLOCK,
  GET_USER_ID,
  BLOCK_PLACE,
  GET_TICKET_INFO,
  GET_SEASON_TICKET_INFO,
} from '../graphql';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'ramda/es/isEmpty';
import './index.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const HistorySideMenu = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    isLoaded: false,
    blockInfo: {},
    commentary: '',
  });
  const [errors, setErrors] = useState({});

  const returnDroppedDate = (ev) => {
    const date = new Date(Date.parse(ev));
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const tryToSetDate = (string, key) => {
    if (string.length === 10) {
      const stringArr = string.split('.');
      const dateString = stringArr[1] + '.' + stringArr[0] + '.' + stringArr[2];
      const date = new Date(dateString);

      if (date.getDate()) {
        setState({
          ...stateProps,
          blockDate: {
            ...stateProps.blockDate,
            [key]: dateString,
          },
        });
      } else {
        setErrors({
          ...errors,
          [key]: true,
        });
      }
    } else if (errors[key]) {
      setErrors({
        ...errors,
        [key]: false,
      });
    }
  };

  const tryToSetTime = (string, key) => {
    if (string.length === 5) {
      const stringArr = string.split(':').map((item) => parseInt(item));
      if (stringArr[0] <= 23 && stringArr[1] <= 59) {
        setState({
          ...stateProps,
          blockDate: {
            ...stateProps.blockDate,
            [key]: string,
          },
        });
      } else {
        setErrors({
          ...errors,
          [key]: true,
        });
      }
    } else if (errors[key]) {
      setErrors({
        ...errors,
        [key]: false,
      });
    }
  };

  const blockPlace = async (singleBlock) => {
    try {
      const userRes = await props.client.query({
        query: GET_USER_ID,
        fetchPolicy: 'no-cache',
      });

      const userId = pathOr('', ['data', 'users', 'getProfile', 'id'], userRes);

      const dateStart = singleBlock
        ? new Date(
            new Date(new Date(new Date(stateProps.blockDate).setHours(0)).setMinutes(0)).setSeconds(0)
          ).toISOString()
        : new Date(stateProps.blockDate.startDate + ' ' + (stateProps.blockDate.startTime || '')).toISOString();

      const dateEnd = singleBlock
        ? new Date(
            new Date(new Date(new Date(stateProps.blockDate).setHours(23)).setMinutes(59)).setSeconds(0)
          ).toISOString()
        : new Date(stateProps.blockDate.endDate + ' ' + (stateProps.blockDate.endTime || '23:59')).toISOString();

      const uniqId = uuidv4();

      await props.client.query({
        query: BLOCK_PLACE,
        variables: {
          data: stateProps.placeIds.map((item) => {
            return {
              blockerType: 'ADMIN',
              status: 'BLOCKED',
              blockerId: userId,
              blockerItemId: uniqId,
              placeId: item,
              comment: stateProps.commentary ? stateProps.commentary : undefined,
              venueId: props.stadiumid,
              period: {
                start: dateStart,
                end: dateEnd,
              },
            };
          }),
        },
        fetchPolicy: 'no-cache',
      });

      message.success(t('modules.history_side_menu.message_success.place_blocked'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.history_side_menu.message_error.place_lock_error'));
    } finally {
      props.onClose();
    }
  };

  const cancelPlaceBlock = async () => {
    try {
      await props.client.query({
        query: CANCEL_PLACE_BLOCK,
        variables: {
          data: [
            {
              blockerType: stateProps.selectedBlock.blockerType,
              status: 'ACTIVE',
              placeId: stateProps.selectedBlock.placeId,
              venueId: stateProps.selectedBlock.venueId,
              period: {
                start: stateProps.selectedBlock.period.start,
                end: stateProps.selectedBlock.period.end,
              },
            },
          ],
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.history_side_menu.message_success.location_unlocked'));
      props.onClose();
    } catch (e) {
      message.error(t('modules.history_side_menu.message_error.place_unlock_error'));
      console.error(e);
      props.onClose();
    }
  };

  const getBlockInfo = async (block, blockDate) => {
    try {
      if (block.blockerType === 'TICKET') {
        const ticketRes = await props.client.query({
          query: GET_TICKET_INFO,
          fetchPolicy: 'no-cache',
          variables: {
            id: block.blockerId,
          },
        });
        const ticket = pathOr({}, ['data', 'ticket', 'getById'], ticketRes);

        const eventTime = new Date(pathOr('', ['event', 'startTime'], ticket));

        const eventString =
          pathOr('', ['event', 'title'], ticket) +
          ', ' +
          eventTime.getDate() +
          ' ' +
          `new Intl.DateTimeFormat(i18next.language, { month: 'long' }).format(eventTime)` +
          ' ' +
          eventTime.getFullYear();

        const currentElm = document.getElementById(blockDate);
        currentElm.setAttribute(
          'style',
          'border-radius: 100%; border: 1px solid rgb(255, 71, 71); background-color: transparent; color: #000;'
        );
        if (stateProps.blockDate) {
          const lastElm = document.getElementById(stateProps.blockDate);
          lastElm &&
            lastElm.setAttribute(
              'style',
              'border-radius: 100%; border: 1px solid rgb(255, 71, 71); background-color: transparent; color: #000;'
            );
        }

        setState({
          ...stateProps,
          blockInfo: {
            visibleId: pathOr('', ['visibleId'], ticket),
            orderId: pathOr('', ['order', 'visibleId'], ticket),
            eventString,
            type: t('modules.history_side_menu.block_info'),
          },
          blockDate,
        });
      } else {
        const ticketRes = await props.client.query({
          query: GET_SEASON_TICKET_INFO,
          fetchPolicy: 'no-cache',
          variables: {
            id: block.blockerId,
          },
        });
        const ticket = pathOr({}, ['data', 'seasonTicket', 'getById'], ticketRes);

        const currentElm = document.getElementById(blockDate);
        currentElm.setAttribute(
          'style',
          'border-radius: 100%; border: 1px solid rgb(255, 71, 71); background-color: transparent; color: #000;'
        );
        if (stateProps.blockDate) {
          const lastElm = document.getElementById(stateProps.blockDate);
          lastElm &&
            lastElm.setAttribute(
              'style',
              'border-radius: 100%; border: 1px solid rgb(255, 71, 71); background-color: transparent; color: #000;'
            );
        }

        setState({
          ...stateProps,
          blockInfo: {
            visibleId: pathOr('', ['visibleId'], ticket),
            orderId: pathOr('', ['order', 'visibleId'], ticket),
            type: t('modules.history_side_menu.type'),
            descriptorTitle: pathOr('', ['descriptor', 'title'], ticket),
          },
          blockDate,
        });
      }
    } catch (e) {
      message.error(t('modules.history_side_menu.message_error.error_loading_detailed_information'));
      console.error(e);
    }
  };

  useEffect(() => {
    const init = async () => {
      const placeIds = props.multipleMode
        ? props.selectedPlaces.map((item) => item.options.id)
        : [props.selectedPlaces[0].options.id];

      const res = !props.multipleMode
        ? await props.client.query({
            query: GET_PLACE_BY_ID,
            variables: {
              id: placeIds[0],
            },
            fetchPolicy: 'no-cache',
          })
        : null;

      const blockRes = await props.client.query({
        query: GET_PLACE_BLOCK_INFO,
        variables: {
          filter: {
            placeIds,
          },
        },
        fetchPolicy: 'no-cache',
      });

      const blockedPlacesList = pathOr([], ['data', 'placeStatus', 'getStatusList'], blockRes);

      let blockList = [];

      blockedPlacesList.forEach((item) => {
        const blockArr = pathOr([], ['statusList'], item);
        blockList = [...blockList, ...blockArr];
      });

      const place = pathOr({}, ['data', 'place', 'getById'], res);

      setState({
        ...stateProps,
        isLoaded: true,
        place: {
          row: pathOr('', ['row', 'number'], place),
          sector: pathOr('', ['row', 'sector', 'title'], place),
        },
        blockList,
        placeIds,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return (
    <div className="sales__tickets__side-menu__container">
      <div className="sales__tickets__side-menu__header">
        <CloseOutlined
          onClick={() => {
            props.onClose();
          }}
          className="sales__tickets__side-menu__header__icon"
        />
        <div className="sales__tickets__side-menu__header__action">
          {props.header}
        </div>
      </div>
      <div className="stadium__markup__side-menu__container">
        {!stateProps.isLoaded && (
          <div className="stadium__markup__side-menu__body__loader">
            <div className="with-preloader__map">
              <LoadingOutlined />
            </div>
          </div>
        )}
        {stateProps.isLoaded && (
          <div className="stadium__history__side-menu__body">
            {!props.multipleMode && (
              <>
                <div className="stadium__history__side-menu__body__header">
                  <div className="stadium__history__side-menu__body__header__item">
                    <div className="stadium__history__side-menu__body__header__title">
                      {t('modules.history_side_menu.div.sector')}
                    </div>
                    <div className="stadium__history__side-menu__body__header__text">{stateProps.place.sector}</div>
                  </div>
                  <div className="stadium__history__side-menu__body__header__item">
                    <div className="stadium__history__side-menu__body__header__title">
                      {t('modules.history_side_menu.div.row')}
                    </div>
                    <div className="stadium__history__side-menu__body__header__text">{stateProps.place.row}</div>
                  </div>
                  <div className="stadium__history__side-menu__body__header__item">
                    <div className="stadium__history__side-menu__body__header__title">
                      {t('modules.history_side_menu.div.place')}
                    </div>
                    <div className="stadium__history__side-menu__body__header__text">
                      {props.selectedPlaces[0].options.number}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="stadium__history__side-menu__body__info-container">
              <div className="stadium__history__side-menu__body__info-container__circle-red"></div>
              <div className="stadium__history__side-menu__body__info-container__text">
                {t('modules.history_side_menu.div.purchased')}
              </div>
              {!props.multipleMode && (
                <>
                  <div className="stadium__history__side-menu__body__info-container__circle-grey"></div>
                  <div style={{}} className="stadium__history__side-menu__body__info-container__text">
                    {t('modules.history_side_menu.div.blocked')}
                  </div>
                </>
              )}
            </div>
            <div className="stadium__history__side-menu__body__datepicker-container">
              <Calendar
                fullscreen={false}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];

                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    const month = current.month(i);
                    months.push(localeData.monthsShort(month));
                  }

                  for (let index = start; index < end; index++) {
                    monthOptions.push(<Select.Option key={`${index}`}>{months[index]}</Select.Option>);
                  }
                  const month = value.month();

                  const year = value.year();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div style={{ padding: 8 }}>
                      <Row gutter={8}>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            value={String(year)}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                              const newValue = value.clone();
                              const changeValue = newValue.month(parseInt(selectedMonth, 10));
                              onChange(changeValue);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                dateFullCellRender={(date) => {
                  const style = [];
                  const timeAtDayStart = returnDroppedDate(date);
                  const selectedBlock = stateProps.blockList.find((block) => {
                    const start = returnDroppedDate(pathOr('', ['period', 'start'], block));
                    const end = returnDroppedDate(pathOr('', ['period', 'end'], block));
                    return timeAtDayStart >= start && timeAtDayStart <= end;
                  });

                  if (selectedBlock && (!props.multipleMode || selectedBlock.blockerType !== 'ADMIN')) {
                    style.borderRadius = '50%';
                    style.border = selectedBlock.blockerType === 'ADMIN' ? '1px solid #8E8E8E' : '1px solid #FF4747';
                    style.backgroundColor =
                      selectedBlock.blockerType === 'ADMIN'
                        ? '#8E8E8E'
                        : stateProps.blockDate === new Date(date).getTime()
                        ? 'transparent'
                        : '#FF4747';
                    style.color = stateProps.blockDate === new Date(date).getTime() ? '#000' : '#fff';
                  }
                  const onClick = (ev) => {
                    const dateAtDayStart = returnDroppedDate(date);

                    const selectedBlock = stateProps.blockList.find((block) => {
                      const start = returnDroppedDate(pathOr('', ['period', 'start'], block));
                      const end = returnDroppedDate(pathOr('', ['period', 'end'], block));
                      return dateAtDayStart >= start && dateAtDayStart <= end;
                    });

                    if (!selectedBlock) {
                      setState({
                        ...stateProps,
                        showBlockModal: true,
                        blockDate: dateAtDayStart,
                      });
                    } else {
                      if (selectedBlock.blockerType !== 'ADMIN' && !props.multipleMode) {
                        setState({
                          ...stateProps,
                          blockInfo: {},
                        });
                        getBlockInfo(selectedBlock, new Date(date).getTime());
                      } else {
                        message.warn(t('modules.history_side_menu.message_warn'));
                      }
                    }
                  };
                  return (
                    <div className="ant-picker-cell-inner" onClick={onClick} id={date} style={style}>
                      {timeAtDayStart.getDate()}
                    </div>
                  );
                }}
              />
            </div>
            {!isEmpty(stateProps.blockInfo) && (
              <div className="stadium__history__side-menu__body__info">
                <div className="stadium__history__side-menu__body__info__item">
                  <div className="stadium__history__side-menu__body__info__title">
                    {t('modules.history_side_menu.div.status')}
                  </div>
                  <div className="stadium__history__side-menu__body__info__text">
                    {t('modules.history_side_menu.div.busy')}
                  </div>
                </div>
                <div className="stadium__history__side-menu__body__info__item">
                  <div className="stadium__history__side-menu__body__info__title">
                    {t('modules.history_side_menu.div.type')}
                  </div>
                  <div className="stadium__history__side-menu__body__info__text">{stateProps.blockInfo.type}</div>
                </div>
                <div className="stadium__history__side-menu__body__info__item">
                  <div className="stadium__history__side-menu__body__info__title">
                    {t('modules.history_side_menu.div.ticket')}
                  </div>
                  <div className="stadium__history__side-menu__body__info__text">{stateProps.blockInfo.visibleId}</div>
                </div>
                <div className="stadium__history__side-menu__body__info__item">
                  <div className="stadium__history__side-menu__body__info__title">
                    {t('modules.history_side_menu.div.order')}
                  </div>
                  <div className="stadium__history__side-menu__body__info__text">{stateProps.blockInfo.orderId}</div>
                </div>
                {stateProps.blockInfo.descriptorTitle && (
                  <div className="stadium__history__side-menu__body__info__item">
                    <div className="stadium__history__side-menu__body__info__title">
                      {t('modules.history_side_menu.div.subscription')}
                    </div>
                    <div className="stadium__history__side-menu__body__info__text">
                      {stateProps.blockInfo.descriptorTitle}
                    </div>
                  </div>
                )}
                {stateProps.blockInfo.eventString && (
                  <div className="stadium__history__side-menu__body__info__item">
                    <div className="stadium__history__side-menu__body__info__title">
                      {t('modules.history_side_menu.div.event')}
                    </div>
                    <div className="stadium__history__side-menu__body__info__text">
                      {stateProps.blockInfo.eventString}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="history__menu-container__block-container">
              <div className="history__menu-container__block-container__date-contianer">
                <div className="history__menu-container__block-container__date-contianer__text">
                  {t('modules.history_side_menu.div.with')}
                </div>
                <InputMask
                  size="large"
                  type="tel"
                  name="dateInput"
                  placeholder="DD/MM/YY"
                  mask="99.99.9999"
                  maskChar=""
                  style={{
                    borderColor: errors.startDate ? 'tomato' : '#D9D9D9',
                  }}
                  className="history__menu-container__block-container__date-contianer__date-input"
                  onChange={(ev) => {
                    tryToSetDate(ev.target.value, 'startDate');
                  }}
                />
                <InputMask
                  size="large"
                  name="timeInput"
                  type="tel"
                  placeholder="00:00"
                  mask="99:99"
                  maskChar=""
                  style={{
                    borderColor: errors.startTime ? 'tomato' : '#D9D9D9',
                  }}
                  className="history__menu-container__block-container__date-contianer__time-input"
                  onChange={(ev) => {
                    tryToSetTime(ev.target.value, 'startTime');
                  }}
                />
              </div>
              <div className="history__menu-container__block-container__date-contianer">
                <div className="history__menu-container__block-container__date-contianer__text">
                  {t('modules.history_side_menu.div.by')}
                </div>
                <InputMask
                  size="large"
                  type="tel"
                  name="dateInput"
                  placeholder="DD/MM/YY"
                  mask="99.99.9999"
                  maskChar=""
                  style={{
                    borderColor: errors.endDate ? 'tomato' : '#D9D9D9',
                  }}
                  className="history__menu-container__block-container__date-contianer__date-input"
                  onChange={(ev) => {
                    tryToSetDate(ev.target.value, 'endDate');
                  }}
                />
                <InputMask
                  size="large"
                  name="timeInput"
                  type="tel"
                  placeholder="23:59"
                  mask="99:99"
                  maskChar=""
                  style={{
                    borderColor: errors.endTime ? 'tomato' : '#D9D9D9',
                  }}
                  className="history__menu-container__block-container__date-contianer__time-input"
                  onChange={(ev) => {
                    tryToSetTime(ev.target.value, 'endTime');
                  }}
                />
              </div>
              <div className="history__menu-container__block-container__title">
                {t('modules.history_side_menu.div.comment')}
              </div>
              <input
                className="history__menu-container__block-container__input"
                placeholder={t('modules.history_side_menu.input')}
                onBlur={(ev) => {
                  setState({
                    ...stateProps,
                    commentary: ev.target.value,
                  });
                }}
              />
              <Button
                id="HistorySideMenuBlockButton"
                onClick={() => {
                  if (stateProps.blockDate && stateProps.blockDate.startDate && stateProps.blockDate.endDate) {
                    setState({
                      ...stateProps,
                      isBlockingProcessing: true,
                    });
                    blockPlace(false);
                  } else {
                    message.error(t('modules.history_side_menu.message_error.enter_lock_dates'));
                  }
                }}
                className="history__menu-container__block-container__button"
              >
                <span className="history__menu-container__block-container__button__text">
                  {stateProps.isBlockingProcessing ? <LoadingOutlined /> : t('modules.history_side_menu.span')}
                </span>
              </Button>
            </div>
            {!isEmpty(stateProps.blockList) && !props.multipleMode && (
              <div className="stadium__history__side-menu__body__block-container">
                <div className="stadium__history__side-menu__body__block-title">
                  {t('modules.history_side_menu.div.active_locks')}
                </div>
                <div className="stadium__history__side-menu__body__block-inner-container">
                  {stateProps.blockList.map((item) => {
                    const start = new Date(item.period.start);
                    const end = new Date(item.period.end);
                    const startMonth = new Intl.DateTimeFormat(i18next.language, { month: 'long' }).format(start);
                    const startYear = start.getFullYear();
                    const startDate = start.getDate();
                    const endMonth = new Intl.DateTimeFormat(i18next.language, { month: 'long' }).format(end);
                    const endDate = end.getDate();
                    const endYear = end.getFullYear();
                    const commentary = pathOr('', ['comment'], item);
                    let blockString = '';
                    if (startMonth === endMonth && startDate === endDate) {
                      blockString = `${startDate} ${startMonth} ${startYear}`;
                    } else {
                      blockString = `${startDate} ${startMonth} ${startYear} — ${endDate} ${endMonth} ${endYear}`;
                    }
                    return (
                      <>
                        <div className="stadium__history__side-menu__body__block-item">
                          <div className="stadium__history__side-menu__body__block-item__text">{blockString}</div>
                          <div
                            className="stadium__history__side-menu__body__block-item__button"
                            onClick={() => {
                              setState({
                                ...stateProps,
                                selectedBlock: {
                                  ...item,
                                  blockString,
                                },
                                showCancelModal: true,
                              });
                            }}
                          >
                            {t('modules.history_side_menu.div.take_off')}
                          </div>
                        </div>
                        {commentary && (
                          <div className="stadium__history__side-menu__body__block-item__commentary">{commentary}</div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        visible={stateProps.showCancelModal}
        centered
        closable={false}
        onCancel={() => {
          setState({
            ...stateProps,
            showCancelModal: false,
          });
        }}
        width={415}
        footer={null}
      >
        <>
          <div className="sales__tickets__list-table__accept-modal__text-container">
            <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
            <div className="sales__tickets__list-table__accept-modal__text">
              {t('modules.history_side_menu.div.are_you_sure_unlock')} &nbsp;{' '}
              {stateProps.selectedBlock ? stateProps.selectedBlock.blockString : ''}?
            </div>
          </div>
          <div className="sales__tickets__list-table__accept-modal__button-container">
            <Button
              id="HistorySideMenuTableCancelButton"
              className="sales__tickets__list-table__accept-modal__decline-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  showCancelModal: false,
                });
              }}
            >
              {t('modules.history_side_menu.button.cancel')}
            </Button>
            <Button
              id="HistorySideMenuUnlockButton"
              className="sales__tickets__list-table__accept-modal__accept-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isCancelProcessing: true,
                });
                cancelPlaceBlock();
              }}
            >
              {stateProps.isCancelProcessing ? (
                <LoadingOutlined style={{ color: '#000' }} />
              ) : (
                t('modules.history_side_menu.button.take_off')
              )}
            </Button>
          </div>
        </>
      </Modal>
      <Modal
        visible={stateProps.showBlockModal}
        centered
        closable={false}
        onCancel={() => {
          setState({
            ...stateProps,
            showBlockModal: false,
          });
        }}
        width={415}
        footer={null}
      >
        <>
          <div className="sales__tickets__list-table__accept-modal__text-container">
            <QuestionCircleOutlined className="sales__tickets__list-table__accept-modal__icon-warning" />
            <div className="sales__tickets__list-table__accept-modal__text">
              {t('modules.history_side_menu.div.are_you_sure_block')}
            </div>
          </div>
          <div className="sales__tickets__list-table__accept-modal__button-container">
            <Button
              id="HistorySideMenuCancelButton"
              className="sales__tickets__list-table__accept-modal__decline-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  showBlockModal: false,
                });
              }}
            >
              {t('modules.history_side_menu.button.cancel')}
            </Button>
            <Button
              id="HistorySideMenuTableBlockButton"
              className="sales__tickets__list-table__accept-modal__block-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isCancelProcessing: true,
                });
                blockPlace(true);
              }}
            >
              {stateProps.isCancelProcessing ? (
                <LoadingOutlined style={{ color: '#000' }} />
              ) : (
                t('modules.history_side_menu.button.block')
              )}
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default HistorySideMenu;
