import React, { useEffect, useState } from 'react';
import styles from './DiscountsFormModal.module.scss';
import DiscountsModalTitle from '../DiscountsModalTitle/DiscountsModalTitle';
import DiscountsFormsItems from '../DiscountsFormsItems/DiscountsFormsItems';
import { ConfigProvider, Drawer, Form, message } from 'antd';
import { formatFraction } from 'utils/apiHelpers';
import { ERROR_MESSAGE_TIME_DURATION, MESSAGE_TIME_DURATION } from 'constants.js';
import { ERROR_MESSAGE_STYLE, ERROR_MESSAGE_CONTENT } from '../../../constants';
import {
  DiscountType,
  InitialFormValues,
  PeriodType,
  PromocodeType,
  FormSubmitValues,
  FormUpdateSubmitValues,
  Status,
  PromocodeUseOn,
  Coupon,
} from '../../model/discountsModel';
import { DiscountsRepository } from '../../model/DiscountsRepository';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';
import i18next from 'i18next';

function DiscountsFormModal(props: {
  discountsRepository: DiscountsRepository;
  isDiscountsFormModalOpened: boolean;
  setIsDiscountsFormModalOpened: (value: boolean) => void;
  couponData?: Coupon;
  getCouponsList?: () => void;
  getCouponById?: () => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm<InitialFormValues>();
  const isCouponUpdate = !!props.couponData;
  const EMPTY_FORM_VALUES: InitialFormValues = {
    promocodeType: PromocodeType.PERSONAL_COUPON,
    title: '',
    discountValue: '',
    discountType: DiscountType.ABSOLUTE,
    maxUseCountsPerUser: undefined,
    datePeriodType: PeriodType.PERIOD,
    period: undefined,
    periodStart: undefined,
    periodEnd: undefined,
  };
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.couponData) {
      const initialPeriodType = () => {
        if (props.couponData?.period.start && props.couponData.period.end === undefined) {
          return PeriodType.PERIOD_START;
        } else if (props.couponData?.period.start === undefined && props.couponData?.period.end) {
          return PeriodType.PERIOD_END;
        } else {
          return PeriodType.PERIOD;
        }
      };

      const dateStart = props.couponData.period.start;
      const dateEnd = props.couponData.period.end;

      const initialPeriod = initialPeriodType() === 'PERIOD' ? [dayjs(dateStart), dayjs(dateEnd)] : undefined;
      const initialPeriodStart = initialPeriodType() === 'PERIOD_START' ? dayjs(dateStart) : undefined;
      const initialPeriodEnd = initialPeriodType() === 'PERIOD_END' ? dayjs(dateEnd) : undefined;

      form.setFieldsValue({
        promocodeType: props.couponData.type,
        title: props.couponData.title,
        discountValue: formatFraction(props.couponData.discount.value),
        discountType: props.couponData.discount.type,
        maxUseCountsPerUser: props.couponData.maxUseCountsPerUser,
        datePeriodType: initialPeriodType(),
        period: initialPeriod,
        periodStart: initialPeriodStart,
        periodEnd: initialPeriodEnd,
      });
    }
  }, [isCouponUpdate]);

  const onModalClose = () => {
    form.setFieldsValue(EMPTY_FORM_VALUES);
    setIsSubmitLoading(false);
    props.setIsDiscountsFormModalOpened(false);
  };

  // Form submission
  async function onFinish(values: InitialFormValues) {
    setIsSubmitLoading(true);

    function periodSwitcher() {
      switch (values.datePeriodType) {
        case 'PERIOD':
          return {
            start: values.period && values.period[0].toISOString(),
            end: values.period && values.period[1].toISOString(),
          };
        case 'PERIOD_START':
          return {
            start: values.periodStart && values.periodStart.toISOString(),
            end: undefined,
          };
        case 'PERIOD_END':
          return {
            start: undefined,
            end: values.periodEnd && values.periodEnd.toISOString(),
          };
        default: {
          return {
            start: undefined,
            end: undefined,
          };
        }
      }
    }

    if (!isCouponUpdate) {
      const data: FormSubmitValues = {
        title: values.title,
        type: values.promocodeType,
        maxUseCountsPerUser: Number(values.maxUseCountsPerUser),
        period: periodSwitcher(),
        status: Status.ACTIVE,
        discount: {
          type: values.discountType,
          value: values.discountValue,
        },
        adminUseOnly: false,
        groupIds: [],
        useOn: PromocodeUseOn.ITEM,
      };

      try {
        await props.discountsRepository.createCoupon(data);
        message.success(t('modules.discounts_form_modal.message_success.coupon_created'), MESSAGE_TIME_DURATION);
        props.getCouponsList && props.getCouponsList();
        onModalClose();
      } catch (e) {
        console.log('createCoupon error', e);
        message.error({
          content: `${ERROR_MESSAGE_CONTENT.createError} ${e}`,
          duration: ERROR_MESSAGE_TIME_DURATION,
          style: ERROR_MESSAGE_STYLE,
        });
        setIsSubmitLoading(false);
      } finally {
      }
    } else if (props.couponData) {
      const data: FormUpdateSubmitValues = {
        title: values.title,
        maxUseCountsPerUser: Number(values.maxUseCountsPerUser),
        period: periodSwitcher(),
        status: Status.ACTIVE,
        discount: {
          type: values.discountType,
          value: values.discountValue,
        },
        adminUseOnly: false,
        groupIds: [],
        useOn: PromocodeUseOn.ITEM,
      };

      try {
        await props.discountsRepository.updateCoupon(props.couponData.id, data);
        message.success(t('modules.discounts_form_modal.message_success.coupon_updated'), MESSAGE_TIME_DURATION);
        props.getCouponById && props.getCouponById();
        onModalClose();
      } catch (e) {
        console.log('updateCoupon error', e);
        message.error({
          content: `${ERROR_MESSAGE_CONTENT.updateError} ${e}`,
          duration: ERROR_MESSAGE_TIME_DURATION,
          style: ERROR_MESSAGE_STYLE,
        });
        setIsSubmitLoading(false);
      } finally {
      }
    }
  }

  return (
    <ConfigProvider locale={determiningCurrentLocale()}>
      <Drawer
        placement="right"
        closable={false}
        open={props.isDiscountsFormModalOpened}
        width="80%"
        height="100%"
        className={styles.discountsModal}
        bodyStyle={{ backgroundColor: 'var(--admin-theme_Grey_00)' }}
      >
        <Form
          name="discountsForm"
          form={form}
          className={styles.discountsForm}
          onFinish={onFinish}
          initialValues={EMPTY_FORM_VALUES}
        >
          <DiscountsModalTitle
            isSubmitLoading={isSubmitLoading}
            onModalClose={onModalClose}
            modalTitle={
              isCouponUpdate
                ? t('modules.discounts_form_modal.discounts_modal_title.editing_coupon')
                : t('modules.discounts_form_modal.discounts_modal_title.adding_new_discount')
            }
          />

          <DiscountsFormsItems />
        </Form>
      </Drawer>
    </ConfigProvider>
  );
}

export default DiscountsFormModal;
