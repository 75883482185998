import gql from 'graphql-tag';

export const UPDATE_PRICE_CATEGORY_TEMPLATE = gql`
  mutation($id: ID!, $data: PriceCategoryTemplateInput!) {
    priceCategoryTemplate {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_PLACES_CATEGORY_TEMPLATE = gql`
  mutation($id: ID!, $venueId: ID!, $places: PlaceInput!) {
    priceCategoryTemplate {
      addPlaces(id: $id, venueId: $venueId, places: $places) {
        id
      }
    }
  }
`;
export const UPDATE_PRICE_CATEGORY = gql`
  mutation($id: ID!, $data: PriceCategoryUpdateInput!) {
    priceCategory {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const ADD_PRICE_CATEGORY = gql`
  mutation($data: PriceCategoryInput!) {
    priceCategory {
      add(data: $data) {
        id
      }
    }
  }
`;
export const ADD_PRICE_CATEGORY_TEMPLATE = gql`
  mutation($data: PriceCategoryTemplateInput!) {
    priceCategoryTemplate {
      add(data: $data) {
        id
        title
      }
    }
  }
`;
export const CANCEL_PLACE_BLOCK = gql`
  mutation($data: [PlaceStatusDetailedInput!]!) {
    placeStatus {
      setStatus(data: $data) {
        placeId
      }
    }
  }
`;
export const BLOCK_PLACE = gql`
  mutation($data: [PlaceStatusDetailedInput!]!) {
    placeStatus {
      setStatus(data: $data) {
        placeId
      }
    }
  }
`;
export const DELETE_PRICE_CATEGORY = gql`
  mutation($id: ID!) {
    priceCategory {
      delete(id: $id)
    }
  }
`;
