import { message } from 'antd'
import { useEffect, useState, useRef } from 'react'

type Function = () => void;

export function useLoadingMessage(
  messageText: string
): [boolean, (value: boolean) => void] {
  const [ value, setValue ] = useState(false);
  const ref = useRef<Function>();

  useEffect(() => {
    if (value) {
      ref.current = message.loading(messageText, 0);
    }
    const hide = ref.current;
    if (!value && hide) {
      hide();
    }
  }, [value]);

  return [ value, setValue ];
}
