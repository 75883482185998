import React, { useEffect, useState } from 'react';
import styles from './AccreditationCard.module.scss';
import {
  Accreditation,
  ACC_RequestType,
  AccreditationRequestStatus,
  Season,
  Tournament,
  Match,
} from '../../model/accreditationModel';
import { Link } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
import { Button, message } from 'antd';
import AccreditationStatus from './AccreditationStatus';
import Download from '../../assets/Icon/Download';
import userIcon from '../assets/user.svg';
import calendarIcon from '../assets/calendar.svg';
import { withTranslation } from 'react-i18next';

const requestTypeToString = (requestType: ACC_RequestType, t: any) => {
  switch (requestType) {
    case ACC_RequestType.FOR_MATCH:
      return t('modules.accreditation_card.request_type_to_string.onetime_accreditation');
    case ACC_RequestType.FOR_SEASON:
      return t('modules.accreditation_card.request_type_to_string.seasonal_accreditation');
    case ACC_RequestType.FOR_TOURNAMENT:
      return t('modules.accreditation_card.request_type_to_string.accreditation_tournament');
  }
};

const formatRequestType = (requestType: ACC_RequestType, t: any) => {
  switch (requestType) {
    case ACC_RequestType.FOR_MATCH:
      return t('modules.accreditation_card.format_request_type.for_match');
    case ACC_RequestType.FOR_SEASON:
      return t('modules.accreditation_card.format_request_type.for_season');
    case ACC_RequestType.FOR_TOURNAMENT:
      return t('modules.accreditation_card.format_request_type.to_tournament');
  }
};
function AccreditationCard(props: {
  accreditation: Accreditation;
  selectedAccreditations: Accreditation[];
  acceptRequest: () => void;
  showModal: () => void;
  mutationSelectedAccreditations: (accreditation: Accreditation) => void;
  printAccreditations: () => void;
  t: any;
}) {
  const name = props.accreditation.accreditedUser.user?.person.name.toString();
  const surname = props.accreditation.accreditedUser.user?.person.surname.toString();
  const patronymic = props.accreditation.accreditedUser.user?.person.patronymic?.toString() || '';
  const fullName = [surname, name, patronymic].join(' ');
  const isChecked = props.selectedAccreditations.some((item) => item.id === props.accreditation.id);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(isChecked);
  }, [isChecked]);

  function handleCheckboxClick(accreditation: Accreditation): void {
    props.mutationSelectedAccreditations(accreditation);
    setIsSelected(!isSelected);
  }

  const formatRequestTypeValue = (requestType: ACC_RequestType, t: any) => {
    switch (requestType) {
      case ACC_RequestType.FOR_MATCH:
        return [
          ...props.accreditation.matches.map(
            (m) =>
              `${m.team1.title} vs ${m.team2.title}, ${m.startTime?.toLocaleDateString(
                'en-En'
              )}, ${m.startTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
          ),
        ];
      case ACC_RequestType.FOR_SEASON:
        return (
          t('modules.accreditation_card.format_request_type_value') +
          ' ' +
          [...props.accreditation.seasons.map((s) => `${s.startDate.getFullYear()}/${s.endDate.getFullYear()}`)]
        );
      case ACC_RequestType.FOR_TOURNAMENT:
        return [
          ...props.accreditation.tournaments.map(
            (t) =>
              `${t.title} (${[
                ...props.accreditation.seasons.map((s) => `${s.startDate.getFullYear()}/${s.endDate.getFullYear()}`),
              ]})`
          ),
        ];
    }
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.checkbox}>
          <input
            className={styles.checkbox__btn}
            onChange={() => {
              handleCheckboxClick(props.accreditation);
            }}
            checked={isChecked}
            type="checkbox"
          />
        </div>

        {props.accreditation.accreditedUser.user?.person?.photo ? (
          <img src={props.accreditation.accreditedUser.user?.person.photo.publicLink} alt={name} />
        ) : (
          <div className={styles.placeholder} />
        )}

        <AccreditationStatus status={props.accreditation.requestStatus} className={styles.status} />
        <div className={styles.infoContainer}>
          <h2 className={styles.name}>{fullName}</h2>
          <div className={styles.infoGrid}>
            <div className={styles.infoGridTop}>
              <span>{formatRequestType(props.accreditation.requestType, props.t)}: </span>
              <span className={styles.itemGrey}>
                {formatRequestTypeValue(props.accreditation.requestType, props.t).toString()}
              </span>
            </div>
            <div className={styles.infoGridBottom}>
              <div className={styles.block}>
                <img src={userIcon} className={styles.icon} alt={props.t('modules.accreditation_card.img.user')!} />
                <span className={styles.item}>{props.accreditation.accessCategory.title.toString()}</span>
              </div>
              <div className={styles.block}>
                <img
                  src={calendarIcon}
                  className={styles.icon}
                  alt={props.t('modules.accreditation_card.img.calendar')!}
                />
                <span className={styles.item}>
                  {props.accreditation.createdAt.toLocaleDateString('ru', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            {props.accreditation.requestStatus === AccreditationRequestStatus.PENDING && (
              <div className={styles.btnContainer}>
                <Button id="AccreditationCardRejectButton" type="ghost" onClick={props.showModal}>
                  {props.t('modules.accreditation_card.button.reject')}
                </Button>
                <Button id="AccreditationCardAcceptButton" type="primary" onClick={props.acceptRequest}>
                  {props.t('modules.accreditation_card.button.accept')}
                </Button>
                <Button
                  id="AccreditationCardAcceptAndPrintButton"
                  type="primary"
                  className={styles.buttonSpan}
                  onClick={() => {
                    props.acceptRequest();
                    props.printAccreditations();
                  }}
                >
                  {props.t('modules.accreditation_card.button.accept_print')}
                </Button>
              </div>
            )}

            {props.accreditation.requestStatus === AccreditationRequestStatus.ACCEPTED && (
              <div className={styles.btnContainer}>
                <Button
                  id="AccreditationCardPrintBadgeButton"
                  className={styles.buttonPrint}
                  type="ghost"
                  onClick={props.printAccreditations}
                >
                  <Download className={styles.buttonPrintIcon} />
                  {props.t('modules.accreditation_card.button.print_badge')}
                </Button>
              </div>
            )}
            <Link to={`/admin/accreditation/${props.accreditation.id}`} className={styles.link}>
              <div className={styles.link}>
                <div>{props.t('modules.accreditation_card.div')}</div>
                <Icon type="right" />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div id={styles.cardMobile} className={styles.cardMobile}>
        <Link to={`/admin/accreditation/${props.accreditation.id}`}>
          <div className={styles.cardMobile__header}>
            {props.accreditation.accreditedUser.user?.person?.photo ? (
              <img
                className={styles.cardMobile__photo}
                src={props.accreditation.accreditedUser.user?.person.photo.publicLink}
                alt={name}
              />
            ) : (
              <div className={styles.placeholder} />
            )}

            <div className={styles.cardMobile__header_text}>
              <AccreditationStatus status={props.accreditation.requestStatus} className={styles.cardMobile__status} />

              <h2 className={styles.cardMobile__name}>{fullName}</h2>
            </div>
          </div>
        </Link>

        <div className={styles.cardMobile__middle}>
          <div className={styles.cardMobile__info}>
            <div className={styles.cardMobile__info_top}>
              <span>{formatRequestType(props.accreditation.requestType, props.t)}: </span>
              <span className={styles.cardMobile__info_text_grey}>
                {formatRequestTypeValue(props.accreditation.requestType, props.t).toString()}
              </span>
            </div>
            <div className={styles.cardMobile__info_bottom}>
              <div className={styles.cardMobile__info_content}>
                <img
                  src={userIcon}
                  className={styles.cardMobile__info_icon}
                  alt={props.t('modules.accreditation_card.img.user')!}
                />
                <span className={styles.cardMobile__info_text}>
                  {props.accreditation.accessCategory.title.toString()}
                </span>
              </div>
              <div className={styles.cardMobile__info_content}>
                <img
                  src={calendarIcon}
                  className={styles.cardMobile__info_icon}
                  alt={props.t('modules.accreditation_card.img.calendar')!}
                />
                <span className={styles.cardMobile__info_text}>
                  {props.accreditation.createdAt.toLocaleDateString('en', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cardMobile__footer}>
          {props.accreditation.requestStatus === AccreditationRequestStatus.PENDING && (
            <div className={styles.cardMobile__btns}>
              <Button id="AccreditationCardRejectMobileButton" type="ghost" onClick={props.showModal}>
                {props.t('modules.accreditation_card.button.reject')}
              </Button>
              <Button id="AccreditationCardAcceptMobileButton" type="primary" onClick={props.acceptRequest}>
                {props.t('modules.accreditation_card.button.accept')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(AccreditationCard);
