import { t } from 'i18next';
import { includes } from 'ramda';

export const getErrorMessage = (err) => {
  if (includes('already registered', err.message)) {
    return t('modules.dictionary.already_registered');
  }
  const errorCodes = [{ code: 'INTERNAL_SERVER_EROR', message: t('modules.dictionary.server_error') }];
  const message = (errorCodes.find((item) => item.code === err.code) || {}).message;

  return message ? message : err.message;
};
