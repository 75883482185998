import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  () => ({
    isSubmitButtonDisabled: false
  }),
  {
    setIsSubmitButtonDisabled: ({ isSubmitButtonDisabled }) => bool => ({
      isSubmitButtonDisabled: bool
    })
  }
);
