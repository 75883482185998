import React from 'react';
import {Select} from 'antd';
import {AccreditationRequestStatus} from '../../../../../model/accreditationModel';
import styles from './RequestStatusSelector.module.scss';
import {useTranslation} from 'react-i18next';

const {Option} = Select;

export default function RequestStatusSelector(props: {
  requestStatus: AccreditationRequestStatus;
  setRequestStatus: (requestStatus: AccreditationRequestStatus | undefined) => void;
}) {
  const {t} = useTranslation();
  return (
    <>
      <Select
        className={styles.select}
        size={'large'}
        defaultValue={props.requestStatus}
        onChange={(value: AccreditationRequestStatus) => props.setRequestStatus(value)}
      >
        <Option value="REJECTED">{t('modules.request_status_selector.rejected')}</Option>
        <Option value="ACCEPTED">{t('modules.request_status_selector.confirmed')}</Option>
        {/* <Option value="PENDING">{t('modules.request_status_selector.consideration')}</Option> */}
      </Select>
    </>
  );
}
