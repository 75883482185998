import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {LocalizedString, localizedStringIsNotEmpty} from 'utils/LocalizedString';
import {transliterate} from 'utils/helpers';

import {TransliterationInputProps} from './TransliterationInput.types';
import styles from './TransliterationInput.module.scss';
import {capitalize} from 'lodash';
import useLocalizedTextValidation from '../hooks/useLocalizedTextValidation/useLocalizedTextValidation';

export default function TransliterationInput({
  labelTitle,
  inputName,
  sourceLngCode = 'ru',
  resultLngCode = 'en',
  value,
  labelPosition = 'left',
  direction = 'row',
  justify = 'start',
  borderRadius = '2px',
  columns,
  singleLabel,
  placeholder,
  validation,
  onChange,
  onPaste,
  onError,
  transliterationHandler = transliterate,
  labelCustomization,
  hintCustomization,
  wrapperClasses
}: TransliterationInputProps) {
  const columnSystem = 24;
  const {t} = useTranslation();
  const initialValue = value ?? LocalizedString.fromObject({});
  const [input, setInput] = useState<LocalizedString>(initialValue);
  const {hint, restrictKeyInput, restrictPaste, handleErrors, checkRequired} = useLocalizedTextValidation({config: validation, onError});

  // Effects

  useEffect(() => {
    if (onChange && input) {
      onChange(localizedStringIsNotEmpty(input) ? input : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  // Dynamic css classes

  const wrapperClassNames = cn(
    styles.wrapper,
    styles[`direction${capitalize(direction)}`],
    {[styles[`justify-${justify}`]]: direction === 'row'},
    {[styles[`align-${justify}`]]: direction === 'column'},
    {[styles[`singleLabel${capitalize(labelPosition)}`]]: singleLabel && (labelCustomization || labelTitle)},
    wrapperClasses
  );

  const labelClasses = cn(
    styles.label,
    {[styles[`label${capitalize(labelPosition)}`]]: !singleLabel && (labelCustomization || labelTitle)}
  );

  const calculateColumnsWidth = () => {
    if (typeof columns === 'number') {
      let widths = [];
      let itemsCount = singleLabel ? 3 : 2;
      for (let i = 0; i < itemsCount; i++) {
        widths.push(Math.floor(100 / columns).toString() + '%');
      }
      return widths;
    } else if (columns?.length) {
      return columns.map((v) => ((v / columnSystem) * 100).toString() + '%');
    } else {
      return undefined;
    }
  };

  const columnWidth = columns ? calculateColumnsWidth() : undefined;

  const calculatedStyle = (elNumber: number) => {
    if (columnWidth) {
      return columnWidth[elNumber] ? { style: { width: columnWidth[elNumber] } } : null;
    } else {
      return null;
    }
  };

  // Handlers

  const handleInputChange = (value: string, lang: string) => {
    const currentInput = input?.toObject();
    let newValue: LocalizedString;
    if (lang === sourceLngCode) {
      newValue = LocalizedString.fromObject({[lang]: value, [resultLngCode]: transliterationHandler(value)});
    } else if (lang === resultLngCode) {
      newValue = LocalizedString.fromObject({...currentInput, [lang]: value});
    } else {
      throw new Error('Incorrect language code');
    }
    setInput(newValue);
    handleErrors();
  };

  // UI

  const definePlaceholder = (placeholder: LocalizedString | string | undefined, lng?: string) => {
    if (placeholder) {
      return (typeof placeholder === 'string') ? placeholder : placeholder.toString(lng);
    } else {
      return '';
    }
  };

  const defineLabel = (lng: string, fallback?: React.ReactNode) => labelCustomization ? labelCustomization(lng) : fallback;

  const displayHints = () => {
    if (hint) {
      return hintCustomization ? hintCustomization(hint) : <span className={styles.hint}>{hint}</span>;
    } else {
      return null;
    }
  };

  return (
    <div className={wrapperClassNames}>
      {singleLabel && (
        <h3 className={styles.itemTitle} {...calculatedStyle(0)}>
          {defineLabel(sourceLngCode, labelTitle)}
        </h3>
      )}
      <label className={labelClasses} {...(singleLabel ? calculatedStyle(1) : calculatedStyle(0))}>
        {!singleLabel && <h3 className={styles.itemTitle}>{defineLabel(sourceLngCode, labelTitle)}</h3>}
        <Input
          id={`${inputName}-${sourceLngCode}`}
          size="large"
          className={styles.input}
          value={input.hasLanguage(sourceLngCode) ? input.toString(sourceLngCode) : ''}
          placeholder={definePlaceholder(placeholder, sourceLngCode)}
          onChange={(e) => handleInputChange(e.target.value, sourceLngCode)}
          onKeyPress={(e) => restrictKeyInput(e, sourceLngCode)}
          onBlur={() => {
            checkRequired(input);
            handleErrors();
          }}
          onPaste={(e)=> {
            onPaste && onPaste(e);
            restrictPaste(e, sourceLngCode);
            handleErrors();
          }}
          maxLength={validation?.length?.max}
          minLength={validation?.length?.min}
          autoComplete={inputName}
          spellCheck={false}
          style={{borderRadius}}
        />
      </label>
      <label className={labelClasses} {...(singleLabel ? calculatedStyle(2) : calculatedStyle(1))}>
        {!singleLabel && <h3
          className={styles.itemTitle}>{defineLabel(resultLngCode, `${labelTitle} ${t('modules.localized_text_input')}`)}</h3>}
        <Input
          id={`${inputName}-${resultLngCode}`}
          size="large"
          className={styles.input}
          value={input.hasLanguage(resultLngCode) ? input.toString(resultLngCode) : ''}
          placeholder={definePlaceholder(placeholder, resultLngCode)}
          onChange={(e) => handleInputChange(e.target.value, resultLngCode)}
          onKeyPress={(e) => restrictKeyInput(e, resultLngCode)}
          onBlur={() => {
            checkRequired(input);
            handleErrors();
          }}
          onPaste={(e)=> {
            onPaste && onPaste(e);
            restrictPaste(e, resultLngCode);
            handleErrors();
          }}
          maxLength={validation?.length?.max}
          minLength={validation?.length?.min}
          autoComplete="nope"
          spellCheck={false}
          style={{borderRadius}}
        />
      </label>
      {displayHints()}
    </div>
  );
}
