import React, { Component } from 'react';
import "./style.scss"

export default class ModalFooter extends Component {

    render() {        
        return (
            <div className="ac-modal__footer">{this.props.children}</div>
       );
    }
}