import { lifecycle } from 'recompose';
import { pathOr } from 'ramda';
import { GET_PROMOCODES } from '../graphql';

export default lifecycle({
  async componentDidMount() {
    const res = await this.props.client.query({
      query: GET_PROMOCODES
    });

    const list = pathOr([], ['data', 'promocodes', 'getList', 'list'], res);

    this.props.formatData(list);
  }
});
