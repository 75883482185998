import React from 'react';
import { Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

const PromoAddComment = (props) => {
  const { t } = useTranslation();
  return (
    <Card title={t('modules.promo_add_comment')}>
      <TextArea value={props.values.comment} onChange={(e) => props.setFieldValue('comment', e.target.value)} />
    </Card>
  );
};

export default PromoAddComment;
