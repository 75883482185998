import LoginForm from './Auth/components';
import { IRouteContainer } from '../../common/src';
import { includes } from 'ramda';
import { withProps } from 'recompose';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';

export class AuthModule {
  private component: any; //TODO
  private routeContainer: IRouteContainer;
  private authClient: AuthClient;

  constructor(authClient: AuthClient, routeContainer: IRouteContainer) {
    // this.uri = encodeURI(window.parametrize('REACT_APP_REDIRECT_URI') || '');
    this.authClient = authClient;

    this.component = {
      auth: withProps({
        authClient: this.authClient
      })(LoginForm)
    };
    this.routeContainer = routeContainer;
    this.routeContainer.addRoute({
      path: '/login',
      component: this.component.auth
    });
  }

  start() {
    //TODO Implement via useHistory
    if (!this.authClient.isAuthorized() && !includes('/login', window.location.pathname)) {
      window.location.href = window.location.origin + '/login';
    } else if (this.authClient.isAuthorized() && !includes('/admin', window.location.pathname)) {
      window.location.href = window.location.origin + '/admin';
    }
  }
}
