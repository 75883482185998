import React from 'react';
import InputMask from 'react-input-mask';
import styles from './PhoneInput.module.scss';
import cn from 'classnames';

export default function PhoneInput(props: { defaultValue?: string; onChange?: any }) {
  return (
    <InputMask
      id="phone"
      mask="+7 (999) 999-99-99"
      placeholder={'+7'}
      value={props.defaultValue}
      onChange={props.onChange}
      className={`ant-input ant-input-lg ${cn(styles.input)}`}
    />
  );
}
