import gql from 'graphql-tag';

export const SEARCH_USERS = gql`
  query searchUsers($quickSearch: String!) {
    users {
      getListAdvanced(filter: { quickSearch: $quickSearch }) {
        list {
          id
          login
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
        }
      }
    }
  }
`;
