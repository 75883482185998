import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  {
    searchText: '',
    isUserUpdate: false,
    user: null,
    currentSearchPage: 0,
    searchString: '',
    isUserDataModalOpened: false
  },
  {
    setUser: ({ user }) => obj => ({
      user: obj
    }),
    handleSearchPageChange: ({ currentSearchPage }) => selectedPage => ({
      currentSearchPage: selectedPage - 1
    }),
    handleSearchInputChange: ({
      searchString,
      currentSearchPage
    }) => value => ({
      searchString: value,
      currentSearchPage: 0
    }),
    handleOpenUserDataModal: () => isOpened => ({
      isUserDataModalOpened: isOpened
    })
  }
);
