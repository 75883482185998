import { message } from 'antd'
import { useEffect, useState } from 'react'

let hide: (() => void) | undefined
export function useLoadingMessage(messageText: string): [boolean, (value: boolean) => void] {
  const [ value, setValue ] = useState(false)

  useEffect(() => {
    if(value) {
      hide = message.loading(messageText, 0);
    }
    if(!value && hide) {
      hide();
    }
  }, [value]);

  return [ value, setValue ]
}
