import React from 'react';
import { Modal } from 'antd';
import { pathOr } from 'ramda';

import CodesModalBody from './chunks/body';

import { IPromoCodesActivationsModal } from '../interfaces/IPromoCodesActivationsModal';
import { useTranslation } from 'react-i18next';

const PromoCodesActivationModal = (props: IPromoCodesActivationsModal | any) => {
  const { t } = useTranslation();
  const { codesData } = props;
  const { openActivationsCodesModal, client, setCodesToReducer } = props;
  const activationsCodesModalIsOpened: boolean = pathOr(false, ['activationsCodesModalIsOpened'], props);

  return (
    <Modal
      visible={activationsCodesModalIsOpened}
      title={t('modules.promo_codes_activation_modal')}
      footer={false}
      onCancel={() => openActivationsCodesModal(false)}
      className="promocodes-modal"
    >
      <CodesModalBody codesData={codesData} client={client} setCodesToReducer={setCodesToReducer} />
    </Modal>
  );
};

export default PromoCodesActivationModal;
