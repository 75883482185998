import { LocalizedString } from 'utils/LocalizedString';
import moment from 'moment';

// a list of users

export type ListOfUsers = {
  userId: string;
  login: string;
  birthday?: Date;
  name?: LocalizedString;
  surname?: LocalizedString;
  patronymic?: LocalizedString;
  phone?: string;
  email?: string;
  isConfirmed?: boolean;
};

export type User = {
  total: number;
  list: ListOfUsers[];
};

// User data

export type UserInfo = {
  userId: string;
  login: string;
  name: LocalizedString;
  surname: LocalizedString;
  patronymic?: LocalizedString;
  birthday?: Date;
  phone?: string;
  email?: string;
};

// History of orders

export enum OrderStatus {
  UNPAYED = 'UNPAYED',
  PAYED = 'PAYED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  CANCELED = 'CANCELED',
  REFUND = 'REFUND',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
}

export enum Product {
  TICKET = 'TICKET',
  SEASON_TICKET = 'SEASON_TICKET',
  PROLONG_SEASON_TICKET = 'PROLONG_SEASON_TICKET',
  OUTFIT = 'OUTFIT',
}

export enum OrderItemStatus {
  RESERVED = 'RESERVED',
  SOLD = 'SOLD',
  REFUNDED = 'REFUNDED',
}

export enum PaymentPlace {
  MOBILE_CLIENT = 'MOBILE_CLIENT',
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  CASHIER = 'CASHIER',
  UNKNOWN = 'UNKNOWN',
}

export type OrderItem = {
  id: string;
  title: string;
  type: Product;
  status: OrderItemStatus;
  price: string;
  priceWithDiscount: string;
  refundTime?: Date;
};

export type Payment = {
  paymentPlace: PaymentPlace;
};

export type Amount = {
  cash: string;
  electronic: string;
};

export type AdditionalData = {
  loyaltyAmount?: string;
  loyaltyAccrued?: string;
  amount?: Amount;
  payment?: Payment;
};

export type UserOrdersList = {
  id: string;
  status: OrderStatus;
  createdTime: Date;
  purchaseTime?: Date;
  price: string;
  priceWithDiscount: string;
  visibleId?: string;
  appliedPromocode?: string;
  additionalData?: AdditionalData;
  items: OrderItem[];
};

export type UserOrders = {
  total: number;
  list: UserOrdersList[];
};

// Loyalty program

export type LoyaltyRules = {
  isActive: boolean;
  currencyNameMod50: string;
};

export type LoyaltyBonusTransactionList = {
  id: string;
  userId: string;
  transactionValue: string;
  isCanceled: boolean;
  createdAt: Date;
  expiresAt?: Date;
  reason?: string;
};

export type LoyaltyBonusTransaction = {
  total: number;
  list: LoyaltyBonusTransactionList[];
};

// Pass data

export enum AccessObjectType {
  ACCREDITATION = 'ACCREDITATION',
  TICKET = 'TICKET',
  SEASON_TICKET = 'SEASON_TICKET',
  VIP_INVITE = 'VIP_INVITE',
}

export type ZonesIn = {
  title: string;
};

export type ZonesOut = {
  title: string;
};

export type Events = {
  title: string;
};

export type Entrance = {
  title: string;
  zonesIn: ZonesIn[];
  zonesOut: ZonesOut[];
};

export type EntrancePassList = {
  entrance: Entrance;
  direction?: string;
  dateTime: Date;
  accessObjectId: string;
  accessObjectTitle: string;
  accessObjectType: AccessObjectType;
  events: Events[];
  isOffline: boolean;
};

export type EntrancePass = {
  total: number;
  list: EntrancePassList[];
};

// User types

export type RoleItem = {
  value: string;
  label: string;
  key: string;
};
export type FormValues = {
  name: LocalizedString;
  patronymic: LocalizedString;
  surname: LocalizedString;
  birthday: moment.MomentInputObject;
  email: string;
  phone: string;
  photoFileId: string;
  roles: RoleItem[];
  tin: string;
};

export enum ContactType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type ContactInput = {
  type: ContactType;
  value: string;
};

export type PersonData = {
  name?: Object;
  patronymic?: Object;
  surname?: Object;
  photoFileId?: string;
  birthday?: string;
  contacts: ContactInput[];
  tin?: string;
};

export type InsertUser = {
  login: string;
  password: string;
  person: PersonData;
  roles: string[];
};

// Role Data

export type Role = {
  id: string;
  name: string;
  description?: string;
  internal: boolean;
};

export type FilteredRole = {
  id: string;
  name: string;
};

export type SelectFormItem = {
  key: string;
  label: string;
  value: string;
};
