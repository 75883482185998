import { connect } from 'react-redux';
import * as actions from '../actions';

const mapStateToProps = state => ({
  data: state.reducer.adminPanel
});
const mapDispatchToProps = { ...actions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
