import types from '../types';
import { assoc } from 'ramda';

const initialState = {
  isAddOpen: false,
  defaultPromo: undefined,
  isEditingMode: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IS_ADD_VISIBLE:
      return assoc('isAddOpen', action.bool, state);

    case types.SET_DEFAULT_PROMO:
      return assoc('defaultPromo', action.obj, state);

    case types.SET_EDITING_MODE:
      return {
        isEditingMode: action.bool
      }
    default:
      return state;
  }
};
