import React from 'react';
import './AccessControl.scss';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';



function AccessControl(props: { client: ApolloClient<any> }) {



  return (
    <>

    </>
  );
}

export default withApollo(AccessControl);
