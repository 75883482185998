import React, { useEffect, useState } from 'react';
import './Segments.scss';
import SegmentsMenu from './components/SegmentsMenu/SegmentsMenu';
import Buttons from './components/Buttons/Buttons';
import TableFormComponent from './components/TableFormComponent/TableFormComponent';

import SegmentForm from './components/SegmentForm/SegmentForm';

import ModalSaveError from './components/ModalSaveError/ModalSaveError';
import EmptyPlaceholder from 'common/EmptyPlaceholder/EmptyPlaceholder';
import Loader from 'common/Loader/Loader';

import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, message } from 'antd';
import { MESSAGE_TIME_DURATION } from '../../../../../../constants';
import { deserialize, fetchData, ObjectDeserializator, sendData, useDidMount } from 'utils/apiHelpers';
import { Pagination } from 'utils/commonTypes';

import {
  SegmentsListDescriptor,
  SegmentsPersonDescriptor,
  StaticSendData,
  DynamicSendData,
  FormSubmitValues,
  PhonesInputList,
  SqlInputRequest,
  InitialFormValues,
  SegmentData,
} from './model/segmentsModel';
import {
  GET_SEGMENTS_LIST,
  GET_SEGMENTS_USERS,
  GET_USER_BY_LOGIN,
  GET_USERS_BY_SQL_REQUEST,
} from './model/segmentsQueries';
import {
  segmentsListDeserializer,
  segmentsPersonDataDeserializer,
  userDataDeserializer,
} from './model/segmentsDeserializers';
import {
  UPDATE_DYNAMIC_SEGMENT,
  UPDATE_STATIC_SEGMENT,
  DELETE_SEGMENT,
  CREATE_DYNAMIC_SEGMENT,
  CREATE_STATIC_SEGMENT,
} from './model/segmentsMutations';
import { useTranslation } from 'react-i18next';

function Segments(props: { authClient: AuthClient; client: ApolloClient<any> }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const defaultPagination = { limit: 10, page: 1 };
  const emptyFormValues: InitialFormValues = {
    title: '',
    comment: '',
    sql: '',
    phonesList: '',
    radios: 'search',
    table: [],
  };

  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [loadingPhones, setLoadingPhones] = useState<boolean>(false);

  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [isCreatingSegment, setIsCreatingSegment] = useState<boolean>(false);
  const [isSaveError, setIsSaveError] = useState<boolean>(false);
  const [isValuesChanged, setIsValuesChanged] = useState<boolean>(false);
  const [isSegmentDynamic, setIsSegmentDynamic] = useState<boolean>(false);

  const [segmentsList, setSegmentsList] = useState<SegmentsListDescriptor>({});
  const [segmentUsers, setSegmentUsers] = useState<SegmentsPersonDescriptor[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<SegmentData>();
  const [selectedSegmentTitle, setSelectedSegmentTitle] = useState<string>('');
  const [initialFormValues, setInitialFormValues] = useState<InitialFormValues>(emptyFormValues);
  const [wayToAddUsers, setWayToAddUsers] = useState<string>('search'); // radio button value of inputs: 'search', 'sql' or 'phonesList'
  const [inputValue, setInputValue] = useState<PhonesInputList & SqlInputRequest>();

  useDidMount(() => {
    getSegmentsList();
  });

  // Fetching functions
  function getSegmentsList() {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_SEGMENTS_LIST,
        fetchPolicy: 'no-cache',
        variables: {
          pagination: { limit: 100, page: 0 },
          userPagination: { limit: 0, page: 0 },
        },
      }),
      setSegmentsList,
      (res) =>
        deserialize(res, (o) =>
          o
            .required('data.segments.getList.list')
            .asArrayOfObjects((d: ObjectDeserializator) => segmentsListDeserializer(d))
        ).reduce(
          (list, item) => ({
            ...list,
            [item.id]: {
              id: item.id,
              title: item.title,
              comment: item.comment,
              userIds: item.userIds,
              users: item.users,
              segmentType: item.segmentType,
              sql: item.sql,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              deletedAt: item.deletedAt,
            },
          }),
          {}
        )
    );
  }

  function getSegmentUsers(userIds: string[]) {
    fetchData(
      loadingTable,
      setLoadingTable,
      props.client.query({
        query: GET_SEGMENTS_USERS,
        fetchPolicy: 'no-cache',
        variables: {
          filter: { ids: userIds },
          paging: { limit: 100000, page: 0 },
        },
      }),
      setSegmentUsers,
      (res) =>
        deserialize(res, (o) =>
          o.required('data.users.getListAdvanced.list').asArrayOfObjects((o: ObjectDeserializator) => ({
            id: o.required('id').asString,
            person: o.required('person').asObject((p: ObjectDeserializator) => segmentsPersonDataDeserializer(p)),
          }))
        )
    );
  }

  function handleSelectSegment(id: string) {
    setIsValuesChanged(false);
    setIsCreatingSegment(false);
    setSelectedSegment(segmentsList[id]);
    setIsEmpty(false);
    setIsSegmentDynamic(segmentsList[id].segmentType === 'DYNAMIC');
    setSelectedSegmentTitle(segmentsList[id].title);
    setWayToAddUsers(segmentsList[id].segmentType === 'DYNAMIC' ? 'sql' : 'search');
  }

  function handleForNewForm() {
    setIsCreatingSegment(true);
    setIsEmpty(false);
    setIsSegmentDynamic(false);
    setSelectedSegmentTitle(t('modules.segments.handle_for_new_form')!);
    setSelectedSegment(undefined);
    setSegmentUsers([]);
    setInitialFormValues(emptyFormValues);
  }

  useEffect(() => {
    if (selectedSegment && !isValuesChanged) {
      setInitialFormValues({
        title: selectedSegment.title,
        comment: selectedSegment.comment,
        sql: selectedSegment.sql,
        phonesList: '',
        radios: isSegmentDynamic ? 'sql' : 'search',
        table: [...segmentUsers],
      });
    } else if (isValuesChanged) {
      form.setFieldsValue({
        table: [...segmentUsers],
      });
    }
  }, [segmentUsers]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [initialFormValues]);

  useEffect(() => {
    selectedSegment && getSegmentUsers(selectedSegment.userIds);
  }, [selectedSegment]);

  // "Cancel" button in the form for creating a new segment
  const handleCancelNewSegment = () => {
    setIsEmpty(true);
    setIsCreatingSegment(false);
  };

  // Button "Delete" existing segment
  const handleDeleteSegment = () => {
    sendData(
      loading,
      setLoading,
      props.client.mutate({ mutation: DELETE_SEGMENT, variables: { id: selectedSegment?.id } }),
      () => {
        message.success(t('modules.segments.message_success.delete'), MESSAGE_TIME_DURATION);
        getSegmentsList();
        setIsEmpty(true);
      },
      () => setIsSaveError(true),
      () => setLoading(false)
    );
  };

  // Pagination
  useEffect(() => {
    setPagination(defaultPagination);
  }, [selectedSegment]);

  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page : 1,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    setPagination(newPagination);
  }

  // Submits
  async function submitUpdateSegment(values: FormSubmitValues) {
    let id = selectedSegment?.id;

    if (isSegmentDynamic) {
      const dynamicData: DynamicSendData = {
        title: values.title,
        comment: values.comment,
        sql: values.sql,
      };

      sendData(
        loading,
        setLoading,
        props.client.mutate({ mutation: UPDATE_DYNAMIC_SEGMENT, variables: { id, data: dynamicData } }),
        () => {
          message.success(t('modules.segments.message_success.save'), MESSAGE_TIME_DURATION);
          getSegmentsList();
          setIsValuesChanged(false);
        },
        () => setIsSaveError(true),
        () => setLoading(false)
      );
    } else if (!isSegmentDynamic) {
      const usersIdsArr: string[] = values.table.map((row: SegmentsPersonDescriptor) => row.id);

      const staticData: StaticSendData = {
        title: values.title,
        comment: values.comment,
        userIds: usersIdsArr as string[],
      };

      sendData(
        loading,
        setLoading,
        props.client.mutate({ mutation: UPDATE_STATIC_SEGMENT, variables: { id, data: staticData } }),
        () => {
          message.success(t('modules.segments.message_success.save'), MESSAGE_TIME_DURATION);
          getSegmentsList();
          setIsValuesChanged(false);
        },
        () => setIsSaveError(true),
        () => setLoading(false)
      );
    }
  }

  async function submitCreateSegment(values: FormSubmitValues) {
    if (values.radios === 'sql') {
      const dynamicData: DynamicSendData = {
        title: values.title,
        comment: values.comment,
        sql: values.sql,
      };

      sendData(
        loading,
        setLoading,
        props.client.mutate({ mutation: CREATE_DYNAMIC_SEGMENT, variables: { data: dynamicData } }),
        () => {
          message.success(t('modules.segments.message_success.create'), MESSAGE_TIME_DURATION);
          getSegmentsList();
          setIsEmpty(true);
          setIsCreatingSegment(false);
          setIsValuesChanged(false);
        },
        () => setIsSaveError(true),
        () => setLoading(false)
      );
    } else {
      const usersIdsArr: string[] = values.table.map((row: SegmentsPersonDescriptor) => row.id);

      const staticData: StaticSendData = {
        title: values.title,
        comment: values.comment,
        userIds: usersIdsArr,
      };

      sendData(
        loading,
        setLoading,
        props.client.mutate({ mutation: CREATE_STATIC_SEGMENT, variables: { data: staticData } }),
        () => {
          message.success(t('modules.segments.message_success.create'), MESSAGE_TIME_DURATION);
          getSegmentsList();
          setIsEmpty(true);
          setIsCreatingSegment(false);
          setIsValuesChanged(false);
        },
        () => setIsSaveError(true),
        () => setLoading(false)
      );
    }
  }

  // The function of adding users by sql query and a list of phone numbers after entering the query into the input with a delay of 2 seconds
  const debouncedSearchTerm = useDebounce(inputValue, 2000);

  function useDebounce(value?: PhonesInputList & SqlInputRequest, delay?: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      addUsersFromPhonesOrSql(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  async function addUsersFromPhonesOrSql(value: PhonesInputList & SqlInputRequest) {
    if (value.phonesList) {
      const loginsArr = value.phonesList
        .match(/[0-9]/g)
        ?.join('')
        .match(/[0-9]{11}/g);
      const newUsers: SegmentsPersonDescriptor[] = [];
      // const wrongPhones: string[] = [];

      loginsArr &&
        (await Promise.all(
          loginsArr.map(async (login: string) => {
            try {
              // Separate loader near Radio while processing the list of numbers
              setLoadingPhones(true);
              const res = await props.client.query({
                query: GET_USER_BY_LOGIN,
                fetchPolicy: 'no-cache',
                variables: { login: login },
              });
              const newUser: SegmentsPersonDescriptor = deserialize(res, (o) =>
                o.required('data.users.findByLogin').asObject((o: ObjectDeserializator) => userDataDeserializer(o))
              );
              newUser && newUsers.push(newUser);
            } catch (e) {
              console.error(e);
              // Erroneous numbers are collected in a separate array for drawing in a separate block
              // wrongPhones.push(login);
              console.error('Wrong phone: ', login);
            } finally {
              setWayToAddUsers('phonesList');
            }
          })
        ));

      await setSegmentUsers([...newUsers, ...form.getFieldValue('table')]);

      form.setFieldsValue({
        phonesList: '',
      });
    } else if (value.sql) {
      await fetchData(
        loadingTable,
        setLoadingTable,
        props.client.query({
          query: GET_USERS_BY_SQL_REQUEST,
          fetchPolicy: 'no-cache',
          variables: {
            sql: value.sql,
            pagination: { limit: 100000, page: 0 },
          },
        }),
        setSegmentUsers,
        (res) =>
          deserialize(res, (o) =>
            o.required('data.segments.previewDynamicSegmentUsers.list').asArrayOfObjects((o: ObjectDeserializator) => ({
              id: o.required('id').asString,
              person: o.required('person').asObject((p: ObjectDeserializator) => segmentsPersonDataDeserializer(p)),
            }))
          )
      );
    }

    setLoadingPhones(false);
  }

  return (
    <section className="segments">
      <div className="segments__header-container">
        <h1 className="segments__header-title">{t('modules.segments.h1')}</h1>
        <Button
          id="SegmentsAddSegmentButton"
          type="ghost"
          className="segments__header-button"
          size="large"
          onClick={() => handleForNewForm()}
        >
          <PlusOutlined className="segments__header-button-icon" />
          <span>{t('modules.segments.span.add_segment')}</span>
        </Button>
      </div>

      <div className="segments__container">
        <div className="segments__container-left">
          <SegmentsMenu
            segmentsList={segmentsList}
            handleSelectSegment={handleSelectSegment}
            isCreatingSegment={isCreatingSegment}
          />
        </div>

        <div className="segments__container-right">
          <div className="segments__segment">
            {loading ? (
              <Loader />
            ) : isEmpty ? (
              <div className="segments__segment-placeholder-wrapper">
                <EmptyPlaceholder
                  handleClick={handleForNewForm}
                  title={t('modules.segments.empty_placeholder.segments')!}
                  text={
                    <span>
                      {t('modules.segments.span.text.0')} <br /> {t('modules.segments.span.text.1')}
                    </span>
                  }
                  buttonText={t('modules.segments.empty_placeholder.add_segment')!}
                />
              </div>
            ) : (
              <Form
                form={form}
                name="newSegment"
                className="segments__segment-form"
                initialValues={initialFormValues}
                onValuesChange={(value: any) => {
                  setIsValuesChanged(true);
                  if (value.phonesList || value.sql) {
                    setInputValue(value);
                  }
                }}
                onFinish={isCreatingSegment ? submitCreateSegment : submitUpdateSegment}
              >
                <SegmentForm
                  selectedSegmentTitle={selectedSegmentTitle}
                  isSegmentDynamic={isSegmentDynamic}
                  isCreatingSegment={isCreatingSegment}
                  isValuesChanged={isValuesChanged}
                  loadingPhones={loadingPhones}
                  wayToAddUsers={wayToAddUsers}
                  setWayToAddUsers={setWayToAddUsers}
                />
                {loadingTable ? (
                  <Loader />
                ) : (
                  <Form.Item
                    name="table"
                    htmlFor="table"
                    // rules={[{ required: true, message: t('modules.segments.form_item') }]}
                  >
                    <TableFormComponent
                      selectedSegment={selectedSegment}
                      wayToAddUsers={wayToAddUsers}
                      isSegmentDynamic={isSegmentDynamic}
                      pagination={pagination}
                      onPageChange={onPageChange}
                      apolloClient={props.client}
                    />
                  </Form.Item>
                )}
                <Buttons
                  id="Segments"
                  loading={loading}
                  handleCancelNewSegment={handleCancelNewSegment}
                  handleDeleteSegment={handleDeleteSegment}
                  isCreatingSegment={isCreatingSegment}
                />
              </Form>
            )}

            <ModalSaveError
              show={isSaveError}
              onOk={() => {
                setIsSaveError(false);
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default withApollo(Segments);
