import React, { useEffect, useState } from 'react';
import { Empty, Table, Badge } from 'antd';
import ApolloClient from 'apollo-client';

import './OrdersTable.scss';
import ExpandedRowRender from './components/ExpandedRowRender/ExpandedRowRender';
import Loader from 'common/Loader/Loader';

import { UserOrders, UserOrdersList, LoyaltyRules, OrderStatus, PaymentPlace } from '../../../../model/usersModel';
import { GET_USER_ORDERS } from '../../../../model/usersQueries';
import { deserializeUserOrders } from '../../../../model/usersDeserializers';

import { Pagination } from 'utils/commonTypes';
import { deserialize, fetchData, formatFraction } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';

const OrdersTable = (props: {
  userId: string;
  userBonusCount: string;
  loyaltyRules?: LoyaltyRules;
  apolloClient: ApolloClient<any>;
}) => {
  const { t } = useTranslation();
  const { Column } = Table;
  const [loading, setLoading] = useState<boolean>(false);
  const [userOrders, setUserOrders] = useState<UserOrders>();

  const defaultPagination = { limit: 10, page: 1 };
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  function getUserOrders(userId: string, pagination: Pagination) {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_USER_ORDERS,
        variables: { userIds: [userId], pagination: { limit: pagination.limit, page: pagination.page - 1 } },
        fetchPolicy: 'no-cache',
      }),
      setUserOrders,
      (res) => deserialize(res, (o) => o.required('data.order.getList').asObject((o) => deserializeUserOrders(o)))
    );
  }

  useEffect(() => {
    setPagination(defaultPagination);
  }, [props.userId]);

  useEffect(() => {
    getUserOrders(props.userId, pagination);
  }, [pagination]);

  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page : 1,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    setPagination(newPagination);
  }

  const STATUS_COLORS = {
    [OrderStatus.PAYED]: '#03B94E',
    [OrderStatus.UNPAYED]: '#F8A608',
    [OrderStatus.PAYMENT_PROCESSING]: 'radial-gradient(circle, #fff 2px, #87d068 2px)',
    [OrderStatus.CANCELED]: '#f50',
    [OrderStatus.REFUND]: '#5C5C5C',
    [OrderStatus.PARTIAL_REFUND]: 'linear-gradient(to right, #03B94E 50%, #5C5C5C 50%)',
  };

  const STATUS_TITLES = {
    [OrderStatus.PAYED]: t('modules.orders_table.status_titles.paid'),
    [OrderStatus.UNPAYED]: t('modules.orders_table.status_titles.booked'),
    [OrderStatus.PAYMENT_PROCESSING]: t('modules.orders_table.status_titles.process_payment'),
    [OrderStatus.CANCELED]: t('modules.orders_table.status_titles.canceled'),
    [OrderStatus.REFUND]: t('modules.orders_table.status_titles.returned'),
    [OrderStatus.PARTIAL_REFUND]: t('modules.orders_table.status_titles.partially_returned'),
  };

  const PAYMENT_PLACE = {
    [PaymentPlace.MOBILE_CLIENT]: t('modules.orders_table.payment_place.fan_app'),
    [PaymentPlace.CLIENT]: t('modules.orders_table.payment_place.fan_site'),
    [PaymentPlace.ADMIN]: t('modules.orders_table.payment_place.admin_panel'),
    [PaymentPlace.CASHIER]: t('modules.orders_table.payment_place.cash_register'),
    [PaymentPlace.UNKNOWN]: '—',
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          dataSource={userOrders?.list}
          rowKey="id"
          pagination={{
            total: userOrders?.total,
            onChange: onPageChange,
            current: pagination.page,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          expandedRowRender={(record: UserOrdersList) => {
            if (record.items.length !== 0) {
              return <ExpandedRowRender record={record} loyaltyRules={props.loyaltyRules} />;
            }
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('modules.orders_table.table')} />;
          }}
          className="components-table-demo-nested"
          style={{ width: '100%', marginTop: '-16px' }}
        >
          <Column
            title={t('modules.orders_table.column.order_no')}
            dataIndex="visibleId"
            align="left"
            className="block-column"
          />
          <Column
            title={t('modules.orders_table.column.price_1')}
            align="left"
            render={(dataSource: UserOrdersList) => formatFraction(dataSource.price)}
            className="block-column"
          />
          <Column
            title={t('modules.orders_table.column.price_2')}
            align="left"
            render={(dataSource: UserOrdersList) => formatFraction(dataSource.priceWithDiscount)}
            className="block-column"
          />
          <Column
            title={t('modules.orders_table.column.order_status')}
            align="left"
            render={(dataSource: UserOrdersList) => (
              <span>
                <Badge color={STATUS_COLORS[dataSource.status]} className="subtable-title__badge" />
                {STATUS_TITLES[dataSource.status]}
              </span>
            )}
            className="block-column"
          />
          <Column
            title={t('modules.orders_table.column.payment_time')}
            key="purchaseTime"
            align="left"
            render={(dataSource: UserOrdersList) =>
              dataSource.purchaseTime?.toLocaleString('ru-Ru', { dateStyle: 'short', timeStyle: 'short' }) || '—'
            }
            className="block-column"
          />
          <Column
            title={t('modules.orders_table.column.payment_point')}
            key="status"
            align="left"
            render={(dataSource: UserOrdersList) =>
              dataSource.additionalData?.payment?.paymentPlace
                ? PAYMENT_PLACE[dataSource.additionalData.payment.paymentPlace]
                : '—'
            }
            className="block-column"
          />
        </Table>
      )}
    </>
  );
};

export default OrdersTable;
