import * as types from '../types';
import { assoc } from 'ramda';

const initialState = {
  promoList: [],
  codesData: {},
  codesModalIsOpened: false,
  activationsCodesModalIsOpened: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROMO_LIST:
      return assoc('promoList', action.arr, state);
    case types.SET_CODES_TO_REDUCER:
      return assoc('codesData', action.codesData, state);
    case types.OPEN_CODES_MODAL:
      return assoc('codesModalIsOpened', action.isOpened, state);
    case types.OPEN_ACTIVATIONS_CODES_MODAL:
      return assoc('activationsCodesModalIsOpened', action.isOpened, state);

    default:
      return state;
  }
};
