import { connect } from 'react-redux';
import * as actions from '../actions';

const mapStateToProps = state => {
  const reducer = () => state.reducer;
  const tickets = () => reducer().map;
  return {
    selectedPlaces: tickets().selectedPlaces,
    mapPlaces: tickets().mapPlaces,
    mapWeight: tickets().mapWeight,
    mapSideMenuStep: tickets().mapSideMenuStep,
  };
};
const mapDispatchToProps = dispatch => ({...actions, dispatch});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
