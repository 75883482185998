import React, { createRef, useState } from 'react';
import './AccessEntrances.scss';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import Loader from '../../../../../common/Loader/Loader';
import { GET_ENTRANCES_DATA, GET_VENUES_LIST, GET_ZONES_LIST } from '../../graphql/query';
import { CREATE_ACCESS_ENTRANCE, DELETE_ACCESS_ENTRANCE, UPDATE_ACCESS_ENTRANCE } from '../../graphql/mutation';
import { MESSAGE_TIME_DURATION } from '../../../../../constants.js';
import { deserialize, fetchData, sendData, useDidMount } from 'utils/apiHelpers';
import { Button, message } from 'antd';
import { Icon } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import AccessMenu from '../AccessMenu/AccessMenu';
import AccessEntranceForm from '../AccessEntranceForm/AccessEntranceForm';
import {
  accessEntranceDeserializer,
  accessVenueDeserializer,
  accessZoneDeserializer
} from '../../graphql/accessDeserializers';
import {
  AccessZoneDescriptor,
  CreateEntranceData,
  EntranceDataDescriptor,
  FormType,
  IdAndTitleDescriptor,
  UpdateEntranceData
} from '../../graphql/accessZoneModel';
import EmptyPlaceholder from '../../../../../common/EmptyPlaceholder/EmptyPlaceholder';
import ModalCloseWindowWarning from '../ModalCloseWindowWarning/ModalCloseWindowWarning';
import { transliterate } from '../../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

// const { TabPane } = Tabs;

function AccessEntrances(props: { client: ApolloClient<any> }) {
  const { t } = useTranslation();
  const [data, setData] = useState<EntranceDataDescriptor>({});
  const [zonesList, setZonesList] = useState<AccessZoneDescriptor[]>([]);
  const [venues, setVenues] = useState<IdAndTitleDescriptor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>('empty');
  const [entranceId, setEntranceId] = useState<string>('');
  const [modalCloseWindowWarningData, setModalCloseWindowWarningData] = useState<{ id: string; visible: boolean }>({
    id: '',
    visible: false,
  });
  const [isChangedInput, setIsChangedInput] = useState<boolean>(false);
  const formRef = createRef<FormComponentProps>();

  //Rendering data on page load
  useDidMount(() => {
    refreshData();
    getZonesList();
    getVenuesList();
  });

  //Styling for the Stadium Tab
  // const tabBarStyle = {
  //   borderBottom: 'none',
  //   marginTop: '40px',
  //   marginBottom: 0,
  //   marginLeft: '24px',
  // };

  //When switching the submenu, we change the data on the right side
  function handleDataChange(id: string) {
    setFormType('update');
    setEntranceId(id);
    setIsChangedInput(false);
  }

  //Open the add form
  const handleClick = () => {
    setFormType('create entrance');
  };

  //Delete handler
  function handleDelete() {
    deleteEntrance(entranceId);
    setFormType('empty');
  }

  //Submission handler
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    formRef.current!.form.validateFieldsAndScroll((err, values) => {
      const zonesInIds = values.zonesIn.map((zone: { key: string }) => zone.key);
      const zoneOutIds = values.zonesOut.map((zone: { key: string }) => zone.key);
      switch (formType) {
        case 'update':
          updateEntranceData({
            id: entranceId,
            data: {
              name: transliterate(values.techName) || transliterate(values.title),
              title: values.title,
              description: values.description || '',
              zoneInIds: zonesInIds,
              zoneOutIds: zoneOutIds,
              venueId: venues[0].id,
            },
          });
          break;

        case 'create entrance':
          createEntrance({
            name: transliterate(values.techName) || transliterate(values.title),
            title: values.title,
            description: values.description || '',
            zoneInIds: zonesInIds,
            zoneOutIds: zoneOutIds,
            venueId: venues[0].id,
          });
          break;
      }
    });
  };

  //Getting data from the server
  async function refreshData() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ENTRANCES_DATA,
        fetchPolicy: 'no-cache',
      }),

      setData,
      (res) =>
        deserialize(res, (d) =>
          d
            .required('data.acs.entrance.getList')
            .asArrayOfObjects((o) => accessEntranceDeserializer(o))
            .reduce(
              (entrances, entrance) => ({
                ...entrances,
                [entrance.id]: {
                  title: entrance.title,
                  name: entrance.name,
                  description: entrance.description,
                  venue: entrance.venue,
                  zonesIn: entrance.zonesIn,
                  zonesOut: entrance.zonesOut,
                },
              }),
              {}
            )
        )
    );
  }

  //Getting the list of Zones from the server
  async function getZonesList() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ZONES_LIST,
        fetchPolicy: 'no-cache',
      }),

      setZonesList,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.acs.accessZone.getList').asArrayOfObjects((o) => accessZoneDeserializer(o))
        )
    );
  }

  //Getting the venue list from the server
  async function getVenuesList() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_VENUES_LIST,
        fetchPolicy: 'no-cache',
      }),

      setVenues,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.venueLocalised.getList.list').asArrayOfObjects((o) => accessVenueDeserializer(o))
        )
    );
  }

  //Editing Login Information
  async function updateEntranceData(entranceData: UpdateEntranceData) {
    await sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: UPDATE_ACCESS_ENTRANCE,
        variables: { id: entranceData.id, data: entranceData.data },
        fetchPolicy: 'no-cache',
      }),

      () => {
        message.success(t('modules.access_entrance.update_entrance_data.message_success'), MESSAGE_TIME_DURATION);
        refreshData();
      },
      () => {
        setFormType('empty');
        setLoading(false);
      },
      () => {
        setIsChangedInput(false);
      }
    );
  }

  //Delete Login
  async function deleteEntrance(id: string) {
    await sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: DELETE_ACCESS_ENTRANCE,
        variables: { id },
        fetchPolicy: 'no-cache',
      }),

      () => {
        refreshData();
      }
    );
  }

  //Adding an Entry
  async function createEntrance(data: CreateEntranceData) {
    await sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: CREATE_ACCESS_ENTRANCE,
        variables: { data },
        fetchPolicy: 'no-cache',
      }),
      () => {
        message.success(t('modules.access_entrance.update_entrance_data.message_success'), MESSAGE_TIME_DURATION);
        setFormType('update');
      },
      () => {
        setFormType('empty');
        setLoading(false);
      },
      () => {
        setIsChangedInput(false);
      },
      (res) => {
        refreshData();
        setEntranceId(deserialize(res, (d) => d.required('data.acs.entrance.create.id').asString));
      }
    );
  }

  return (
    <section className="AccessEntrances">
      <header className="AccessEntrances__header">
        {' '}
        <h2 className="AccessEntrances__title">{t('modules.access_entrance.header')}</h2>
        <Button
          id="AccessEntrancesAddInputButton"
          type="ghost"
          size="large"
          className="AccessEntrances__addButton"
          onClick={() => setFormType('create entrance')}
        >
          <Icon type="plus" className="AccessEntrances__addButton-icon"/>
          <span className="AccessEntrances__addButton-text">{t('modules.access_entrance.span')}</span>
        </Button>
      </header>

      <ModalCloseWindowWarning
        modalData={modalCloseWindowWarningData}
        setModalData={setModalCloseWindowWarningData}
        setFormType={setFormType}
        setId={setEntranceId}
      />

      <div className="AccessEntrances__content">
        <div className="AccessEntrances__left-container">
          <AccessMenu
            data={data}
            handleDataChange={handleDataChange}
            id={entranceId}
            isChangedInput={isChangedInput}
            setModalCloseWindowWarningData={setModalCloseWindowWarningData}
            formType={formType}
          />
        </div>

        <div className="AccessEntrances__right-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {formType !== 'empty' ? (
                <div className="AccessEntrances__right-container-content">
                  <div className="AccessEntrances__right-container-content-block">
                    <h3 className="AccessEntrances__right-container-title">
                      {formType !== 'create entrance'
                        ? data[entranceId]
                          ? data[entranceId].title
                          : ''
                        : t('modules.access_entrance.form_type')}
                    </h3>
                    <CloseOutlined
                      className="AccessEntrances__right-container__body__close-form-icon"
                      onClick={() => {
                        setFormType('empty');
                      }}
                    />
                  </div>
                  <AccessEntranceForm
                    data={data[entranceId]}
                    formType={formType}
                    zonesList={zonesList}
                    setIsChangedInput={setIsChangedInput}
                    handleDelete={handleDelete}
                    onSubmit={handleSubmit}
                    wrappedComponentRef={formRef}
                  />
                </div>
              ) : (
                <EmptyPlaceholder
                  handleClick={handleClick}
                  title={t('modules.access_entrance.empty_placeholder.acs')!}
                  text={t('modules.access_entrance.empty_placeholder.manage_acs')!}
                  buttonText={t('modules.access_entrance.empty_placeholder.add_entrance')!}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default withApollo(AccessEntrances);
