import gql from 'graphql-tag';

export const CREATE_DYNAMIC_SEGMENT = gql `
  mutation createDynamicSegment($data: SEG_DynamicSegmentCreateInput!){ 
    segments { 
      createDynamic(data: $data) {
        id
        title
        comment
        userIds
      } 
    }
  }
`;

export const CREATE_STATIC_SEGMENT = gql `
  mutation createStaticSegment($data: SEG_StaticSegmentCreateInput!){ 
    segments { 
      createStatic(data: $data) {
        id
        title
        comment
        userIds
      } 
    }
  }
`;

export const UPDATE_DYNAMIC_SEGMENT = gql `
  mutation updateDynamicSegment($id: ID!, $data: SEG_DynamicSegmentUpdateInput!){ 
    segments { 
      updateDynamic(id: $id, data: $data) {
        id
        title
        comment
        userIds
        segmentType
        createdAt
        updatedAt
      } 
    }
  }
`;

export const UPDATE_STATIC_SEGMENT = gql `
  mutation updateStaticSegment($id: ID!, $data: SEG_StaticSegmentUpdateInput!){ 
    segments { 
      updateStatic(id: $id, data: $data) {
        id
        title
        comment
        userIds
        segmentType
        createdAt
        updatedAt
      } 
    }
  }
`;

export const DELETE_SEGMENT = gql `
  mutation deleteSegment($id: ID!){ 
    segments { 
      delete(id: $id)
    }
  }
`;