import gql from 'graphql-tag';

export const GET_ROLES = gql`
  query {
    roles {
      getList {
        id
        name
        description
        permissions
        users {
          id
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
          login
        }
      }
    }
  }
`;
export const GET_USER_ROLES = gql`
  query($id: ID!) {
    users {
      getUserById(id: $id) {
        roles {
          id
        }
      }
    }
  }
`;
export const GET_SERVICES = gql`
  query {
    services {
      getList {
        id
        name
        description
        permissions {
          name
          description
          groupName
        }
        groups {
          name
          title
        }
      }
    }
  }
`;
export const SEARCH_USERS = gql`
  query searchUsers($quickSearch: String!) {
    users {
      getListAdvanced(filter: { quickSearch: $quickSearch }) {
        list {
          id
          roles {
            id
          }
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
          login
        }
      }
    }
  }
`;
