import React from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PromoAdd from '../../../../PromoAdd';
import {useTranslation} from 'react-i18next';

// const { Search } = Input;

const PromoHeader = (props) => {
  const {t} = useTranslation();
  return (
    <div className="promocode__up-action-panel df jcsb">
      <h1>{t('modules.promocodes.header')}</h1>
      {/* Will work later */}
      {/* <Search
        placeholder={t('modules.promoсodes.ui.placeholder')}
        // onSearch={debounce(props.handlePromoSearch, 500)}
        style={{ width: 372 }}
      /> */}

      <Button
        id="PromoHeaderCreateCodeButton"
        size="large"
        onClick={() => {
          const { setIsAddVisible, setEditingMode } = props;
          setEditingMode(false);
          setIsAddVisible(true);
        }}
      >
        <PlusOutlined />
        {t('modules.promocodes.ui.button_text')}
      </Button>
      <PromoAdd onClose={() => props.setIsAddVisible(false)} visible={props.isAddVisible} />
    </div>
  );
};

export default PromoHeader;
