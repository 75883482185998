import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const AddHeader = (props) => {
  const { t } = useTranslation();
  return (
    <div className="promo-add__header df">
      <div className="promo-add__close">
        <Button id="AddHeaderCloseOutlinedButton" type="link" size="large" onClick={() => props.setIsAddVisible(false)}>
          <CloseOutlined />
        </Button>
      </div>
      <Input
        size="large"
        placeholder={t('modules.add_header.new_promocode')}
        value={props.values.title}
        onChange={(e) => props.setFieldValue('title', e.target.value)}
      />
      <Button id="AddHeaderSaveButton" size="large" htmlType="submit">
        {t('modules.add_header.save')}
      </Button>
    </div>
  );
};

export default AddHeader;
