import React, { useState } from 'react';
import '../../../../Loyalty.scss';
import { Icon } from '@ant-design/compatible';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

function ModalDeactivationWarning(props: any) {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        id="ModalDeactivationWarningDeactivateProgramButton"
        className="route-content_loyalty-deactivate-button"
        onClick={() => setIsVisible(true)}
      >
        {t('modules.modal_deactivation_warning.button.deactivate_program')}
      </Button>
      <Modal
        title={false}
        closable={false}
        visible={isVisible}
        footer={[
          <Button
            id="ModalDeactivationWarningDeactivateButton"
            key="deactivate"
            className="route-content_loyalty-deactivate-button"
            onClick={() => {
              props.activeProgramState();
              setIsVisible(false);
            }}
          >
            {t('modules.modal_deactivation_warning.button.deactivate')}
          </Button>,
          <Button
            id="ModalDeactivationWarningCancelButton"
            key="cancel"
            className="route-content_loyalty-cancel-button"
            type="primary"
            onClick={() => setIsVisible(false)}
          >
            {t('modules.modal_deactivation_warning.button.cancel')}
          </Button>,
        ]}
        width={415}
      >
        <div className="route-content_loyalty-modal-title-block">
          <Icon type="close-circle" className="route-content_loyalty-close-circle" />
          <h3 className="route-content_loyalty-modal-title">{t('modules.modal_deactivation_warning.h3')}</h3>
        </div>
        <p className="route-content_loyalty-modal-text">
          {t('modules.modal_deactivation_warning.p.0')}&nbsp;{t('modules.modal_deactivation_warning.p.1')}
          &nbsp;{t('modules.modal_deactivation_warning.p.2')}&nbsp;{t('modules.modal_deactivation_warning.p.3')}:{' '}
          {t('modules.modal_deactivation_warning.p.4')}&nbsp;{t('modules.modal_deactivation_warning.p.5')}&nbsp;
          {t('modules.modal_deactivation_warning.p.6')}
        </p>
      </Modal>
    </>
  );
}

export default ModalDeactivationWarning;
