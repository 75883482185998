import React from 'react';
import { pathOr } from 'ramda';
import { ICodesModalBody, ICodesData } from '../../interfaces/IPromoCodesActivationsModal';
import { useTranslation } from 'react-i18next';

const CodesModalBody = (props: ICodesModalBody) => {
  const codes: Array<object> = pathOr([], ['codesData', 'codes'], props);
  const maxUseCounts: string = pathOr('∞', ['codesData', 'maxUseCounts'], props);
  const { t } = useTranslation();
  return (
    <div className="promocodes-activations-modal__body">
      {codes.map((item: ICodesData, index: number) => {
        const isOddNumber: boolean = (index + 1) % 2 !== 0;
        const { value, useCounter } = item;
        return (
          <div
            key={index}
            className={`df promocodes-activations-modal__activations-wrapper ${
              isOddNumber ? 'promocodes-activations-modal__activations-wrapper__odd-elem' : ''
            }`}
          >
            <span>{index + 1}</span>
            <div className="promocodes-activations-modal__activations">{value}</div>
            <p className="promocodes-activations-modal__activations-count">
              {useCounter} {t('modules.codes_modal_body')} {maxUseCounts}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default CodesModalBody;
