import gql from 'graphql-tag';

export const CREATE_PROMOCODE_DESCRIPTOR = gql`
  mutation createPromocodeDescriptor($data: PromocodeDescriptorInput!) {
    promocodes {
      add(data: $data) {
        id
      }
    }
  }
`;
export const ADD_PREFIX_PROMO = gql`
  mutation createPromocodeDescriptor($data: GeneratePromocodesInput) {
    promocodes {
      generatePromocodes(data: $data) {
        id
        title
        maxUseCounts
        maxUseCountsPerUser
        period {
          start
          end
        }
        status
        isActive
        commentary
        discount {
          type
          value
        }
        adminUseOnly
        useOn
        sectorBound {
          sectorIds
        }
        rowBound {
          rowIds
        }
        placeBound {
          placeIds
        }
        tournamentBound {
          tournamentIds
        }
        stageBound {
          stageIds
        }
        seasonBound {
          seasonIds
        }
        priceBound {
          priceRange {
            start
            end
          }
        }
        orderItemTypeBound {
          types
        }
        userBound {
          userIds
        }
        codes {
          list {
            value
            useCounter
          }
        }
      }
    }
  }
`;
export const ADD_PROMO_CODES = gql`
  mutation createPromocodeDescriptor($data: PromocodeInput!) {
    promocodes {
      addPromocodesToDescriptor(data: $data) {
        id
        title
        maxUseCounts
        maxUseCountsPerUser
        period {
          start
          end
        }
        status
        isActive
        commentary
        discount {
          type
          value
        }
        adminUseOnly
        useOn
        sectorBound {
          sectorIds
        }
        rowBound {
          rowIds
        }
        placeBound {
          placeIds
        }
        tournamentBound {
          tournamentIds
        }
        stageBound {
          stageIds
        }
        seasonBound {
          seasonIds
        }
        priceBound {
          priceRange {
            start
            end
          }
        }
        orderItemTypeBound {
          types
        }
        userBound {
          userIds
        }
        codes {
          list {
            value
            useCounter
          }
        }
      }
    }
  }
`;

export const UPDATE_PROMOCODE = gql`
  mutation($id: ID!, $data: PromocodeDescriptorUpdateInput!) {
    promocodes {
      update(id: $id, data: $data) {
        id
        title
      }
    }
  }
`;