import React, { useEffect, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { Icon } from '@ant-design/compatible';
import ApolloClient from 'apollo-client';

import { UserInfo, LoyaltyRules } from '../../model/usersModel';
import { GET_LOYALTY_RULES, GET_USER_BONUS_COUNT, GET_USER_BY_ID } from '../../model/usersQueries';
import { deserializeLoyaltyRules, deserializeUserInfo } from '../../model/usersDeserializers';
import { deserialize, fetchData, formatFraction } from 'utils/apiHelpers';
import OrdersTable from './components/OrdersTable/OrdersTable';
import EntriesTable from './components/EntriesTable/EntriesTable';
import LoyaltyTable from './components/LoyaltyTable/LoyaltyTable';

import phoneImg from 'img/phone.svg';
import mailImg from 'img/mail.svg';
import calendarImg from 'img/calendar.svg';
import { useTranslation } from 'react-i18next';

const UserDataModal = (props: {
  userId: string;
  handleOpenUserDataModal: (value: boolean) => void;
  isUserDataModalOpened: boolean;
  apolloClient: ApolloClient<any>;
}) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userBonusCount, setUserBonusCount] = useState<string>('');
  const [loyaltyRules, setLoyaltyRules] = useState<LoyaltyRules>();
  const [tabActiveKey, setTabActiveKey] = useState<string>('1');

  function getUserById(userId: string) {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_USER_BY_ID,
        variables: { userId },
      }),
      setUserInfo,
      (res) => deserialize(res, (o) => o.required('data.users.getUserById').asObject((o) => deserializeUserInfo(o)))
    );
  }

  function getUserBonusCount(userId: string) {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_USER_BONUS_COUNT,
        variables: { userId },
        fetchPolicy: 'no-cache',
      }),
      setUserBonusCount,
      (res) => deserialize(res, (o) => o.required('data.loyalty.transactionQuery.getUserBonusCount').asString)
    );
  }

  function getLoyaltyRules() {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_LOYALTY_RULES,
        fetchPolicy: 'no-cache',
      }),
      setLoyaltyRules,
      (res) =>
        deserialize(res, (o) =>
          o.required('data.loyalty.loyaltyDescriptorQuery.getDescriptor').asObject((o) => deserializeLoyaltyRules(o))
        )
    );
  }

  useEffect(() => {
    getUserById(props.userId);
    getUserBonusCount(props.userId);
    getLoyaltyRules();
  }, [props.userId]);

  return (
    <Drawer
      title={t('modules.user_data_modal_index.drawer')}
      placement="right"
      closable={true}
      onClose={() => {
        props.handleOpenUserDataModal(false);
        setTabActiveKey('1');
      }}
      visible={props.isUserDataModalOpened}
      width="80%"
      height="100%"
      className="user-data-modal"
      bodyStyle={{ background: '#f0f2f5' }}
      closeIcon={<Icon type="left" />}
    >
      <div className="user-data-modal__wrapper">
        <div className="user-data-modal__personal-data-wrapper">
          <div className="user-data-modal__full-name">
            <p>
              {userInfo &&
                `${userInfo.surname.toString('ru')} ${userInfo.name.toString('ru')} ${
                  userInfo.patronymic?.toString('ru') || ''
                }`}
            </p>
          </div>
          <div className="user-data-modal__personal-data df">
            {loyaltyRules?.isActive && (
              <p className="user-data-modal__bonus-count">
                {formatFraction(userBonusCount) + ' ' + loyaltyRules?.currencyNameMod50.toUpperCase()}
              </p>
            )}
            {userInfo?.phone && (
              <div className="user-data-modal__personal-data__container df aic">
                <div>
                  <img src={phoneImg} alt={t('modules.user_data_modal_index.img.phone')!} />
                  <p>{userInfo?.phone}</p>
                </div>
              </div>
            )}
            {userInfo?.email && (
              <div className="user-data-modal__personal-data__container df aic">
                <div>
                  <img src={mailImg} alt={t('modules.user_data_modal_index.img.mail')!} />
                  <p>{userInfo?.email}</p>
                </div>
              </div>
            )}
            {userInfo?.birthday && (
              <div className="user-data-modal__personal-data__container df aic">
                <div>
                  <img src={calendarImg} alt={t('modules.user_data_modal_index.img.calendar')!} />
                  <p>
                    {userInfo?.birthday.toLocaleString('ru-Ru', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="user-data-modal__events-data-wrapper df jcc aifs">
          <Tabs
            activeKey={tabActiveKey} // defaultActiveKey does not work, done through state, which is set to a default value when closing
            onTabClick={(key: string) => setTabActiveKey(key)}
            style={{ width: '100%', padding: '24px 16px 16px 16px' }}
          >
            <TabPane
              key="1"
              tab={<div className="tab-title">{t('modules.user_data_modal_index.tab_pane.history_orders')}</div>}
            >
              <OrdersTable
                userId={props.userId}
                userBonusCount={userBonusCount}
                loyaltyRules={loyaltyRules}
                apolloClient={props.apolloClient}
              />
            </TabPane>

            <TabPane
              key="2"
              tab={<div className="tab-title">{t('modules.user_data_modal_index.tab_pane.passages')}</div>}
            >
              <EntriesTable userId={props.userId} apolloClient={props.apolloClient} />
            </TabPane>

            <TabPane
              key="3"
              tab={<div className="tab-title">{t('modules.user_data_modal_index.tab_pane.loyalty_program')}</div>}
            >
              <LoyaltyTable userId={props.userId} apolloClient={props.apolloClient} loyaltyRules={loyaltyRules} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Drawer>
  );
};

export default UserDataModal;
