import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import {ACC_AccessCategory} from '../../../../../model/accreditationModel';
import styles from './AccessCategorySelector.module.scss';

const {Option} = Select;

export default function AccessCategorySelector(props: {
  accessCategories?: ACC_AccessCategory[];
  accessCategory: ACC_AccessCategory;
  setAccessCategory: (accessCategory: ACC_AccessCategory | undefined) => void;
  onChange?: (value?: any) => void;
}) {
  const [input, setInput] = useState<any>(props.accessCategory.id);

  useEffect(() => {
    if (props.onChange && input) props.onChange(input);
  }, [input]);

  return (
    <Select
      className={styles.select}
      size="large"
      onChange={(value) => {
        props.setAccessCategory(props.accessCategories?.find((item: ACC_AccessCategory) => item.id === value));
        setInput(value);
      }}
      defaultValue={props.accessCategory.title.toString()}
    >
      {props.accessCategories?.map((item: ACC_AccessCategory, index: number) => (
        <Option key={index} value={item.id} className={styles.option}>
          {item.title.toString()}
        </Option>
      ))}
    </Select>
  );
}
