import React from 'react';
import './ModalCloseWindowWarning.scss';
import { Icon } from '@ant-design/compatible';
import { Button, Modal } from 'antd';
import { FormType } from '../../graphql/accessZoneModel';
import { useTranslation } from 'react-i18next';

function ModalCloseWindowWarning(props: {
  modalData: { id: string; visible: boolean };
  setModalData: (object: { id: string; visible: boolean }) => void;
  setId: (id: string) => void;
  setFormType: (item: FormType) => void;
}) {
  const { t } = useTranslation();
  const handleOk = () => {
    props.setFormType('initial');
    props.setId(props.modalData.id);
    props.setModalData({ id: '', visible: false });
  };

  const handleCancel = () => {
    props.setModalData({ id: '', visible: false });
  };

  return (
    <div className="ModalCloseWindowWarning">
      <Modal
        title={false}
        closable={false}
        visible={props.modalData.visible}
        bodyStyle={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
        footer={null}
        width={415}
      >
        <div className="ModalCloseWindowWarning__content">
          <h3 className="ModalCloseWindowWarning__title">
            {' '}
            <Icon type="close-circle" className="ModalCloseWindowWarning__icon"/>{' '}
            {t('modules.modal_close_window_warning.header')}
          </h3>
          <p className="ModalCloseWindowWarning__text">{t('modules.modal_close_window_warning.text')}</p>
        </div>
        <div>
          <Button
            id="ModalCloseWindowWarningCloseButton"
            key="close"
            className="ModalCloseWindowWarning__button-close"
            onClick={() => {
              handleOk();
            }}
          >
            {t('modules.modal_close_window_warning.button.close')}
          </Button>

          <Button id="ModalCloseWindowWarningCancelButton" key="cancel" className="ModalCloseWindowWarning__button-cancel" type="primary" onClick={handleCancel}>
            {t('modules.modal_close_window_warning.button.cancel')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ModalCloseWindowWarning;
