import gql from 'graphql-tag';

export const GET_ACCREDITATIONS_LIST = gql`
  query accreditationList($pagination: Pagination, $filter: ACC_AccreditationListRequestFilterInput) {
    accreditation {
      accreditation {
        getList(pagination: $pagination, filter: $filter) {
          total
          list {
            id
            visibleId
            accreditedUser {
              createdAt
              updatedAt
              user {
                id
                login
                visibleId
                person {
                  name
                  patronymic
                  surname
                  photo {
                    id
                    publicLink
                  }
                  contacts {
                    type
                    value
                  }
                }
              }
            }
            requestStatus
            createdAt
            requestType
            seasons {
              id
              startDate
              endDate
            }

            tournaments {
              id
              title
            }

            matches {
                id
                title
                description
                startTime
                endTime
                team1 {
                  id
                  title
                }
                team2 {
                  id
                  title
                }
            }
            accessCategory {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_REJECTION_REASONS = gql`
  query getRejectionReasons {
    accreditation {
      rejectionReason {
        getList {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_TOURNAMENTS_LIST = gql`
  query tornamentsList {
    tournament {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_ACCREDITATION_DATA_BY_ID = gql`
  query getAccreditationDataById($id: ID!) {
    accreditation {
      accreditation {
        getById(id: $id) {
          id
          requestType
          additionalFields {
            value
            field {
              id
              title
              description
              type
              appliedToCategoryId
              hint
              required
            }
          }
          accessCategory {
            id
            title
            fields {
              id
              title
              hint
              type
              description
              appliedToCategoryId
              required
            }
            zones {
              title
              name
              description
              id
            }
          }
          tournaments {
            title
            id
          }
          seasons {
            id
            startDate
            endDate
          }
          matches {
            id
            title
            description
            startTime
            endTime
            team1 {
              title
              id
            }
            team2 {
              title
              id
            }
            cover {
              publicLink
              fileName
            }
          }
          visibleId
          requestStatus
          createdAt
          accreditedUser {
            createdAt
            updatedAt
            user {
              id
              login
              visibleId
              person {
                name
                patronymic
                surname
                photo {
                  publicLink
                  id
                }
                contacts {
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCSESS_CATEGORIES = gql`
  query getAccessCategories {
    accreditation {
      accessCategory {
        getList {
          list {
            fields {
              id
              title
              hint
              type
              description
              appliedToCategoryId
              required
            }
            title
            id
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_TOURNAMENT = gql`
  query getCurrentTournament {
    tournament {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_FUTURE_MATHES = gql`
  query getFutureMathes($datetimeFrom: Datetime) {
    match {
      getList(filter: { datetimeFrom: $datetimeFrom }) {
        list {
          title
          id
          team1 {
            title
          }
          team2 {
            title
          }
          id
          startTime
        }
      }
    }
  }
`;

export const GET_ACCREDITATION_REQUEST_DESCRIPTOR = gql`
  query getAccreditationDescriptor {
    accreditation {
      accreditationRequestDescriptor {
        getActive {
          id
          requestTypes
          availableAccessCategories {
            id
            title
            fields {
              id
              hint
              description
              title
              required
              type
            }
            hidden
          }
          allIncludedMatches {
            id
            title
            startTime
            team1 {
              id
              title
              logo {
                id
                publicLink
              }
            }
            team2 {
              id
              title
              logo {
                id
                publicLink
              }
            }
          }
          availableSeasons {
            id
            startDate
            endDate
          }
          availableTournaments {
            id
            title
            logo {
              id
              publicLink
            }
          }
        }
      }
    }
  }
`;

export const GET_FILE = gql`
  query file ($id: String!) {
    file {
      getFileDescriptor (id: $id){
        fileName
        publicLink
        id
      }
    }
  }
`;

export const GET_PDF_BY_IDS =gql`
  query getPdfById($ids: [ID!]! ){
    accreditation{
      accreditation{
        getPdfByIds(ids: $ids){
          publicLink
        }
      }
    }
  }
`;

