import { message } from 'antd';
import { ERROR_MESSAGE_TIME_DURATION } from 'constants.js';
import { ERROR_MESSAGE_STYLE } from '../../constants';


export default function generateError(messageContent: string) {
  message.error({
    content: `${messageContent}`,
    duration: ERROR_MESSAGE_TIME_DURATION,
    style: ERROR_MESSAGE_STYLE
  });
}
