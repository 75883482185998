import gql from 'graphql-tag';

export const GET_PROFILE = gql`
query {
  users {
    getProfile {
      id
      login
      person {
        name
        surname
        patronymic
      }
    }
  }
}
`;
