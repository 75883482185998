import { connect } from 'react-redux';
import pathOr from 'ramda/es/pathOr';

import { openActivationsCodesModal, setCodesToReducer } from '../../actions';

const mapStateToProps = (state: any) => {
  return {
    codesData: pathOr(
      {},
      ['reducer', 'promocodes', 'promoRootReducer', 'codesData'],
      state
    ),
    activationsCodesModalIsOpened: pathOr(
      false,
      [
        'reducer',
        'promocodes',
        'promoRootReducer',
        'activationsCodesModalIsOpened'
      ],
      state
    )
  };
};

const mapDispatchToProps = { openActivationsCodesModal, setCodesToReducer };

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
