import { ObjectDeserializator } from 'utils/apiHelpers';
import { LocalizedString } from 'utils/LocalizedString';
import { User } from './types';

export const deserializeUser = (u: ObjectDeserializator): User => {
  const contacts = u.required('person.contacts').asArrayOfObjects((c) => ({
    value: c.required('value').asString,
    type: c.required('type').asString,
  }));

  return {
    userId: u.required('id').asString,
    login: u.required('login').asString,
    name: u.required('person.name').as((o) => LocalizedString.fromObject(o)),
    surname: u.required('person.surname').as((o) => LocalizedString.fromObject(o)),
    patronymic: u.optional('person.patronymic')?.as((o) => LocalizedString.fromObject(o)),
    phone: contacts.find((contact) => contact.type === 'PHONE')?.value,
    email: contacts.find((contact) => contact.type === 'EMAIL')?.value,
  };
};
