import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import {
  AccessCategoryDataDescriptor,
  AccessEntranceDescriptor,
  AccessZoneDescriptor,
  FormType
} from '../../graphql/accessZoneModel';
import './AccessCategoriesForm.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface AccessCategoriesFormProps extends FormComponentProps {
  data: AccessCategoryDataDescriptor[];
  zonesList: AccessZoneDescriptor[];
  entrancesList: AccessEntranceDescriptor[];
  formType: FormType;
  setIsChangedSelect: (isChanged: boolean) => void;
  setMutationId: React.Dispatch<React.SetStateAction<string[]>>;
  mutationId?: string[];
}

type Ref = FormComponentProps;

const AccessCategoriesForm = forwardRef<Ref, AccessCategoriesFormProps>(
  (
    {
      form,
      data,
      zonesList,
      entrancesList,
      setMutationId,
      setIsChangedSelect,
      formType,
      mutationId,
    }: AccessCategoriesFormProps,
    ref
  ) => {
    const { t } = useTranslation();
    useImperativeHandle(ref, () => ({
      form,
    }));

    const isTouched = form.isFieldsTouched();

    //Resetting values in form fields when switching between menu items and between forms
    useEffect(() => {
      formType === 'initial' && form.resetFields();
      setIsChangedSelect(false);
    }, [formType]);

    useEffect(() => {
      isTouched && setIsChangedSelect(isTouched);
    }, [isTouched]);

    //Collecting selection options for selects
    const zonesElements = zonesList.map((zone: { id: string; title: string }) => (
      <Option
        key={zone.id}
        title={zone.title}
        value={zone.id}
        style={{
          backgroundColor: '#fff',
        }}
      >
        {zone.title}
      </Option>
    ));

    const entrancesElements = entrancesList.map((entrance: { id: string; title: string }) => (
      <Option
        key={entrance.id}
        title={entrance.title}
        value={entrance.id}
        style={{
          backgroundColor: '#fff',
        }}
      >
        {entrance.title}
      </Option>
    ));

    //Collecting selects
    const formElement = data.map((item: AccessCategoryDataDescriptor) => (
      <div className="AccessCategoriesForm__selects" key={item.id}>
        <h3 className="AccessCategoriesForm__selects-title">{item.title}</h3>
        <p className="AccessCategoriesForm__selects-subtitle">{t('modules.access_categories_form.subtitle.zones')}</p>
        <Form.Item style={{marginBottom: '12px'}}>
          {form.getFieldDecorator(`zones${item.id}`, {
            initialValue: item.zones
          })(
            <Select
              mode="multiple"
              style={{width: '100%'}}
              placeholder={t('modules.access_categories_form.placeholder.zones')}
              showArrow
              size="large"
              labelInValue
              onChange={() => setMutationId([...(mutationId ?? []), item.id])}
            >
              {zonesElements}
            </Select>
          )}
        </Form.Item>
        <p
          className="AccessCategoriesForm__selects-subtitle">{t('modules.access_categories_form.subtitle.entrances')}</p>

        <Form.Item style={{ marginBottom: '0' }}>
          {form.getFieldDecorator(`entrances${item.id}`, {
            initialValue: item.entrances,
          })(
            <Select
              mode="multiple"
              style={{width: '100%'}}
              placeholder={t('modules.access_categories_form.placeholder.entrances')}
              showArrow
              size="large"
              labelInValue
              onChange={() => setMutationId([...(mutationId ?? []), item.id])}
            >
              {entrancesElements}
            </Select>
          )}
        </Form.Item>
      </div>
    ));

    return (
      <>
        <Form className="AccessCategoriesForm" autoComplete="off">
          <div className="AccessCategoriesForm__content">{formElement}</div>
        </Form>
      </>
    );
  }
);

export default Form.create<AccessCategoriesFormProps>()(AccessCategoriesForm);
