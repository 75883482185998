import React from 'react';
import '../../../../Loyalty.scss';
import './ModalSaveError.scss';
import { Icon } from '@ant-design/compatible';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

function ModalSaveError(props: { isSaveError: boolean; setIsSaveError: (value: boolean) => void }) {
  const { isSaveError, setIsSaveError } = props;
  const { t } = useTranslation();
  return (
    <Modal
      title={false}
      closable={false}
      visible={isSaveError}
      footer={[
        <Button
          id="ModalSaveErrorOkButton"
          key="ok"
          className="route-content_loyalty-ok-button"
          onClick={() => setIsSaveError(false)}
        >
          {t('modules.modal_save_error_loyalti.fine')}
        </Button>,
      ]}
      width={430}
    >
      <div className="route-content_loyalty-modal-title-block">
        <Icon type="exclamation-circle" className="route-content_loyalty-exclamation-circle" />
        <h3 className="route-content_loyalty-modal-title">{t('modules.modal_save_error_loyalti.error')}</h3>
      </div>
      <p className="route-content_loyalty-modal-text">
        {t('modules.modal_save_error_loyalti.text_1.0')}&nbsp;{t('modules.modal_save_error_loyalti.text_1.1')}
      </p>
      <p className="route-content_loyalty-modal-text">{t('modules.modal_save_error_loyalti.try_again')}</p>
    </Modal>
  );
}

export default ModalSaveError;
