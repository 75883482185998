import React from 'react';
import { Button } from 'antd';
import styles from './AccreditationHeader.module.scss';
import { LeftOutlined } from '@ant-design/icons';

export default function AccreditationHeader(props: {
  isEdit: boolean;
  setIsEdit: any;
  setVisible: any;
  title: string;
  buttonClickHandler?: () => any;
  refreshCardData?: () => void;
  form?: any;
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.header}>
      <div className={styles.group}>
        <Button id="AccreditationHeaderButton" className={styles.cancel} type="ghost" onClick={() => props.setVisible(false)} ><LeftOutlined /></Button>
        <h1 className={styles.title}>{props.title}</h1>
      </div>
      {props.children}
    </div>
  );
}
