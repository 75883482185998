import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select } from 'antd';
import './AccessEntranceForm.scss';
import { AccessZoneDescriptor, EntranceFormDescriptor, FormType } from '../../graphql/accessZoneModel';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import ModalDeleteWarning from '../ModalDeleteWarning/ModalDeleteWarning';
import InfoPopover from '../../../../../common/InfoPopover/InfoPopover';
import { transliterate } from '../../../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

interface EntranceZoneFormProps extends FormComponentProps {
  data: EntranceFormDescriptor;
  formType: FormType;
  zonesList: AccessZoneDescriptor[];
  setIsChangedInput: (isChanged: boolean) => void;
  handleDelete: () => void;
  onSubmit: (e: React.MouseEvent) => void;
}

type Ref = FormComponentProps;

const AccessEntranceForm = forwardRef<Ref, EntranceZoneFormProps>(
  ({ form, onSubmit, data, setIsChangedInput, formType, handleDelete, zonesList }: EntranceZoneFormProps, ref) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const { t } = useTranslation();

    const [isVisibleModalDelete, setIsVisibleModalDelete] = useState<boolean>(false);
    const errors = form.getFieldsError();
    const isTouched = form.isFieldsTouched(['title', 'description', 'techName', 'zonesIn', 'zonesOut']);
    const disabledButton = Boolean(errors.title) || !isTouched;

    //Resetting values in form fields when switching between menu items and between forms
    useEffect(() => {
      formType === 'initial' && form.resetFields();
      setIsChangedInput(false);
    }, [formType]);

    useEffect(() => {
      isTouched && setIsChangedInput(isTouched);
    }, [isTouched]);

    //Collecting selection options for selects
    const zonesElement = zonesList.map((zone: { id: string; title: string }) => (
      <Option
        key={zone.id}
        title={zone.title}
        value={zone.id}
        style={{
          backgroundColor: '#fff',
        }}
      >
        {zone.title}
      </Option>
    ));

    return (
      <>
        <Form className="AccessEntranceForm" autoComplete="off">
          <div className="AccessEntranceForm__content">
            <div className="AccessEntranceForm__inputs">
              <div className="AccessEntranceForm__input-wrap">
                <div className="AccessEntranceForm__subtitle-wrap">
                  <p className="AccessEntranceForm__subtitle AccessEntranceForm__subtitle_wrapped ">
                    {t('modules.access_entrance_form.input.title')}
                  </p>
                  <InfoPopover content={t('modules.access_entrance_form.info_popover.title')}/>
                </div>

                <Form.Item>
                  {form.getFieldDecorator('title', {
                    initialValue: formType !== 'create entrance' && data ? data.title : '',
                    rules: [{ required: true, message: t('modules.access_entrance_form.form_item') }],
                  })(
                    <Input
                      className="AccessEntranceForm__input"
                      placeholder={t('modules.access_entrance_form.placeholder.input_name')!}
                      maxLength={40}
                      size="large"
                      onChange={(e: any) => form.setFieldsValue({ techName: transliterate(e.target.value) })}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="AccessEntranceForm__input-wrap">
                <div className="AccessEntranceForm__subtitle-wrap">
                  <p className="AccessEntranceForm__subtitle AccessEntranceForm__subtitle_wrapped ">
                    {t('modules.access_entrance_form.input.tech_title')}
                  </p>
                  <InfoPopover content={t('modules.access_entrance_form.info_popover.tech_title')}/>
                </div>
                <Form.Item>
                  {form.getFieldDecorator('techName', {
                    initialValue: formType !== 'create entrance' && data ? data.name : '',
                    rules: [{ required: true, message: t('modules.access_entrance_form.form_item') }],
                  })(
                    <Input
                      className="AccessEntranceForm__input"
                      placeholder={t('modules.access_entrance_form.placeholder.automatic')!}
                      size="large"
                    />
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="AccessEntranceForm__textarea-wrap">
              <p className="AccessEntranceForm__subtitle">{t('modules.access_entrance_form.input.description')}</p>

              <Form.Item>
                {form.getFieldDecorator('description', {
                  initialValue: formType !== 'create entrance' && data ? data.description : '',
                })(
                  <TextArea
                    className="AccessEntranceForm__textarea"
                    placeholder={t('modules.access_entrance_form.textarea')!}
                    rows={3}
                  />
                )}
              </Form.Item>
            </div>
            <div className="AccessEntranceForm__selects">
              <div className="AccessEntranceForm__select-wrap">
                <p className="AccessEntranceForm__subtitle">{t('modules.access_entrance_form.input.internal_zones')}</p>
                <Form.Item>
                  {form.getFieldDecorator('zonesIn', {
                    initialValue: formType !== 'create entrance' && data ? data.zonesIn : []
                  })(
                    <Select
                      className="AccessEntranceForm__select"
                      mode="multiple"
                      size="large"
                      showArrow
                      labelInValue
                      placeholder={t('modules.access_entrance_form.select')}
                    >
                      {zonesElement}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="AccessEntranceForm__select-wrap">
                <p className="AccessEntranceForm__subtitle">{t('modules.access_entrance_form.input.external_zones')}</p>
                <Form.Item>
                  {form.getFieldDecorator('zonesOut', {
                    initialValue: formType !== 'create entrance' && data ? data.zonesOut : []
                  })(
                    <Select
                      className="AccessEntranceForm__select"
                      size="large"
                      placeholder={t('modules.access_entrance_form.select')}
                      mode="multiple"
                      labelInValue
                      showArrow
                    >
                      {zonesElement}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        <footer
          className={
            formType !== 'create entrance'
              ? 'AccessEntranceForm__footer'
              : 'AccessEntranceForm__footer  AccessEntranceForm__footer_create'
          }
        >
          <Button
            id="AccessEntranceFormDeleteButton"
            type="primary"
            className={
              formType !== 'create entrance'
                ? 'AccessEntranceForm__button-delete'
                : 'AccessEntranceForm__button-delete AccessEntranceForm__button-delete_hidden'
            }
            onClick={() => setIsVisibleModalDelete(true)}
          >
            {t('modules.access_entrance_form.button.delete')}
          </Button>
          <button
            id="AccessEntranceFormSaveButton"
            className="AccessEntranceForm__button"
            disabled={disabledButton}
            onClick={onSubmit}
          >
            {t('modules.access_entrance_form.button.save')}
          </button>
        </footer>

        <ModalDeleteWarning
          isVisibleModalDelete={isVisibleModalDelete}
          setIsVisibleModalDelete={setIsVisibleModalDelete}
          handleDelete={handleDelete}
          title={t('modules.access_entrance_form.modal_delete_warning')}
        />
      </>
    );
  }
);
export default Form.create<EntranceZoneFormProps>()(AccessEntranceForm);
