import PromoAdd from './components';
import withFormik from './containers/withFormik';
import connect from './containers/connect';
import withHandlers from './containers/withHandlers';
import withStateHandlers from './containers/withStateHandlers';
import withFormatHandlers from '../containers/withHandlers';
import graphql from './containers/graphql';
import { withFormikHandlers } from './containers/withHandlers';
import { withApollo } from 'react-apollo';

import { compose } from 'ramda';

export default compose(
  graphql,
  connect,
  withStateHandlers,
  withFormatHandlers,
  withFormikHandlers,
  withFormik,
  withHandlers,
  withApollo
)(PromoAdd);
