import {
  SegmentsTitles,
  SegmentsSalesConfiguration,
  SegmentsConfiguration,
  Segment,
  SegmentType
} from './eventsModel';
import { ObjectDeserializator } from 'utils/apiHelpers';
import { enumFromString } from 'utils/typeHelpers';

export const deserializeSegmentsTitles = (o: ObjectDeserializator): SegmentsTitles => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
  };
};


export const deserializeSegment = (s: ObjectDeserializator): Segment => {
  return {
    id: s.required('id').asString,
    title: s.required('title').asString,
    segmentType: enumFromString(SegmentType, s.required('segmentType').asString),
    deletedAt: s.optional('deletedAt')?.asDate
  };
};

export const deserializeSegmentsConfiguration = (s: ObjectDeserializator): SegmentsConfiguration => {
  return {
    segmentId: s.optional('segmentId')?.asString,
    releaseDatetime: s.required('releaseDatetime').asDate,
    segment: s.optional('segment')?.asObject((s) => deserializeSegment(s)),
  };
};

export const deserializeSegmentsSalesConfiguration = (o: ObjectDeserializator): SegmentsSalesConfiguration => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    hidden: o.required('hidden').asBool,
    segmentsConfiguration: o.required('segmentsConfiguration').asArrayOfObjects((s) => deserializeSegmentsConfiguration(s))
  };
};
