import gql from 'graphql-tag';

export const GET_ALL_SEGMENTS_LIST = gql`
  query getSegments {
    segments {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;

export const GET_SEGMENTS_CONFIGURATION = gql `
  query getSegmentsConfiguration($id: ID!) {
    match {
      getById(id: $id) {
        segmentsConfiguration {
          segmentId
          releaseDatetime
          segment {
            id
            title
            segmentType
            deletedAt
          }
        }
      }
    }
  }
`
