import React from 'react';
import styles from './AccreditationFilter.module.scss';
import { Input } from 'antd';
import { AccreditationFilterInput } from '../../model/accreditationModel';
import { useTranslation } from 'react-i18next';

export default function AccreditationFilter(props: {
  onChange: (newFilterFields: AccreditationFilterInput) => any;
  defaultValue: string | undefined;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.filter}>
      <Input.Search
        size="large"
        className={styles.searchInput}
        placeholder={t('modules.accreditation_filter')!}
        defaultValue={props.defaultValue}
        allowClear
        onChange={(e) => props.onChange({ quickSearch: e.target.value })}
        onSearch={(value) => props.onChange({ quickSearch: value })}
      />
    </div>
  );
}
