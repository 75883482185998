import React from 'react';
import { Modal } from 'antd';
import PromoAddSelect from './PromoAddSelect';
import Type10PromoAddSelect from './chunks/type10PromoAddSelect';
import PromoAddRange from './PromoAddRange';
import pathOr from 'ramda/es/pathOr';
import { Query } from 'react-apollo';
import { GET_MAP, GET_ROWS, GET_SECTORS, GET_TOURNAMENTS, GET_STAGE, GET_SEASON, GET_USERS } from '../graphql';
import { useTranslation } from 'react-i18next';

const PromoAddModal = (props) => {
  const { t } = useTranslation();
  const orderTypeList = [
    { id: 'TICKET', title: t('modules.type_title.ticket') },
    { id: 'SEASON_TICKET', title: t('modules.type_title.subscription') },
    { id: 'PROLONG_SEASON_TICKET', title: t('modules.type_title.subscription_renewal') },
    { id: 'OUTFIT', title: t('modules.type_title.merch') },
  ];
  const type = pathOr(false, ['boundItem', 'type'], props);
  return (
    <Modal footer={null} open={props.visible} onCancel={props.onClose}>
      {type === '0' && (
        <Query
          query={GET_MAP}
          onCompleted={(data) =>
            props.setPlaceList({
              initial: data,
              current: [],
            })
          }
        >
          {() => {
            return (
              <PromoAddSelect
                title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.position_selection')}
                boundTypeList={props.placeList.current}
                handleChangePlace={props.handleChangePlace}
                handleSelectBoundItems={props.handleSelectBoundItems}
              />
            );
          }}
        </Query>
      )}
      {type === '1' && (
        <Query query={GET_ROWS}>
          {({ data }) => {
            const list = props.getRowList(data);
            return (
              <PromoAddSelect
                title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.row_selection')}
                boundTypeList={list}
                handleSelectBoundItems={props.handleSelectBoundItems}
              />
            );
          }}
        </Query>
      )}
      {type === '2' && (
        <Query query={GET_SECTORS}>
          {({ data }) => {
            const list = props.getSectorList(data);
            return (
              <PromoAddSelect
                title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.sector_selection')}
                boundTypeList={list}
                handleSelectBoundItems={props.handleSelectBoundItems}
              />
            );
          }}
        </Query>
      )}
      {type === '3' && (
        <Query query={GET_TOURNAMENTS}>
          {({ data }) => {
            const list = props.getTournamentList(data);
            return (
              <Query query={GET_SEASON}>
                {(res) => {
                  const seasonList = props.getSeasonList(res.data);
                  return (
                    <PromoAddSelect
                      seasonList={seasonList}
                      title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.tournament_selection')}
                      boundTypeList={list}
                      handleSelectSeason={props.handleSelectSeason}
                      handleSelectBoundItems={props.handleSelectBoundItems}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      )}
      {type === '4' && (
        <Query query={GET_STAGE}>
          {({ data }) => {
            const list = props.getStageList(data);
            return (
              <Query query={GET_SEASON}>
                {(res) => {
                  const seasonList = props.getSeasonList(res.data);
                  return (
                    <PromoAddSelect
                      seasonList={seasonList}
                      title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.stage_selection')}
                      boundTypeList={list}
                      handleSelectSeason={props.handleSelectSeason}
                      handleSelectBoundItems={props.handleSelectBoundItems}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      )}
      {type === '5' && (
        <Query query={GET_SEASON}>
          {({ data }) => {
            const list = props.getSeasonList(data);
            return (
              <PromoAddSelect
                title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.season_selection')}
                boundTypeList={list}
                handleSelectBoundItems={props.handleSelectBoundItems}
              />
            );
          }}
        </Query>
      )}
      {type === '6' && (
        <PromoAddRange
          title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_range.price_selection')}
          boundItem={props.boundItem}
          handleSelect={props.setFieldValue}
          onClose={props.onClose}
          values={props.values}
        />
      )}
      {type === '7' && (
        <PromoAddSelect
          title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.order_type_selection')}
          boundTypeList={orderTypeList}
          handleSelectBoundItems={props.handleSelectBoundItems}
        />
      )}
      {type === '8' && (
        <Query query={GET_USERS}>
          {({ data }) => {
            const list = props.getUserList(data);
            return (
              <PromoAddSelect
                title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_select.user_choice')}
                boundTypeList={list}
                handleSelectBoundItems={props.handleSelectBoundItems}
              />
            );
          }}
        </Query>
      )}
      {type === '9' && (
        <PromoAddRange
          title={t('modules.promo_add_modal.promo_add_modal_index.promo_add_range.quantity_selection')}
          boundItem={props.boundItem}
          handleSelect={props.setFieldValue}
          values={props.values}
          onClose={props.onClose}
        />
      )}
      {type === '10' && <Type10PromoAddSelect {...props} />}
    </Modal>
  );
};

export default PromoAddModal;
