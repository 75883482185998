export enum PlaceActions {
  PRINT = 'PRINT_PDF',
  BUY = 'BUY',
  REDEEM_ORDER = 'REDEEM_ORDER',
  REDEEM_ORDER_PARTIALLY = 'REDEEM_ORDER_PARTIALLY',
  CHANGE_RESERVATION = 'CHANGE_RESERVATION',
  CANCEL_RESERVATION = 'CANCEL_RESERVATION',
  SEND_BY_EMAIL = 'SEND_BY_EMAIL',
  SEND_BY_TEXT_MESSAGE = 'SEND_BY_TEXT_MESSAGE',
  BOOK = 'BOOK',
  REFUND = 'REFUND',
}

export enum TicketStatuses {
  BLOCKED = 'BLOCKED',
  RESERVED = 'RESERVED',
  ACTIVE = 'ACTIVE',
  SOLD = 'SOLD',
}

export enum SideMenuStep {
  BUYING = 'BUYING',
  RESERVING = 'RESERVING',
  RESERVATION_CHANGING = 'RESERVATION_CHANGING',
  REDEEMING = 'REDEEMING',
  PARTIAL_REDEEMING = 'PARTIAL_REDEEMING',
  DEFAULT = 'DEFAULT',
}

export type ActionByStatus = {
  status: TicketStatuses,
  actions: PlaceActions[];
}

// TODO: Implement actions by place status into MapActionSideMenu and ActionsSideMenu
export const actionsAvailability: ActionByStatus[] = [
  {
    status: TicketStatuses.ACTIVE,
    actions: [PlaceActions.BUY, PlaceActions.BOOK]
  },
  {
    status: TicketStatuses.RESERVED,
    actions: [PlaceActions.REDEEM_ORDER, PlaceActions.REDEEM_ORDER_PARTIALLY, PlaceActions.CANCEL_RESERVATION, PlaceActions.CHANGE_RESERVATION]
  },
  {
    status: TicketStatuses.SOLD,
    actions: [PlaceActions.PRINT, PlaceActions.SEND_BY_EMAIL, PlaceActions.SEND_BY_TEXT_MESSAGE, PlaceActions.REFUND]
  },
  {
    status: TicketStatuses.BLOCKED,
    actions: []
  }
];

type ActionConfig = {
  action: PlaceActions,
  [key: string]: any,
}

export const getAvailableActions = (status: TicketStatuses, actionsConfig?: ActionConfig[]) => {
  const availableActions = actionsAvailability.find(actionByStatus => actionByStatus.status === status)?.actions;
  if (actionsConfig && availableActions) {
    return availableActions.map((actionName) => {
      return actionsConfig.find(config => config.action === actionName);
    });
  } else {
    return availableActions;
  }
};
