import React, { Component } from 'react';
import "./style.scss"

export default class ModalComponent extends Component {
   
    render() {
        
        return (
            <div className="ac-modal">
               {this.props.children}
            </div>
       );
    }
}