import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { EditOutlined, LoadingOutlined, PlusOutlined, CopyOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Select, Tooltip } from 'antd';
import {
  GET_PRICE_CATEGORIES,
  GET_PRICE_CATEGORY_INFO,
  UPDATE_PRICE_CATEGORY_TEMPLATE,
  ADD_PLACES_CATEGORY_TEMPLATE,
  UPDATE_PRICE_CATEGORY,
  ADD_PRICE_CATEGORY,
  ADD_PRICE_CATEGORY_TEMPLATE,
  DELETE_PRICE_CATEGORY
} from '../graphql';
import isEmpty from 'ramda/es/isEmpty';
import uniq from 'ramda/es/uniq';
import { zoomValues } from './MapComponent';
import { useTranslation } from 'react-i18next';
import { generateValidationErrors } from '../utils/stadiumHelpers'
import classNames from 'classnames';

const SelectOption = Select.Option;

const MarkupSideMenu = (props) => {
  const { t } = useTranslation();
  const defaultPlacesColor = props.defaultPlacesColor;
  const [stateProps, setState] = useState({
    defaultStep: true,
    editedTemplates: [],
    newTemplates: [],
  });

  const restoreState = () => {
    setState({
      defaultStep: true,
      editedTemplates: [],
      newTemplates: [],
      isLoaded: false,
    });
  };

  const resetUpdating = () => {
    setState({ ...stateProps, isUpdating: false });
  };

  const setDefaultPlaceColor = (mapPlace) => {
    mapPlace.setStyle({
      color: defaultPlacesColor,
      fillColor: defaultPlacesColor,
      originalColor: defaultPlacesColor,
      weight: 1,
      isSelected: false,
    });
  }

  const hideTemplates = () => {
    props.mapPlaces.forEach((mapPlace) => {
      setDefaultPlaceColor(mapPlace);
    });

    setState({
      categoryList: stateProps.categoryList.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      }),
      defaultStep: true,
      editedTemplates: [],
      newTemplates: [],
      isLoaded: true,
      isUpdating: false,
    });
    props.setSelectedPlaces([]);
  };
  const saveNewTemplate = async () => {
    try {
      const res = await props.client.query({
        query: ADD_PRICE_CATEGORY_TEMPLATE,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            title: stateProps.newTemplateTitle,
          },
        },
      });
      const newTemplate = pathOr('', ['data', 'priceCategoryTemplate', 'add'], res);

      for (const template of stateProps.newTemplates) {
        if (template.title && template.placesIds && !isEmpty(template.placesIds)) {
          const res = await props.client.query({
            query: ADD_PRICE_CATEGORY,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                title: template.title,
                color: template.color,
                priceCategoryTemplateId: newTemplate.id,
              },
            },
          });
          const id = pathOr('', ['data', 'priceCategory', 'add', 'id'], res);

          //category seats update
          await props.client.query({
            query: ADD_PLACES_CATEGORY_TEMPLATE,
            fetchPolicy: 'no-cache',
            variables: {
              id: newTemplate.id,
              venueId: props.stadiumid,
              places: {
                list: uniq(template.placesIds).map((item) => ({
                  categoryId: id,
                  placeId: item,
                })),
              },
            },
          });
        }
      }

      message.success(t('modules.markup_side_menu.message_success.markup_created'));
      restoreState();
    } catch (e) {
      console.error(e);
      message.error(t('modules.markup_side_menu.message_error.save'));
      resetUpdating();
    }
  };

  const saveEditedChanges = async () => {
    try {
      const selectedPriceCategoryTemplate = stateProps.categoryList.find((item) => {
        return item.isSelected;
      });
      //update template name
      if (stateProps.newTemplateTitle) {
        await props.client.query({
          query: UPDATE_PRICE_CATEGORY_TEMPLATE,
          fetchPolicy: 'no-cache',
          variables: {
            id: selectedPriceCategoryTemplate.id,
            data: {
              title: stateProps.newTemplateTitle,
            },
          },
        });
      }

      for (const template of stateProps.editedTemplates) {
        if (template.title) {
          await props.client.query({
            query: UPDATE_PRICE_CATEGORY,
            fetchPolicy: 'no-cache',
            variables: {
              id: template.id,
              data: {
                title: template.title,
              },
            },
          });
        }
        //category seats update
        if (template.placesIds) {
          await props.client.query({
            query: ADD_PLACES_CATEGORY_TEMPLATE,
            fetchPolicy: 'no-cache',
            variables: {
              id: selectedPriceCategoryTemplate.id,
              venueId: props.stadiumid,
              places: {
                list: uniq(template.placesIds).map((item) => ({
                  categoryId: template.id,
                  placeId: item,
                })),
              },
            },
          });
        }
      }

      for (const template of stateProps.newTemplates) {
        if (template.title && template.placesIds && !isEmpty(template.placesIds)) {
          const res = await props.client.query({
            query: ADD_PRICE_CATEGORY,
            fetchPolicy: 'no-cache',
            variables: {
              data: {
                title: template.title,
                color: template.color,
                priceCategoryTemplateId: selectedPriceCategoryTemplate.id,
              },
            },
          });
          const id = pathOr('', ['data', 'priceCategory', 'add', 'id'], res);

          //category seats update
          await props.client.query({
            query: ADD_PLACES_CATEGORY_TEMPLATE,
            fetchPolicy: 'no-cache',
            variables: {
              id: selectedPriceCategoryTemplate.id,
              venueId: props.stadiumid,
              places: {
                list: uniq(template.placesIds).map((item) => ({
                  categoryId: id,
                  placeId: item,
                })),
              },
            },
          });
        }
      }

      props.mapPlaces.forEach((mapPlace) => {
        mapPlace.setStyle({
          color: defaultPlacesColor,
          fillColor: defaultPlacesColor,
          originalColor: defaultPlacesColor,
          weight: 1,
          isSelected: false,
        });
      });

      message.success(t('modules.markup_side_menu.message_success.markup_edited'));
      restoreState();
    } catch (e) {
      console.error(e.uid);
      message.error(t('modules.markup_side_menu.message_error.edit'));
      resetUpdating();
    }
  };

  const displaySelectedPlaces = () => {
    props.selectedPlaces.forEach((mapPlace) => {
      const zoom = mapPlace._map._zoom;
      const zoomKf = zoomValues.find(item => item.value === zoom).coef || 1;
      mapPlace.setStyle({
        color: mapPlace.options.originalColor,
        fillColor: '#ffffff',
        isSelected: true,
        weight: props.weight * zoomKf,
      })
    })
  }

  const removeNewPriceCategory = async (matchedItem) => {
    props.mapPlaces.forEach((mapPlace) => {
      if (mapPlace.options.color === matchedItem.color) {
        setDefaultPlaceColor(mapPlace);
      }
    });
    displaySelectedPlaces();

    setState({
      ...stateProps,
      newTemplates: stateProps.newTemplates.filter(template => template.color !== matchedItem.color)
    });
    const input = document.querySelector(`[data-key="template__input_${matchedItem.color}"]`);
    input.value = '';
  }

  const deletePriceCategory = async (matchedItem) => {
    try {
      const res = await props.client.query({
        query: DELETE_PRICE_CATEGORY,
        fetchPolicy: 'no-cache',
        variables: {
          id: matchedItem.id
        },
      });
      message.success(t('modules.markup_side_menu.message_success.price_category_deleted'));
      return res;
    } catch (e) {
      pathOr([], ['graphQLErrors'], e).forEach((error) => {
        const condition = error.extensions.message.includes('update or delete on table "price_category" violates foreign key');
        if (condition) {
          message.error(t('modules.markup_side_menu.message_error.price_was_set'));
        } else {
          message.error(t('modules.markup_side_menu.message_error.price_category_delete_err'));
        }
      })
      return null;
    }
  }

  const removeExistingPriceCategory = async (matchedItem) => {
    try {
      const res = await deletePriceCategory(matchedItem);
      if (res) {
        props.mapPlaces.forEach((mapPlace) => {
          if (mapPlace.options.color === matchedItem.color) {
            setDefaultPlaceColor(mapPlace);
          }
        });
        displaySelectedPlaces();
        if (stateProps.editedTemplates.find(item => item.id === matchedItem.id)) {
          setState({
            ...stateProps,
            templates: stateProps.templates.filter(template => template.id !== matchedItem.id),
            editedTemplates: stateProps.editedTemplates.filter(template => template.id !== matchedItem.id)
          });
        } else {
          setState({
            ...stateProps,
            templates: stateProps.templates.filter(template => template.id !== matchedItem.id)
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const removePriceCategoryHandler = async (matchedItem) => {
    const condition = stateProps.templates?.find(template => template.id === matchedItem.id);
    if (condition) {
      await removeExistingPriceCategory(matchedItem);
    } else {
      await removeNewPriceCategory(matchedItem);
    }
  }

  const checkPlacesBelongToCategories = () => {
    const colorOfIds = {};
    props.selectedPlaces.forEach((place) => {
      if (place.options.color !== defaultPlacesColor) {
        if (!Object.keys(colorOfIds).includes(place.options.color)) {
          colorOfIds[place.options.color] = [];
        };
        colorOfIds[place.options.color].push(place.options.id);
      }
    });

    return colorOfIds;
  }

  const checkCategoriesIntersection = () => {
    const colorOfIds = checkPlacesBelongToCategories();

    const filteredNewTemplates = stateProps.newTemplates?.map((template) => {
      const setA = new Set(template.placesIds);
      const setB = new Set(colorOfIds[template.color]);
      const diffA = Array.from(setA).filter(x => !setB.has(x));
      template.placesIds = diffA;
      return template;
    })

    let filteredTemplates = [];
    !!stateProps.templates && stateProps.templates.forEach((template) => {
      const editedTemplate = stateProps.editedTemplates.find((editedTemplate) => {
        return editedTemplate.id === template.id;
      });

      let setA = {};
      if (!!editedTemplate) {
        setA = new Set(editedTemplate.placesIds);
      } else {
        setA = new Set(template.places.map(item => item.id));
      };
      const setB = new Set(colorOfIds[template.color]);
      const diffA = Array.from(setA).filter(x => !setB.has(x));
      const diffB = Array.from(setB).filter(x => setA.has(x));

      if (diffB.length > 0) {
        filteredTemplates.push({
          id: template.id,
          title: editedTemplate ? editedTemplate.title : template.title,
          placesIds: diffA,
        });
      }
    })

    return { filteredNewTemplates, filteredTemplates }
  }

  const getUpdatedValidationErrors = (hint, id) => {
    let updatedValidationErrors = {};

    if (!!hint) {
      for (const key in stateProps.validationErrors) {
        if (key !== id) {
          updatedValidationErrors[key] = stateProps.validationErrors[key];
        }
      }
    } else {
      updatedValidationErrors = stateProps.validationErrors
    };

    return updatedValidationErrors;
  }

  const editCategory = (matchedCategory) => {
    const editedTemplate = stateProps.editedTemplates.find((editedTemplate) => {
      return editedTemplate.id === matchedCategory.id;
    });

    const { filteredNewTemplates, filteredTemplates } = checkCategoriesIntersection();

    const hint = stateProps.validationErrors?.[matchedCategory.id];
    const updatedValidationErrors = getUpdatedValidationErrors(hint, matchedCategory.id);

    setState({
      ...stateProps,
      editedTemplates: [
        ...filterEditedTemplates(filteredTemplates).filter((template) => {
          return matchedCategory.id !== template.id;
        }),
        editedTemplate
          ? {
              id: editedTemplate.id,
              title: editedTemplate.title,
              color: editedTemplate.color,
              placesIds: uniq([
                ...props.selectedPlaces.map((place) => {
                  return place.options.id;
                }),
                ...editedTemplate.placesIds,
              ]),
            }
          : {
              id: matchedCategory.id,
              title: matchedCategory.title,
              color: matchedCategory.color,
              placesIds: uniq([
                ...props.selectedPlaces.map((place) => {
                  return place.options.id;
                }),
                ...matchedCategory.places.map(template => {return template.id}),
              ])
            },
      ],
      newTemplates:
        (filteredNewTemplates.length > 0)
          ? filteredNewTemplates
          : [...stateProps.newTemplates]
      ,
      validationErrors: updatedValidationErrors
    });
    props.selectedPlaces.map((selectedPlace) =>
      selectedPlace.setStyle({
        fillColor: matchedCategory.color,
        originalColor: matchedCategory.color,
        color: matchedCategory.color,
        weight: 0,
        isSelected: false,
      })
    );
    props.setSelectedPlaces([]);
  }

  const filterEditedTemplates = (filteredTemplates) => {
    let result = [];
    stateProps.editedTemplates.forEach(template => {
      const findedTemplate = filteredTemplates.find(item => item.id === template.id)
      if (!findedTemplate) {
        result.push(template)
      }
    })

    return [...result, ...filteredTemplates];
  }

  const writeNewTemplateItem = (newTemplateItem, color) => {
    const result = {
      id: 'template__input_' + color,
      title: '',
      color: color,
      placesIds: [],
    }
    if (!!newTemplateItem) {
      result.title = newTemplateItem.title;
      result.placesIds = [
        ...props.selectedPlaces.map((place) => {
          return place.options.id;
        }),
        ...newTemplateItem.placesIds,
      ];
      return [result];
    } else if (!!props.selectedPlaces.length) {
      result.placesIds = props.selectedPlaces.map((place) => {
        return place.options.id;
      });
      return [result];
    } else {
      return [];
    }
  }

  const createCategory = (newTemplateItem, color) => {
    const { filteredNewTemplates, filteredTemplates } = checkCategoriesIntersection();
    const hint = newTemplateItem && stateProps.validationErrors?.[newTemplateItem.id];
    const updatedValidationErrors = newTemplateItem ? getUpdatedValidationErrors(hint, newTemplateItem.id) : {};

    setState({
      ...stateProps,
      newTemplates: [
        ...filteredNewTemplates.filter((template) => {
          return color !== template.color;
        }),
        ...writeNewTemplateItem(newTemplateItem, color)
      ],
      editedTemplates:
        (filteredTemplates.length > 0)
          ? filterEditedTemplates(filteredTemplates)
          : [...stateProps.editedTemplates]
      ,
      validationErrors: updatedValidationErrors
    });
    props.selectedPlaces.forEach((selectedPlace) => {
      selectedPlace.setStyle({
        fillColor: color,
        originalColor: color,
        color: color,
        weight: 0,
        isSelected: false,
      });
    });
    props.setSelectedPlaces([]);
  }

  const colorArray = [
    '#F2013B',
    '#03B94E',
    '#00B2FF',
    '#933FFF',
    '#FFA901',
    '#DF02A1',
    '#0031FF',
    '#D2AEFF',
    '#007A4A',
    '#FF5C04',
    '#743393',
    '#9EF3FF',
    '#17A4AD',
    '#9DE800',
    '#D1731D',
  ];

  const generateSortedCategories = () => {
    const newColorArr = colorArray.map((item, index) => {
      const matchedItem = stateProps.templates.find((template) => {
        return template.color === item;
      });
      const newTemplateItem = stateProps.newTemplates.find((newTemplate) => {
        return newTemplate.color === item;
      });

      if (matchedItem) {
        const hint = stateProps.validationErrors?.[matchedItem.id];
        return {
          color: matchedItem.color,
          html: (
            <Tooltip
              title={hint}
              color={'red'}
              placement={'left'}
              key={`priceCategoryTooltip-${item}`}
              overlayStyle={{zIndex: 1101}}
            >
              <div
                key={'category_' + item}
                className={classNames("stadium__markup__side-menu__template", !!hint ? "stadium__markup__side-menu__template__hint" : "")}
              >
                <div
                  className="stadium__markup__side-menu__template__circle"
                  style={{
                    backgroundColor: matchedItem.color,
                  }}
                >
                  <div
                    className="stadium__markup__side-menu__template__circle__inner"
                    onClick={() => editCategory(matchedItem)}
                  >
                    <PlusOutlined className="stadium__markup__side-menu__template__circle__inner__icon" />
                  </div>
                </div>
                <div className="stadium__markup__side-menu__template__title">
                  <input
                    className="stadium__markup__side-menu__template__input"
                    defaultValue={matchedItem.title}
                    placeholder={t('modules.markup_side_menu.input.enter_title')}
                    onChange={(ev) => {
                      const editedTemplate = stateProps.editedTemplates.find((editedTemplate) => {
                        return editedTemplate.id === matchedItem.id;
                      });
                      const updatedValidationErrors = getUpdatedValidationErrors(hint, matchedItem.id);

                      setState({
                        ...stateProps,
                        editedTemplates: [
                          ...stateProps.editedTemplates.filter((template) => {
                            return matchedItem.id !== template.id;
                          }),
                          {
                            color: matchedItem.color,
                            id: matchedItem.id,
                            title: ev.target.value,
                            placesIds: editedTemplate ? editedTemplate.placesIds : matchedItem.places.map(item => item.id),
                          },
                        ],
                        validationErrors: updatedValidationErrors
                      });
                    }}
                  />
                </div>
                <CloseCircleFilled className="stadium__markup__side-menu__template__closeButton" onClick={() => removePriceCategoryHandler(matchedItem)}/>
              </div>

            </Tooltip>
          ),
        };
      } else {
        const hint = newTemplateItem && stateProps.validationErrors?.[newTemplateItem.id];
        return {
          html: (
            <Tooltip
              title={hint}
              color={'red'}
              placement={'left'}
              key={`priceCategoryTooltip-${item}`}
              overlayStyle={{zIndex: 1101}}
            >
              <div
                key={'new_category_' + item}
                className={classNames("stadium__markup__side-menu__template", !!hint ? "stadium__markup__side-menu__template__hint" : "")}
              >
                <div
                  className="stadium__markup__side-menu__template__circle"
                  style={{
                    backgroundColor: item,
                  }}
                >
                  <div
                    className="stadium__markup__side-menu__template__circle__inner"
                    onClick={() => createCategory(newTemplateItem, item)}
                  >
                    <PlusOutlined className="stadium__markup__side-menu__template__circle__inner__icon" />
                  </div>
                </div>
                <div className="stadium__markup__side-menu__template__title">
                  <input
                    key={'template__input_' + item}
                    data-key={'template__input_' + item}
                    className="stadium__markup__side-menu__template__input"
                    placeholder={t('modules.markup_side_menu.input.enter_title')}
                    value={newTemplateItem?.title}
                    onChange={(ev) => {
                      const updatedValidationErrors = newTemplateItem && getUpdatedValidationErrors(hint, newTemplateItem.id);
                      setState({
                        ...stateProps,
                        newTemplates: [
                          ...stateProps.newTemplates.filter((template) => {
                            return item !== template.color;
                          }),
                          newTemplateItem
                            ? {
                                id: 'template__input_' + item,
                                color: item,
                                title: ev.target.value,
                                placesIds: newTemplateItem.placesIds,
                              }
                            : {
                                id: 'template__input_' + item,
                                color: item,
                                title: ev.target.value,
                                placesIds: [],
                              },
                        ],
                        validationErrors: updatedValidationErrors
                      });
                    }}
                  />
                </div>
                {!!newTemplateItem && <CloseCircleFilled className="stadium__markup__side-menu__template__closeButton" onClick={() => removePriceCategoryHandler(newTemplateItem)}/>}
              </div>


            </Tooltip>
          ),
        };
      }
    });

    //NEW TEMPLATE
    const newTemplateArr = stateProps.templates.map(
      (item) =>
        !newColorArr.find((obj) => obj.color && obj.color === item.color) && (
          <div className="stadium__markup__side-menu__template">
            <div
              className="stadium__markup__side-menu__template__circle"
              style={{
                backgroundColor: item.color,
              }}
            >
              <div
                className="stadium__markup__side-menu__template__circle__inner"
                onClick={() => editCategory(item)}
              >
                <PlusOutlined className="stadium__markup__side-menu__template__circle__inner__icon" />
              </div>
            </div>

            <div className="stadium__markup__side-menu__template__title">
              <input
                className="stadium__markup__side-menu__template__input"
                defaultValue={item.title}
                onChange={(ev) => {
                  // input
                  const editedTemplate = stateProps.editedTemplates.find((editedTemplate) => {
                    return editedTemplate.id === item.id;
                  });

                  setState({
                    ...stateProps,
                    editedTemplates: [
                      ...stateProps.editedTemplates.filter((template) => {
                        return item.id !== template.id;
                      }),
                      {
                        color: item.color,
                        id: item.id,
                        title: ev.target.value,
                        placesIds: editedTemplate ? editedTemplate.placesIds : [],
                      },
                    ],
                  });
                }}
              />
            </div>
          </div>
        )
    );

    return [...newTemplateArr, ...newColorArr.map((item) => item.html)];
  };

  const loadTemplates = async (id) => {
    try {
      const res = await props.client.query({
        query: GET_PRICE_CATEGORY_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          id,
        },
      });
      const list = pathOr([], ['data', 'priceCategoryTemplate', 'getById', 'priceCategories'], res);

      const idToIndex = {};
      for (let index = 0, len = props.mapPlaces.length; index < len; index++) {
        props.mapPlaces[index].setStyle({
          color: defaultPlacesColor,
          fillColor: defaultPlacesColor,
          originalColor: defaultPlacesColor,
          weight: 1,
          isSelected: false,
        });
        idToIndex[props.mapPlaces[index].options.id] = index;
      }

      list.forEach((item) => {
        item.places.forEach((place) => {
          if (idToIndex.hasOwnProperty(place.id)) {
            props.mapPlaces[idToIndex[place.id]].setStyle({
              color: item.color,
              originalColor: item.color,
              fillColor: item.color,
              weight: 1,
              isSelected: false,
            });
          }
        });
      });
      displaySelectedPlaces();

      return list;
    } catch (e) {
      console.error(e);
      message.error(t('modules.markup_side_menu.message_error.load'));
      restoreState();
    }
  };

  const displayCategory = async (id, isEditing) => {
    const templatesList = await loadTemplates(id);

    setState({
      ...stateProps,
      isUpdating: false,
      categoryList: stateProps.categoryList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isSelected: true,
          };
        } else {
          return {
            ...item,
            isSelected: false,
          };
        }
      }),
      templates: templatesList.map((item) => {
        return {
          id: item.id,
          title: item.title,
          color: item.color,
          places: item.places
        };
      }),
      defaultStep: !isEditing,
      isEditing,
    });
  }

  const handleSaveTemplate = async () => {
    const validationErrors = generateValidationErrors(stateProps.editedTemplates, stateProps.newTemplates, stateProps.newTemplateTitle, stateProps.isAddNewTemplate);

    if (!Object.keys(validationErrors).length) {
      setState({
        ...stateProps,
        isUpdating: true,
      });
      if (stateProps.isEditing) {
        await saveEditedChanges();
      } else {
        if (stateProps.newTemplateTitle) {
          await saveNewTemplate();
        } else {
          message.error(t('modules.markup_side_menu.message_error.empty_title'));
          setState({
            ...stateProps,
            isUpdating: false,
          });
        }
      }
      props.setSelectedPlaces([]);
    } else {
      setState({
        ...stateProps,
        validationErrors: validationErrors,
      });
      message.error(t('modules.markup_side_menu.message_error.categories_titles_err'));
    }
  }

  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_PRICE_CATEGORIES,
        fetchPolicy: 'no-cache',
      });
      const list = pathOr([], ['data', 'priceCategoryTemplate', 'getList', 'list'], res);
      setState({
        ...stateProps,
        isLoaded: true,
        categoryList: list.map((item) => {
          const id = pathOr('', ['id'], item);
          const title = pathOr('', ['title'], item);
          return {
            id,
            title,
            isSelected: false,
          };
        }),
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return (
    <>
      {stateProps.isLoaded ? (
        <div className="sales__tickets__side-menu__container">
          <div className="sales__tickets__side-menu__header">
            {stateProps.defaultStep ? (
              ''
            ) : (
              <LegacyIcon
                type={stateProps.isAddNewTemplate ? 'close' : 'left'}
                onClick={() => {
                  setState({
                    ...stateProps,
                    isUpdating: true,
                  });
                  hideTemplates();
                }}
                className="sales__tickets__side-menu__header__icon"
              />
            )}
            <div className="sales__tickets__side-menu__header__action">
              {stateProps.defaultStep
                ? t('modules.markup_side_menu.div.markups')
                : stateProps.isAddNewTemplate
                ? t('modules.markup_side_menu.div.new_markup')
                : t('modules.markup_side_menu.div.editing')}
            </div>
            {!stateProps.defaultStep && (
              <div
                className="sales__price-template__side-menu__header__update"
                onClick={async () => handleSaveTemplate()}
              >
                {t('modules.markup_side_menu.div.save')}
              </div>
            )}
          </div>
          <div className="stadium__markup__side-menu__container">
            {stateProps.isUpdating && (
              <div className="stadium__markup__side-menu__body__loader">
                <div className="with-preloader__map">
                  <LoadingOutlined />
                </div>
              </div>
            )}
            <div className="stadium__markup__side-menu__body">
              {!stateProps.defaultStep && (
                <>
                <div className="stadium__markup__side-menu__copy-wrapper">
                  {(stateProps.isAddNewTemplate) &&
                    <p
                      className={classNames("stadium__markup__side-menu__copy", stateProps.isCopy ? "stadium__markup__side-menu__copy__active" : "")}
                      onClick={async () => {
                        setState({
                          ...stateProps,
                          isCopy: true,
                        });
                      }}
                    >
                      <CopyOutlined/>
                      <span> {t('modules.markup_side_menu.select.title')}</span>
                    </p>
                  }
                  {stateProps.isCopy &&
                    <Select
                      placeholder={t('modules.markup_side_menu.select.placeholder')}
                      className="events__right-container__body__select"
                      onChange={async (id) => {
                        const templatesList = await loadTemplates(id);
                        const copyTitle = stateProps.categoryList.find((item) => item.id === id);
                        const copyTemplates = [];
                        templatesList.forEach(item => {
                          copyTemplates.push({
                            color: item.color,
                            title: item.title,
                            placesIds: item.places.map(place => { return place.id }),
                          })
                        });
                        setState({
                          ...stateProps,
                          newTemplates: copyTemplates,
                          newTemplateTitle: copyTitle.title + ' copy',
                        });
                      }}
                    >
                      {stateProps.categoryList.map(item => {
                        return (
                          <SelectOption key={item.id+"_select_option"} value={item.id}>
                            {item.title}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  }
                </div>
                  <div className="stadium__markup__side-menu__title-edit">
                    <div className="stadium__markup__side-menu__title-edit__title">
                      {t('modules.markup_side_menu.div.label_name')}
                    </div>
                    <Tooltip
                      title={stateProps.validationErrors?.['noTemplateTitle']}
                      color={'red'}
                      placement={'left'}
                      key={`TemplateTitleTooltip`}
                      overlayStyle={{zIndex: 1101}}
                    >
                      <input
                        className={classNames("stadium__markup__side-menu__title-edit__input", !!stateProps.validationErrors?.['noTemplateTitle'] ? "stadium__markup__side-menu__title-edit__input__hint" : "")}
                        required={true}
                        defaultValue={
                          stateProps.isEditing
                            ? stateProps.categoryList.find((item) => item.isSelected).title
                            : (stateProps.newTemplateTitle) || ''
                        }
                        placeholder={t('modules.markup_side_menu.input.enter_markup_name')}
                        onChange={(ev) => {
                          const updatedValidationErrors = getUpdatedValidationErrors(stateProps.validationErrors?.['noTemplateTitle'], 'noTemplateTitle');
                          setState({
                            ...stateProps,
                            newTemplateTitle: ev.target.value,
                            validationErrors: updatedValidationErrors
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                  {stateProps.isEditing && generateSortedCategories()}
                </>
              )}
              {stateProps.defaultStep &&
                stateProps.categoryList.map((item) => {
                  return (
                    <div key={'side-menu__category_' + item.id} className="stadium__markup__side-menu__category">
                      <div className="stadium__markup__side-menu__category__header">
                        <div
                          className="stadium__markup__side-menu__category__left-side"
                          onClick={() => {
                            if (!item.isSelected) {
                              setState({
                                ...stateProps,
                                isUpdating: true,
                              });
                              displayCategory(item.id);
                            } else {
                              setState({
                                ...stateProps,
                                isUpdating: true,
                              });
                              hideTemplates();
                            }
                          }}
                        >
                          <div className="stadium__markup__side-menu__category__title">{item.title}</div>
                          <LegacyIcon
                            type={item.isSelected ? 'up' : 'down'}
                            className="stadium__markup__side-menu__category__arrow"
                          />
                        </div>
                        <EditOutlined
                          className="stadium__markup__side-menu__category__edit"
                          onClick={async () => {
                            setState({
                              ...stateProps,
                              isUpdating: true,
                            });
                            await displayCategory(item.id, true);
                          }}
                        />
                      </div>
                      {item.isSelected && (
                        <>
                          {stateProps.templates.map((item) => {
                            return (
                              <div className="stadium__markup__side-menu__template">
                                <div
                                  className="stadium__markup__side-menu__template__circle"
                                  style={{
                                    backgroundColor: item.color,
                                  }}
                                ></div>
                                <div className="stadium__markup__side-menu__template__title">{item.title}</div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  );
                })}
              {!stateProps.defaultStep &&
                !stateProps.isEditing &&
                colorArray.map((item) => {
                  const newTemplateItem = stateProps.newTemplates.find((newTemplate) => {
                    return newTemplate.color === item;
                  });
                  const hint = newTemplateItem && stateProps.validationErrors?.[newTemplateItem.id];
                  return (
                    <Tooltip
                      title={hint}
                      color={'red'}
                      placement={'left'}
                      key={`priceCategoryTooltip-${item}`}
                      overlayStyle={{zIndex: 1101}}
                    >

                      <div
                        key={'new_template_' + item}
                        className={classNames("stadium__markup__side-menu__template", !!hint ? "stadium__markup__side-menu__template__hint" : "")}
                      >
                        <div
                          className="stadium__markup__side-menu__template__circle"
                          style={{
                            backgroundColor: item,
                          }}
                        >
                          <div
                            className="stadium__markup__side-menu__template__circle__inner"
                            onClick={() => createCategory(newTemplateItem, item)}
                          >
                            <PlusOutlined className="stadium__markup__side-menu__template__circle__inner__icon" />
                          </div>
                        </div>
                        <div className="stadium__markup__side-menu__template__title">
                        <input
                          key={'template__input_' + item}
                          data-key={'template__input_' + item}
                          className="stadium__markup__side-menu__template__input"
                          placeholder={t('modules.markup_side_menu.input.enter_title')}
                          defaultValue={newTemplateItem?.title}
                          onChange={(ev) => {
                            const updatedValidationErrors = newTemplateItem ? getUpdatedValidationErrors(hint, newTemplateItem.id) : {};
                            setState({
                              ...stateProps,
                              newTemplates: [
                                ...stateProps.newTemplates.filter((template) => {
                                  return item !== template.color;
                                }),
                                newTemplateItem
                                  ? {
                                      id: 'new_template__input_' + item,
                                      color: item,
                                      title: ev.target.value,
                                      placesIds: newTemplateItem.placesIds,
                                    }
                                  : {
                                      id: 'new_template__input_' + item,
                                      color: item,
                                      title: ev.target.value,
                                      placesIds: [],
                                    },
                              ],
                              validationErrors: updatedValidationErrors
                            });
                          }}
                        />
                        </div>
                        {!!newTemplateItem && <CloseCircleFilled className="stadium__markup__side-menu__template__closeButton" onClick={() => removePriceCategoryHandler(newTemplateItem)}/>}
                      </div>
                    </Tooltip>
                  )
                })}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <Button
        id="MarkupSideMenuAddMarkupButton"
        className="sales__price-template__add-template"
        onClick={() => {
          setState({
            ...stateProps,
            defaultStep: false,
            isAddNewTemplate: true,
          });
        }}
      >
        <PlusOutlined className="sales__price-template__add-template__icon" />
        <span className="sales__price-template__add-template__text">{t('modules.markup_side_menu.span')}</span>
      </Button>
    </>
  );
};

export default MarkupSideMenu;
