import React from 'react';
import ModalWindow from './modal';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import List from './list';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();
  const { handleModalVisible } = props.state;
  const {
    setState,
    setCategoryTemplate,
    setPriceTemplateTitle,
    setPricesOfTemplate,
    setTypeOfButton,
    setTemplate,
    template,
    pricesOfTemplate,
    categoryTemplate,
    typeOfButton,
  } = props;
  return (
    <>
      <h1>{t('modules.category_price_components_index.prices')}</h1>
      <div className="route-content">
        <div className="category-price__main-page-button df jcfe aic">
          <Button
            id="CategoryPriceComponentsAddTemplateButton"
            size="large"
            type="primary"
            data-type="create"
            onClick={(e) => {
              setState({ handleModalVisible: true });
              setPriceTemplateTitle('');
              setPricesOfTemplate([{}]);
              setCategoryTemplate(null);
              setTypeOfButton(e.target.dataset.type);
            }}
          >
            <PlusOutlined />
            {t('modules.category_price_components_index.add_template')}
          </Button>
        </div>
        <List
          setState={setState}
          setTemplate={setTemplate}
          setTypeOfButton={setTypeOfButton}
          setCategoryTemplate={setCategoryTemplate}
          setPriceTemplateTitle={setPriceTemplateTitle}
          setPricesOfTemplate={setPricesOfTemplate}
        />
        {handleModalVisible && (
          <ModalWindow
            setTypeOfButton={setTypeOfButton}
            template={template}
            typeOfButton={typeOfButton}
            pricesOfTemplate={pricesOfTemplate}
            priceTemplateTitle={props.priceTemplateTitle}
            setCategoryTemplate={setCategoryTemplate}
            handleModalVisible={handleModalVisible}
            categoryTemplate={categoryTemplate}
            setState={setState}
          />
        )}
      </div>
    </>
  );
};

//GorlaksLegends
