import * as types from "../types";

export const setCategoryTemplate = (id: any) => ({
    type: types.SET_CATEGORY_TEMPLATE,
    id
})

export const setPriceTemplateTitle = (title: any) => ({
    type: types.SET_PRICE_TEMPLATE_TITLE,
    title
})

export const setPricesOfTemplate = (value: any) => ({
    type: types.SET_PRICES_OF_TEMPLATE,
    value
})

export const setTypeOfButton = (value: any) => ({
    type: types.SET_TYPE_OF_BUTTON,
    value
})

export const setTemplate = (id: any) => ({
    type: types.SET_TEMPLATE,
    id
})