import React from 'react';
import { COMMON_REGEXP } from 'constants.js';
import { getSupportedLngs } from 'i18n';
import { LocalizedString } from 'utils/LocalizedString';
import { ERROR_MESSAGE_CONTENT } from 'modules/users/src/constants';
import { RestrictionRules, Rules } from '../hooks/useLocalizedTextValidation/useLocalizedTextValidation.types';

const supportedLangs = getSupportedLngs();
export type LangCode = typeof supportedLangs[number];
type Langs = typeof supportedLangs;

declare const Justify: readonly ['start', 'end', 'center', 'space-around', 'space-between', 'space-evenly'];
export type BorderRadius = '2px' | '4px' | '6px' | 'unset';

export interface LocalizedTextInputProps {
  /* Input name also used for generating input id's */
  inputName: string;
  /* The label for each input. Adds the language code to the label by default */
  labelTitle?: string;
  /* Defines the visual location of the label relative to the input field */
  labelPosition?: 'above' | 'left';
  /* Defines the direction of the input groups */
  direction?: 'row' | 'column';
  /* Inputs border radius */
  borderRadius?: BorderRadius;
  /* Sets justify when its row direction and align when it's column */
  justify?: typeof Justify[number];
  /* Divides into columns of the same width when the number of columns is passed */
  columns?: number;
  /* Array of available localizations (by default it is taken from i18n): [ru,en, ...] */
  localizationLangs?: Langs;
  /* Validation configuration */
  validation?: Rules;
  onChange?: (value?: LocalizedString) => void;
  /* Value in localized string format */
  value?: LocalizedString;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  /* The error handler is called for every change in the errors state or hint state*/
  onError?: (hint: string | undefined, errors?: Map<string, string>) => void;
  /* One placeholder for all fields or for each language */
  placeholder?: LocalizedString | string;
  /* Label displaying custom handler */
  labelCustomization?: (langCode: LangCode) => React.ReactNode;
  /* Hint displaying custom handler */
  hintCustomization?: (hint: string) => React.ReactNode;
  /* Handler to remove button */
  removeHandler?: () => void;
  /* Custom classes for component wrapper */
  wrapperClasses?: string;
}

export const defaultRestrictionRules: RestrictionRules = {
  ru: {
    expression: COMMON_REGEXP.CYRYLLIC_LETTERS_AND_SYMBOLS,
    flags: 'g',
    errorMessage: ERROR_MESSAGE_CONTENT.languageError
  },
  en: {
    expression: COMMON_REGEXP.LATIN_LETTERS_AND_SYMBOLS,
    flags: 'g',
    errorMessage: ERROR_MESSAGE_CONTENT.languageError
  }
};
