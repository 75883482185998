import ApolloClient from 'apollo-client';
import { Pagination } from 'utils/commonTypes';
import { deserialize, ObjectDeserializator } from 'utils/apiHelpers';
import { groupBy, mapToArray } from 'utils/typeHelpers';

import { GET_COUPONS_LIST, GET_USER_BY_LOGIN, GET_COUPON_BY_ID, GET_COUPONS_USERS } from './discountsQueries';
import { CREATE_COUPON, ADD_USERS_TO_COUPON, UPDATE_COUPON } from './discountsMutations';
import {
  couponsDeserializer,
  usersListPromocodeDeserializer
} from './discountsDeserializers';
import {
  CouponsList,
  FormSubmitValues,
  FormUpdateSubmitValues,
  Coupon,
  CouponsUsersList,
  UsersListPromocode
} from './discountsModel';
import { User } from 'common/users/types';
import { deserializeUser } from 'common/users/deserializers';


export interface DiscountsRepository {
  getCouponsList(paging: Pagination, searchRequest?: string): Promise<CouponsList>,
  getUserByLogin(login: string): Promise<User>,
  getCouponById(id: string): Promise<Coupon>,
  getCouponsUsers(id: string): Promise<CouponsUsersList[]>,
  createCoupon(data: FormSubmitValues): Promise<void>,
  updateCoupon(id: string, data: FormUpdateSubmitValues): Promise<void>,
  addCouponToUsers(descriptorId: string, userIds: string[]): Promise<void>,
}

export class DiscountsRepositoryImpl implements DiscountsRepository {
  constructor(private client: ApolloClient<any>) {}


  async getCouponsList(pagination: Pagination, searchRequest?: string) {
    const response = await this.client.query({
      query: GET_COUPONS_LIST,
      fetchPolicy: 'no-cache',
      variables: {
        paging: { limit: pagination.limit, page: pagination.page },
        filter: { type: 'PERSONAL_COUPON', search: searchRequest }
      }
    });
    const result: CouponsList = deserialize(response, (o) => {
      return {
        total: o.required('data.promocodes.getList.total').asNumber,
        list: o.required('data.promocodes.getList.list').asArrayOfObjects((o: ObjectDeserializator) => couponsDeserializer(o))
      }}
    );
    return result;
  };


  async getUserByLogin(login: string) {
    const response = await this.client.query({
      query: GET_USER_BY_LOGIN,
      fetchPolicy: 'no-cache',
      variables: { login: login }
    });
    const result: User = deserialize(response, (o) =>
      o.required('data.users.findByLogin').asObject((o: ObjectDeserializator) => deserializeUser(o)),
    );
    return result;
  };


  async getCouponById(couponId: string) {
    const response = await this.client.query({
      query: GET_COUPON_BY_ID,
      fetchPolicy: 'no-cache',
      variables: { id: couponId }
    });
    const result: Coupon = deserialize(response, (c) =>
      c.required('data.promocodes.getById').asObject((c: ObjectDeserializator) => couponsDeserializer(c))
    );
    return result;
  };

  async getCouponsUsers(couponId: string) {
    const response = await this.client.query({
      query: GET_COUPONS_USERS,
      fetchPolicy: 'no-cache',
      variables: {
        id: couponId,
        paging: { limit: 5000, page: 0 },
      }
    });
    const result: UsersListPromocode[] = deserialize(response, (u) =>
      u.required('data.promocodes.getById.codes.list').asArrayOfObjects((u: ObjectDeserializator) => usersListPromocodeDeserializer(u))
    );
    return mapToArray(groupBy(result, (item: UsersListPromocode) => item.couponUserId));
  }


  async createCoupon(data: FormSubmitValues) {
    await this.client.mutate({ mutation: CREATE_COUPON, variables: { data: data } });
  };


  async updateCoupon(id: string, data: FormUpdateSubmitValues) {
    await this.client.mutate({ mutation: UPDATE_COUPON, variables: { id: id, data: data } });
  };


  async addCouponToUsers(id: string, userIds: string[]) {
    await this.client.mutate({ mutation: ADD_USERS_TO_COUPON, variables: { descriptorId: id, userIds: userIds }});
  }

}
