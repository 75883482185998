import * as types from "../types";

const initialState = {
    template: null,
    categoryTemplate: null,
    isModalOpen: false,
    priceTemplateTitle: "",
    pricesOfTemplate: null,
    priceTemplate: null,
    typeOfButton: ""
}

export default (state = initialState, action: any) => {
    switch(action.type) {
        case types.SET_TEMPLATE: return ({
            ...state,
            template: action.id
        });
        case types.SET_CATEGORY_TEMPLATE: return ({
            ...state,
            categoryTemplate: action.id
        });
        case types.SET_PRICE_TEMPLATE_TITLE: return ({
            ...state,
            priceTemplateTitle: action.title
        });
        case types.SET_PRICES_OF_TEMPLATE: return ({
            ...state,
            pricesOfTemplate: action.value
        });
        case types.SET_TYPE_OF_BUTTON: return ({
            ...state,
            typeOfButton: action.value
        });
        default: return state;
    }
}