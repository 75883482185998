import gql from 'graphql-tag';

export const GET_USERS = gql`
  query {
    users {
      getList {
        id
        login
        person {
          birthday
          name
          patronymic
          surname
          contacts {
            type
            value
            isConfirmed
          }
        }
      }
    }
  }
`;
export const GET_USERS_BY_PAGE = gql`
  query getUsersByPage($page: Int!, $searchString: String!) {
    users {
      getListAdvanced(
        filter: { quickSearch: $searchString }
        paging: { limit: 10, page: $page }
      ) {
        total
        list {
          id
          login
          person {
            birthday
            name
            patronymic
            surname
            contacts {
              type
              value
              isConfirmed
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query($id: ID!) {
    users {
      getUserById(id: $id) {
        id
        visibleId
        login
        person {
          name
          surname
          patronymic
          contacts {
            type
            value
          }
          birthday
        }
      }
    }
  }
`;
