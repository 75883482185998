import gql from 'graphql-tag';

export const REMOVE_CODE: string = gql`
  mutation($data: PromocodeInput!) {
    promocodes {
      removePromocodes(data: $data) {
        title
      }
    }
  }
`;

export const GENERATE_NEW_CODES: string = gql`
  mutation($data: GeneratePromocodesInput!) {
    promocodes {
      generatePromocodes(data: $data) {
        title
        codes {
          list {
            value
          }
        }
      }
    }
  }
`;
