import gql from 'graphql-tag';

export const UPDATE_ACCESS_ZONE = gql`
  mutation updateAccessZone($id: ID!, $data: ACS_AccessZoneUpdateInput!) {
    acs {
      accessZone {
        update(id: $id, data: $data) {
          id

          name
          title
          description

          controlPresence
          venue {
            id
            title
          }
        }
      }
    }
  }
`;
export const DELETE_ACCESS_ZONE = gql`
  mutation deleteZone($id: ID!) {
    acs {
      accessZone {
        delete(id: $id)
      }
    }
  }
`;

export const CREATE_ACCESS_ZONE = gql`
  mutation createAccessZone($data: ACS_AccessZoneInput!) {
    acs {
      accessZone {
        create(data: $data) {
          id
          deletedDate
          name
          title
          description
          syncSourceId
          controlPresence
          venue {
            id
            title
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCESS_ENTRANCE = gql`
  mutation updateEntrance($id: ID!, $data: ACS_EntranceUpdateInput!) {
    acs {
      entrance {
        update(id: $id, data: $data) {
          id
          name
          title
          description
          venue {
            id
            title
          }
          zonesIn {
            id
            title
          }
          zonesOut {
            id
            title
          }
        }
      }
    }
  }
`;

export const DELETE_ACCESS_ENTRANCE = gql`
  mutation deleteEntrance($id: ID!) {
    acs {
      entrance {
        delete(id: $id)
      }
    }
  }
`;

export const CREATE_ACCESS_ENTRANCE= gql`
  mutation createEntrance($data: ACS_EntranceInput!) {
    acs {
      entrance {
        create(data: $data) {
          id
          name
          title
          description
          venue {
            id
            title
          }
          zonesIn {
            id
            title
          }
          zonesOut {
            id
            title
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCESS_CATEGORY = gql`
  mutation updateAccessCategory($id: ID!, $data: ACS_AccessCategoryUpdateInput!) {
    acs {
      accessCategory {
        update(id: $id, data: $data) {
          zones {
            name
          }
        }
      }
    }
  }
`;
