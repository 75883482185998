//TODO: similar constants are used in discounts/src/constants.js, should they be shared?

import { t } from 'i18next';

export const ERROR_MESSAGE_STYLE = { maxWidth: '300px', margin: '0 auto' };

export const ERROR_MESSAGE_CONTENT = {
  loadRolesError: `${t('modules.constants_users.error_loading_roles')}:`,
  loadPhotoError: t('modules.constants_users.error_loading_image'),
  userRegistrationError: `${t('modules.constants_users.error_creating_user')}:`,
  sameNumberError: t('modules.constants_users.user_already_exists'),
  fileTypeError: t('modules.constants_users.files_format_supported'),
  fileSizeError: t('modules.constants_users.size_should_not_exceed'),
  languageError: t('modules.constants_users.insertion_language_does_not_match'),
};

export const FORMAT_DATE = 'DD.MM.YYYY';
export const MAX_FILE_SIZE = 15;
export const FILE_TYPES = ['.jpg', '.png', '.jpeg'];
