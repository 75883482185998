import { LocalizedString } from 'utils/LocalizedString';

export type UsersTotal = {
  total: number;
};

export interface DynamicSendData {
  title: string;
  comment?: string;
  sql: string;
}

export interface StaticSendData {
  title: string;
  comment?: string;
  userIds: string[];
}

export enum SegmentType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export type SegmentData = {
  id: string;
  title: string;
  comment?: string;
  userIds: string[];
  users?: number;
  segmentType: SegmentType;
  sql?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
};

export type SegmentsListDescriptor = {
  [id: string]: SegmentData;
};

export type PersonContacts = {
  value: string;
};

export type PersonData = {
  name: LocalizedString;
  surname: LocalizedString;
  patronymic?: LocalizedString;
  phone?: string;
  email?: string;
};

export type UsersList = {
  person?: PersonData;
};

export type SegmentsPersonDescriptor = {
  id: string;
  person: PersonData;
};

export type User = {
  userId: string;
  login: string;
  name: LocalizedString;
  surname: LocalizedString;
  patronymic?: LocalizedString;
  phone?: string;
  email?: string;
};

export type InitialFormValues = {
  title: string;
  comment?: string;
  sql?: string;
  phonesList?: string;
  radios: string;
  table: SegmentsPersonDescriptor[];
};

export type FormValues = {
  title: string;
  comment?: string;
  radios: string;
  table: SegmentsPersonDescriptor[];
};

export type InputSql = {
  sql: string;
};

export type InputPhonesList = {
  phonesList: string;
};

export type FormSubmitValues = FormValues & InputSql & InputPhonesList;

export type PhonesInputList = {
  phonesList: string;
};

export type SqlInputRequest = {
  sql: string;
};
