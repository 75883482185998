import React, { useState, useEffect } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Select } from 'antd';
import {
  GET_PRICE_CATEGORY_TEMPLATES,
  GET_PRICE_CATEGORY_INFO,
  CREATE_TEMPLATE,
  ADD_PLACES_TEMPLATE,
} from '../../graphql';
import { useTranslation } from 'react-i18next';
import PriceCategoryItem from './PriceCategoryItem';
import {
  formatPrice,
  generatePriceTemplateErrors,
  isValidPrices,
  pricesSort,
  updatePriceArray
} from '../../utils/priceTemplateHelpers';

const SelectOption = Select.Option;

const PriceAddMenu = (props) => {
  const {t} = useTranslation();
  const [stateProps, setState] = useState({
    newTitle: '',
    newPrices: [],
    isLoaded: false,
    isUpdated: false,
    hints: new Map(),
  });

  const addPricesToTemplate = async (priceTemplateId, prices) => {
    const pricesList = prices.map((price) => ({
      value: price.value + '.00',
      priceCategoryId: price.id,
    }));

    await props.client.query({
      query: ADD_PLACES_TEMPLATE,
      variables: {
        id: priceTemplateId,
        prices: {
          list: pricesList,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  const createTemplate = async () => {
    const res = await props.client.query({
      query: CREATE_TEMPLATE,
      variables: {
        data: {
          title: stateProps.newTitle,
          priceCategoryTemplateId: stateProps.selectedCategoryId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return pathOr('', ['data', 'priceTemplate', 'add', 'id'], res);
  };

  const saveNewTemplate = async () => {
    try {
      const newPrices = stateProps.newPrices.filter((price) => price.value);
      if (newPrices && !isEmpty(newPrices) && isValidPrices(newPrices)) {
        const priceTemplateId = await createTemplate();
        await addPricesToTemplate(priceTemplateId, newPrices);
        message.success(t('modules.price_add_menu.message_success'));
        props.setLoading();
      } else {
        updateState({
          hints: generatePriceTemplateErrors(stateProps.newPrices, t('modules.price_edit_menu.message_error.invalid_price')),
          isCreating: false,
        })
        message.error(t('modules.price_edit_menu.message_error.invalid_price'));
      }
    } catch (e) {
      console.error(e);
      message.error(t('modules.price_add_menu.message_error.creation_error'));
    }
  }

  const loadCategoryTemplate = async (id) => {
    const res = await props.client.query({
      query: GET_PRICE_CATEGORY_INFO,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });

    const list = pathOr([], ['data', 'priceCategoryTemplate', 'getById', 'priceCategories'], res);

    setState({
      ...stateProps,
      isUpdated: false,
      priceCategories: list,
      selectedCategoryId: id,
    });
  };

  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_PRICE_CATEGORY_TEMPLATES,
        fetchPolicy: 'no-cache',
      });

      const list = pathOr([], ['data', 'priceCategoryTemplate', 'getList', 'list'], res);

      setState({
        ...stateProps,
        isLoaded: true,
        categoryList: list,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  const updateState = (props) => {
    setState(prevState => {
      return {
        ...prevState,
        ...props,
      }
    })
  }

  const onChangePrice = (e, item) => {
    e.preventDefault();
    const newPrices = updatePriceArray(stateProps.newPrices, e.target.value, item);
    updateState({newPrices: newPrices});
  }

  const onBlurPrice = (e, item) => {
    e.preventDefault();
    if (e.target.value) {
      const newValue = formatPrice(e.target.value);
      const newPrices = updatePriceArray(stateProps.newPrices, newValue, item);
      const errors = generatePriceTemplateErrors(newPrices, t('modules.price_edit_menu.message_error.invalid_price'));
      updateState({
        newPrices: newPrices,
        hints: errors,
      });
    }
  }


  const renderPriceCategories = () => {
    return (
      stateProps.priceCategories &&
      stateProps.priceCategories
      .sort(pricesSort)
      .map((item) => (
        <PriceCategoryItem
          price={item}
          hint={stateProps.hints.get(item.id)}
          value={stateProps.newPrices.find(price => price.id === item.id)?.value}
          onChange={onChangePrice}
          onBlur={onBlurPrice}
        />
      ))
    )
  }

  return (
    <>
      {stateProps.isLoaded && (
        <div className="sales__price-template__side-menu__container">
          <div className="sales__tickets__side-menu__header">
            <CloseOutlined
              onClick={() => {
                props.onClose();
              }}
              className="sales__tickets__side-menu__header__icon"
            />
            <div
              className="sales__tickets__side-menu__header__action">{t('modules.price_add_menu.div.new_template')}</div>

            {(stateProps.priceCategories && !!stateProps.newPrices.length)
              ? (<div
                className="sales__price-template__side-menu__header__update"
                onClick={async () => {
                  if (stateProps.newTitle) {
                    setState({
                      ...stateProps,
                      isCreating: true,
                    });
                    await saveNewTemplate();
                  } else {
                    message.error(t('modules.price_add_menu.message_error.title_error'));
                  }
                }}
              >
                {stateProps.isCreating ? <LoadingOutlined/> : t('modules.price_add_menu.div.save')}
              </div>)
              : null}
          </div>
          <div className="sales__price-template__side-menu__body">
            <div className="sales__tickets__side-menu__subtitle__pd sales__tickets__side-menu__subtitle">
              {t('modules.price_add_menu.div.template_name')}
            </div>
            <input
              className="sales__price-template__side-menu__body__title-input"
              placeholder={t('modules.price_add_menu.input.name')}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  newTitle: ev.target.value,
                });
              }}
            />
            <div className="sales__tickets__side-menu__subtitle__pd sales__tickets__side-menu__subtitle">
              {t('modules.price_add_menu.div.markup_type')}
            </div>
            <Select
              placeholder={t('modules.price_add_menu.select')}
              className="sales__price-template__side-menu__body__select"
              onChange={async (ev) => {
                setState({
                  ...stateProps,
                  newPrices: [],
                });
                await loadCategoryTemplate(ev);
              }}
            >
              {stateProps.categoryList.map((item) => {
                return <SelectOption value={item.id}>{item.title}</SelectOption>;
              })}
            </Select>
            <div className="sales__price-template__side-menu__body__container">
              {renderPriceCategories()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PriceAddMenu;
