import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu } from 'antd';
import '../style.scss';
import pathOr from 'ramda/es/pathOr';
import drop from 'ramda/es/drop';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

const NavBar = (props) => {
  const { t } = useTranslation();
  const color = window.parametrize('REACT_APP_PRIMARY_COLOR');
  const [openKeys, setOpenKeys] = React.useState([]);
  const [stateProps, setState] = useState({
    isLoaded: false,
  });
  const GET_USER_PERMISSION = gql`
    query {
      users {
        getProfile {
          roles {
            permissions
          }
        }
      }
    }
  `;
  const currentItem = window.location.pathname;
  useEffect(() => {
    const init = async () => {
      const permissionRes = await props.client.query({
        query: GET_USER_PERMISSION,
        fetchPolicy: 'no-cache',
      });
      let permissions = [];

      const roles = pathOr([], ['data', 'users', 'getProfile', 'roles'], permissionRes);
      roles.forEach((role) => {
        permissions.push(...role.permissions);
      });
      const filteredTabList = props.list.filter((listItem) => {
        return permissions.find((prm) => {
          return prm === listItem.permission;
        });
      });

      setState({
        isLoaded: true,
        filteredTabList,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, props.list]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
    if (keys.length >= 2) {
      keys.shift();
    }
  };

  const SUBITEM_PREFIX = 'subitem-';
  const isSubitem = (value) => typeof value == 'string' && value.includes(SUBITEM_PREFIX);

  const onSelected = (e) => {
    if (!isSubitem(e.key)) {
      setOpenKeys([]);
    }
  };

  return (
    <Menu
      className="nav-bar"
      defaultSelectedKeys={[drop(1, currentItem)]}
      mode="inline"
      inlineCollapsed={props.collapsed}
      theme="dark"
      onOpenChange={onOpenChange}
      onSelect={onSelected}
      openKeys={openKeys}
    >
      {stateProps.isLoaded
        ? stateProps.filteredTabList.map((item, index) => {
            if (pathOr(false, ['subitems', 'length'], item)) {
              return (
                <SubMenu
                  key={item.id}
                  className="nav-bar__item"
                  title={
                    <span>
                      <LegacyIcon type={item.icon} />
                      {t(`navbar.${item.localizationKey}`)}
                    </span>
                  }
                >
                  {item.subitems.map((subitem, index) => {
                    return (
                      <Menu.Item
                        key={SUBITEM_PREFIX + subitem.route}
                        className="df nav-bar__item aic"
                        style={{
                          color,
                        }}
                      >
                        <NavLink to={`/${subitem.route}`}>
                          <span>
                            <LegacyIcon type={subitem.icon} />
                            {t(`subitems.${subitem.localizationKey}`)}
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={item.route} className="df nav-bar__item aic" style={{color}}>
                <LegacyIcon type={item.icon}/>
                <NavLink to={`/${item.route}`}>{t(`navbar.${item.localizationKey}`)}</NavLink>
              </Menu.Item>
            );
          })
        : ''}
    </Menu>
  );
};

export default withApollo(NavBar);
