import React from 'react';
import { Button } from 'antd';
import './index.scss';

const HeaderAddButton = props => {
  return (
    <>
      <Button
        id="HeaderAddButtonTextButton"
        className={`${props.containerClassName} header-add-button`}
        onClick={ev => {
          props.onClick();
        }}
        disabled={props.disabled}
      >
        <span className={`${props.containerClassName} header-add-button__text`}>
          {props.buttonText}
        </span>
      </Button>
    </>
  );
};

export default HeaderAddButton;
