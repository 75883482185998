import gql from 'graphql-tag';

export const SET_PRICE_TEMPLATE = gql`
  mutation addTemplateToMatch($data: PriceTemplateEventInput!) {
    priceTemplateEvent {
      add(data: $data) {
        tag
      }
    }
  }
`;

export const DELETE_PRICE_TEMPLATE = gql`
  mutation addTemplateToMatch($data: PriceTemplateEventInput!) {
    priceTemplateEvent {
      delete(data: $data)
    }
  }
`;

export const CHANGE_MATCH_DATA = gql`
  mutation changeMatchData($id: ID!, $data: MatchUpdateInput!) {
    match {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;

export const CHANGE_PRICE_TEMPLATE = gql`
  mutation update($id: ID!, $data: SeasonTicketDescriptorUpdate!) {
    seasonTicketDescriptor {
      update(id: $id, data: $data) {
        priceTemplate {
          id
          title
        }
      }
    }
  }
`;
export const SEND_ORDER_SMS = gql`
  mutation($orderId: ID!, $phone: String!) {
    order {
      sendToCustomPhone(orderId: $orderId, phone: $phone)
    }
  }
`;
export const CREATE_ORDER = gql`
  mutation($data: OrderInput) {
    order {
      create(data: $data) {
        id
      }
    }
  }
`;
export const UPDATE_ORDER = gql`
  mutation($orderId: ID!, $data: OrderUpdateInput!) {
    order {
      updateOrder(orderId: $orderId, data: $data) {
        id
      }
    }
  }
`;

export const CHANGE_ORDER_USER = gql`
  mutation($orderId: ID! $newUserId: ID!) {
    order {
      changeOrderUser(orderId: $orderId, newUserId: $newUserId) {
        id
      }
    }
  }
`;
export const CREATE_TICKETS = gql`
  mutation($data: TicketInput!, $reserveUntil: Datetime, $ignoreAdminBlocking: Boolean) {
    ticket {
      create(data: $data, reserveUntil: $reserveUntil, ignoreAdminBlocking: $ignoreAdminBlocking ) {
        id
      }
    }
  }
`;
export const CREATE_SEASON_TICKETS = gql`
  mutation($data: SeasonTicketInput!, $reserveUntil: Datetime) {
    seasonTicket {
      create(data: $data, reserveUntil: $reserveUntil) {
        id
      }
    }
  }
`;
export const SEND_ORDER_TO_EMAIL = gql`
  mutation sendOrderToEmail($orderId: ID!, $overrideEmail: String) {
    cashier {
      sendPurchasedOrderToCustomerEmail(
        orderId: $orderId
        overrideEmail: $overrideEmail
      )
    }
  }
`;
export const SEND_TICKET_TO_EMAIL = gql`
  mutation sendTicketToEmail($ticketId: ID!, $overrideEmail: String) {
    ticket {
      sendToCustomEmail(
        ticketId: $ticketId
        email: $overrideEmail
      )
    }
  }
`;
export const SEND_SEASON_TICKET_TO_EMAIL = gql`
  mutation sendSeasonTicketToEmail($seasonTicketId: ID!, $overrideEmail: String) {
    seasonTicket {
      sendToCustomEmail(
        seasonTicketId: $seasonTicketId
        email: $overrideEmail
      )
    }
  }
`;
export const PARTIAL_REFUND_ORDER = gql`
  mutation partialRefundOrder($orderId: ID!, $orderItemIds: [ID!]!) {
    payments {
      partialRefund(orderId: $orderId, orderItemIds: $orderItemIds) {
        id
      }
    }
  }
`;

export const CANCEL_RESERVATION = gql`
  mutation cancelReservation($orderId: ID!, $orderItemIds: [ID!]!) {
    order {
      removeItems(orderId: $orderId, itemIds: $orderItemIds) {
        id
      }
    }
  }
`;
export const APPLY_PROMOCODE = gql`
  mutation applyPromocode($orderId: ID!, $promocode: String!) {
    order {
      applyPromocode(orderId: $orderId, promocode: $promocode) {
        priceWithDiscount
      }
    }
  }
`;
export const CANCEL_PROMOCODE = gql`
  mutation cancelPromocode($orderId: ID!) {
    order {
      cancelPromocode(orderId: $orderId) {
        id
      }
    }
  }
`;
export const UPDATE_PRICE_TEMPLATE = gql`
  mutation($id: ID!, $data: PriceTemplateUpdateInput!) {
    priceTemplate {
      update(id: $id, data: $data) {
        id
      }
    }
  }
`;
export const UPDATE_PRICES = gql`
  mutation($value: Money, $priceTemplateId: ID, $priceCategoryId: ID) {
    price {
      update(
        priceCategoryId: $priceCategoryId
        value: $value
        priceTemplateId: $priceTemplateId
      ) {
        value
      }
    }
  }
`;
export const DELETE_TEMPLATE = gql`
  mutation($id: ID!) {
    priceTemplate {
      delete(id: $id)
    }
  }
`;
export const CREATE_TEMPLATE = gql`
  mutation($data: PriceTemplateInput!) {
    priceTemplate {
      add(data: $data) {
        id
      }
    }
  }
`;
export const ADD_PLACES_TEMPLATE = gql`
  mutation($id: ID!, $prices: PricesInput!) {
    priceTemplate {
      addPrices(id: $id, prices: $prices) {
        id
      }
    }
  }
`;
export const SEND_CONFIRMATION_SMS = gql`
  mutation($phoneNumber: String!) {
    contacts {
      checkPhoneWithConfirmationCode(phoneNumber: $phoneNumber) {
        operationId
      }
    }
  }
`;
export const CHECK_CONFIRMATIONS_SMS = gql`
  mutation($operationId: ID!, $code: String!) {
    contacts {
      confirmWithCode(operationId: $operationId, code: $code)
    }
  }
`;
export const REGISTER_USER = gql`
  mutation($phone: String!) {
    registration {
      simpleUserRegistration(phone: $phone) {
        id
      }
    }
  }
`;
export const UPDATE_PROFILE = gql`
  mutation($id: ID!, $user: UserUpdateInput!) {
    users {
      update(id: $id, user: $user) {
        id
      }
    }
  }
`;


