import React from 'react';
import { Menu } from 'antd';
import 'antd/dist/reset.css';
import './AccessMenu.scss';
import { FormType } from '../../graphql/accessZoneModel';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from '../../../../../utils/LocalizedString';
import i18next from 'i18next';

function AccessMenu(props: {
  data: object;
  handleDataChange: (id: string) => void;
  isChangedInput?: boolean;
  setModalCloseWindowWarningData: (data: { id: string; visible: boolean }) => void;
  id: string;
  formType?: FormType;
}) {
  const {t} = useTranslation();

  const itemStyles = {
    height: '60px !important',
    padding: '20px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
  };

  const newItemStyles = {
    backgroundColor: '#ebedee',
    height: '60px !important',
    padding: '20px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
  };

  const menuElement = Object.entries(props.data).map(([id, value]) => (
    <Menu.Item
      title={(value.title as LocalizedString).toString(i18next.language)}
      key={id}
      className="AccessMenu__item"
      style={itemStyles}
      onClick={() =>
        props.isChangedInput
          ? props.setModalCloseWindowWarningData({ id: id, visible: true })
          : props.handleDataChange(id)
      }
    >
      {(value.title as LocalizedString).toString(i18next.language)}
    </Menu.Item>
  ));

  return (
    <Menu className="AccessMenu__list" selectedKeys={props.formType === 'update' ? [props.id] : []}>
      {(props.formType === 'create zone' || props.formType === 'create entrance') && (
        <Menu.Item className="AccessMenu__item AccessMenu__item_new" style={newItemStyles}>
          {props.formType === 'create zone'
            ? t('modules.access_menu.menu_item.new_zone')
            : t('modules.access_menu.menu_item.new_entrance')}
        </Menu.Item>
      )}
      {menuElement}
    </Menu>
  );
}

export default AccessMenu;
