import React, { useEffect, useState } from 'react';
import * as L from 'leaflet';
import { pathOr } from 'ramda';
//DONT DELETE
import SelectArea from 'leaflet-area-select';
//DONT DELETE

const MapComponent = props => {
  const placeRadius = props.placeRadius ? props.placeRadius : 80 / 164;
  const weight = props.weight ? props.weight : 0.2;
  const initialZoom = props.initialZoom ? props.initialZoom : 3;
  const minZoom = props.minZoom ? props.minZoom : 3;
  const maxZoom = props.maxZoom ? props.maxZoom : 8;
  const eventHandler = props.eventHandler ? props.eventHandler : () => {};
  const remoteHandler = props.remoteHandler ? props.remoteHandler : () => {};
  const canvasTolerance = props.canvasTolerance ? props.canvasTolerance : 5;
  const clickableFromZoom = props.clickableFromZoom ? props.clickableFromZoom : 5;

  const [placesState, setPlacesState] = useState(null);

  const [mapState, setMapState] = useState(null);

  const [zoomKfState, setZoomKf] = useState(1);

  let zoomKf = zoomKfState;

  let placeCount = 0;

  useEffect(() => {
    const map = L.map(props.containerId, {
      keyboard: false,
      crs: L.CRS.Simple,
      renderer: L.canvas({ tolerance: canvasTolerance }),
      minZoom: minZoom,
      maxZoom: maxZoom,
      zoomControl: false,
      selectArea: true, // will enable it by default,
      preferCanvas: true,
      boxZoom: false,
      mapMode: 'SELECT',
    });
    map.selectArea.setShiftKey(true)
    //rendering plazes
    const placeNumbers = [];

    const places = props.placesList.map(item => {
      const lat = pathOr(0, ['coordinates', 'y'], item);
      const lng = pathOr(0, ['coordinates', 'x'], item);
      const latLng = L.latLng([lat, lng]);

      const place = L.circleMarker(latLng, {
        radius: 1,
        weight: 0,
        id: pathOr('', ['id'], item),
        number: pathOr('', ['number'], item),
        row: pathOr('', ['row', 'number'], item),
        sector: pathOr('', ['row', 'sector', 'title'], item),
        fillColor: '#CAE6FF',
        originalColor: '#CAE6FF',
        fillOpacity: 1,
        interactive: true,
        placeObj: item,
      }).addTo(map);

      return place;
    });

    setPlacesState(places);

    setMapState(map);

    places.forEach(place => {
      place.on('click', async function() {
        const zoomLevel = map._zoom;
        if (zoomLevel >= clickableFromZoom) {
          if (!place.options.isSelected && placeCount < props.maxSelection) {
            if (props.multipleMode) {
              ++placeCount;
              place.setStyle({
                color: place.options.originalColor,
                fillColor: '#ffffff',
                weight: weight * zoomKf,
                isSelected: true,
              });
            } else {
              places.forEach(item => {
                if (item.options.id !== place.options.id) {
                  item.setStyle({
                    fillColor: place.options.originalColor,
                    weight: 0,
                    isSelected: false,
                  });
                } else {
                  item.setStyle({
                    color: place.options.originalColor,
                    fillColor: '#ffffff',
                    weight: weight * zoomKf,
                    isSelected: true,
                  });
                }
              });
            }
            eventHandler({
              type: 'placeSelect',
              data: place,
            });
          } else if (place.options.isSelected) {
            if (props.multipleMode) {
              --placeCount;
            }
            place.setStyle({
              fillColor: place.options.originalColor,
              weight: 0,
              isSelected: false,
            });
            eventHandler({
              type: 'placeRemove',
              data: {
                id: place.options.id,
              },
            });
          }
        }
      });
    });

    map.on('areaselected', e => {
      if (props.multipleMode) {
        const selectedPlaces = places
          .filter(item => e.bounds.contains(item.getLatLng()) && !item.options.isSelected)
          .map(item =>
            item.setStyle({
              color: item.options.originalColor,
              fillColor: '#ffffff',
              weight: weight * zoomKf,
              isSelected: true,
            })
          );
        eventHandler({
          type: 'placeArraySelect',
          data: selectedPlaces,
        });
      }
    });

    eventHandler({
      type: 'mapInit',
      data: {
        map,
        places,
      },
    });
    ///
    //add overlay
    const bounds = [[0, 0], [props.overlaySize.height, props.overlaySize.width]];
    L.imageOverlay(props.overlayUrl, bounds).addTo(map);
    map.setMaxBounds(bounds);
    //add zoom
    L.control
      .zoom({
        position: 'topleft',
      })
      .addTo(map);
    //setting map drag
    map.dragging.enable();
    //set viev
    map.setView(new L.LatLng(props.overlaySize.height / 2, props.overlaySize.width / 2), initialZoom);
    //
    const restandNumbers = () => {
      dropNumbers();
      const zoomClass = map.getZoom() === 8 ? 'zoom-1' : 'zoom-2';
      places.forEach(item => {
        if (map.getBounds().contains(item.getLatLng())) {
          const divIcon = L.divIcon({
            html: `<div class="${zoomClass}">${pathOr('0', ['options', 'number'], item)}</div>`,
          });
          placeNumbers.push(
            L.marker(item.getLatLng(), {
              icon: divIcon,
            }).addTo(map)
          );
        }
      });
    };
    const dropNumbers = () => {
      placeNumbers.forEach(item => item.remove());
      placeNumbers.length = 0;
    };
    map.on('moveend', () => {
      if (map.getZoom() > 6) {
        restandNumbers();
      }
      eventHandler({
        type: 'moveEnd',
        data: weight * zoomKf,
      });
    });
    map.on('zoom', e => {
      const handler = (k, item) => {
        item.setRadius(placeRadius * k);
        if (item.options.isSelected) {
          item.setStyle({
            ...item.options,
            weight: weight * k,
          });
        }
      };
      eventHandler({
        type: 'moveEnd',
        data: {
          zoom: map.getZoom(),
          position: map.getCenter(),
        },
      });
      const zoom = e.target._zoom;
      switch (zoom) {
        case 3:
          places.map(item => {
            setZoomKf(3);
            zoomKf = 3;
            handler(3, item);
            return true;
          });
          dropNumbers();
          break;
        case 4:
          places.map(item => {
            setZoomKf(4);
            zoomKf = 4;
            handler(4, item);
            return true;
          });
          dropNumbers();
          break;
        case 5:
          places.map(item => {
            setZoomKf(8);
            zoomKf = 8;
            handler(8, item);
            return true;
          });
          dropNumbers();
          break;
        case 6:
          places.map(item => {
            setZoomKf(17);
            zoomKf = 17;
            handler(17, item);
            return true;
          });
          dropNumbers();
          break;
        case 7:
          places.map(item => {
            setZoomKf(30);
            zoomKf = 30;
            handler(30, item);
            return true;
          });
          restandNumbers();
          break;
        case 8:
          places.map(item => {
            setZoomKf(55);
            zoomKf = 55;
            handler(55, item);
            return true;
          });
          restandNumbers();
          break;
        default:
          setZoomKf(1);
          zoomKf = 1;
          places.map(item => handler(1, item));
          dropNumbers();
      }
      return true;
    });
    remoteHandler(map, places);
  }, []);

  useEffect(() => {
    if (props.mapLocked) {
      placesState.forEach(place => {
        place.off('click');
      });
      mapState.off('areaselected');
    } else if (mapState && placesState) {
      placesState.forEach(item => {
        item.setStyle({
          fillColor: item.options.originalColor,
          weight: 0,
          isSelected: false,
        });
      });
      placesState.forEach(place => {
        place.off('click');
      });
      mapState.off('areaselected');
      placesState.forEach(place => {
        place.on('click', async function() {
          const zoomLevel = mapState._zoom;
          if (zoomLevel >= clickableFromZoom) {
            if (!place.options.isSelected && placeCount < props.maxSelection) {
              if (props.multipleMode) {
                ++placeCount;
                place.setStyle({
                  color: place.options.originalColor,
                  fillColor: '#ffffff',
                  weight: weight * zoomKf,
                  isSelected: true,
                });
              } else {
                placesState.forEach(item => {
                  if (item.options.id !== place.options.id) {
                    item.setStyle({
                      fillColor: place.options.originalColor,
                      weight: 0,
                      isSelected: false,
                    });
                  } else {
                    item.setStyle({
                      color: place.options.originalColor,
                      fillColor: '#ffffff',
                      weight: weight * zoomKf,
                      isSelected: true,
                    });
                  }
                });
              }
              eventHandler({
                type: 'placeSelect',
                data: place,
              });
            } else if (place.options.isSelected) {
              if (props.multipleMode) {
                --placeCount;
              }
              place.setStyle({
                fillColor: place.options.originalColor,
                weight: 0,
                isSelected: false,
              });
              eventHandler({
                type: 'placeRemove',
                data: {
                  id: place.options.id,
                },
              });
            }
          }
        });
      });

      mapState.on('areaselected', e => {
        if (props.multipleMode) {
          const selectedPlaces = placesState
            .filter(item => e.bounds.contains(item.getLatLng()) && !item.options.isSelected)
            .map(item =>
              item.setStyle({
                color: item.options.originalColor,
                fillColor: '#ffffff',
                weight: weight * zoomKf,
                isSelected: true,
              })
            );
          eventHandler({
            type: 'placeArraySelect',
            data: selectedPlaces,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapLocked, props.mapState, props.multipleMode]);

  return <div id={props.containerId}></div>;
};

export default MapComponent;
