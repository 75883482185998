import {Rules, TextValidationConfig, ValidationResult} from './useLocalizedTextValidation.types';
import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {LocalizedString, localizedStringIsNotFull} from '../../../utils/LocalizedString';

export default function useTextValidation({config, onError}: TextValidationConfig): ValidationResult {

  const [hint, setHint] = useState<string | undefined>(undefined);
  const errors = new Map<keyof Rules, string>();
  type ValidationDataRefType = {
    hint: string | undefined,
    errors: Map<keyof Rules, string>,
  }
  const validationDataRef = useRef<ValidationDataRefType>();
  // Effects

  useEffect(() => {
    defineHints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const setRequiredHint = (indicator: boolean) => {
    switch (indicator) {
      case true:
        if (config?.required?.message) {
          const message = config.required.message;
          const newHint = typeof message === 'string' ? message : message.toString(i18next.language);
          errors.set('required', newHint);
          defineHints();
        }
        break;
      case false:
        errors.delete('required');
        defineHints();
        break;
      default:
        break;
    }
  };

  const defineHints = () => {
    const newHint = errors.keys() ? Array.from(errors.values()).join('; ') : undefined;
    if (newHint !== hint) {
      setHint(newHint);
    }
    validationDataRef.current = {
      hint: newHint,
      errors,
    }
  };


  const restrictKeyInput = (e: React.KeyboardEvent<HTMLInputElement>, lang: string) => {
    const restriction = config?.restrict;
    if (restriction && restriction.value) {
      const re = new RegExp(restriction.rule[lang].expression, restriction.rule[lang].flags);
      if (!re.test(e.key)) {
        e.preventDefault();
        const message = restriction.rule[lang].errorMessage || restriction.message || '';
        const newHint = typeof message === 'string' ? message : message.toString(i18next.language);
        message && errors.set('restrict', newHint);
        defineHints();
      } else {
        errors.delete('restrict');
        defineHints();
      }
    } else {
      return undefined;
    }
  };

  const restrictPaste = (e: React.ClipboardEvent<HTMLInputElement>, lang: string) => {
    const restriction = config?.restrict;
    if (restriction && restriction.value) {
      const re = new RegExp(restriction.rule[lang].expression, restriction.rule[lang].flags);
      const data = e.clipboardData.getData('text');
      if (!re.test(data)) {
        e.preventDefault();
        const message = restriction.rule[lang].errorMessage || restriction.message || '';
        const newHint = typeof message === 'string' ? message : message.toString(i18next.language);
        message && errors.set('restrict', newHint);
        defineHints();
      } else {
        errors.delete('restrict');
        defineHints();
      }
    } else {
      return undefined;
    }
  };

  const checkRequired = (value: LocalizedString) => {
    if (config?.required?.value && localizedStringIsNotFull(value)) {
      setRequiredHint(true);
    } else {
      setRequiredHint(false);
    }
  };

  const handleErrors = () => {
    defineHints();
    const currentData = validationDataRef.current;
    onError && onError(currentData?.hint, currentData?.errors);
  };

  // TODO: Add minimum and maximum length handling
  return {
    errors,
    hint,
    restrictKeyInput,
    restrictPaste,
    checkRequired,
    handleErrors,
  };
}
