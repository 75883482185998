import React from 'react';
import { Query } from 'react-apollo';
import { GET_TEMPLATES } from '../graphql';

export default ({
  setState,
  setPriceTemplateTitle,
  setPricesOfTemplate,
  setCategoryTemplate,
  setTypeOfButton,
  setTemplate
}) => (
  <>
    <Query query={GET_TEMPLATES}>
      {({ data, loading, error }) => {
        if (loading) {
          return <p>Loading...</p>;
        } else if (error) {
          return <p>Error :(</p>;
        } else {
          let pricesOfTemplate = [];
          const idTemplates = Array.from(
            new Set(data.priceTemplate.getList.list.map(item => item.id))
          ).map(id => {
            return {
              id: id,
              title: data.priceTemplate.getList.list.find(
                elem => elem.id === id
              ).title // Changed == to === may invoke some issues
            };
          });
          return (
            <>
              <div className="category-price__list">
                {data.priceTemplate.getList.list.map((item, index) => {
                  return (
                    <>
                      {idTemplates[index] ? (
                        // Code below is bad and need to fix
                        <div
                          key={item.id}
                          data-type="update"
                          data-id={item.id}
                          onClick={e => {
                            item.prices.forEach(price => {
                              const { priceCategory } = price;
                              if (item.id === e.target.dataset.id) {
                                pricesOfTemplate.push({
                                  id: priceCategory.id,
                                  value: price.value
                                });
                              }
                            });
                            setState({ handleModalVisible: true });
                            setPriceTemplateTitle(item.title);
                            setCategoryTemplate(item.priceCategoryTemplate.id);
                            setTypeOfButton(e.target.dataset.type);
                            setPricesOfTemplate(pricesOfTemplate);
                            setTemplate(e.target.dataset.id);
                          }}
                          className="category-price__list-template df jcsb aic"
                        >
                          <p data-type="update" data-id={item.id}>
                            {idTemplates[index].title}
                          </p>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </>
          );
        }
      }}
    </Query>
  </>
);

// {idTitles[index] !== "" ? (<p data-type="update" data-id={item.id}>{idTitles[index]}</p>) : false}
