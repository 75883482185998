import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDidMount } from 'utils/apiHelpers';
import ApolloClient from 'apollo-client';
import { withApollo } from 'react-apollo';

import styles from './PersonalCoupon.module.scss';
import ExpandedRows from './ExpandedRows/ExpandedRows';
import Loader from 'common/Loader/Loader';
import EmptyPlaceholder from 'common/EmptyPlaceholder/EmptyPlaceholder';
import AddUsersModal from '../AddUsersModal/AddUsersModal';

import { Button, message, Table, Dropdown, Menu } from 'antd';
import {
  LoadingOutlined,
  EditOutlined,
  LeftOutlined,
  DownOutlined,
  UpOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { DiscountsRepositoryImpl, DiscountsRepository } from '../../model/DiscountsRepository';
import { Coupon, CouponsUsersList, UsersListPromocode } from '../../model/discountsModel';
import { ERROR_MESSAGE_TIME_DURATION, NBS } from 'constants.js';
import { ERROR_MESSAGE_STYLE, ERROR_MESSAGE_CONTENT } from '../../../constants';

import formatDiscount from '../../utils/formatDiscount';
import discountBoundMaker from '../../utils/discountBoundMaker';
import formatPeriod from '../../utils/formatPeriod';
import formatUseCoupons from '../../utils/formatUseCoupons';
import declOfNum from '../../utils/declOfNum';
import iconLoader from '../../../img/iconLoader.svg';
import DiscountsFormModal from '../DiscountsFormModal/DiscountsFormModal';
import { useTranslation } from 'react-i18next';

function PersonalCoupon(props: { client: ApolloClient<any> }) {
  const { t } = useTranslation();
  const discountsRepository: DiscountsRepository = new DiscountsRepositoryImpl(props.client);
  let couponId = useParams<{ id: string }>();
  const { Column } = Table;

  const [isCouponListLoading, setIsCouponListLoading] = useState<boolean>(false);
  const [isCouponsUsersLoading, setIsCouponsUsersLoading] = useState<boolean>(false);
  const [couponData, setCouponData] = useState<Coupon>();
  const [isCouponDataError, setIsCouponDataError] = useState<boolean>(false);
  const [usersList, setUserList] = useState<CouponsUsersList[]>();
  const [isDiscountsFormModalOpened, setIsDiscountsFormModalOpened] = useState<boolean>(false);
  const [isAddUsersModalOpened, setIsAddUsersModalOpened] = useState<boolean>(false);

  const contentBlockClass = classNames(styles.contentBlock, {
    [styles.contentBlockHeight]: isCouponListLoading || isCouponsUsersLoading,
  });

  async function getCouponById() {
    try {
      setIsCouponListLoading(true);
      couponId.id && setCouponData(await discountsRepository.getCouponById(couponId.id));
    } catch (e) {
      setIsCouponDataError(true);
      console.log('getCouponById error', e);
      message.error({
        content: `${ERROR_MESSAGE_CONTENT.loadError} ${e}`,
        duration: ERROR_MESSAGE_TIME_DURATION,
        style: ERROR_MESSAGE_STYLE,
      });
    } finally {
      setIsCouponListLoading(false);
    }
  }

  async function getCouponsUsers() {
    try {
      setIsCouponsUsersLoading(true);
      couponId.id && setUserList(await discountsRepository.getCouponsUsers(couponId.id));
    } catch (e) {
      console.log('getCouponById error', e);
      message.error({
        content: `${ERROR_MESSAGE_CONTENT.loadError} ${e}`,
        duration: ERROR_MESSAGE_TIME_DURATION,
        style: ERROR_MESSAGE_STYLE,
      });
    } finally {
      setIsCouponsUsersLoading(false);
    }
  }

  useDidMount(() => {
    getCouponById();
    getCouponsUsers();
  });

  // const handleMenuClick = (ids: any) => {
  //   console.log('handleMenuClick', ids);
  // }
  //
  // const dropdownMenu = (
  //   <Menu onClick={ handleMenuClick } >
  //     <Menu.Item key='1'>
  //       {t('modules.personal_coupon.dropdown_menu')}
  //     </Menu.Item>
  //   </Menu>
  // );
  //
  // // Checkboxes
  // const onSelectChange = (selectedRowKeys: string[]) => {
  //   // Displays user id
  //   // handleMenuClick(selectedRowKeys);
  //   console.log('selectedRowKeys', selectedRowKeys);
  // }
  //
  // const rowSelection = {
  //   onChange: onSelectChange
  // }

  return (
    <section className={styles.personalCoupon}>
      {!couponData && isCouponDataError ? (
        <div className={styles.contentBlock}>
          <EmptyPlaceholder text={t('modules.personal_coupon.empty_placeholder.text.information')!} />
        </div>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <a href="/admin/discounts" className={styles.iconLink} title={t('modules.personal_coupon.a')!}>
              <LeftOutlined className={styles.leftOutlinedIcon} />
            </a>
            {isCouponListLoading ? <LoadingOutlined /> : <h1 className={styles.headerTitle}>{couponData?.title}</h1>}
            <Button
              id="PersonalCouponIssueCouponsButton"
              type="ghost"
              className={styles.headerButton}
              size="large"
              onClick={() => setIsAddUsersModalOpened(true)}
            >
              {t('modules.personal_coupon.button.issue_coupons')}
              {/*{ isSubmitLoading ? <LoadingOutlined /> : t('modules.personal_coupon.button.issue_coupons') }*/}
            </Button>
          </div>

          <div className={contentBlockClass}>
            {isCouponListLoading ? (
              <Loader />
            ) : (
              <>
                <div className={styles.headerContentContainer}>
                  <h2 className={styles.contentTitle}>{couponData?.title}</h2>
                  <EditOutlined
                    className={styles.editOutlinedIcon}
                    onClick={() => setIsDiscountsFormModalOpened(true)}
                  />
                </div>

                <Table dataSource={couponData && [couponData]} rowKey="id" pagination={false}>
                  <Column
                    title={t('modules.personal_coupon.column.discount')}
                    key="discount.value"
                    align="left"
                    render={(couponsData: Coupon) => {
                      return formatDiscount(couponsData.discount);
                    }}
                  />
                  <Column
                    title={t('modules.personal_coupon.column.condition')}
                    key="priceBound.start"
                    align="left"
                    render={(couponData: Coupon) => {
                      return discountBoundMaker(couponData);
                    }}
                  />
                  <Column
                    title={t('modules.personal_coupon.column.validity_period')}
                    key="couponsData.period.start"
                    align="left"
                    render={(couponsData: Coupon) => {
                      return formatPeriod(couponsData.period);
                    }}
                  />
                  <Column
                    title={`${t('modules.personal_coupon.column.activations_on')}${NBS}${t(
                      'modules.personal_coupon.column.account'
                    )}`}
                    dataIndex="maxUseCountsPerUser"
                    align="left"
                  />
                </Table>
              </>
            )}
          </div>

          <div className={styles.contentBlock} style={{ minHeight: '50vh' }}>
            <h2 className={styles.contentTitle}>{t('modules.personal_coupon.h2')}</h2>

            {isCouponsUsersLoading ? (
              <Loader />
            ) : usersList && usersList.length !== 0 ? (
              <>
                <Table
                  dataSource={usersList}
                  // rowSelection={ rowSelection }
                  rowKey="key"
                  className={styles.usersTable}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (usersList) => <ExpandedRows data={usersList.value} />,
                    rowExpandable: (usersList) => usersList.value.length > 1,
                    // expandIcon: ({ expanded, onExpand, record }) =>
                    //   expanded ? (
                    //     <UpOutlined onClick={e => onExpand(record, e)} />
                    //   ) : (
                    //     <DownOutlined onClick={e => onExpand(record, e)} />
                    //   )
                  }}
                >
                  <Column
                    title={t('modules.personal_coupon.column.copy_coupon')}
                    key="value.value"
                    align="left"
                    render={(usersList: CouponsUsersList) => {
                      const words: string[] = [
                        t('modules.personal_coupon.words.coupon_1'),
                        t('modules.personal_coupon.words.coupon_2'),
                        t('modules.personal_coupon.words.coupon_3'),
                      ];

                      return usersList.value.length > 1
                        ? `${usersList.value.length} ${declOfNum(usersList.value.length, words)}`
                        : usersList.value[0].value.slice(-5);
                    }}
                  />
                  <Column
                    title={t('modules.personal_coupon.column.user')}
                    key="value.couponUserId"
                    align="left"
                    render={(usersList: CouponsUsersList) => {
                      const surname = usersList.value[0].couponUser?.person.surname.toString();
                      const name = usersList.value[0].couponUser?.person.name.toString();
                      const patronymic = usersList.value[0].couponUser?.person.patronymic?.toString() || '';

                      return usersList.value[0].couponUser?.person && `${surname} ${name} ${patronymic}`;
                    }}
                  />
                  <Column
                    title={t('modules.personal_coupon.column.applying_coupon')}
                    key="value.useCounter"
                    align="left"
                    render={(usersList: CouponsUsersList) => {
                      if (usersList.value.length > 1) {
                        const usedCouponsCount = usersList.value.filter((elem) => elem.useCounter > 0).length;

                        const usageMessage =
                          usedCouponsCount === 0
                            ? t('modules.personal_coupon.span.not_applied_yet')
                            : usedCouponsCount < usersList.value.length
                            ? t('modules.personal_coupon.span.not_applied')
                            : t('modules.personal_coupon.span.all_applied');

                        return (
                          <>
                            {usedCouponsCount < usersList.value.length ? (
                              <img
                                src={iconLoader}
                                alt={t('modules.personal_coupon.img')!}
                                style={{ width: '14px', marginRight: '8px' }}
                              />
                            ) : (
                              <CheckOutlined style={{ color: '#03B94E', marginRight: '8px' }} />
                            )}
                            <span>{usageMessage}</span>
                          </>
                        );
                      } else {
                        return formatUseCoupons(
                          usersList.value[0].useCounter,
                          usersList.value[0].descriptor.maxUseCountsPerUser
                        );
                      }
                    }}
                  />
                </Table>

                {/*<Dropdown*/}
                {/*  overlay={ dropdownMenu }*/}
                {/*  className={ styles.dropdown }*/}
                {/*>*/}
                {/*  <Button>*/}
                {/*    {t('modules.personal_coupon.button.action')} <DownOutlined />*/}
                {/*  </Button>*/}
                {/*</Dropdown>*/}
              </>
            ) : (
              <EmptyPlaceholder
                handleClick={() => setIsAddUsersModalOpened(true)}
                // title={t('modules.personal_coupon.empty_placeholder.title')!}
                text={t('modules.personal_coupon.empty_placeholder.text.issued_yet')!}
                buttonText={t('modules.personal_coupon.empty_placeholder.button_text')!}
              />
            )}
          </div>

          {isDiscountsFormModalOpened && (
            <DiscountsFormModal
              discountsRepository={discountsRepository}
              isDiscountsFormModalOpened={isDiscountsFormModalOpened}
              setIsDiscountsFormModalOpened={setIsDiscountsFormModalOpened}
              couponData={couponData}
              getCouponById={getCouponById}
            />
          )}

          {isAddUsersModalOpened && couponData && (
            <AddUsersModal
              isAddUsersModalOpened={isAddUsersModalOpened}
              setIsAddUsersModalOpened={setIsAddUsersModalOpened}
              couponId={couponData.id}
              discountsRepository={discountsRepository}
              getCouponsUsers={getCouponsUsers}
            />
          )}
        </>
      )}
    </section>
  );
}

export default withApollo(PersonalCoupon);
