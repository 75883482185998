export const addTicket = data => ({
  type: 'ADD_TICKET',
  data
});
export const addTicketArray = data => ({
  type: 'ADD_TICKET_ARRAY',
  data
});
export const removeTicket = id => ({
  type: 'REMOVE_TICKET',
  id
});
export const clearTickets = () => ({
  type: 'CLEAR_TICKETS'
});
export const setPlaces = data => ({
  type: 'SET_PLACES',
  data
});
export const setSelectedPlaces = data => ({
  type: 'SET_SELECTED_PLACES',
  data
});
export const removePlace = data => ({
  type: 'REMOVE_PLACE',
  data
});
export const setSideMenuStep = data => ({
  type: 'SET_SIDE_MENU_STEP',
  data
});

export const setMapWeight = data => ({
  type: 'SET_MAP_WEIGHT',
  data
});
