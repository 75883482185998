import React from 'react';
import './DropdownList.scss';
import { Dropdown } from 'react-bootstrap';

export default function DropdownList(props: { list: any[]; title: string; itemStyles?: string; disabled: boolean; }) {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle disabled={props.disabled} variant="success" id="dropdown-basic" className="ac-dropdown">
          {props.title}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.list.map((item) => {
            return (
              <Dropdown.Item
                className={props.itemStyles}
                key={item.id}
                id={item.id}
                onClick={(evt: any) => {
                  item.clickAction(evt);
                }}
              >
                {item.buttonTitle}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
