import gql from 'graphql-tag';

export const UPDATE_PRICE = gql`
  mutation updatePrice(
    $value: Money!
    $priceTemplateId: ID!
    $priceCategoryId: ID!
  ) {
    price {
      update(
        value: $value
        priceTemplateId: $priceTemplateId
        priceCategoryId: $priceCategoryId
      ) {
        value
        priceCategory {
          id
        }
      }
    }
  }
`;

export const SET_NEW_CATEGORY_TEMPLATE = gql`
  mutation add($data: PriceTemplateInput!) {
    priceTemplate {
      add(data: $data) {
        id
        title
        priceCategoryTemplate {
          id
        }
        prices {
          value
          priceCategory {
            id
            color
            title
          }
        }
      }
    }
  }
`;

export const SET_PRICE_CATEGORY_TEMPLATES = gql`
  mutation addPrices($id: ID!, $prices: PricesInput!) {
    priceTemplate {
      addPrices(id: $id, prices: $prices) {
        id
        title
        priceCategoryTemplate {
          id
        }
        prices {
          value
          priceCategory {
            id
            color
            title
          }
        }
      }
    }
  }
`;

export const SET_NEW_TITLE_TEMPLATES = gql`
  mutation updateTitle($id: ID!, $data: PriceTemplateUpdateInput!) {
    priceTemplate {
      update(id: $id, data: $data) {
        id
        title
      }
    }
  }
`;
