import React from 'react';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Button, Col, Divider, Row} from 'antd';
import styles from './EditForm.module.scss';
import ApolloClient from 'apollo-client';
import {
  ACC_AccessCategory,
  ACC_AccreditationRequestUpdateInput,
  ACC_RequestType,
  Accreditation,
  AccreditationDescriptor,
  AccreditationRequestStatus,
  AdditionalFields,
  Events,
  RemoteFile
} from '../../../model/accreditationModel';
import {date} from 'utils/helpers';
import {FormComponentProps} from '@ant-design/compatible/lib/form/Form';
import PropTypes from 'prop-types';
import AccreditationHeader from '../AccreditationHeader/AccreditationHeader';
import {LocalizedString} from 'utils/LocalizedString';
import {deserialize, fetchData} from 'utils/apiHelpers';
import AccessCategorySelector from './Components/AccessCategorySelector/AccessCategorySelector';
import RequestStatusSelector from './Components/RequestStatusSelector/RequestStatusSelector';
import {GET_ACCREDITATION_REQUEST_DESCRIPTOR, GET_ACCSESS_CATEGORIES} from '../../../model/accreditationQueries';
import {ACCEPT_ACCREDITATION, ACCREDITATION_UPDATE, REJECT_ACCREDITATION} from '../../../model/accreditationMutations';
import {
  accessCategoryDeserializer,
  deserializeAccreditationRequestDescriptor
} from '../../../model/accreditationDeserializers';
import AdditionalFieldsComponent from './Components/AdditionalFieldsComponent/AdditionalFieldsComponent';
import Loader from './../../components/Loader/Loader';
import AccreditationTypeSelector from './Components/AccreditationTypeSelector/AccreditationTypeSelector';
import Contacts from './Components/Contacts/Contacts';
import {Contact, ContactType} from 'utils/commonTypes';
import ImageUpload from './Components/ImageUpload/ImageUpload';
import {withTranslation} from 'react-i18next';
import {TransliterationInput, TransliterationInputProps} from 'common/TransliterationInput';

const FormItem = Form.Item;

export const transliterationItemCommonProps: Partial<TransliterationInputProps> = {
  sourceLngCode: 'ru',
  resultLngCode: 'en',
  columns: [5, 9.5, 9.5],
  direction: 'row',
  labelPosition: 'left',
  justify: 'start',
  singleLabel: true,
  wrapperClasses: styles.transliterationInputGroup
};

interface StandardComponentProps {
  isEdit: any;
  setIsEdit: any;
  setVisible?: () => void;
  accreditation: Accreditation;
  publicLink: string;
  client: ApolloClient<any>;
  form: any;
  currentEvent: Events;
  setCurrentEvent: (currentEvent: Events) => void;
  additionalFields: AdditionalFields[];
  refreshCardData: () => void;
  remoteFile: RemoteFile;
  t?: any;
}

interface IState {
  visible: boolean;
  loading: boolean;
  accessCategory: ACC_AccessCategory;
  descriptor?: AccreditationDescriptor;
  accessCategories: ACC_AccessCategory[];
  requestStatus: AccreditationRequestStatus;
  accreditationType: ACC_RequestType;
  events: Events;
  currentEvent: Events;
  name?: LocalizedString;
  surname?: LocalizedString;
  patronymic?: LocalizedString;
  publicLink?: string;
  currentAdditinalFieldsData: AdditionalFields[] | undefined;
  contacts?: Contact[];
  photoFileId?: string;
}

class NormalLoginForm extends React.Component<StandardComponentProps & FormComponentProps, IState> {
  constructor(props: StandardComponentProps) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      accessCategory: this.props.accreditation.accessCategory,
      descriptor: undefined,
      requestStatus: this.props.accreditation.requestStatus,
      accreditationType: this.props.accreditation.requestType,
      events: { matches: [], tournaments: [], seasons: [] },
      currentEvent: this.props.currentEvent,
      name: this.props.accreditation.accreditedUser.user?.person.name,
      surname: this.props.accreditation.accreditedUser.user?.person.surname,
      patronymic: this.props.accreditation.accreditedUser.user?.person.patronymic,
      publicLink: this.props.accreditation.accreditedUser.user?.person.photo?.publicLink,
      currentAdditinalFieldsData: [],
      contacts: this.props.accreditation.accreditedUser.user?.person.contacts,
      photoFileId: this.props.accreditation.accreditedUser.user?.person.photo?.id,
      accessCategories: [],
    };
  }

  componentDidMount() {
    fetchData(
      this.state.loading,
      this.setLoading.bind(this),
      this.props.client.query({ query: GET_ACCREDITATION_REQUEST_DESCRIPTOR }),
      (res: any) => {
        this.setDescriptor(res);
        this.setEvents({
          matches: res.availableMatches,
          tournaments: res.availableTournaments,
          seasons: res.availableSeasons,
        });
        this.setCurrentAdditionalFieldsData();
      },
      (res) => {
        return deserialize(res, (d) =>
          d
            .optional('data.accreditation.accreditationRequestDescriptor.getActive')
            ?.asObject((o) => deserializeAccreditationRequestDescriptor(o))
        );
      }
    );
    fetchData(
      this.state.loading,
      this.setLoading.bind(this),
      this.props.client.query({ query: GET_ACCSESS_CATEGORIES }),
      (res: any) => {
        this.setAccessCategories(res);
      },
      (res) => {
        return deserialize(res, (d) =>
          d
            .optional('data.accreditation.accessCategory.getList.list')
            ?.asArrayOfObjects((o) => accessCategoryDeserializer(o))
        );
      }
    );
  }

  static propTypes = {
    isEdit: PropTypes.any,
    setIsEdit: PropTypes.any,
    setVisible: PropTypes.func,
    accreditation: PropTypes.any,
    client: PropTypes.any,
    accessCategory: PropTypes.any,
    accessCategoryList: PropTypes.array,
    accreditationTypeList: PropTypes.array,
    accreditationType: PropTypes.string,
    currentEvent: PropTypes.any,
    setCurrentEvent: PropTypes.any,
    additionalFields: PropTypes.any,
    refreshCardData: PropTypes.any,
    remoteFile: PropTypes.any,
  };
  static defaultProps = {
    isEdit: false,
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.buttonClickHandler();
      }
      if (err) {
        console.log('err', err);
      }
    });
  };

  setCurrentAdditionalFieldsData() {
    this.setState({
      ...this.state,
      currentAdditinalFieldsData: this.state.accessCategory.fields?.map((field) => ({
        value: this.props.additionalFields.find((item) => item.field.id === field.id)?.value || '',
        field,
      })),
    });
  }

  setName(value: LocalizedString) {
    this.setState({
      ...this.state,
      name: value,
    });
  }

  setSurname(value: LocalizedString) {
    this.setState({
      ...this.state,
      surname: value,
    });
  }

  setPatronymic(value: LocalizedString) {
    this.setState({
      ...this.state,
      patronymic: value,
    });
  }

  setPhotoFileId(value: string) {
    this.setState({
      ...this.state,
      photoFileId: value,
    });
  }

  setVisible(value: boolean) {
    this.setState({
      ...this.state,
      visible: value,
    });
  }

  setDescriptor(descriptor: AccreditationDescriptor) {
    this.setState({
      ...this.state,
      descriptor,
    });
  }

  setAccessCategories(accessCategories: ACC_AccessCategory[]) {
    this.setState({
      ...this.state,
      accessCategories,
    });
  }

  setLoading(value: boolean) {
    this.setState({
      ...this.state,
      loading: value,
    });
  }

  setAccessCategory(accessCategory: ACC_AccessCategory) {
    this.setState(
      {
        ...this.state,
        accessCategory,
      },
      () => this.setCurrentAdditionalFieldsData()
    );
  }
  setRequestStatus(requestStatus: AccreditationRequestStatus) {
    this.setState({
      ...this.state,
      requestStatus,
    });
  }
  setAccreditationType(accreditationType: ACC_RequestType) {
    this.setState({
      ...this.state,
      accreditationType,
    });
  }
  setEvents(events: Events) {
    this.setState({
      ...this.state,
      events,
    });
  }

  setCurrentEvent(currentEvent: Events) {
    this.setState({
      ...this.state,
      currentEvent,
    });
  }

  setContacts(value: string, type: ContactType) {
    this.setState({
      ...this.state,
      contacts:
        this.state.contacts &&
        this.state.contacts.map((contact) => {
          if (contact.type === type) {
            return { type, value };
          }
          return contact;
        }),
    });
  }

  setAdditionalFieldsData(id: string, value: any) {
    this.setState({
      ...this.state,
      currentAdditinalFieldsData: this.state.currentAdditinalFieldsData?.map((data) => {
        if (data.field.id === id && data.field.type === 'LOCALIZED_TEXT') {
          return { ...data, value: value ? value.toObject() : '' };
        }
        if (data.field.id === id && data.field.type === 'FILE') {
          return { ...data, value: value || '' };
        }
        return data;
      }),
    });
  }

  buttonClickHandler = async () => {
    const accreditationId: string = this.props.accreditation?.id;
    // const userId: any = this.props.accreditation?.accreditedUser.user?.id;
    let formData: ACC_AccreditationRequestUpdateInput = {
      name: this.state.name?.toObject(),
      surname: this.state.surname?.toObject(),
      patronymic: this.state.patronymic?.toObject(),
      email: this.state.contacts?.find((item) => {
        return item.type === 'EMAIL';
      })?.value,
      photoFileId: this.state.photoFileId,
      requestType: this.state.accreditationType,
      accessCategoryId: this.state.accessCategory.id ? this.state.accessCategory.id : undefined,
      additionalFields: this.state.currentAdditinalFieldsData?.map((field) =>
        field.field.type !== 'LOCALIZED_TEXT'
          ? {
              id: field.field.id,
              value: field.value,
            }
          : {
              id: field.field.id,
              value: JSON.stringify(field.value),
            }
      ),
      matchIds: this.state.currentEvent.matches.map((match) => match.id),
      seasonIds: this.state.currentEvent.seasons.map((season) => season.id),
      tournamentIds: this.state.currentEvent.tournaments.map((tournament) => tournament.id),
      stageIds: [],
    };

    await this.props.client.mutate({
      mutation: ACCREDITATION_UPDATE,
      variables: { id: accreditationId, data: formData },
    });

    if (this.state.requestStatus !== this.props.accreditation.requestStatus) {
      if (this.state.requestStatus === 'ACCEPTED') {
        await this.props.client.mutate({ mutation: ACCEPT_ACCREDITATION, variables: { id: accreditationId } });
      }
      if (this.state.requestStatus === 'REJECTED') {
        await this.props.client.mutate({
          mutation: REJECT_ACCREDITATION,
          variables: { id: accreditationId, data: { rejectionReasonId: '8bccacca3b0a4e6b8eedacfc0b0d44cf' } },
        });
      }
    }
    this.props.refreshCardData();
    this.props.setIsEdit(false);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <AccreditationHeader
            isEdit={this.props.isEdit}
            setIsEdit={this.props.setIsEdit}
            setVisible={this.props.setVisible}
            title={this.props.t('modules.edit_form.accreditation_header')}
            form={this.props.form}
          >
            <Button className={styles.btnEdit} type={'primary'} htmlType="submit">
              {this.props.t('modules.edit_form.button')}
            </Button>
          </AccreditationHeader>
          <div className={styles.loginForm}>
            <Row className={styles.item}>
              <Col className={styles.name} span={5}>
                {this.props.t('modules.edit_form.field.id')}
              </Col>
              <Col span={19}>{this.props.accreditation?.visibleId}</Col>
            </Row>
            <Row className={styles.item}>
              <Col className={styles.name} span={5}>
                {this.props.t('modules.edit_form.field.date')}
              </Col>
              <Col span={19}>{date(this.props.accreditation?.createdAt)}</Col>
            </Row>
            <FormItem className={styles.item}>
              <Row>
                <Col className={styles.name} span={5}>
                  {this.props.t('modules.edit_form.field.status')}
                </Col>
                <Col span={9}>
                  {getFieldDecorator('requestStatus', {
                    initialValue: this.state.requestStatus,
                    rules: [{required: true, message: this.props.t('modules.edit_form.get_field_decorator.status')}]
                  })(
                    <RequestStatusSelector
                      requestStatus={this.state.requestStatus}
                      setRequestStatus={this.setRequestStatus.bind(this)}
                    />
                  )}
                </Col>
              </Row>
            </FormItem>

            <Divider orientation="left" orientationMargin={0}>
              <Row>
                <Col className={styles.title} span={24}>
                  {this.props.t('modules.edit_form.field.personal')}
                </Col>
              </Row>
            </Divider>
            {/* Personal Data */}
            <Row className={styles.item + ' ' + styles.item_photoContainer}>
              <Col className={styles.name} span={5}>
                {this.props.t('modules.edit_form.field.photo')}
              </Col>
              <Col span={19}>
                {' '}
                <ImageUpload value={this.state.photoFileId} onChange={this.setPhotoFileId.bind(this)}/>
              </Col>
            </Row>
            <FormItem>
              {getFieldDecorator('name', {
                initialValue: this.state.name,
                rules: [{required: true, message: this.props.t('modules.edit_form.get_field_decorator.name')}]
              })(
                <TransliterationInput
                  onChange={this.setName.bind(this)}
                  value={this.state.name}
                  inputName={'name'}
                  labelTitle={this.props.t('modules.edit_form.localized_text_input.name')}
                  {...transliterationItemCommonProps}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('surname', {
                initialValue: this.state.surname,
                rules: [{required: true, message: this.props.t('modules.edit_form.get_field_decorator.surname')}]
              })(
                <TransliterationInput
                  onChange={this.setSurname.bind(this)}
                  value={this.state.surname}
                  inputName={'surname'}
                  labelTitle={this.props.t('modules.edit_form.localized_text_input.surname')}
                  {...transliterationItemCommonProps}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('patronymic', {
                rules: [{required: false}],
                initialValue: this.state.patronymic
              })(
                <TransliterationInput
                  onChange={this.setPatronymic.bind(this)}
                  value={this.state.patronymic}
                  inputName={'patronymic'}
                  labelTitle={this.props.t('modules.edit_form.localized_text_input.patronymic')}
                  {...transliterationItemCommonProps}
                />
              )}
            </FormItem>
            <>
              {this.state.loading ? (
                <Loader />
              ) : (
                <>
                  <Divider orientation="left" orientationMargin={0}>
                    <Row className={styles.item}>
                      <Col className={styles.title} span={24}>
                        {this.props.t('modules.edit_form.field.business')}
                      </Col>
                    </Row>
                  </Divider>
                  <FormItem>
                    <Row>
                      <Col className={styles.name} span={5}>
                        {this.props.t('modules.edit_form.field.acc_category')}
                      </Col>
                      <Col span={9}>
                        {getFieldDecorator('accessCategory', {
                          rules: [{required: true}]
                        })(
                          <AccessCategorySelector
                            accessCategories={this.state.accessCategories}
                            accessCategory={this.state.accessCategory}
                            setAccessCategory={this.setAccessCategory.bind(this)}
                          />
                        )}
                      </Col>
                    </Row>
                  </FormItem>
                  <FormItem>
                    <AdditionalFieldsComponent
                      form={this.props.form}
                      additionalFields={this.state.accessCategory.fields}
                      currentAdditinalFieldsData={this.state.currentAdditinalFieldsData}
                      initialAccessCategory={this.props.accreditation.accessCategory}
                      currentAccessCategory={this.state.accessCategory}
                      setAdditionalFieldsData={this.setAdditionalFieldsData.bind(this)}
                      remoteFile={this.props.remoteFile}
                    />
                  </FormItem>
                  <FormItem>
                    <AccreditationTypeSelector
                      requestTypes={this.state.descriptor?.requestTypes}
                      accreditationType={this.state.accreditationType}
                      setAccreditationType={this.setAccreditationType.bind(this)}
                      events={this.state.events}
                      currentEvent={this.state.currentEvent}
                      setCurrentEvent={this.setCurrentEvent.bind(this)}
                    />
                  </FormItem>
                  <Divider orientation="left" orientationMargin={0}>
                    <Row>
                      <Col className={styles.title} span={24}>
                        {this.props.t('modules.edit_form.field.contacts')}
                      </Col>
                    </Row>
                  </Divider>
                  <Contacts
                    contacts={this.state.contacts}
                    setContacts={this.setContacts.bind(this)}
                    getFieldDecorator={getFieldDecorator}
                  />
                </>
              )}
            </>
          </div>
        </Form>
      </>
    );
  }
}

const Extended = Form.create()(NormalLoginForm);

export default withTranslation()(Extended);
