import PromoAddModal from './components';
import withHandlers from './containers/withHandlers';
import withState from './containers/withState';

import { compose } from 'ramda';

export default compose(
  withState,
  withHandlers
)(PromoAddModal);
