import React, { useState, useEffect } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Button, message, DatePicker } from 'antd';
import { GET_SEASONS_INFO_LIST, ADD_SEASON, UPDATE_SEASON } from '../graphql';
import { withApollo } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';

const { RangePicker } = DatePicker;

const SeasonsComponent = (props) => {
  const [stateProps, setState] = useState({
    selectedSeason: {},
  });
  const { t } = useTranslation();

  const restoreState = () => {
    setState({
      selectedSeason: {},
    });
  };

  const getDay = (date) => {
    return date < 10 ? '0' + date : date;
  };
  const getMonth = (index) => {
    return index + 1 < 10 ? '0' + (index + 1) : index + 1;
  };
  const getDate = (timeValue) => {
    const date = new Date(timeValue);
    const day = date.getDate();
    return (day < 10 ? '0' + day : day) + '.' + getMonth(date.getMonth()) + '.' + date.getFullYear();
  };

  const addSeason = async () => {
    try {
      const startDate = new Date(stateProps.newRangeDate[0]);
      const endDate = new Date(stateProps.newRangeDate[1]);
      await props.client.query({
        query: ADD_SEASON,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            startDate:
              startDate.getFullYear() + '-' + getMonth(startDate.getMonth()) + '-' + getDay(startDate.getDate()),
            endDate: endDate.getFullYear() + '-' + getMonth(endDate.getMonth()) + '-' + getDay(endDate.getDate()),
          },
        },
      });

      message.success(t('modules.seasons_component.message_success.season_created'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.seasons_component.message_error.season_creation_error'));
    } finally {
      restoreState();
    }
  };

  const updateSeason = async () => {
    try {
      const startDate = new Date(stateProps.newRangeDate[0]);
      const endDate = new Date(stateProps.newRangeDate[1]);

      await props.client.query({
        query: UPDATE_SEASON,
        fetchPolicy: 'no-cache',
        variables: {
          id: stateProps.selectedSeason.id,
          data: {
            startDate:
              startDate.getFullYear() + '-' + getMonth(startDate.getMonth()) + '-' + getDay(startDate.getDate()),
            endDate: endDate.getFullYear() + '-' + getMonth(endDate.getMonth()) + '-' + getDay(endDate.getDate()),
          },
        },
      });
      message.success(t('modules.seasons_component.message_success.season_updated'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.seasons_component.message_error.season_update_error'));
    } finally {
      restoreState();
    }
  };
  useEffect(() => {
    const init = async () => {
      const seasonsRes = await props.client.query({
        query: GET_SEASONS_INFO_LIST,
        fetchPolicy: 'no-cache',
      });

      const seasonsList = pathOr([], ['data', 'season', 'getList', 'list'], seasonsRes);

      setState({
        ...stateProps,
        isLoaded: true,
        seasonsList,
      });
    };

    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <h1>{t('modules.seasons_component.h1')}</h1>

        <div className="events">
          {stateProps.isLoaded ? (
            <>
              <div className="events__left-container">
                {stateProps.isNewSeason && (
                  <div className="events__left-container__item-active">
                    <div className="events__left-container__item__text">
                      {t('modules.seasons_component.div.new_season')}
                    </div>
                  </div>
                )}
                {stateProps.seasonsList.map((season) => {
                  return (
                    <div
                      className={
                        stateProps.selectedSeason.id === season.id
                          ? 'events__left-container__item-active events__left-container__item'
                          : 'events__left-container__item'
                      }
                      onClick={() => {
                        setState({
                          ...stateProps,
                          selectedSeason: season,
                          isUpdated: true,
                          isNewSeason: false,
                        });
                      }}
                    >
                      <div className="events__left-container__item__text">
                        {t('modules.seasons_component.div.season')}{' '}
                        {new Date(season.startDate).getFullYear() + '-' + new Date(season.endDate).getFullYear()}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* //////// */}

              {(!isEmpty(stateProps.selectedSeason) || stateProps.isNewSeason) && !stateProps.isUpdated && (
                <div className="events__right-container">
                  <div className="events__right-container__body">
                    <CloseOutlined
                      className="permissions__right-container__body__close-form-icon"
                      onClick={() => {
                        restoreState();
                      }}
                    />
                    <div className="events__right-container__body__title-first">
                      {t('modules.seasons_component.div.validity')}
                    </div>
                    <RangePicker
                      className="events__right-container__body__range-picker"
                      placeholder={[
                        stateProps.isNewSeason
                          ? t('modules.seasons_component.range_picker.date_start')
                          : getDate(stateProps.selectedSeason.startDate),
                        stateProps.isNewSeason
                          ? t('modules.seasons_component.range_picker.date_end')
                          : getDate(stateProps.selectedSeason.endDate),
                      ]}
                      format={['DD.MM.YYYY', 'DD.MM.YYYY']}
                      separator="—"
                      onChange={(ev) => {
                        setState({
                          ...stateProps,
                          newRangeDate: ev,
                        });
                      }}
                    />
                  </div>

                  <div className="events__right-container__footer">
                    <Button
                      id="SeasonsComponentSaveButton"
                      type="primary"
                      className="permissions__right-container__footer__button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isUpdatingProcessing: true,
                        });
                        if (stateProps.isNewSeason) {
                          addSeason();
                        } else {
                          updateSeason();
                        }
                      }}
                    >
                      {stateProps.isUpdatingProcessing ? (
                        <LoadingOutlined />
                      ) : stateProps.isNewMatch ? (
                        t('modules.seasons_component.button.save_season')
                      ) : (
                        t('modules.seasons_component.button.save_changes')
                      )}
                    </Button>
                    {/* {!stateProps.isNewMatch && (
                  <Button
                    id="SeasonsComponentDeleteMatchButton"
                    type="primary"
                    className="events__right-container__footer__delete"
                    onClick={ev => {
                      setState({
                        ...stateProps,
                        isDeleteModalVisible: true
                      });
                    }}
                  >
                    {t('modules.seasons_component.button.delete_match')}
                  </Button>
                  )} */}
                  </div>
                </div>
              )}
              <Button
                id="SeasonsComponentAddSeasonButton"
                className="sales__price-template__add-template"
                onClick={() => {
                  setState({
                    ...stateProps,
                    isNewSeason: true,
                    isUpdated: true,
                    selectedSeason: {},
                    singleSelect: true,
                    singleTemplate: null,
                    onlineTemplate: null,
                    offlineTemplate: null,
                  });
                }}
              >
                <PlusOutlined className="sales__price-template__add-template__icon" />
                <span className="sales__price-template__add-template__text">{t('modules.seasons_component.span')}</span>
              </Button>
              <Modal
                visible={stateProps.isDeleteModalVisible}
                centered
                closable={false}
                onCancel={() => {
                  setState({
                    ...stateProps,
                    isDeleteModalVisible: false,
                  });
                }}
                width={415}
                footer={null}
              >
                <>
                  <div className="sales__tickets__list-table__accept-modal__text-container">
                    <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
                    <div className="sales__tickets__list-table__accept-modal__text">
                      {t('modules.seasons_component.div.are_you_sure')}
                    </div>
                  </div>
                  <div className="sales__tickets__list-table__accept-modal__button-container">
                    <Button
                      id="SeasonsComponentCancelButton"
                      className="sales__tickets__list-table__accept-modal__decline-button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDeleteModalVisible: false,
                        });
                      }}
                    >
                      {t('modules.seasons_component.button.cancel')}
                    </Button>
                    <Button
                      id="SeasonsComponentDeleteButton"
                      className="sales__tickets__list-table__accept-modal__accept-button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDeleteProcessing: true,
                        });
                        // deleteMatch();
                      }}
                    >
                      {stateProps.isDeleteProcessing ? (
                        <LoadingOutlined />
                      ) : (
                        t('modules.seasons_component.button.delete')
                      )}
                    </Button>
                  </div>
                </>
              </Modal>
            </>
          ) : (
            <div className="with-preloader__map">
              <LoadingOutlined />
              <div className="with-preloader__info"></div>
            </div>
          )}
        </div>
      </ConfigProvider>
    </>
  );
};

export default withApollo(SeasonsComponent);
