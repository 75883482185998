import React, { ReactNode } from 'react';
import styles from './InfoPopover.module.scss';
import { Popover } from 'antd';
import infoIcon from '../../img/Info.svg';

function InfoPopover(props: { content: string | ReactNode }) {


  const content = <div>{props.content}</div>;
  return (
    <Popover placement="rightTop" content={content} className={styles.InfoPopover}>
      <img className={styles.InfoPopover__icon} src={infoIcon} alt="Info icon" />
    </Popover>
  );
}

export default InfoPopover;
