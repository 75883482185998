import { compose, graphql } from 'react-apollo';
import {
  CREATE_PROMOCODE_DESCRIPTOR,
  ADD_PREFIX_PROMO,
  ADD_PROMO_CODES,
  UPDATE_PROMOCODE
} from '../graphql';

export default compose(
  graphql(CREATE_PROMOCODE_DESCRIPTOR, { name: 'createPromoDescriptor' }),
  graphql(ADD_PREFIX_PROMO, { name: 'addPrefixPromo' }),
  graphql(ADD_PROMO_CODES, { name: 'addPromoCodes' }),
  graphql(UPDATE_PROMOCODE, { name: 'updatePromocode' })
);
