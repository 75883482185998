import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TicketsComponent from './ticketsList/TicketsComponent';
import { withApollo } from 'react-apollo';
import { withProps } from 'recompose';
import PriceTemplatesComponent from './priceTemplate/PriceTemplatesComponent';
import { ConfigProvider } from 'antd';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';

const SalesComponent = (props) => {
  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <Switch>
          <Route
            key={0}
            path={'/admin/sales'}
            exact
            component={withApollo(
              withProps({
                authClient: props.authClient,
              })(TicketsComponent)
            )}
          />
          <Route key={1} path={'/admin/sales/price-templates'} component={withApollo(PriceTemplatesComponent)} />
        </Switch>
      </ConfigProvider>
    </>
  );
};

export default SalesComponent;
