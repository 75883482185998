import AccessControl from './AccessControl';
import AccessCategories from './components/AccessCategories/AccessCategories';
import AccessEntrances from './components/AccessEntrances/AccessEntrances';
import AccessZones from './components/AccessZones/AccessZones';

import { Action, IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { t } from 'i18next';

export class ModuleAccessControl implements Module {
  private component: any;

  constructor(private app: IApp, private routeContainer: IRouteContainer, private menuContainer: ISidebar) {
    this.component = {
      AccessControl,
      AccessCategories,
      AccessEntrances,
      AccessZones
    };

    this.app = app;

    this.routeContainer.addRoute({
      path: '/admin/access',
      component: this.component.AccessControl
    });
    this.routeContainer.addRoute({
      path: '/admin/access/categories',
      component: this.component.AccessCategories
    });
    this.routeContainer.addRoute({
      path: '/admin/access/entrances',
      component: this.component.AccessEntrances
    });
    this.routeContainer.addRoute({
      path: '/admin/access/zones',
      component: this.component.AccessZones
    });

    const categories = new RouteAction({
      title: t('modules.index_access_control.title.categories'),
      name: 'access-categories',
      order: 12.1,
      enabled: true,
      visible: true,
      route: 'admin/access/categories',
      localizationKey: 'access_categories'
    });
    const entrances = new RouteAction({
      title: t('modules.index_access_control.title.entrances'),
      name: 'access-entrances',
      order: 12.2,
      enabled: true,
      visible: true,
      route: 'admin/access/entrances',
      localizationKey: 'access_entrances'
    });
    const zones = new RouteAction({
      title: t('modules.index_access_control.title.zones'),
      name: 'access-zones',
      order: 12.3,
      enabled: true,
      visible: true,
      route: 'admin/access/zones',
      localizationKey: 'access_zones'
    });

    const accessLink = new Action({
      title: t('modules.index_access_control.title.acs'),
      icon: 'lock',
      name: 'access',
      order: 12,
      enabled: true,
      visible: true,
      subitems: [categories, entrances, zones],
      permission: 'ACCESS_ACS',
      localizationKey: 'access'
    });

    this.menuContainer.addLink(accessLink);
  }

  start() {
  }
}
