import i18next from 'i18next';

export type Pagination = {
  limit: number;
  page: number;
};

export type PeriodInput = {
  start: Date;
  end: Date;
};

export enum ContactType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type Contact = {
  type: ContactType;
  value: string;
};

export type NamedEntity = {
  id: string;
  title: string;
};
