import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruYaml from './assets/locales/ru/translation.yaml';
import enYaml from './assets/locales/en/translation.yaml';
import { merge } from 'lodash';
import shinnikYaml from './assets/custom_locales/shinnik/translation.yaml';
import chernomorochkaYaml from './assets/custom_locales/chernomorochka/translation.yaml';
import fanzillaYaml from './assets/custom_locales/fanzilla/translation.yaml';
import locoYaml from './assets/custom_locales/loco/translation.yaml';
import cskaMenYaml from "./assets/custom_locales/cska-m/translation.yaml";
import cskaWomenYaml from "./assets/custom_locales/cska-w/translation.yaml";
import { parametrizeEnv } from 'utils/helpers';

const currentClub = parametrizeEnv('REACT_APP_CLUB');

function defineClubLanguage(clubId?: string) {
  let clubData = {
    mergedClubLocale: {},
    fallbackLng: ''
  };

  // FIXME: Конфигурирование заголовков для конкретного клуба надо перенести в переменные среды и убрать из файлов
  //        локализации. В исходниках не должно быть упоминаний каких-то конкретных клубов.
  switch (clubId) {
    case 'fanzilla':
      clubData.mergedClubLocale = merge(fanzillaYaml, ruYaml, enYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'loco':
      clubData.mergedClubLocale = merge(locoYaml, ruYaml, enYaml);
      clubData.fallbackLng = 'en';
      break;
    case 'shinnik':
      clubData.mergedClubLocale = merge(shinnikYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'chernomorochka':
      clubData.mergedClubLocale = merge(chernomorochkaYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'cska-m':
      clubData.mergedClubLocale = merge(cskaMenYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'cska-w':
      clubData.mergedClubLocale = merge(cskaWomenYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    default:
      break;
  }

  return clubData;
}

export const clubLanguage = defineClubLanguage(currentClub);

function definePriorityLanguage() {
  return localStorage.getItem('lng') ?? clubLanguage.fallbackLng;
}

function languagesByPriority() {
  const priotityLng = definePriorityLanguage();
  const anotherLngs = Object.keys(clubLanguage.mergedClubLocale).filter((lng) => lng !== priotityLng);
  return [priotityLng, ...anotherLngs];
}

const currentLngs = languagesByPriority();

export function getSupportedLngs() {
  return currentLngs;
}

i18n.use(initReactI18next).init({
  supportedLngs: currentLngs,
  fallbackLng: clubLanguage.fallbackLng,
  lng: localStorage.getItem('lng') ?? 'ru',
  resources: clubLanguage.mergedClubLocale,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
