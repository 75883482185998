import { SideMenuStep } from '../utils/model';

const initialState = {
  selectedPlaces: [],
  mapPlaces: [],
  mapWeight: 0,
  mapSideMenuStep: SideMenuStep.DEFAULT,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TICKET':
      return {
        ...state,
        selectedPlaces: [...state.selectedPlaces, action.data]
      };
    case 'ADD_TICKET_ARRAY':
      return {
        ...state,
        selectedPlaces: [...state.selectedPlaces, ...action.data]
      };
    case 'REMOVE_TICKET':
      return {
        ...state,
        selectedPlaces: state.selectedPlaces.filter(item => {
          return item.id !== action.id;
        })
      };
    case 'REMOVE_PLACE':
      return {
        ...state,
        selectedPlaces: state.selectedPlaces.filter(item => {
          return item.options.id !== action.data;
        })
      };
    case 'SET_SELECTED_PLACES':
      return {
        ...state,
        selectedPlaces: action.data
      };
    case 'CLEAR_TICKETS':
      return {
        ...state,
        selectedPlaces: []
      };
    case 'SET_PLACES':
      return {
        ...state,
        mapPlaces: action.data
      };
    case 'SET_SIDE_MENU_STEP':
      return {
        ...state,
        mapSideMenuStep: action.data
      };
    case 'SET_MAP_WEIGHT':
      return {
        ...state,
        mapWeight: action.data
      };

    default:
      return state;
  }
};
