import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Button, Divider, Select, Empty, Tag } from 'antd';
import map from 'ramda/es/map';
import when from 'ramda/es/when';
import propEq from 'ramda/es/propEq';
import assoc from 'ramda/es/assoc';

import PromoAddModal from './PromoAddModal';
import pathOr from 'ramda/es/pathOr';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const PromoAddBounds = (props) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = props;
  const promoBounds = [
    t('modules.constants_app.local'),
    t('modules.constants_app.by_row'),
    t('modules.constants_app.by_sector'),
    t('modules.constants_app.by_tournament'),
    t('modules.constants_app.by_stage'),
    t('modules.constants_app.by_season'),
    t('modules.constants_app.by_price'),
    t('modules.constants_app.by_order_type'),
    t('modules.constants_app.by_user'),
    t('modules.constants_app.by_number_items_order'),
    t('modules.constants_app.by_matches_subscriptions'),
  ];
  const updateBound = (id, type, value) => {
    const fieldValue = map(when(propEq('id', id), assoc(type, value)), values.bounds);
    setFieldValue('bounds', fieldValue);
  };
  const bounds = pathOr([], ['bounds'], values);
  return (
    <div className="promo-add__bound-list">
      <Card title={t('modules.promo_add_bounds.card')}>
        {bounds.map((bound, index) => {
          const boundValues = pathOr(null, ['values'], bound) || pathOr([], ['value'], bound);
          let rangeTypeValue = '';
          const boundType = pathOr('', ['type'], bound);
          const boundTitle = pathOr('', ['title'], bound);
          const isRangeType =
            boundType === '6' ||
            boundType === '9' ||
            boundTitle.includes(t('modules.promo_add_bounds.is_range_type.price')) ||
            boundTitle.includes(t('modules.promo_add_bounds.is_range_type.items_order'));
          try {
            rangeTypeValue = `${t('modules.promo_add_bounds.range_type_value.from')} ${bound.values[0].title} ${t(
              'modules.promo_add_bounds.range_type_value.to'
            )} ${bound.values[1].title}`;
          } catch (err) {
            rangeTypeValue = bound.value;
          }

          return (
            <div className="promo-add__bound-item" key={index}>
              <Button
                id="PromoAddBoundsDeleteOutlinedButton"
                className="promo-add__bound-remove"
                shape="circle"
                type="link"
                onClick={() =>
                  props.setFieldValue(
                    'bounds',
                    values.bounds.filter((item) => item.id !== bound.id)
                  )
                }
              >
                <DeleteOutlined />
              </Button>
              <Row>
                <Col span={8}>
                  <Form.Item label={t('modules.promo_add_bounds.form_item.bound_type')}>
                    <Select
                      defaultValue="0"
                      value={bound.type || bound.title}
                      onChange={(e) => updateBound(bound.id, 'type', e)}
                    >
                      {promoBounds.map((type, index) => (
                        <Option key={index}>{type}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('modules.promo_add_bounds.form_item.condition')}>
                    <Select
                      defaultValue="0"
                      disabled
                      value={bound.condition}
                      onChange={(e) => updateBound(bound.id, 'condition', e)}
                    >
                      <Option key="0">{t('modules.promo_add_bounds.option.including')}</Option>
                      <Option key="1">{t('modules.promo_add_bounds.option.everything_except')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <Form.Item label={t('modules.promo_add_bounds.form_item.positions')}>
                    <div className="promo-add__bound-positions">
                      {isRangeType && boundValues.length ? (
                        <Tag>{rangeTypeValue}</Tag>
                      ) : bound.values ? (
                        bound.values.map((item) => {
                          return <Tag key={item.id}>{item.title}</Tag>;
                        })
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    id="PromoAddBoundsChooseButton"
                    type="default"
                    className="btn__select"
                    onClick={() => {
                      bound.condition = '0';
                      props.handleBoundPositionsSelect(bound);
                    }}
                  >
                    {t('modules.promo_add_bounds.button.choose')}
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}

        {bounds.length === 0 && <Empty description={t('modules.promo_add_bounds.empty')} />}

        <Divider />
        <Button id="PromoAddBoundsAddConstraintButton" size="large" type="link" onClick={props.handleAddNewBound}>
          <PlusOutlined />
          {t('modules.promo_add_bounds.button.add_bound')}
        </Button>
      </Card>
      <PromoAddModal
        boundItem={props.values.currentBound}
        visible={props.values.isModalVisible}
        onClose={props.handleModalClose}
        setFieldValue={setFieldValue}
        values={values}
      />
    </div>
  );
};

export default PromoAddBounds;
