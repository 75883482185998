import React, { useEffect, useState } from 'react';
import styles from './AccreditationModal.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { withApollo } from 'react-apollo';
import { Modal } from 'antd';
import { GET_ACCREDITATION_DATA_BY_ID, GET_FILE } from '../model/accreditationQueries';
import { fetchData, useDidMount, deserialize } from '../../../../utils/apiHelpers';
import { enumFromString } from '../../../../utils/typeHelpers';
import {
  AccreditationRequestStatus,
  ACC_RequestType,
  Accreditation,
  AdditionalFields,
  Events,
  RemoteFile,
} from '../model/accreditationModel';
import {
  additionalFieldsDeserializer,
  accessCategoryDeserializer,
  userDeserializer,
  tournamentsDeserializer,
  seasonsDeserializer,
  matchesDeserializer,
  fileDeserializer,
} from '../model/accreditationDeserializers';
import Loader from './components/Loader/Loader';
import EditForm from '../AccreditationModal/components/EditForm/EditForm';
import Card from '../AccreditationModal/components/Card/Card';

function AccreditationModal(props: { client: ApolloClient<any> }) {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [visible, setVisible] = useState(true);
  const [accreditation, setAccreditation] = useState<Accreditation>();
  const [additionalFields, setAdditionalFields] = useState<AdditionalFields[]>();
  const [currentEvent, setCurrentEvent] = useState<Events>({ seasons: [], tournaments: [], matches: [] });
  const [remoteFile, setRemoteFile] = useState<RemoteFile>();
  const [uploadFileId, setUploadFileId] = useState<String>('');

  useEffect(() => {
    uploadFileId &&
      fetchData(
        loadingFile,
        setLoadingFile,
        props.client.query({
          query: GET_FILE,
          variables: { id: uploadFileId },
          fetchPolicy: 'no-cache',
        }),
        (res: any) => {
          setRemoteFile(res.remoteFile);
        },
        (res: any) => {
          return deserialize(res, (d) =>
            d.required('data.file').asObject((o) => ({
              remoteFile: o.required('getFileDescriptor').asObject((o) => fileDeserializer(o)),
            }))
          );
        }
      );
  }, [uploadFileId]);

  useDidMount(() => {
    refreshCardData();
  });

  let history = useHistory();
  const { id } = useParams<{ id: string }>();

  async function refreshCardData() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({ query: GET_ACCREDITATION_DATA_BY_ID, variables: { id }, fetchPolicy: 'no-cache' }),
      (res: any) => {
        setAccreditation({
          id: res.id,
          visibleId: res.visibleId,
          accreditedUser: res.accreditedUser,
          requestStatus: res.requestStatus,
          accessCategory: res.accessCategory,
          createdAt: res.createdAt,
          requestType: res.requestType,
          seasons: res.seasons,
          tournaments: res.tournaments,
          matches: res.matches,
        });
        setAdditionalFields(res.additionalFields);
        setCurrentEvent({ seasons: res.seasons, tournaments: res.tournaments, matches: res.matches });
        setUploadFileId(res.additionalFields.find((field: any) => field.field.type === 'FILE')?.value);
      },
      (res) => {
        return deserialize(res, (d) =>
          d.required('data.accreditation.accreditation.getById').asObject((o) => ({
            id: o.required('id').asString,
            visibleId: o.required('visibleId').asString,
            accreditedUser: o.required('accreditedUser').asObject((u) => userDeserializer(u)),
            requestStatus: enumFromString(AccreditationRequestStatus, o.required('requestStatus').asString),
            accessCategory: o.required('accessCategory').asObject((c) => accessCategoryDeserializer(c)),
            requestType: enumFromString(ACC_RequestType, o.required('requestType').asString),
            createdAt: o.required('createdAt').asDate,

            additionalFields: o.optional('additionalFields')?.asArrayOfObjects((o) => {
              return additionalFieldsDeserializer(o);
            }),
            tournaments: o.optional('tournaments')?.asArrayOfObjects((o) => {
              return tournamentsDeserializer(o);
            }),
            seasons: o.optional('seasons')?.asArrayOfObjects((o) => {
              return seasonsDeserializer(o);
            }),
            matches: o.optional('matches')?.asArrayOfObjects((o) => {
              return matchesDeserializer(o);
            }),
          }))
        );
      }
    );
  }

  return (
    <>
      <Modal
        className={styles.modal}
        centered={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: '0 83px 100px', height: '100%', backgroundColor: '#ebedee' }}
        closable={false}
        destroyOnClose={true}
        afterClose={() => history.goBack()}
        footer={null}
      >
        {loading ? (
          <Loader />
        ) : isEdit ? (
          <EditForm
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setVisible={setVisible}
            accreditation={accreditation}
            client={props.client}
            currentEvent={currentEvent}
            additionalFields={additionalFields}
            refreshCardData={refreshCardData}
            remoteFile={remoteFile}
          />
        ) : (
          <Card
            accreditation={accreditation}
            additionalFields={additionalFields}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setVisible={setVisible}
            currentEvent={currentEvent}
            client={props.client}
            remoteFile={remoteFile}
          />
        )}
      </Modal>
    </>
  );
}

export default withApollo(AccreditationModal);
