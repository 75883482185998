import gql from 'graphql-tag';

export const GET_CATEGORYS_LIST = gql`
  query accessCategoriesList {
    acs {
      sync {
        getSourcesList(filter: { types: [ACCESS_OBJECT] }) {
          id
          title
        }
      }
    }
  }
`;

export const GET_CATEGORYS_DATA = gql`
  query accessCategories($syncId: ID!) {
    acs {
      accessCategory {
        findAllBySyncId(syncId: $syncId) {
          id
          syncSourceId
          title
          description
          zones {
            id
            title
          }
          entrances {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_ENTRANCES_DATA = gql`
  query accessEntrances {
    acs {
      entrance {
        getList {
          id
          name
          title
          description
          deletedDate
          venue {
            id
            title
          }
          zonesIn {
            id
            title
          }
          zonesOut {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_ZONES_LIST = gql`
  query accessZonesList {
    acs {
      accessZone {
        getList {
          id
          name
          title
          description
          deletedDate
          venue {
            id
            title
          }
          syncSourceId
          controlPresence
        }
      }
    }
  }
`;

export const GET_VENUES_LIST = gql`
  query venues {
    venueLocalised {
      getList {
        list {
          title
          id
        }
      }
    }
  }
`;
