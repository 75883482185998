import {
  Coupon,
  PromocodeType,
  Period,
  Discount,
  DiscountType,
  CodesPaginatedResult,
  CouponCode,
  PromocodeDescriptor,
  UserData,
  PersonData,
  TournamentBound,
  Season,
  Tournaments,
  StageBound,
  Stages,
  SectorBound,
  PriceBound,
  OrderItemTypeBound,
  ItemType,
  OrderItemConnection,
  CouponsUsersList,
  UsersListPromocode,
  UserListPromocodeDescriptor,
} from './discountsModel';
import { ObjectDeserializator } from 'utils/apiHelpers';
import { enumFromString } from 'utils/typeHelpers';
import { NamedEntity } from 'utils/commonTypes';
import { LocalizedString } from 'utils/LocalizedString';

export const periodDeserializer = (p: ObjectDeserializator): Period => {
  return {
    start: p.optional('start')?.asDate,
    end: p.optional('end')?.asDate,
  };
};

export const seasonDeserializer = (s: ObjectDeserializator): Season => {
  return {
    id: s.required('id').asString,
    startDate: s.required('startDate').asDate,
    endDate: s.required('endDate').asDate,
  };
};
export const tournamentsDeserializer = (t: ObjectDeserializator): Tournaments => {
  return {
    title: t.required('title').asString,
  };
};
export const tournamentBoundDeserializer = (b: ObjectDeserializator): TournamentBound => {
  return {
    season: b.required('season').asObject((s) => seasonDeserializer(s)),
    tournaments: b.required('tournaments').asArrayOfObjects((t) => tournamentsDeserializer(t)),
  };
};

export const stagesDeserializer = (s: ObjectDeserializator): Stages => {
  return {
    title: s.required('title').asString,
  };
};
export const stageBoundDeserializer = (b: ObjectDeserializator): StageBound => {
  return {
    season: b.required('season').asObject((s) => seasonDeserializer(s)),
    stages: b.required('stages').asArrayOfObjects((s) => stagesDeserializer(s)),
  };
};

export const sectorBoundDeserializer = (b: ObjectDeserializator): SectorBound => {
  return {
    sectorIds: b.required('sectorIds').asArray((s) => s),
  };
};

export const priceBoundDeserializer = (b: ObjectDeserializator): PriceBound => {
  return {
    start: b.optional('start')?.asString,
    end: b.optional('end')?.asString,
  };
};

export const itemsBoundDeserializer = (i: ObjectDeserializator): NamedEntity => {
  return {
    id: i.required('id').asString,
    title: i.required('title').asString,
  };
};

export const orderItemTypeBoundDeserializer = (t: ObjectDeserializator): OrderItemTypeBound => {
  return {
    types: t.required('types').asArray((o) => enumFromString(ItemType, o)),
  };
};

export const orderItemBoundDeserializer = (i: ObjectDeserializator): OrderItemConnection => {
  return {
    type: i.required('type')?.as((t) => enumFromString(ItemType, t)),
    descriptorIds: i.required('descriptorIds').asArray((o) => o),
    items: i.required('items').asArrayOfObjects((i) => itemsBoundDeserializer(i)),
  };
};

export const discountDeserializer = (d: ObjectDeserializator): Discount => {
  return {
    type: d.required('type').as((o) => enumFromString(DiscountType, o)),
    value: d.required('value').asString,
  };
};

export const promocodeDescriptorDeserializer = (u: ObjectDeserializator): PromocodeDescriptor => {
  return {
    maxUseCountsPerUser: u.optional('maxUseCountsPerUser')?.asNumber,
  };
};
export const personDataDeserializer = (o: ObjectDeserializator): PersonData => {
  const contacts = o.required('contacts').asArrayOfObjects((c) => ({
    value: c.required('value').asString,
    type: c.required('type').asString,
  }));

  return {
    name: o.required('name').as((o) => LocalizedString.fromObject(o)),
    surname: o.required('surname').as((o) => LocalizedString.fromObject(o)),
    patronymic: o.optional('patronymic')?.as((o) => LocalizedString.fromObject(o)),
    phone: contacts.find((contact) => contact.type === 'PHONE')?.value,
    email: contacts.find((contact) => contact.type === 'EMAIL')?.value,
  };
};
export const couponUserDeserializer = (u: ObjectDeserializator): UserData => {
  return {
    id: u.required('id').asString,
    login: u.required('login').asString,
    person: u.required('person').asObject((p) => personDataDeserializer(p)),
  };
};
export const codesListDeserializer = (o: ObjectDeserializator): CouponCode => {
  return {
    value: o.required('value').asString,
    useCounter: o.required('useCounter').asNumber,
    descriptor: o.required('descriptor').asObject((d) => promocodeDescriptorDeserializer(d)),
    couponUser: o.optional('couponUser')?.asObject((u) => couponUserDeserializer(u)),
  };
};
export const codesDeserializer = (c: ObjectDeserializator): CodesPaginatedResult => {
  return {
    total: c.required('total').asNumber,
    list: c.required('list').asArrayOfObjects((o) => codesListDeserializer(o)),
  };
};

export const couponsDeserializer = (o: ObjectDeserializator): Coupon => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    type: o.required('type').as((o) => enumFromString(PromocodeType, o)),
    period: o.required('period').asObject((p) => periodDeserializer(p)),
    maxUseCountsPerUser: o.optional('maxUseCountsPerUser')?.asNumber,
    tournamentBound: o.optional('tournamentBound')?.asObject((b) => tournamentBoundDeserializer(b)),
    stageBound: o.optional('stageBound')?.asObject((b) => stageBoundDeserializer(b)),
    seasonBound: o.optional('seasonBound.seasons')?.asArrayOfObjects((b) => seasonDeserializer(b)),
    sectorBound: o.optional('sectorBound')?.asObject((b) => sectorBoundDeserializer(b)),
    priceBound: o.optional('priceBound.priceRange')?.asObject((b) => priceBoundDeserializer(b)),
    orderItemTypeBound: o.optional('orderItemTypeBound')?.asObject((t) => orderItemTypeBoundDeserializer(t)),
    orderItemBound: o.optional('orderItemBound.items')?.asArrayOfObjects((i) => orderItemBoundDeserializer(i)),
    discount: o.required('discount').asObject((d) => discountDeserializer(d)),
    codes: o.required('codes').asObject((c) => codesDeserializer(c)),
  };
};

export const userListPromocodeDescriptorDeserializer = (o: ObjectDeserializator): UserListPromocodeDescriptor => {
  return {
    maxUseCountsPerUser: o.optional('maxUseCountsPerUser')?.asNumber,
  };
};
export const usersListPromocodeDeserializer = (o: ObjectDeserializator): UsersListPromocode => {
  return {
    value: o.required('value').asString,
    useCounter: o.required('useCounter').asNumber,
    descriptor: o.required('descriptor').asObject((d) => userListPromocodeDescriptorDeserializer(d)),
    couponUserId: o.optional('couponUserId')?.asString,
    couponUser: o.optional('couponUser')?.asObject((u) => couponUserDeserializer(u)),
  };
};
