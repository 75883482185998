import af from 'dayjs/locale/af';
import am from 'dayjs/locale/am';
import ar_DZ from 'dayjs/locale/ar-dz';
import ar_IQ from 'dayjs/locale/ar-iq';
import ar_KW from 'dayjs/locale/ar-kw';
import ar_LY from 'dayjs/locale/ar-ly';
import ar_MA from 'dayjs/locale/ar-ma';
import ar_SA from 'dayjs/locale/ar-sa';
import ar_TN from 'dayjs/locale/ar-tn';
import ar from 'dayjs/locale/ar';
import az from 'dayjs/locale/az';
import be from 'dayjs/locale/be';
import bg from 'dayjs/locale/bg';
import bi from 'dayjs/locale/bi';
import bm from 'dayjs/locale/bm';
import bn_BD from 'dayjs/locale/bn-bd';
import bn from 'dayjs/locale/bn';
import bo from 'dayjs/locale/bo';
import br from 'dayjs/locale/br';
import bs from 'dayjs/locale/bs';
import ca from 'dayjs/locale/ca';
import cs from 'dayjs/locale/cs';
import cv from 'dayjs/locale/cv';
import cy from 'dayjs/locale/cy';
import da from 'dayjs/locale/da';
import de_AT from 'dayjs/locale/de-at';
import de_CH from 'dayjs/locale/de-ch';
import de from 'dayjs/locale/de';
import dv from 'dayjs/locale/dv';
import el from 'dayjs/locale/el';
import en_AU from 'dayjs/locale/en-au';
import en_CA from 'dayjs/locale/en-ca';
import en_GB from 'dayjs/locale/en-gb';
import en_IE from 'dayjs/locale/en-ie';
import en_IL from 'dayjs/locale/en-il';
import en_IN from 'dayjs/locale/en-in';
import en_NZ from 'dayjs/locale/en-nz';
import en_SG from 'dayjs/locale/en-sg';
import en_TT from 'dayjs/locale/en-tt';
import en from 'dayjs/locale/en';
import eo from 'dayjs/locale/eo';
import es_DO from 'dayjs/locale/es-do';
import es_MX from 'dayjs/locale/es-mx';
import es_PR from 'dayjs/locale/es-pr';
import es_US from 'dayjs/locale/es-us';
import es from 'dayjs/locale/es';
import et from 'dayjs/locale/et';
import eu from 'dayjs/locale/eu';
import fa from 'dayjs/locale/fa';
import fi from 'dayjs/locale/fi';
import fo from 'dayjs/locale/fo';
import fr_CA from 'dayjs/locale/fr-ca';
import fr_CH from 'dayjs/locale/fr-ch';
import fr from 'dayjs/locale/fr';
import fy from 'dayjs/locale/fy';
import ga from 'dayjs/locale/ga';
import gd from 'dayjs/locale/gd';
import gl from 'dayjs/locale/gl';
import gom_LATN from 'dayjs/locale/gom-latn';
import gu from 'dayjs/locale/gu';
import he from 'dayjs/locale/he';
import hi from 'dayjs/locale/hi';
import hr from 'dayjs/locale/hr';
import ht from 'dayjs/locale/ht';
import hu from 'dayjs/locale/hu';
import hy_AM from 'dayjs/locale/hy-am';
import id from 'dayjs/locale/id';
import is from 'dayjs/locale/is';
import it_CH from 'dayjs/locale/it-ch';
import it from 'dayjs/locale/it';
import ja from 'dayjs/locale/ja';
import jv from 'dayjs/locale/jv';
import ka from 'dayjs/locale/ka';
import kk from 'dayjs/locale/kk';
import km from 'dayjs/locale/km';
import kn from 'dayjs/locale/kn';
import ko from 'dayjs/locale/ko';
import ku from 'dayjs/locale/ku';
import ky from 'dayjs/locale/ky';
import lb from 'dayjs/locale/lb';
import lo from 'dayjs/locale/lo';
import lt from 'dayjs/locale/lt';
import lv from 'dayjs/locale/lv';
import me from 'dayjs/locale/me';
import mi from 'dayjs/locale/mi';
import mk from 'dayjs/locale/mk';
import ml from 'dayjs/locale/ml';
import mn from 'dayjs/locale/mn';
import mr from 'dayjs/locale/mr';
import ms_MY from 'dayjs/locale/ms-my';
import ms from 'dayjs/locale/ms';
import mt from 'dayjs/locale/mt';
import my from 'dayjs/locale/my';
import nb from 'dayjs/locale/nb';
import ne from 'dayjs/locale/ne';
import nl_BE from 'dayjs/locale/nl-be';
import nl from 'dayjs/locale/nl';
import nn from 'dayjs/locale/nn';
import oc_LNC from 'dayjs/locale/oc-lnc';
import pa_IN from 'dayjs/locale/pa-in';
import pl from 'dayjs/locale/pl';
import pt_BR from 'dayjs/locale/pt-br';
import pt from 'dayjs/locale/pt';
import rn from 'dayjs/locale/rn';
import ro from 'dayjs/locale/ro';
import ru from 'dayjs/locale/ru';
import rw from 'dayjs/locale/rw';
import sd from 'dayjs/locale/sd';
import se from 'dayjs/locale/se';
import si from 'dayjs/locale/si';
import sk from 'dayjs/locale/sk';
import sl from 'dayjs/locale/sl';
import sq from 'dayjs/locale/sq';
import sr_CYRL from 'dayjs/locale/sr-cyrl';
import sr from 'dayjs/locale/sr';
import ss from 'dayjs/locale/ss';
import sv_FI from 'dayjs/locale/sv-fi';
import sv from 'dayjs/locale/sv';
import sw from 'dayjs/locale/sw';
import ta from 'dayjs/locale/ta';
import te from 'dayjs/locale/te';
import tet from 'dayjs/locale/tet';
import tg from 'dayjs/locale/tg';
import th from 'dayjs/locale/th';
import tk from 'dayjs/locale/tk';
import tl_PH from 'dayjs/locale/tl-ph';
import tlh from 'dayjs/locale/tlh';
import tr from 'dayjs/locale/tr';
import tzl from 'dayjs/locale/tzl';
import tzm_LATN from 'dayjs/locale/tzm-latn';
import tzm from 'dayjs/locale/tzm';
import ug_CN from 'dayjs/locale/ug-cn';
import uk from 'dayjs/locale/uk';
import ur from 'dayjs/locale/ur';
import uz_LATN from 'dayjs/locale/uz-latn';
import uz from 'dayjs/locale/uz';
import vi from 'dayjs/locale/vi';
import x_PSEUDO from 'dayjs/locale/x-pseudo';
import yo from 'dayjs/locale/yo';
import zh_CN from 'dayjs/locale/zh-cn';
import zh_HK from 'dayjs/locale/zh-hk';
import zh_TW from 'dayjs/locale/zh-tw';
import zh from 'dayjs/locale/zh';
import i18n from 'i18n';
import dayjs from 'dayjs';

const listDayjsLanguages: any = {
  af,
  am,
  ar_DZ,
  ar_IQ,
  ar_KW,
  ar_LY,
  ar_MA,
  ar_SA,
  ar_TN,
  ar,
  az,
  be,
  bg,
  bi,
  bm,
  bn_BD,
  bn,
  bo,
  br,
  bs,
  ca,
  cs,
  cv,
  cy,
  da,
  de_AT,
  de_CH,
  de,
  dv,
  el,
  en_AU,
  en_CA,
  en_GB,
  en_IE,
  en_IL,
  en_IN,
  en_NZ,
  en_SG,
  en_TT,
  en,
  eo,
  es_DO,
  es_MX,
  es_PR,
  es_US,
  es,
  et,
  eu,
  fa,
  fi,
  fo,
  fr_CA,
  fr_CH,
  fr,
  fy,
  ga,
  gd,
  gl,
  gom_LATN,
  gu,
  he,
  hi,
  hr,
  ht,
  hu,
  hy_AM,
  id,
  is,
  it_CH,
  it,
  ja,
  jv,
  ka,
  kk,
  km,
  kn,
  ko,
  ku,
  ky,
  lb,
  lo,
  lt,
  lv,
  me,
  mi,
  mk,
  ml,
  mn,
  mr,
  ms_MY,
  ms,
  mt,
  my,
  nb,
  ne,
  nl_BE,
  nl,
  nn,
  oc_LNC,
  pa_IN,
  pl,
  pt_BR,
  pt,
  rn,
  ro,
  ru,
  rw,
  sd,
  se,
  si,
  sk,
  sl,
  sq,
  sr_CYRL,
  sr,
  ss,
  sv_FI,
  sv,
  sw,
  ta,
  te,
  tet,
  tg,
  th,
  tk,
  tl_PH,
  tlh,
  tr,
  tzl,
  tzm_LATN,
  tzm,
  ug_CN,
  uk,
  ur,
  uz_LATN,
  uz,
  vi,
  x_PSEUDO,
  yo,
  zh_CN,
  zh_HK,
  zh_TW,
  zh,
};

export const determiningCurrentDayjsLocale = () => {
  for (let key in listDayjsLanguages) {
    if (key === i18n.language) {
      dayjs.locale(listDayjsLanguages[key]);
      break;
    } else {
      dayjs.locale(en);
    }
  }
};
