import React, { Component } from 'react';
import "./style.scss"

export default class ModalContent extends Component {
    
    render() {
        return (
            <div className="ac-modal__content">{this.props.children}</div>
        );
    }
}