import * as types from '../types';

const initialState = {
  menuList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case (types.ADD_MENU_ITEM):
      return ({
        ...state,
        menuList: [...state.menuList, action.menuItem]
      });
    case (types.UPDATE_MENU_ITEM):
      return ({
        ...state,
        menuList: [...state.menuList.filter(item => item.id !== action.id), {...action.data, id: action.id}]
      });
    case (types.REMOVE_MENU_ITEM):
      return ({
        ...state,
        menuList: [...state.menuList.filter(item => item.id !== action.id)]
      });
    default:
      return state;
  }
}