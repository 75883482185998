import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  () => ({
    isModalVisible: false
  }),
  {
    setIsModalVisible: () => bool => ({
      isModalVisible: bool
    })
  }
);
