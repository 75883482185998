import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import StadiumMarkupComponent from './StadiumMarkup/StadiumMarkupComponent';
import StadiumHistoryComponent from './StadiumHistory/StadiumHistoryComponent';
import { ConfigProvider } from 'antd';
import { determiningCurrentLocale } from 'utils/antdLocalizationHelpers';

const Stadium = () => {
  return (
    <>
      <ConfigProvider locale={determiningCurrentLocale()}>
        <Switch>
          <Route key={0} path={'/admin/stadium/markup'} component={withApollo(StadiumMarkupComponent)} />
          <Route key={1} path={'/admin/stadium/history'} component={withApollo(StadiumHistoryComponent)} />
        </Switch>
      </ConfigProvider>
    </>
  );
};

export default Stadium;
