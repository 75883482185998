import gql from 'graphql-tag';

export const GET_SEGMENTS_LIST = gql`
  query getSegments($pagination: Pagination, $userPagination: Pagination) {
    segments {
      getList(pagination: $pagination) {
        list {
          id
          title
          comment
          userIds
          users(pagination: $userPagination) {
            total
          }
          segmentType
          ...on SEG_DynamicSegment {
            sql
          }
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const GET_SEGMENTS_USERS = gql`
  query getSegmentsUsers($filter: AuthUserFilterInput, $paging: AuthPaginationInput) {
    users {
      getListAdvanced(filter: $filter, paging: $paging) {
        list {
          id
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_LOGIN = gql`
  query getUserByLogin($login: String!) {
    users {
      findByLogin(login: $login) {
        id
        person {
          name
          surname
          patronymic
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_SQL_REQUEST = gql `
  query getUserIdsBySqlRequest($sql: String!, $pagination: Pagination) {
    segments {
      previewDynamicSegmentUsers(sql: $sql, pagination: $pagination) {
        list {
          id
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
        }
      }
    }
  }
`;
