import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cross from '../../common/icons/Cross';
import './style.scss';

export default class ModalHeader extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  handleCloseClick = () => this.props.onClose();

  render() {
    const { title } = this.props;

    return (
      <div
        className={classNames('ac-modal__header', {
          'ac-modal__header--with-title': title
        })}
      >
        {title ? (
          <div className="ac-modal__header-title">{title}</div>
        ) : (
          this.props.children
        )}
        <div onClick={this.handleCloseClick} className="ac-modal__close">
          <Cross
            onClick={this.resetFilterValue}
            className="ac-modal__close-img"
            size="16"
          />
        </div>
      </div>
    );
  }
}
