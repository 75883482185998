import gql from 'graphql-tag';

export const GET_MATCHES = gql`
  query {
    matchLocalised {
      getList(filter: { hidden: null }) {
        list {
          id
          description
          team1 {
            title
            id
          }
          hidden
          team2 {
            title
            logo {
              publicLink
            }
            id
          }
          cover {
            publicLink
          }
          status
          startTime
          venue {
            id
          }
          stage {
            id
            tournament {
              id
            }
          }
          visibilityPlaces
        }
      }
    }
  }
`;
export const GET_TOURNAMENTS = gql`
  query {
    tournamentLocalised {
      getList {
        list {
          title
          id
        }
      }
    }
  }
`;
export const GET_STAGES = gql`
  query {
    stageLocalised {
      getList {
        list {
          id
          title
          tournament {
            id
          }
        }
      }
    }
  }
`;
export const GET_TEAMS = gql`
  query {
    teamLocalised {
      getList {
        list {
          title
          id
        }
      }
    }
  }
`;
export const GET_STADIUMS = gql`
  query {
    venueLocalised {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;
export const GET_TEMPLATES = gql`
  query {
    priceTemplate {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;
export const GET_MATCH_TEMPLATE = gql`
  query($filter: PriceTemplateFilter!) {
    priceTemplate {
      getList(filter: $filter) {
        list {
          id
          title
        }
      }
    }
  }
`;
export const GET_TEAMS_INFO_LIST = gql`
  query {
    teamLocalised {
      getList {
        list {
          id
          title
          location
          logo {
            id
            publicLink
          }
        }
      }
    }
  }
`;
export const GET_SEASONS_INFO_LIST = gql`
  query {
    season {
      getList {
        list {
          id
          startDate
          endDate
        }
      }
    }
  }
`;
export const GET_FILE_DESCRIPTOR = gql`
  query getPublicLink($id: String!) {
    file {
      getFileDescriptor(id: $id) {
        publicLink,
        useCount
      }
    }
  }
`;
export const GET_TOURNAMENTS_INFO_LIST = gql`
  query {
    tournamentLocalised {
      getList {
        list {
          id
          title
          description
          logo {
            id
            publicLink
          }
          stages {
            id
            title
          }
        }
      }
    }
  }
`;
export const GET_SEASON_TICKET_INFO_LIST = gql`
  query {
    seasonTicketDescriptor {
      getList(filter: { hidden: null }) {
        list {
          status
          hidden
          title
          id
        }
      }
    }
  }
`;
export const GET_SEASON_TICKET_INFO = gql`
  query($id: ID!) {
    seasonTicketDescriptor {
      getById(id: $id) {
        id
        status
        hidden
        title
        description
        gracePeriod {
          start
          end
        }
        parentDescriptors {
          id
          title
        }
        priceTemplate {
          id
          title
        }
        venue {
          id
          title
        }
        seasons {
          id
          startDate
          endDate
        }
        stages {
          title
          id
          tournament {
            id
          }
        }
        matches {
          id
          team1 {
            title
          }
          team2 {
            title
            logo {
              publicLink
            }
          }
          status
          startTime
          venue {
            id
          }
        }
        tournaments {
          title
          id
        }
      }
    }
  }
`;
export const GET_ACTIVE_MATCHES = gql`
  query {
    matchLocalised {
      getList(filter: { statuses: [ACTIVE] }) {
        list {
          id
          title
          team1 {
            title
          }
          team2 {
            title
            logo {
              publicLink
            }
          }
          status
          startTime
          venue {
            id
          }
        }
      }
    }
  }
`;
