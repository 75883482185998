import React, { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import { LoadingOutlined } from '@ant-design/icons';
import { compose, pathOr } from 'ramda';
import { GET_STADIUMS } from '../graphql';
import connect from '../../Tickets/containers/connect';
import MarkupSideMenu from './MarkupSideMenu';
import { useTranslation } from 'react-i18next';

const StadiumMarkupComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    isLoaded: false,
  });
  const screenWidth = document.body.offsetWidth;
  const initialZoom = screenWidth <= 768 ? 2 : 3;
  const defaultPlacesColor = '#CAE6FF';
  const setSelectedPlaces = (data) => {
    props.dispatch(props.setSelectedPlaces(data));
  };
  const setLoading = () => {
    setState({
      ...stateProps,
      isLoaded: false,
    });
  };

  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_STADIUMS,
      });
      setSelectedPlaces([]);
      const stadium = pathOr([], ['data', 'venueLocalised', 'getList', 'list'], res)[0];
      const placesList = pathOr([], ['places', 'list'], stadium);
      const publicLink = pathOr('', ['map', 'publicLink'], stadium);
      const mapSize = pathOr('', ['size'], stadium);
      const id = pathOr('', ['id'], stadium);

      setState({
        ...stateProps,
        placesList,
        publicLink,
        mapSize,
        stadiumid: id,
        isLoaded: true,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return (
    <>
      <h1>{t('modules.stadium_markup_component.markup')}</h1>
      <div className="route-content">
        {!stateProps.isLoaded ? (
          <div className="with-preloader__map">
            <LoadingOutlined />
            <div className="with-preloader__info">
              <div>{t('modules.stadium_markup_component.loading_arena_layout')}</div>
            </div>
          </div>
        ) : (
          <>
            <MapComponent
              containerId={'map'}
              placesList={stateProps.placesList}
              overlayUrl={stateProps.publicLink}
              overlaySize={stateProps.mapSize}
              maxSelection={999}
              placeRadius={Number(window.parametrize('REACT_APP_MAP_PLACE_RADIUS'))}
              weight={Number(window.parametrize('REACT_APP_MAP_PLACE_WEIGHT'))}
              initialZoom={initialZoom}
              minZoom={initialZoom}
              maxZoom={Number(window.parametrize('REACT_APP_MAP_MAX_ZOOM'))}
              canvasTolerance={5}
              clickableFromZoom={5}
              defaultPlacesColor={defaultPlacesColor}
              remoteHandler={() => {}}
              setSelectedPlaces={(data) => {
                setSelectedPlaces(data);
              }}
              eventHandler={(ev) => {
                if (ev.type === 'mapInit') {
                  props.dispatch(props.setPlaces(ev.data.places));
                }
                if (ev.type === 'placeRemove') {
                  props.dispatch(props.removePlace(ev.data.id));
                }
                if (ev.type === 'placeSelect') {
                  props.dispatch(props.addTicket(ev.data));
                }
              }}
            />

            <MarkupSideMenu
              client={props.client}
              mapPlaces={props.mapPlaces}
              defaultPlacesColor={defaultPlacesColor}
              weight={Number(window.parametrize('REACT_APP_MAP_PLACE_WEIGHT'))}
              setSelectedPlaces={(data) => {
                setSelectedPlaces(data);
              }}
              selectedPlaces={props.selectedPlaces}
              stadiumid={stateProps.stadiumid}
              setLoading={() => {
                setLoading();
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default compose(connect)(StadiumMarkupComponent);
