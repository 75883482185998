import gql from "graphql-tag";

export const GET_TYPE_CATEGORY_TEMPLATES = gql`
query {
    priceCategoryTemplate {
            getList {
                list {
                    id
                    title
                    priceCategories {
                        id
                        title
                        color
                    }
                }
            }
        }
    }
`;

export const GET_TEMPLATES = gql`
query {
  priceTemplate {
    getList {
      list {
        id
        title
        priceCategoryTemplate {
          id
          title
          priceCategories {
            id
            title
            color
          }
        }
        prices {
          value
          priceCategory {
            id
          }
        }
      }
    }
  }
}
`;