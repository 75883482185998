import { connect } from "react-redux";
import * as actions from "../actions";



const mapStateToProps = state => {
    const categoryPrice = () => state.reducer.categoryPrice;
    return ({
    categoryTemplate: categoryPrice().categoryTemplate,
    priceTemplateTitle: categoryPrice().priceTemplateTitle,
    pricesOfTemplate: categoryPrice().pricesOfTemplate,
    typeOfButton: categoryPrice().typeOfButton,
    template: categoryPrice().template
    })
}

const mapDispatchToProps = {...actions}

export default connect(mapStateToProps, mapDispatchToProps);