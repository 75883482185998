import ar_EG from 'antd/locale/ar_EG';
import az_AZ from 'antd/locale/az_AZ';
import bg_BG from 'antd/locale/bg_BG';
import bn_BD from 'antd/locale/bn_BD';
import by_BY from 'antd/locale/by_BY';
import ca_ES from 'antd/locale/ca_ES';
import cs_CZ from 'antd/locale/cs_CZ';
import da_DK from 'antd/locale/da_DK';
import de_DE from 'antd/locale/de_DE';
import el_GR from 'antd/locale/el_GR';
import en_GB from 'antd/locale/en_GB';
import en from 'antd/locale/en_US';
import es_ES from 'antd/locale/es_ES';
import et_EE from 'antd/locale/et_EE';
import eu_ES from 'antd/locale/eu_ES';
import fa_IR from 'antd/locale/fa_IR';
import fi_FI from 'antd/locale/fi_FI';
import fr_BE from 'antd/locale/fr_BE';
import fr_CA from 'antd/locale/fr_CA';
import fr_FR from 'antd/locale/fr_FR';
import ga_IE from 'antd/locale/ga_IE';
import gl_ES from 'antd/locale/gl_ES';
import he_IL from 'antd/locale/he_IL';
import hi_IN from 'antd/locale/hi_IN';
import hr_HR from 'antd/locale/hr_HR';
import hu_HU from 'antd/locale/hu_HU';
import hy_AM from 'antd/locale/hy_AM';
import id_ID from 'antd/locale/id_ID';
import is_IS from 'antd/locale/is_IS';
import it_IT from 'antd/locale/it_IT';
import ja_JP from 'antd/locale/ja_JP';
import ka_GE from 'antd/locale/ka_GE';
import kk_KZ from 'antd/locale/kk_KZ';
import km_KH from 'antd/locale/km_KH';
import kmr_IQ from 'antd/locale/kmr_IQ';
import kn_IN from 'antd/locale/kn_IN';
import ko_KR from 'antd/locale/ko_KR';
import ku_IQ from 'antd/locale/ku_IQ';
import lt_LT from 'antd/locale/lt_LT';
import lv_LV from 'antd/locale/lv_LV';
import mk_MK from 'antd/locale/mk_MK';
import ml_IN from 'antd/locale/ml_IN';
import mn_MN from 'antd/locale/mn_MN';
import ms_MY from 'antd/locale/ms_MY';
import my_MM from 'antd/locale/my_MM';
import nb_NO from 'antd/locale/nb_NO';
import ne_NP from 'antd/locale/ne_NP';
import nl_BE from 'antd/locale/nl_BE';
import nl_NL from 'antd/locale/nl_NL';
import pl_PL from 'antd/locale/pl_PL';
import pt_BR from 'antd/locale/pt_BR';
import pt_PT from 'antd/locale/pt_PT';
import ro_RO from 'antd/locale/ro_RO';
import ru from 'antd/locale/ru_RU';
import si_LK from 'antd/locale/si_LK';
import sk_SK from 'antd/locale/sk_SK';
import sl_SI from 'antd/locale/sl_SI';
import sr_RS from 'antd/locale/sr_RS';
import sv_SE from 'antd/locale/sv_SE';
import ta_IN from 'antd/locale/ta_IN';
import th_TH from 'antd/locale/th_TH';
import tk_TK from 'antd/locale/tk_TK';
import tr_TR from 'antd/locale/tr_TR';
import uk_UA from 'antd/locale/uk_UA';
import ur_PK from 'antd/locale/ur_PK';
import vi_VN from 'antd/locale/vi_VN';
import zh_CN from 'antd/locale/zh_CN';
import zh_HK from 'antd/locale/zh_HK';
import zh_TW from 'antd/locale/zh_TW';

import i18n from 'i18n';
import { determiningCurrentDayjsLocale } from './dayjsLocalizationHelpers';

const listAntdLanguages: any = {
  ar_EG,
  az_AZ,
  bg_BG,
  bn_BD,
  by_BY,
  ca_ES,
  cs_CZ,
  da_DK,
  de_DE,
  el_GR,
  en_GB,
  en,
  es_ES,
  et_EE,
  eu_ES,
  fa_IR,
  fi_FI,
  fr_BE,
  fr_CA,
  fr_FR,
  ga_IE,
  gl_ES,
  he_IL,
  hi_IN,
  hr_HR,
  hu_HU,
  hy_AM,
  id_ID,
  is_IS,
  it_IT,
  ja_JP,
  ka_GE,
  kk_KZ,
  km_KH,
  kmr_IQ,
  kn_IN,
  ko_KR,
  ku_IQ,
  lt_LT,
  lv_LV,
  mk_MK,
  ml_IN,
  mn_MN,
  ms_MY,
  my_MM,
  nb_NO,
  ne_NP,
  nl_BE,
  nl_NL,
  pl_PL,
  pt_BR,
  pt_PT,
  ro_RO,
  ru,
  si_LK,
  sk_SK,
  sl_SI,
  sr_RS,
  sv_SE,
  ta_IN,
  th_TH,
  tk_TK,
  tr_TR,
  uk_UA,
  ur_PK,
  vi_VN,
  zh_CN,
  zh_HK,
  zh_TW,
};

export const determiningCurrentLocale = () => {
  let res;
  for (let key in listAntdLanguages) {
    if (key === i18n.language) {
      res = listAntdLanguages[key];
      break;
    } else {
      res = en;
    }
  }
  determiningCurrentDayjsLocale();
  return res;
};
