import {
  ACC_AccessCategory,
  ACC_AccessCategoryField,
  ACC_Field_Type,
  ACC_RequestType,
  Accreditation,
  AccreditationDescriptor,
  AccreditationRejectionReason,
  AccreditationRequestStatus,
  AdditionalFields,
  Match,
  RemoteFile,
  RemotePerson,
  RemoteUser,
  Season,
  Team,
  Tournament,
  User
} from './accreditationModel';
import {ObjectDeserializator} from '../../../../utils/apiHelpers';
import {Contact, ContactType} from '../../../../utils/commonTypes';
import {enumFromString} from '../../../../utils/typeHelpers';
import {LocalizedString} from 'utils/LocalizedString';

export const accreditationDeserializer = (a: ObjectDeserializator): Accreditation => {
  return {
    id: a.required('id').asString,
    visibleId: a.required('visibleId').asString,
    accreditedUser: a.required('accreditedUser').asObject((u) => userDeserializer(u)),
    requestStatus: enumFromString(AccreditationRequestStatus, a.required('requestStatus').asString),
    accessCategory: a.required('accessCategory').asObject((c) => accessCategoryDeserializer(c)),
    requestType: enumFromString(ACC_RequestType, a.required('requestType').asString),
    createdAt: a.required('createdAt').asDate,
    seasons: a.required('seasons').asArrayOfObjects((o) => seasonsDeserializer(o)),
    tournaments: a.required('tournaments').asArrayOfObjects((o) => tournamentsDeserializer(o)),
    matches: a.required('matches').asArrayOfObjects((o) => matchesDeserializer(o))
  };
};

export const userDeserializer = (u: ObjectDeserializator): User => {
  return {
    user: u.optional('user')?.asObject((o) => remoteUserDeserializer(o)),
    createdAt: u.required('createdAt').asDate,
    updatedAt: u.required('updatedAt').asDate
  };
};

const remoteUserDeserializer = (r: ObjectDeserializator): RemoteUser => {
  return {
    id: r.required('id').asString,
    login: r.required('login').asString,
    visibleId: r.required('visibleId').asString,
    person: r.required('person').asObject((o) => personDeserializer(o))
  };
};

const personDeserializer = (p: ObjectDeserializator): RemotePerson => {
  return {
    name: p.required('name').as((o) => LocalizedString.fromObject(o)),
    surname: p.required('surname').as((o) => LocalizedString.fromObject(o)),
    patronymic: p.optional('patronymic')?.as((o) => LocalizedString.fromObject(o)),
    photo: p.optional('photo')?.asObject((o) => fileDeserializer(o)),
    contacts: p.required('contacts').asArrayOfObjects((o) => contactsDeserializer(o))
  };
};

export const fileDeserializer = (o: ObjectDeserializator): RemoteFile => {
  return {
    id: o.required('id').asString,
    publicLink: o.required('publicLink').asString,
    fileName: o.optional('fileName')?.asString
  };
};

const contactsDeserializer = (c: ObjectDeserializator): Contact => {
  return {
    type: c.required('type').as((o) => enumFromString(ContactType, o)),
    value: c.required('value').asString
  };
};

export const accessCategoryDeserializer = (c: ObjectDeserializator): ACC_AccessCategory => {
  return {
    id: c.required('id').asString,
    title: c.required('title').as((o) => LocalizedString.fromObject(o)),
    fields: c.optional('fields')?.asArrayOfObjects((o) => accessCategoryFieldDeserializer(o))
  };
};

export const rejectionReasonsDeserializer = (r: ObjectDeserializator): AccreditationRejectionReason => {
  return {
    id: r.required('id').asString,
    title: r.required('title').as((o) => LocalizedString.fromObject(o)),
    description: r.required('description').as((o) => LocalizedString.fromObject(o))
  };
};

export const tournamentsDeserializer = (o: ObjectDeserializator): Tournament => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString
  };
};

export function seasonsDeserializer(o: ObjectDeserializator): Season {
  return {
    id: o.required('id').asString,
    startDate: o.required('startDate').asDate,
    endDate: o.required('endDate').asDate
  };
}

export function matchesDeserializer(o: ObjectDeserializator): Match {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    endTime: o.optional('endTime')?.asDate,
    startTime: o.optional('startTime')?.asDate,
    season: o.optional('season')?.asObject((o) => seasonsDeserializer(o)),
    team1: o.required('team1').asObject((o) => teamDeserializer(o)),
    team2: o.required('team2').asObject((o) => teamDeserializer(o)),
    cover: o.optional('cover')?.asObject((o) => remoteFileDeserialize(o))
  };
}

export function remoteFileDeserialize(o: ObjectDeserializator): RemoteFile {
  return {
    id: o.required('id').asString,
    publicLink: o.required('publicLink').asString,
    fileName: o.optional('fileName')?.asString
  };
}

export function teamDeserializer(o: ObjectDeserializator): Team {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    location: o.optional('location')?.asString,
    logo: o.optional('logo')?.asObject((o) => remoteFileDeserialize(o))
  };
}

export const additionalFieldsDeserializer = (o: ObjectDeserializator): AdditionalFields => {
  return {
    value: o.optional('value')?.asString,
    field: o.required('field').asObject((o) => ({
      id: o.required('id').asString,
      title: o.required('title').as((o) => LocalizedString.fromObject(o)),
      description: o.optional('description')?.as((o) => LocalizedString.fromObject(o)),
      type: enumFromString(ACC_Field_Type, o.required('type').asString),
      hint: o.required('hint').as((o) => LocalizedString.fromObject(o)),
      required: o.required('required').asBool,
      createdAt: o.optional('createdAt')?.asDate,
      appliedCategoryId: o.optional('appliedCategoryId')?.asString
    }))
  };
};

export const accessCategoryFieldDeserializer = (o: ObjectDeserializator): ACC_AccessCategoryField => {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((o) => LocalizedString.fromObject(o)),
    description: o.optional('description')?.as((o) => LocalizedString.fromObject(o)),
    hint: o.required('hint').as((o) => LocalizedString.fromObject(o)),
    required: o.required('required').asBool,
    type: enumFromString(ACC_Field_Type, o.required('type').asString),
    appliedCategoryId: o.optional('appliedCategoryId')?.asString
  };
};

export function deserializeAccreditationRequestDescriptor(o: ObjectDeserializator): AccreditationDescriptor {
  return {
    id: o.required('id').asString,
    requestTypes: o.required('requestTypes').asArray((r) => enumFromString(ACC_RequestType, r)),
    availableMatches: o.required('allIncludedMatches').asArrayOfObjects((o) => matchesDeserializer(o)),
    availableSeasons: o.required('availableSeasons').asArrayOfObjects((o) => seasonsDeserializer(o)),
    availableTournaments: o.required('availableTournaments').asArrayOfObjects((o) => tournamentsDeserializer(o)),
    availableAccessCategories: o
      .required('availableAccessCategories')
      .asArrayOfObjects((o) => accessCategoryDeserializer(o))
  };
}
