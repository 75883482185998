import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from './NavBar';
import Header from './Header';
import { Col, Container } from 'react-bootstrap';
import { withState } from 'recompose';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

const AdminPanel = (props) => {
  const { t } = useTranslation();
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2,
          colorPrimary: window.parametrize('REACT_APP_PRIMARY_COLOR'),
          colorBorder: window.parametrize('REACT_APP_PRIMARY_COLOR'),
          controlItemBgActiveHover: undefined,
          colorInfo: window.parametrize('REACT_APP_PRIMARY_COLOR'),
          colorErrorBorder: window.parametrize('REACT_APP_PRIMARY_COLOR'),
        },
      }}
    >
      <div className="App main-content">
        <Header collapsed={props.collapsed} setCollapsed={props.setCollapsed} authClient={props.authClient} />
        <Container className="clearfix df jcsb">
          <div className="aside" style={{ background: window.parametrize('REACT_APP_PRIMARY_COLOR') }}>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 0,
                },
              }}
            >
              <NavBar list={props.data.menuList} collapsed={props.collapsed} />
            </ConfigProvider>
            <div
              id={'assembly'}
              className="aside__assembly"
              style={{ background: window.parametrize('REACT_APP_PRIMARY_COLOR') }}
            >
              {t('modules.admin_panel')}: {process.env['REACT_APP_VERSION_TAG']}
            </div>
          </div>
          <Col sm={9} md={9} className="page-wrapper">
            <div className="page-content">
              <Switch>
                {props.admin.routes.length
                  ? props.admin.routes.map((route, index) => (
                      <Route key={index} exact path={route.path} component={route.component} />
                    ))
                  : null}
              </Switch>
            </div>
          </Col>
        </Container>
      </div>
    </ConfigProvider>
  );
};

export default withState('collapsed', 'setCollapsed', false)(AdminPanel);
