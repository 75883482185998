import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Button, Select, Input, message } from 'antd';
import { Query, Mutation } from 'react-apollo';
import ModalComponent from '../../../../../common/ModalComponent/ModalComponent';
import ModalHeader from '../../../../../common/ModalHeader/ModalHeader';
import ModalFooter from '../../../../../common/ModalFooter/ModalFooter';
import ModalContent from '../../../../../common/ModalContent/ModalContent';
import {
  GET_TYPE_CATEGORY_TEMPLATES,
  UPDATE_PRICE,
  SET_PRICE_CATEGORY_TEMPLATES,
  SET_NEW_CATEGORY_TEMPLATE,
  SET_NEW_TITLE_TEMPLATES,
} from '../graphql';

import { STYLE_BLOCK_COLOR_TITLE, STYLE_MODAL_BUTTONS } from '../styles/styles';

import '../styles/style.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default ({
  setState,
  priceTemplateTitle,
  pricesOfTemplate,
  categoryTemplate,
  typeOfButton,
  template,
  setTypeOfButton,
}) => {
  const { t } = useTranslation();
  message.config({
    maxCount: 1,
  });
  return (
    <>
      <div className="ReactModal__Overlay ReactModal__Overlay--after-open ac-modal-overlay">
        <div className="ReactModal__Content ReactModal__Content--after-open ReactModal__category-price--center">
          <Query query={GET_TYPE_CATEGORY_TEMPLATES}>
            {({ data, loading, error }) => {
              if (loading) {
                return <p>Loading...</p>;
              } else if (error) {
                return <p>Error :(</p>;
              } else {
                return (
                  <ModalComponent>
                    <Formik
                      initialValues={{
                        empty: false,
                        priceTemplateTitle: priceTemplateTitle,
                        categoryTemplate: categoryTemplate,
                        emptyText: [],
                        full: [],
                        newTitle: false,
                      }}
                    >
                      {({ values, setFieldValue }) => {
                        return (
                          <Form>
                            <ModalHeader
                              onClose={() => {
                                setState({ handleModalVisible: false });
                              }}
                            />
                            <ModalContent>
                              <div className="category-price__modal-form-content">
                                <Row>
                                  <Col span={24}>
                                    <div className="category-price__modal-title">
                                      <h2>{t('modules.category_price_modal.h2')}</h2>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={24}>
                                    <div className="category-price__modal-field-name flex flex-center">
                                      <label htmlFor="priceTemplateTitle">
                                        {t('modules.category_price_modal.label')}
                                      </label>
                                      <Field
                                        name="priceTemplateTitle"
                                        render={(fieldProps) => (
                                          <Input
                                            style={{ width: `90%` }}
                                            size="large"
                                            defaultValue={values.priceTemplateTitle}
                                            onChange={(e) => {
                                              setFieldValue('newTitle', true);
                                              setFieldValue('priceTemplateTitle', e.target.value);
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={24}>
                                    <div className="category-price__modal-field-template">
                                      <Field
                                        name="categoryTemplate"
                                        render={(fieldProps) => {
                                          return (
                                            <Select
                                              style={{ width: `90%` }}
                                              disabled={typeOfButton === 'update' ? true : false}
                                              size="large"
                                              {...fieldProps}
                                              defaultValue={
                                                values.categoryTemplate || t('modules.category_price_modal.select')
                                              }
                                              onChange={(value) => setFieldValue('categoryTemplate', value)}
                                            >
                                              {data.priceCategoryTemplate.getList.list.map((item) => (
                                                <Option key={item.id} id={item.id} value={item.id}>
                                                  {item.title}
                                                </Option>
                                              ))}
                                            </Select>
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <div className="category-price__modal-circles-prices flex flex-center">
                                    <Col style={STYLE_BLOCK_COLOR_TITLE} span={24}>
                                      {data.priceCategoryTemplate.getList.list.map((item) => {
                                        return item.id === values.categoryTemplate ? (
                                          <div key={item.id}>
                                            {item.priceCategories.map((price) => {
                                              return (
                                                <div
                                                  key={price.id}
                                                  className="category-price__modal-wrapper df jcsb aic"
                                                >
                                                  {item.id === values.categoryTemplate ? (
                                                    <>
                                                      <div className="category-price__modal-wrapper-titles df jcsb aic">
                                                        <div
                                                          style={{
                                                            backgroundColor: price.color,
                                                            width: `20px`,
                                                            height: `20px`,
                                                            borderRadius: `50%`,
                                                          }}
                                                        />
                                                        <p>{price.title}</p>
                                                      </div>
                                                      <div className="flex flex-center">
                                                        <p
                                                          style={{
                                                            marginRight: `20px`,
                                                            opacity: `0`,
                                                            display: `none`,
                                                          }}
                                                        >
                                                          {Math.trunc(
                                                            (
                                                              pricesOfTemplate.find((item) => item.id === price.id) ||
                                                              {}
                                                            ).value
                                                          ) || ''}
                                                        </p>
                                                        <Field
                                                          name={price.id}
                                                          render={(fieldProps) => {
                                                            return (
                                                              <input
                                                                className="category-price__modal-field-prices"
                                                                type="text"
                                                                defaultValue={
                                                                  Math.trunc(
                                                                    (
                                                                      pricesOfTemplate.find(
                                                                        (item) => item.id === price.id
                                                                      ) || {}
                                                                    ).value
                                                                  ) || ''
                                                                }
                                                                placeholder={t('modules.category_price_modal.input')}
                                                                onChange={(e) => {
                                                                  setFieldValue(price.id, e.target.value);
                                                                }}
                                                                onBlur={(e) => {
                                                                  if (
                                                                    e.target.value !==
                                                                    e.target.previousElementSibling.textContent
                                                                  ) {
                                                                    if (
                                                                      e.target.previousElementSibling.textContent
                                                                        .length < 1
                                                                    ) {
                                                                      setFieldValue('emptyText', [
                                                                        ...values.emptyText,
                                                                        {
                                                                          priceCategoryId: price.id,
                                                                          value: e.target.value,
                                                                        },
                                                                      ]);
                                                                    } else
                                                                      setFieldValue('full', [
                                                                        ...values.full,
                                                                        {
                                                                          priceCategoryId: price.id,
                                                                          value: e.target.value,
                                                                        },
                                                                      ]);
                                                                  }
                                                                }}
                                                              />
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </>
                                                  ) : null}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : null;
                                      })}
                                    </Col>
                                  </div>
                                </Row>
                              </div>
                              <ModalFooter>
                                <Row>
                                  <Col style={STYLE_MODAL_BUTTONS} span={24}>
                                    <Button
                                      id="CategoryPriceComponentsModalCancelButton"
                                      size="large"
                                      onClick={() => {
                                        setState({ handleModalVisible: false });
                                      }}
                                      style={{
                                        background: 'white',
                                        color: '#1d1d1d',
                                        borderColor: '#1d1d1d',
                                        marginRight: `30px`,
                                        width: `170px`,
                                        height: `40px`,
                                      }}
                                    >
                                      {t('modules.category_price_modal.button.cancel')}
                                    </Button>
                                    <Mutation mutation={SET_NEW_CATEGORY_TEMPLATE}>
                                      {(createTemplate) => (
                                        <Mutation mutation={UPDATE_PRICE}>
                                          {(updatePrice) => (
                                            <Mutation mutation={SET_PRICE_CATEGORY_TEMPLATES}>
                                              {(setNewPrice) => (
                                                <Mutation mutation={SET_NEW_TITLE_TEMPLATES}>
                                                  {(updateTitle) => (
                                                    <Button
                                                      id="CategoryPriceComponentsModalSaveButton"
                                                      size="large"
                                                      type="primary"
                                                      onClick={() => {
                                                        if (typeOfButton === 'create') {
                                                          let arr = [];
                                                          Object.keys(values)
                                                            .filter(
                                                              (item) =>
                                                                item !== 'categoryTemplate' &&
                                                                item !== 'empty' &&
                                                                item !== 'priceTemplateTitle' &&
                                                                item !== 'emptyText' &&
                                                                item !== 'full' &&
                                                                item !== 'newTitle'
                                                            )
                                                            .map((item) =>
                                                              arr.push({
                                                                value: values[item],
                                                                priceCategoryId: item,
                                                              })
                                                            );
                                                          (async function () {
                                                            let response = await createTemplate({
                                                              ignoreResults: false,
                                                              update: () => {
                                                                message.success(
                                                                  t(
                                                                    'modules.category_price_modal.message_success.template_added'
                                                                  )
                                                                );
                                                              },
                                                              variables: {
                                                                data: {
                                                                  title: values.priceTemplateTitle,
                                                                  priceCategoryTemplateId: values.categoryTemplate,
                                                                },
                                                              },
                                                            });
                                                            const data = await response.data;
                                                            setTimeout(() => {
                                                              if (arr.length > 0) {
                                                                setNewPrice({
                                                                  variables: {
                                                                    id: data.priceTemplate.add.id,
                                                                    prices: {
                                                                      list: arr,
                                                                    },
                                                                  },
                                                                });
                                                              }
                                                            }, 200);
                                                          })();
                                                        }
                                                        if (typeOfButton === 'update') {
                                                          if (values.full.length > 0) {
                                                            values.full.map((item) =>
                                                              updatePrice({
                                                                ignoreResults: false,
                                                                update: () => {
                                                                  message.success(
                                                                    t(
                                                                      'modules.category_price_modal.message_success.price_changed'
                                                                    )
                                                                  );
                                                                },
                                                                variables: {
                                                                  value: item.value,
                                                                  priceCategoryId: item.priceCategoryId,
                                                                  priceTemplateId: template,
                                                                },
                                                              })
                                                            );
                                                          }
                                                          if (values.emptyText.length > 0) {
                                                            setNewPrice({
                                                              update: () => {
                                                                message.success(
                                                                  t(
                                                                    'modules.category_price_modal.message_success.prices_changed'
                                                                  )
                                                                );
                                                              },
                                                              variables: {
                                                                id: template,
                                                                prices: {
                                                                  list: values.emptyText,
                                                                },
                                                              },
                                                            });
                                                          }
                                                          if (values.priceTemplateTitle && values.newTitle === true) {
                                                            updateTitle({
                                                              ignoreResults: false,
                                                              update: () => {
                                                                message.success(
                                                                  t(
                                                                    'modules.category_price_modal.message_success.title_changed'
                                                                  )
                                                                );
                                                              },
                                                              variables: {
                                                                id: template,
                                                                data: {
                                                                  title: values.priceTemplateTitle,
                                                                },
                                                              },
                                                            });
                                                          }
                                                        }
                                                        setState({
                                                          handleModalVisible: false,
                                                        });
                                                      }}
                                                      style={{
                                                        width: `170px`,
                                                        height: `40px`,
                                                      }}
                                                    >
                                                      {t('modules.category_price_modal.button.save')}
                                                    </Button>
                                                  )}
                                                </Mutation>
                                              )}
                                            </Mutation>
                                          )}
                                        </Mutation>
                                      )}
                                    </Mutation>
                                  </Col>
                                </Row>
                              </ModalFooter>
                            </ModalContent>
                          </Form>
                        );
                      }}
                    </Formik>
                  </ModalComponent>
                );
              }
            }}
          </Query>
        </div>
      </div>
    </>
  );
};
