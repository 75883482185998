import gql from 'graphql-tag';

export const PROMOCODE_DESCRIPTOR_FRAGMENT = gql `
  fragment PromocodeDescriptorFragment on PromocodeDescriptor {
    id
    title
    type
    period {
      start
      end
    }
    commentary
    discount {
      type
      value
    }
    maxUseCountsPerUser
    tournamentBound {
      season {
        id
        startDate
        endDate
      }
      tournaments {
        title
      }
    }
    stageBound {
      season {
        id
        startDate
        endDate
      }
      stages {
        title
      }
    }
    seasonBound {
      seasons {
        id
        startDate
        endDate
      }
    }
    sectorBound {
      sectorIds
    }
    rowBound {
      rowIds
    }
    priceBound {
      priceRange {
        start
        end
      }
    }
    orderItemTypeBound {
      types
    }
    orderItemBound {
      items {
        type
        descriptorIds
        items {
          __typename
          ... on Tickets_RemoteMatch {
            id
            title
          }
          ... on SeasonTicketDescriptor {
            id
            title
          }
        }
      }
    }
    codes {
      total
      list {
        value
        useCounter
        descriptor {
          maxUseCountsPerUser
        }
        couponUser {
          id
          login
          person {
            name
            surname
            patronymic
            contacts {
              type
              value
            }
          }
        }
      }
    }
  }
`;
