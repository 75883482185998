import React, { createRef, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { GET_CATEGORYS_DATA, GET_CATEGORYS_LIST, GET_ENTRANCES_DATA, GET_ZONES_LIST } from '../../graphql/query';
import { UPDATE_ACCESS_CATEGORY } from '../../graphql/mutation';
import { MESSAGE_TIME_DURATION } from '../../../../../constants.js';
import {
  accessCategoryDataDeserializer,
  accessCategoryListDeserializer,
  accessEntranceDeserializer,
  accessZoneDeserializer
} from '../../graphql/accessDeserializers';
import {
  AccessCategoryDataDescriptor,
  AccessEntranceDescriptor,
  AccessZoneDescriptor,
  CategoryMenuDescriptor,
  FormType,
  UpdateCategoryData
} from '../../graphql/accessZoneModel';
import { deserialize, fetchData, sendData, useDidMount } from 'utils/apiHelpers';
import { message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import Loader from '../../../../../common/Loader/Loader';
import EmptyPlaceholder from '../../../../../common/EmptyPlaceholder/EmptyPlaceholder';
import AccessMenu from '../AccessMenu/AccessMenu';
import AccessCategoriesForm from '../AccessCategoriesForm/AccessCategoriesForm';

import ModalCloseWindowWarning from '../ModalCloseWindowWarning/ModalCloseWindowWarning';
import './AccessCategories.scss';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';

// const { TabPane } = Tabs;

function AccessCategories(props: { client: ApolloClient<any> }) {
  const {t} = useTranslation();
  const [menuData, setMenuData] = useState<CategoryMenuDescriptor>({});
  const [categotyId, setCategoryId] = useState<string>('');
  const [data, setData] = useState<AccessCategoryDataDescriptor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>('empty');
  const [zonesList, setZonesList] = useState<AccessZoneDescriptor[]>([]);
  const [entrancesList, setEntrancesList] = useState<AccessEntranceDescriptor[]>([]);
  const [modalCloseWindowWarningData, setModalCloseWindowWarningData] = useState<{ id: string; visible: boolean }>({
    id: '',
    visible: false,
  });
  const [isChangedSelect, setIsChangedSelect] = useState<boolean>(false);
  const [mutationId, setMutationId] = useState<Array<string>>([]);

  const formRef = createRef<FormComponentProps>();

  //When switching the submenu, we change the data on the right side
  const handleDataChange = (id: string) => {
    setCategoryId(id);
    setFormType('update');
    setIsChangedSelect(false);
  };

  //Refresh data on page load
  useDidMount(() => {
    refreshMenuData();
    getZonesList();
    getEntrancesData();
  });

  useEffect(() => {
    refreshData(categotyId);
  }, [categotyId]);

  //Submission handler
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    formRef.current!.form.validateFieldsAndScroll((err, values) => {
      mutationId.forEach((id) => {
        const zonesIds: Array<string> = values[`zones${id}`].map((zone: { key: string }) => zone.key);
        const entranceIds: Array<string> = values[`entrances${id}`].map((entrance: { key: string }) => entrance.key);
        updateCategoryData(id, {
          title: values.title,
          description: '',
          entranceIds: entranceIds,
          zoneIds: zonesIds,
        });
      });
      message.success(t('modules.access_categories.update_category_data_message_success'), MESSAGE_TIME_DURATION);
    });
  };

  //Getting menu items from the server
  function refreshMenuData() {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_CATEGORYS_LIST,
        fetchPolicy: 'no-cache',
      }),
      setMenuData,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.acs.sync.getSourcesList').asArrayOfObjects((o) => accessCategoryListDeserializer(o))
        ).reduce(
          (menuItems, menuItem) => ({
            ...menuItems,
            [menuItem.id]: {
              title: menuItem.title,
            },
          }),
          {}
        )
    );
  }

  //Getting data by categories from the server
  function refreshData(id: string) {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_CATEGORYS_DATA,
        variables: { syncId: id },
        fetchPolicy: 'no-cache',
      }),
      setData,
      (res) =>
        deserialize(res, (c) =>
          c
            .required('data.acs.accessCategory.findAllBySyncId')
            .asArrayOfObjects((a) => accessCategoryDataDeserializer(a))
        )
    );
  }

  //Getting the list of Zones from the server
  async function getZonesList() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ZONES_LIST,
        fetchPolicy: 'no-cache',
      }),

      setZonesList,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.acs.accessZone.getList').asArrayOfObjects((o) => accessZoneDeserializer(o))
        )
    );
  }

  //Getting a list of inputs from the server
  async function getEntrancesData() {
    await fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ENTRANCES_DATA,
        fetchPolicy: 'no-cache',
      }),

      setEntrancesList,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.acs.entrance.getList').asArrayOfObjects((o) => accessEntranceDeserializer(o))
        )
    );
  }

  //Editing data Categories
  async function updateCategoryData(id: string, data: UpdateCategoryData) {
    await sendData(
      loading,
      setLoading,
      props.client.mutate({
        mutation: UPDATE_ACCESS_CATEGORY,
        variables: { id, data },
        fetchPolicy: 'no-cache',
      }),

      () => {
        refreshData(categotyId);
      }
    );
    setIsChangedSelect(false);
    // setIsVisibleModalSave(true);
  }
  return (
    <section className="AccessCategories">
      <h2 className="AccessCategories__title">{t('modules.access_categories.header')}</h2>

      <ModalCloseWindowWarning
        modalData={modalCloseWindowWarningData}
        setModalData={setModalCloseWindowWarningData}
        setFormType={setFormType}
        setId={setCategoryId}
      />
      <div className="AccessCategories__content">
        <div className="AccessCategories__left-container">
          <AccessMenu
            data={menuData}
            handleDataChange={handleDataChange}
            id={categotyId}
            isChangedInput={isChangedSelect}
            setModalCloseWindowWarningData={setModalCloseWindowWarningData}
          />
        </div>

        <div className="AccessCategories__right-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {formType !== 'empty' ? (
                <div className="AccessCategories__right-container-content">
                  <div className="AccessCategories__right-container-content-block">
                    <h3 className="AccessCategories__right-container-title">
                      {menuData[categotyId] && menuData[categotyId].title.toString(i18next.language)}
                    </h3>
                    <CloseOutlined
                      className="AccessCategories__right-container__body__close-form-icon"
                      onClick={() => {
                        setFormType('empty');
                      }}
                    />
                  </div>
                  <AccessCategoriesForm
                    data={data}
                    zonesList={zonesList}
                    entrancesList={entrancesList}
                    setIsChangedSelect={setIsChangedSelect}
                    formType={formType}
                    setMutationId={setMutationId}
                    mutationId={mutationId}
                    wrappedComponentRef={formRef}
                  />

                  <footer className="AccessCategories__footer">
                    <button
                      id="AccessCategoriesButton"
                      className="AccessCategories__button"
                      disabled={!isChangedSelect}
                      onClick={handleSubmit}
                    >
                      {t('modules.access_categories.button')}
                    </button>
                  </footer>
                </div>
              ) : (
                <EmptyPlaceholder
                  title={t('modules.access_categories.empty_placeholder.title')!}
                  text={t('modules.access_categories.empty_placeholder.text')!}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default withApollo(AccessCategories);
