import * as types from '../types';
import { v4 as uuidv4 } from 'uuid'

export const addMenuItem = obj => ({
  type: types.ADD_MENU_ITEM,
  menuItem: {
    ...obj,
    id: uuidv4()
  }
});

export const updateMenuItem = (id, data) => ({
  type: types.UPDATE_MENU_ITEM,
  id,
  data
});
