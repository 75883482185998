import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Field = Form.Item;

const UserUpdateForm = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Form onSubmit={props.handleSubmit}>
        <Field label={t('modules.user_update_form.surname')}>
          <Input value={props.values.surname} onChange={(e) => props.setFieldValue('surname', e.target.value)} />
        </Field>
        <Field label={t('modules.user_update_form.name')}>
          <Input value={props.values.name} onChange={(e) => props.setFieldValue('name', e.target.value)} />
        </Field>
        <Field label={t('modules.user_update_form.patronymic')}>
          <Input value={props.values.patronymic} onChange={(e) => props.setFieldValue('patronymic', e.target.value)} />
        </Field>
        <Field label="E-mail">
          <Input value={props.values.email} onChange={(e) => props.setFieldValue('email', e.target.value)} />
        </Field>
        <Field label={t('modules.user_update_form.phone')}>
          <Input value={props.values.phone} onChange={(e) => props.setFieldValue('phone', e.target.value)} />
        </Field>
        <Field label={t('modules.user_update_form.date_birth')}>
          <DatePicker
            defaultValue={moment(props.values.birthday, props.dateFormat)}
            onChange={(e) => props.setFieldValue('birthday', e)}
            format={props.dateFormat}
          />
        </Field>
        <div className="user-update__footer">
          <Button id="UserUpdateFormCancelButton" onClick={props.onCancel}>
            {t('modules.user_update_form.cancel')}
          </Button>
          <Button
            id="UserUpdateFormSaveButton"
            type="primary"
            htmlType="submit"
            disabled={props.isSubmitButtonDisabled}
          >
            {props.isSubmitButtonDisabled ? <LoadingOutlined /> : t('modules.user_update_form.save')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UserUpdateForm;
