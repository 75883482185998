import Component from './components';
import withFormik from './containers/withFormik';
import withProps from './containers/withProps';
import withHandlers from './containers/withHandlers';
import withStateHandlers from './containers/withStateHandlers';
import graphql from './containers/graphql';

import { withApollo } from 'react-apollo';

import { compose } from 'ramda';

export default compose(
  withApollo,
  withProps,
  graphql,
  withHandlers,
  withStateHandlers,
  withFormik
)(Component);
