import React, {useEffect, useState} from 'react';
import MDEditor from '@uiw/react-md-editor';
import styles from './TextEditor.module.scss';
import {useTranslation} from 'react-i18next';

type TextEditorProps = {
  initialValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const maxLength = 1000;

const TextEditor = (props: TextEditorProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.initialValue || '');
  const [rowsCount, setRowsCount] = useState(value.split('\n').length + 1);

  useEffect(() => {
    setRowsCount(value.split('\n').length + 1);
  }, [value]);

  return (
    <>
      <div className={styles.texteditor}>
        <MDEditor
          className={styles.texteditor__content}
          id={'text-editor'}
          value={value}
          onChange={(value) => {
            if (value !== undefined) {
              props.onChange(value);
              setValue(value);
            }
          }}
          textareaProps={{
            placeholder: t('common.text_editor_component.mdeditor.placeholder') || '',
            maxLength: maxLength,
            spellCheck: true,
            rows: rowsCount,
          }}
          preview={'edit'}
          autoFocus={false}
          minHeight={100}
          visiableDragbar={false}
          placeholder={props.placeholder}
        />

        <span className={styles.texteditor__counter}>
          <i className={styles.texteditor__counter_value}>
            {t('common.text_editor_component.mdeditor.text')} {maxLength - value.length}
          </i>
        </span>
      </div>
    </>
  );
};

export default TextEditor;
