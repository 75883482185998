import React from 'react';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Col, Input, Row} from 'antd';
import PhoneInput from './PhoneInput/PhoneInput';
import {Contact, ContactType} from '../../../../../../../../utils/commonTypes';
import {contactType} from '../../../../../../../../utils/helpers';
import {useTranslation} from 'react-i18next';
import styles from '../../EditForm.module.scss';

export default function Contacts(props: {
  getFieldDecorator: any;
  contacts?: Contact[];
  setContacts: (value: string, type: ContactType) => void;
}) {
  const {t} = useTranslation();
  return (
    <>
      <Form.Item>
        <Row>
          <Col className={styles.name} sm={5} xs={24}>
            {t('modules.contacts.form_item.phone')}
          </Col>
          <Col sm={12} xs={24}>
            {props.getFieldDecorator('phone', {
              initialValue: contactType(props.contacts, 'PHONE'),
              rules: [
                {
                  required: true,
                  message: t('modules.contacts.get_field_decorator.phone'),
                  pattern: /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}/
                  // validateTrigger: 'onBlur',
                }
              ]
            })(
              <PhoneInput
                onChange={(e: any) => {
                  props.setContacts(e.target.value.match(/\d/g).join(''), ContactType.PHONE);
                }}
                defaultValue={contactType(props.contacts, 'PHONE')}
              />
            )}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Row>
          <Col className={styles.name} sm={5} xs={24}>
            {t('modules.contacts.form_item.email')}
          </Col>
          <Col sm={12} xs={24}>
            {props.getFieldDecorator('email', {
              initialValue: contactType(props.contacts, 'EMAIL'),
              rules: [{required: true, type: 'email', message: t('modules.contacts.get_field_decorator.email')}]
            })(
              <Input
                onChange={(e: any) => {
                  props.setContacts(e.target.value, ContactType.EMAIL);
                }}
                size="large"
              />
            )}
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}
