import React from 'react';
import Icon from 'react-icon-base';

const Cross = props => (
  <Icon viewBox="0 0 48 48" {...props}>
        <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="5.5">
            <path d="M0 0l48 48M0 48L48 0"/>
        </g>
  </Icon>
);

export default Cross;
