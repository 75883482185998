import { withApollo } from 'react-apollo';
import { compose } from 'recompose';

import PromoCodesModal from './components/promoCodesModal';
import connect from './containers/connect';

export default compose(
  withApollo,
  connect
)(PromoCodesModal);
