import { t } from 'i18next';

export const ERROR_MESSAGE_STYLE = { maxWidth: '300px', margin: '0 auto' };

export const ERROR_MESSAGE_CONTENT = {
  loadError: `${t('modules.constants.error_loading')}:`,
  createError: `${t('modules.constants.error_creating_coupon')}:`,
  updateError: `${t('modules.constants.error_updating_coupon')}:`,
  addUsersError: `${t('modules.constants.error_when_issuing_coupons')}:`,
};
