import React, { useState } from 'react';
import './Loyalty.scss';
import PromoPage from './components/PromoPage/PromoPage';
import ProgramSettings from './components/ProgramSettings/ProgramSettings';
import Loader from './components/Loader/Loader';

import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import AuthClient from "@sebbia/auth-client/lib/AuthClient";

import { GET_ACTIVE_STATUS } from './model/loyaltyQueries';
import { CHANGE_ACTIVE_STATUS } from './model/loyaltyMutations';
import { deserialize, fetchData, sendData, useDidMount } from "../../../../utils/apiHelpers";


function Loyalty(props: {
  authClient: AuthClient,
  client: ApolloClient<any>,
}) {
  const [isActive, setIsActive] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  function getActiveStatus() {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ACTIVE_STATUS,
        fetchPolicy: 'no-cache',
      }),
      setIsActive,
      (res: any) =>
        deserialize(res, (o) =>
          o.required('data.loyalty.loyaltyDescriptorQuery.getDescriptor.isActive').asBool
          )
        )
  };

  useDidMount(() => {
    getActiveStatus();
  });

  function handleChangeActiveStatus(data: object) {
    sendData(loading, setLoading, props.client.mutate({ mutation: CHANGE_ACTIVE_STATUS, variables: { data } }), () =>
      setLoading(false)
    );
  }

  const handleIsActive: () => void = () => {
    handleChangeActiveStatus({'isActive': !isActive});
    setIsActive(!isActive);
  };

  const Promocodes = () =>
    isActive ? <ProgramSettings
        apolloClientProps={ props.client }
        authClientProps={ props.authClient }
        activeProgramState={ handleIsActive }
      /> :
      <PromoPage activeProgramState={ handleIsActive } />

  return (
    <>
      { loading ? <Loader /> : <Promocodes /> }
    </>
  )
}

export default withApollo(Loyalty);