import React, { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Empty, message, Select, Switch } from 'antd';
import { compose, pathOr } from 'ramda';
import { CANCEL_PLACE_BLOCK, GET_PLACE_BLOCK_INFO, GET_STADIUMS_EXTRA_INFO } from '../graphql';
import connect from '../../Tickets/containers/connect';
import HistorySideMenu from './HistorySideMenu';
import isEmpty from 'ramda/es/isEmpty';
import HeaderAddButton from '../../Components/HeaderAddButton/HeaderAddButton';
import SideMenuContainer from '../../Components/SideMenuContainer/SideMenuContainer';
import ConfirmationModal from '../../Components/ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { LocalizedString } from 'utils/LocalizedString';
import i18next from 'i18next';

const SelectOption = Select.Option;

const StadiumHistoryComponent = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    isLoaded: false,
    mapLocked: false,
    blockedMenuActive: false,
  });
  const [curSector, setCurSector] = useState(null);
  const screenWidth = document.body.offsetWidth;
  const initialZoom = screenWidth <= 768 ? 2 : 3;
  const setSelectedPlaces = (data) => {
    props.dispatch(props.setSelectedPlaces(data));
  };
  const setLoading = () => {
    setState({
      ...stateProps,
      isLoaded: false,
    });
  };

  const onListClose = () => {
    setState({
      ...stateProps,
      blockedMenuActive: false,
      mapLocked: false,
      selectedBlock: undefined,
    });
  };

  const cancelPlacesBlock = async (blockItem) => {
    try {
      const filteredArr = stateProps.blockedStatusListArr
        .filter((item) => {
          return item.blockerItemId === blockItem.blockerItemId;
        })
        .filter((obj, pos, arr) => {
          return arr.map((mapObj) => mapObj['placeId']).indexOf(obj['placeId']) === pos;
        });

      await props.client.query({
        query: CANCEL_PLACE_BLOCK,
        variables: {
          data: filteredArr.map((item) => {
            return {
              blockerType: blockItem.blockerType,
              status: 'ACTIVE',
              placeId: item.placeId,
              venueId: blockItem.venueId,
              period: {
                start: item.period.start,
                end: item.period.end,
              },
            };
          }),
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.stadium_history_component.message_success'));
      onListClose();
    } catch (e) {
      message.error(t('modules.stadium_history_component.message_error'));
      console.error(e);
      onListClose();
    }
  };

  const getDateString = (time) => {
    const date = new Date(time);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    return (
      (day < 10 ? '0' + day : day) +
      '.' +
      (monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1) +
      '.' +
      date.getFullYear()
    );
  };

  const getBlockedList = async (onlyMultiple = false) => {
    const blockRes = await props.client.query({
      query: GET_PLACE_BLOCK_INFO,
      variables: {
        filter: {
          blockerTypes: ['ADMIN'],
          onlyMultipleBlocks: onlyMultiple,
        },
      },
      fetchPolicy: 'no-cache',
    });

    const multipleBlockList = pathOr([], ['data', 'placeStatus', 'getStatusList'], blockRes);

    let blockedStatusListArr = [];

    multipleBlockList.forEach((item) => {
      blockedStatusListArr = [...blockedStatusListArr, ...item.statusList];
    });

    const filteredBlockArr = blockedStatusListArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj['blockerItemId']).indexOf(obj['blockerItemId']) === pos;
    });

    setState((prevState) => {
      return {
        ...prevState,
        blockedStatusListArr,
        filteredBlockArr,
        mapLocked: !stateProps.mapLocked,
      }
    });
  };

  useEffect(() => {
    const init = async () => {
      setSelectedPlaces([]);
      setCurSector(null);
      const res = await props.client.query({
        query: GET_STADIUMS_EXTRA_INFO,
      });
      const stadium = pathOr([], ['data', 'venueLocalised', 'getList', 'list'], res)[0];
      const placesList = pathOr([], ['places', 'list'], stadium);
      const publicLink = pathOr('', ['map', 'publicLink'], stadium);
      const mapSize = pathOr('', ['size'], stadium);
      const id = pathOr('', ['id'], stadium);
      const sectorsArr = pathOr([], ['sectors', 'list'], stadium);

      setState({
        ...stateProps,
        placesList,
        publicLink,
        mapSize,
        sectorsArr,
        stadiumid: id,
        isLoaded: true,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    if (stateProps.isUpdated) {
      setState({
        ...stateProps,
        isUpdated: false,
      });
    }
    if (stateProps.sideMenuUpdated) {
      setState({
        ...stateProps,
        sideMenuUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated, stateProps.sideMenuUpdated]);

  return (
    <>
      <h1>{t('modules.stadium_history_component.h1')}</h1>
      <div className="route-content">
        {stateProps.isLoaded === false ? (
          <div className="with-preloader__map">
            <LoadingOutlined />
            <div className="with-preloader__info">
              <div>{t('modules.stadium_history_component.div.loading_arena_layout')}</div>
            </div>
          </div>
        ) : (
          <>
            <HeaderAddButton
              buttonText={t('modules.stadium_history_component.locks_list.button')}
              onClick={async () => {
                setState({
                  ...stateProps,
                  mapLocked: !stateProps.mapLocked,
                  blockedMenuActive: !stateProps.blockedMenuActive,
                  blockedStatusListArr: undefined,
                  filteredBlockArr: undefined,
                });
                await getBlockedList();
              }}
              disabled={stateProps.sideMenuActive || !isEmpty(props.selectedPlaces)}
            />


            {stateProps.blockedMenuActive ? (
              <SideMenuContainer
                headerTitle={t('modules.stadium_history_component.locks_list.header')}
                isLoaded={stateProps.filteredBlockArr}
                onClose={() => {
                  props.mapPlaces.forEach((place) => {
                    if (place.options.fillColor === '#000') {
                      place.setStyle({
                        fillColor: place.options.originalColor,
                        weight: 0,
                        isSelected: false,
                      });
                    }
                  });
                  onListClose();
                }}
              >
                {
                  stateProps.filteredBlockArr?.length ? (
                    <>
                      <div className="history__side-menu-switcher">
                        <Switch
                          onChange={async (ev) => {
                            await getBlockedList(ev)
                          }}
                        />
                        <div className="history__side-menu-switcher__text">
                          {t('modules.stadium_history_component.locks_list.switch')}
                        </div>
                      </div>
                      {stateProps.filteredBlockArr.map((block) => {
                        return (
                          <div className="history__block-item">
                            <div className="history__block-item__date-text">
                              {getDateString(block.period.start) + ' — ' + getDateString(block.period.end)}
                            </div>
                            {block.comment ? (
                              <>
                                <div className="history__block-item__commentary-title">
                                  {t('modules.stadium_history_component.div.comment')}
                              </div>
                              <div className="history__block-item__commentary-text">{block.comment}</div>
                            </>
                          ) : (
                            ''
                          )}
                          <div className="history__block-item__footer">
                            <Button
                              id="StadiumHistoryComponentUnlockButton"
                              className="history__block-item__footer__unblock-button"
                              onClick={() => {
                                setState({
                                  ...stateProps,
                                  selectedBlock: block,
                                });
                              }}
                            >
                              <span className="history__block-item__footer__unblock-button__text">
                                {t('modules.stadium_history_component.span.unlock')}
                              </span>
                            </Button>
                            <span
                              className="history__block-item__footer__show-places"
                              onClick={() => {
                                const filteredArr = stateProps.blockedStatusListArr.filter((item) => {
                                  return item.blockerItemId === block.blockerItemId;
                                });
                                props.mapPlaces.forEach((place) => {
                                  if (
                                    filteredArr.find((blockedPlace) => {
                                      return place.options.id === blockedPlace.placeId;
                                    })
                                  ) {
                                    place.setStyle({
                                      fillColor: '#000',
                                      weight: 0,
                                      isSelected: false,
                                    });
                                  } else if (place.options.fillColor === '#000') {
                                    place.setStyle({
                                      fillColor: place.options.originalColor,
                                      weight: 0,
                                      isSelected: false,
                                    });
                                  }
                                });
                              }}
                            >
                              {t('modules.stadium_history_component.span.show_places')}
                            </span>
                          </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <Empty
                      description={t('modules.stadium_history_component.locks_list.empty')}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                <ConfirmationModal
                  visible={stateProps.selectedBlock}
                  onClose={() => {
                    setState({
                      ...stateProps,
                      selectedBlock: undefined,
                    });
                  }}
                  infoText={t('modules.stadium_history_component.confirmation_modal.unlock_places')}
                  onConfirm={async () => {
                    await cancelPlacesBlock(stateProps.selectedBlock);
                  }}
                  buttonText={t('modules.stadium_history_component.confirmation_modal.unlock')}
                />
              </SideMenuContainer>
            ) : (
              ''
            )}
            <div className="history__header-container">
              {stateProps.multipleMode ? (
                <>
                  <Select
                    placeholder={t('modules.stadium_history_component.select')}
                    value={curSector}
                    className="history__header-container__select"
                    onChange={(ev) => {
                      const sector = stateProps.sectorsArr.find((item) => {
                        return item.id === ev;
                      });
                      setCurSector(LocalizedString.fromObject(sector.title).toString(i18next.language).toUpperCase());
                      const sectorPlaces = pathOr([], ['places', 'list'], sector);
                      const filteredPlaces = sectorPlaces.map((place) => {
                        return props.mapPlaces.find((mapPlace) => {
                          return mapPlace.options.id === place.id;
                        });
                      });

                      filteredPlaces.forEach((place) => {
                        place.setStyle({
                          color: place.options.originalColor,
                          fillColor: '#ffffff',
                          weight: props.mapWeight,
                          isSelected: true,
                        });
                      });

                      props.dispatch(props.addTicketArray(filteredPlaces));
                    }}
                  >
                    {stateProps.sectorsArr.map((item) => {
                      return (
                        <SelectOption value={item.id}>
                          {LocalizedString.fromObject(item.title).toString(i18next.language).toUpperCase()}
                        </SelectOption>
                      );
                    })}
                  </Select>

                  <Button
                    id="StadiumHistoryComponentMakeBulkBlockButton"
                    className="history__header-container__button"
                    disabled={!curSector && isEmpty(props.selectedPlaces)}
                    onClick={() => {
                      if (!isEmpty(props.selectedPlaces)) {
                        if (stateProps.sideMenuActive) {
                          setState({
                            ...stateProps,
                            sideMenuUpdated: true,
                          });
                        } else {
                          setState({
                            ...stateProps,
                            sideMenuActive: true,
                          });
                        }
                      } else {
                        message.warning(t('modules.stadium_history_component.message_warning'));
                      }
                    }}
                  >
                    <span className="history__header-container__button__text">
                      {t('modules.stadium_history_component.span.make_block')}
                    </span>
                  </Button>
                </>
              ) : (
                ''
              )}

              <div className="history__header-container__switcher-container">
                <div className="history__header-container__switcher-container__text">
                  {t('modules.stadium_history_component.div.blocking_mode')}
                </div>
                <Switch
                  onChange={(ev) => {
                    setSelectedPlaces([]);
                    setCurSector(null);
                    setState({
                      ...stateProps,
                      multipleMode: ev,
                      isUpdated: true,
                    });
                  }}
                />
              </div>
            </div>
            <MapComponent
              containerId={'map'}
              mapLocked={stateProps.mapLocked}
              placesList={stateProps.placesList}
              overlayUrl={stateProps.publicLink}
              overlaySize={stateProps.mapSize}
              maxSelection={999}
              placeRadius={Number(window.parametrize('REACT_APP_MAP_PLACE_RADIUS'))}
              weight={Number(window.parametrize('REACT_APP_MAP_PLACE_WEIGHT'))}
              initialZoom={initialZoom}
              minZoom={initialZoom}
              maxZoom={Number(window.parametrize('REACT_APP_MAP_MAX_ZOOM'))}
              canvasTolerance={5}
              clickableFromZoom={5}
              remoteHandler={() => {}}
              setSelectedPlaces={() => {
                // setSelectedPlaces(data);
              }}
              multipleMode={stateProps.multipleMode}
              eventHandler={(ev) => {
                if (ev.type === 'mapInit') {
                  props.dispatch(props.setPlaces(ev.data.places));
                }
                if (ev.type === 'placeRemove') {
                  props.dispatch(props.removePlace(ev.data.id));
                }
                if (ev.type === 'placeSelect') {
                  if (stateProps.multipleMode) {
                    props.dispatch(props.addTicket(ev.data));
                  } else {
                    setSelectedPlaces([]);
                    setSelectedPlaces([ev.data]);
                  }
                }
                if (ev.type === 'placeArraySelect') {
                  if (stateProps.multipleMode) {
                    props.dispatch(props.addTicketArray(ev.data));
                  }
                }
                if (ev.type === 'moveEnd') {
                  props.dispatch(props.setMapWeight(ev.data));
                }
              }}
            />
            {!stateProps.sideMenuUpdated && (
              <>
                {(stateProps.multipleMode ? stateProps.sideMenuActive : !isEmpty(props.selectedPlaces)) && (
                  <HistorySideMenu
                    header={
                      stateProps.multipleMode
                        ? t('modules.history_side_menu.header.blocking')
                        : t('modules.history_side_menu.header.history')
                    }
                    client={props.client}
                    mapPlaces={props.mapPlaces}
                    onClose={() => {
                      props.selectedPlaces.forEach((item) =>
                        item.setStyle({
                          color: '#CAE6FF',
                          fillColor: '#CAE6FF',
                          originalColor: '#CAE6FF',
                          weight: 1,
                          isSelected: false,
                        })
                      );
                      setSelectedPlaces([]);
                      setCurSector(null);
                      setState({
                        ...stateProps,
                        sideMenuActive: false,
                      });
                    }}
                    selectedPlaces={props.selectedPlaces}
                    stadiumid={stateProps.stadiumid}
                    setLoading={() => {
                      setLoading();
                    }}
                    multipleMode={stateProps.multipleMode}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default compose(connect)(StadiumHistoryComponent);
